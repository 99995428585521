export const dayFormat = "DD-MM-YYYY";

export const closedDates = {
  // '10-04-2022': true, // Palmesøndag
  "13-04-2025": true, // Palmesøndag
  "17-04-2025": true, // Skjærtorsdag
  "18-04-2025": true, // Langfredag
  "20-04-2025": true, // 1. påskedag
  "21-04-2025": true, // 2. påskedag
  "29-05-2025": true, // Kristi Himmelfartsdag
  "08-06-2025": true, // 1. pinsedag
  "09-06-2025": true, // 2. pinsedag
};

export const dayFees = {
  "20-04-2019": 200,
};
