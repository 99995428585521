import React from "react";
import styled from "styled-components/macro";
import Breadcrumb from "./Breadcrumb";
import { FaHome } from "../../../../../components/Icons";

const IconHome = styled.div`
  font-size: 16px;
  position: relative;
  top: 2px;
`;

/* HOME & DIVERSE */
export const HomeBC = (
  <Breadcrumb link="/" title="Forside">
    <IconHome>
      <FaHome />
    </IconHome>
  </Breadcrumb>
);

/* FADØL */
export const FadoelBC = (
  <Breadcrumb link="/fadoel" title="Fadøl">
    Fadøl
  </Breadcrumb>
);
export const FadoelPaaskebrygBC = (
  <Breadcrumb link="/fadoel/paaskebryg" title="Påaskebryg">
    Påskebryg
  </Breadcrumb>
);
export const FadoelPilsnerBC = (
  <Breadcrumb link="/fadoel/pilsner" title="Pilsner">
    Pilsner
  </Breadcrumb>
);
export const FadoelClassicBC = (
  <Breadcrumb link="/fadoel/classic" title="Classic">
    Classic
  </Breadcrumb>
);
export const FadoelIpaBC = (
  <Breadcrumb link="/fadoel/ipa" title="IPA">
    IPA
  </Breadcrumb>
);
export const FadoelAleBC = (
  <Breadcrumb link="/fadoel/ale" title="Ale">
    Ale
  </Breadcrumb>
);
export const FadoelDarkAleBC = (
  <Breadcrumb link="/fadoel/ale/moerk-ale" title="Mørk Ale">
    Mørk Ale
  </Breadcrumb>
);
export const FadoelBrownAleBC = (
  <Breadcrumb link="/fadoel/ale/brown-ale" title="Brown Ale">
    Brown Ale
  </Breadcrumb>
);
export const FadoelLysAleBC = (
  <Breadcrumb link="/fadoel/ale/lys-ale" title="Lys Ale">
    Lys Ale
  </Breadcrumb>
);
export const FadoelPorterBC = (
  <Breadcrumb link="/fadoel/porter" title="Porter / Stout">
    Porter / Stout
  </Breadcrumb>
);
export const FadoelLetoelBC = (
  <Breadcrumb link="/fadoel/letoel" title="Letøl">
    Letøl
  </Breadcrumb>
);
export const FadoelHvedeBC = (
  <Breadcrumb link="/fadoel/hvedeoel" title="Hvedeøl">
    Hvedeøl
  </Breadcrumb>
);
export const FadoelJuleoelBC = (
  <Breadcrumb link="/fadoel/juleoel" title="Juleøl">
    Juleøl
  </Breadcrumb>
);
export const FadoelModular20BC = (
  <Breadcrumb link="/fadoel/modular-20" title="Modular 20">
    Modular 20
  </Breadcrumb>
);
export const FadoelFlex20BC = (
  <Breadcrumb link="/fadoel/flex-20" title="Flex 20">
    Flex 20
  </Breadcrumb>
);
export const FadoelCocktailsCiderVinBC = (
  <Breadcrumb
    link="/fadoel/cocktails-vin-cider"
    title="Cocktails, vin &amp; cider"
  >
    Cocktails, vin &amp; cider
  </Breadcrumb>
);

export const DanskeFadoelBC = (
  <Breadcrumb link="/fadoel/dansk-fadoel" title="Danske Fadøl">
    Danske Fadøl
  </Breadcrumb>
);
export const UdenlandskeFadoelBC = (
  <Breadcrumb
    link="/fadoel/udenlandske-oel-paa-fad"
    title="Udenlandske øl på fad"
  >
    Udenlandske øl på fad
  </Breadcrumb>
);
export const HerslevBC = (
  <Breadcrumb link="/fadoel/herslev-bryghus" title="Herslev Fadøl">
    Herslev Fadøl
  </Breadcrumb>
);
export const FaqBC = (
  <Breadcrumb link="/faq/faq" title="FAQ">
    FAQ
  </Breadcrumb>
);
export const FlaskerOgDaaserBC = (
  <Breadcrumb
    link="/leverandoer-sodavand-kildevand-oel"
    title="Flasker &amp; Dåser"
  >
    Flasker &amp; Dåser
  </Breadcrumb>
);
export const VideoerBC = (
  <Breadcrumb link="/videoer" title="Videoer">
    Videoer
  </Breadcrumb>
);
export const FadoelAnlaegBC = (
  <Breadcrumb link="/fadoel-til-fest" title="Fadøl &amp; anlæg">
    Fadøl &amp; anlæg
  </Breadcrumb>
);

/* FADØLSANLÆG */
export const FadoelsanlaegBC = (
  <Breadcrumb link="/fadoelsanlaeg" title="Fadølsanlæg">
    Fadølsanlæg
  </Breadcrumb>
);
export const FadoelsBarBC = (
  <Breadcrumb link="/fadoelsbar" title="Fadølsbar">
    Bar til fadølsanlæg
  </Breadcrumb>
);
export const FadoelsGlasBC = (
  <Breadcrumb link="/oelglas" title="Fadølsglas">
    Fadølsglas
  </Breadcrumb>
);

/* RESERVEDELE */
export const ReservedeleBC = (
  <Breadcrumb link="/reservedele" title="Reservedele">
    Reservedele
  </Breadcrumb>
);
export const ReservedeleRensningBC = (
  <Breadcrumb
    link="/reservedele/rensning-fadoelsanlaeg"
    title="Rensning af fadølsanlæg"
  >
    Rensning af fadølsanlæg
  </Breadcrumb>
);
export const ReservedeleKulsyreTilkoblingBC = (
  <Breadcrumb link="/reservedele/kulsyre-tilkobling" title="Kulsyretilkobling">
    Kulsyretilkobling
  </Breadcrumb>
);
export const ReservedeleOelTilkoblingBC = (
  <Breadcrumb link="/reservedele/oel-tilkobling" title="Øltilkobling">
    Øltilkobling
  </Breadcrumb>
);
export const ReservedeleOelAftapningBC = (
  <Breadcrumb link="/reservedele/oel-aftapning" title="Ølaftapning">
    Ølaftapning
  </Breadcrumb>
);
export const ReservedeleKoeleanlaegBC = (
  <Breadcrumb link="/reservedele/koeleanlaeg" title="Køleanlæg">
    Køleanlæg
  </Breadcrumb>
);

/* ØL */
export const AlkoholFriOelBC = (
  <Breadcrumb link="/oel/alkoholfri-oel" title="Alkoholfri Øl">
    Alkoholfri Øl
  </Breadcrumb>
);
export const OelBC = (
  <Breadcrumb link="/oel" title="Køb øl online">
    Køb øl online
  </Breadcrumb>
);
export const OelDaaseOelBC = (
  <Breadcrumb link="/oel/daaseoel" title="Dåseøl">
    Dåseøl
  </Breadcrumb>
);
export const FlaskeOelBC = (
  <Breadcrumb link="/oel/flaskeoel" title="Flaskeøl">
    Flaskeøl
  </Breadcrumb>
);

/* ALKOHOLSODAVAND */
export const AlkoholSodavandBC = (
  <Breadcrumb link="/alkoholsodavand" title="Alkoholsodavand">
    Alkoholsodavand
  </Breadcrumb>
);

/* VIN */
export const VinBC = (
  <Breadcrumb link="/vin" title="Vin">
    Vin
  </Breadcrumb>
);

/* SODAVAND */
export const SodavandBC = (
  <Breadcrumb link="/sodavand" title="Sodavand">
    Sodavand
  </Breadcrumb>
);
export const SodavandCocaColaBC = (
  <Breadcrumb link="/sodavand/coca-cola" title="Coca-Cola">
    Coca-Cola
  </Breadcrumb>
);
export const SodavandFremBC = (
  <Breadcrumb link="/sodavand/frem-sodavand" title="Frem Sodavand">
    Frem Sodavand
  </Breadcrumb>
);
export const SodavandSanPellegrinoBC = (
  <Breadcrumb
    link="/sodavand/san-pellegrino-sodavand"
    title="San Pellegrino Sodavand"
  >
    San Pellegrino Sodavand
  </Breadcrumb>
);
export const SodavandSchweppesBC = (
  <Breadcrumb link="/sodavand/schweppes" title="Schweppes">
    Schweppes
  </Breadcrumb>
);
export const SodavandFaxeKondiBC = (
  <Breadcrumb link="/sodavand/faxe-kondi" title="Faxe Kondi">
    Faxe Kondi
  </Breadcrumb>
);
export const SodavandTuborgSquashBC = (
  <Breadcrumb link="/sodavand/tuborg-squash" title="Tuborg Squash">
    Tuborg Squash
  </Breadcrumb>
);
export const SodavandKinleyBC = (
  <Breadcrumb link="/sodavand/kinley" title="Kinley">
    Kinley
  </Breadcrumb>
);
export const SodavandPepsiColaBC = (
  <Breadcrumb link="/sodavand/pepsi-cola" title="Pepsi Cola">
    Pepsi Cola
  </Breadcrumb>
);

/* DANSKVAND */
export const DanskvandBC = (
  <Breadcrumb link="/danskvand" title="Danskvand">
    Danskvand
  </Breadcrumb>
);

/* KILDEVAND */
export const KildevandBC = (
  <Breadcrumb link="/kildevand" title="Kildevand">
    Kildevand
  </Breadcrumb>
);
export const KildevandAquadorBC = (
  <Breadcrumb link="/kildevand/aquador" title="Aqua d'or">
    Aqua d&apos;or
  </Breadcrumb>
);
export const KildevandDeniceBC = (
  <Breadcrumb link="/kildevand/denice-vand" title="Denice vand">
    Denice vand
  </Breadcrumb>
);
export const KildevandPallerBC = (
  <Breadcrumb link="/kildevand/vand-paller" title="Kildevand i paller">
    Kildevand i paller
  </Breadcrumb>
);
export const KildevandLogoBC = (
  <Breadcrumb link="/kildevand/vand-med-logo" title="Vand med Logo">
    Vand med Logo
  </Breadcrumb>
);
export const KildevandSanPellegrinoBC = (
  <Breadcrumb link="/kildevand/san-pellegrino-vand" title="San Pellegrino Vand">
    San Pellegrino Vand
  </Breadcrumb>
);
export const KildevandUdenPantBC = (
  <Breadcrumb link="/kildevand/vand-uden-pant" title="Vand uden pant">
    Vand uden pant
  </Breadcrumb>
);
export const KildevandVitaminWellBC = (
  <Breadcrumb link="/kildevand/vitamin-well" title="Vitamin Well">
    Vitamin Well
  </Breadcrumb>
);

/* COCIO */
export const CocioBC = (
  <Breadcrumb link="/cocio" title="Cocio">
    Cocio
  </Breadcrumb>
);

/* RED BULL */
export const RedBullBC = (
  <Breadcrumb link="/red-bull" title="Red Bull">
    Red Bull
  </Breadcrumb>
);

/* LÆSKEDRIKKE */
export const AntonsBC = (
  <Breadcrumb link="/antons-saft" title="Antons Saft">
    Antons Saft
  </Breadcrumb>
);
export const SoebogaardBC = (
  <Breadcrumb link="/soebogaard" title="Søbogaard">
    Søbogaard
  </Breadcrumb>
);
export const IceTeaBC = (
  <Breadcrumb link="/ice-tea" title="Ice Tea">
    Ice Tea
  </Breadcrumb>
);
export const JuiceBC = (
  <Breadcrumb link="/juice" title="Juice">
    Juice
  </Breadcrumb>
);

/* KULSYRE */
export const KulsyreBC = (
  <Breadcrumb link="/kulsyre" title="Kulsyre">
    Kulsyre
  </Breadcrumb>
);

/* LEVERING */
export const LeveringAfhentningBC = (
  <Breadcrumb link="/levering-afhentning" title="Levering &amp; Afhentning">
    Levering &amp; afhentning
  </Breadcrumb>
);
