import React from "react";
import loadable from "@loadable/component";
import Datoer from "../../../components/Datoer";
import {
  Bold,
  Heading3,
  Heading4,
  Paragraph,
  LinkStyled,
  List,
  Span,
  Spacer,
  Hr,
  Heading2,
  LinkButton,
} from "../../../components/typography";
import { Row, Col } from "../../../components/grid";
import {
  RecieptPackEntryView,
  ReturPantRecieptEntryView,
} from "../../../components/commerce/Bucket";
import {
  MomsChange,
  Money,
  Price,
  PriceSum,
} from "../../../containers/commerce";
import { Table, Td } from "../../../components/tables";
import HideAtDate from "../../../components/HideAtDate";
import IconBox from "../../../components/IconBox";
import TooltipModalLink from "../../../components/TooltipModal/TooltipModalLink";
import { ScreenSizeDetector } from "../../../lib/helpers";
import {
  HomeBC,
  SodavandBC,
  SodavandCocaColaBC,
  FadoelPilsnerBC,
  FadoelBC,
  FadoelClassicBC,
  FadoelIpaBC,
  FadoelAleBC,
  FadoelDarkAleBC,
  FadoelBrownAleBC,
  FadoelLysAleBC,
  FadoelPorterBC,
  FadoelLetoelBC,
  FadoelHvedeBC,
  FadoelJuleoelBC,
  FadoelModular20BC,
  FadoelFlex20BC,
  FadoelsanlaegBC,
  ReservedeleBC,
  ReservedeleRensningBC,
  ReservedeleKulsyreTilkoblingBC,
  ReservedeleOelTilkoblingBC,
  ReservedeleOelAftapningBC,
  ReservedeleKoeleanlaegBC,
  OelBC,
  OelDaaseOelBC,
  FlaskeOelBC,
  AlkoholFriOelBC,
  FadoelCocktailsCiderVinBC,
  AlkoholSodavandBC,
  VinBC,
  SodavandFremBC,
  SodavandSanPellegrinoBC,
  SodavandSchweppesBC,
  SodavandFaxeKondiBC,
  SodavandTuborgSquashBC,
  SodavandKinleyBC,
  SodavandPepsiColaBC,
  DanskvandBC,
  KildevandBC,
  KildevandAquadorBC,
  KildevandPallerBC,
  KildevandSanPellegrinoBC,
  KildevandVitaminWellBC,
  CocioBC,
  SoebogaardBC,
  IceTeaBC,
  JuiceBC,
  KildevandDeniceBC,
  KildevandUdenPantBC,
  RedBullBC,
  KulsyreBC,
  FadoelsBarBC,
  FadoelsGlasBC,
  AntonsBC,
  FadoelPaaskebrygBC,
} from "../../../layout/DefaultLayout/Header/components/Breadcrumbs/BreadcrumbsList";
import ItemExpired from "./ItemExpired";
import { Image, ImagePopup } from "../../../components/Image";
import Lejeperiode from "../../../components/Variables/Lejeperiode";
import { BigView } from "../../../components/commerce/Item/list-views";
import TheVideoPlayer from "../../../components/TheVideoPlayer";
import KulsyreMedfoelger from "../../../components/Variables/KulsyreMedfoelger";
import Sikrest10DageFoerText from "../../../components/Variables/Sikrest10DageFoerText";
import MonteringPaaEgetBordModal from "../../../components/Variables/MonteringPaaEgetBordModal";
import HancockText from "../../../components/Variables/HancockText";

export default [
  ...ItemExpired.map((v) => ({ ...v, expired: true })), // Expired items will not overwrite items in this file, remove the specific item from this file if you want it to be expired
  /* *
   *
   * IS
   *
   * */
  {
    varenr: 50025,
    name: "Isterninger 25kg cylinder inkl. leje af termoboks",
    keywords: "is ice isterninger is terning termotønde",
    generalSearchPriority: 1200,
    imageSingle: "diverse.ice-cylinder.jpg",
    imagePlural: "diverse.isboks-firkantet.png",
    usePluralImage: true,
    productTypeIs: true,
    invoiceDisplay: "SimpleItemRow",
    packageNoun: "tønde",
    singleNoun: "tønde",
    containerMaterial: "",
    recieptItemView: RecieptPackEntryView,
    categories: ["huskIs"],
    smallDescription: () => (
      <React.Fragment>
        <Span blue block fontSize="13px" paddingTop="5px">
          Isterningerne vejer ca. 20g pr. stk = 50 stk. pr. kg, svarende til ca.
          1250 isterninger i 25kg. Holbarheden på isterningerne er ca. 2 døgn i
          termoboksen. Der er yderligere <Price embPrice varenr="50025" /> pant
          på tønden, refunderes hvis tønden tilbageleveres i hel og ren
          tilstand.
        </Span>
      </React.Fragment>
    ),
  },
  {
    varenr: 50026,
    name: "Knust is 25kg inkl. leje af termoboks",
    keywords: "is ice isterninger is terning termotønde isterninger",
    generalSearchPriority: 1200,
    imageSingle: "diverse.ice-crushed.jpg",
    imagePlural: "diverse.isboks-firkantet.png",
    usePluralImage: true,
    productTypeIs: true,
    invoiceDisplay: "SimpleItemRow",
    packageNoun: "tønde",
    singleNoun: "tønde",
    containerMaterial: "",
    categories: ["huskIs"],
    recieptItemView: RecieptPackEntryView,
    smallDescription: () => (
      <React.Fragment>
        <Span blue block fontSize="13px" paddingTop="5px">
          Holbarheden på isterningerne er ca. 2 døgn i termoboksen. Der er
          yderligere <Price embPrice varenr="50025" /> pant på tønden,
          refunderes hvis tønden tilbageleveres i hel og ren tilstand.
        </Span>
      </React.Fragment>
    ),
  },
  /* *
   *
   * Fadøl - Pilsner
   *
   * */
  {
    varenr: 3615,
    name: "Grøn Tuborg",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl tuborg fustage dansk danske danmark 25l 25 l liter",
    imageSingle: "produkter-fadoel.groen-tuborg.png",
    imagePlural: "produkter-fadoel.carlsberg-fustage.png",
    fadoelsType: "Pilsner",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    related: [2615, 4615, 4815, 8215],
    invoiceDisplay: "FustageItemRow",
    categories: ["fadoel", "fadoelPilsner", "fadoelDansk"],
    url: "/fadoel/pilsner/groen-tuborg",
    routeDef: {
      path: "/fadoel/pilsner/groen-tuborg",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: "Grøn Tuborg",
      },
      meta: {
        title: "Grøn Tuborg - Danmarks første pilsner øl og mest solgte øl",
        description:
          "Grøn Tuborg er en undergæret øl, som brygges på pilsnermalt, der er fremstillet af vårbyg.  Det giver den dens karakteristiske lyse gule farve. Køb den her.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Hvem elsker ikke en Grøn Tuborg? Grøn Tuborg er Danmarks første
          pilsner øl. Det er den mest solgte øl i Danmark, og den bliver
          eksporteret til mange lande i hele verden. Grøn Tuborg er lys i
          farven. Smagen er blid, frisk og har den helt rette bitterhed.
        </Paragraph>
        <Paragraph>
          Grøn Tuborg brygges efter inspiration fra bryggere i Pilzen i
          Tjekkiet. Grøn Tuborg produceres i dag på Carlsbergs bryggeri i
          Fredericia.
        </Paragraph>
      </React.Fragment>
    ),
    productPageXtraDescription: () => (
      <React.Fragment>
        <Row>
          <Col xs={12} md={6}>
            <Heading3>Grøn Tuborg brygges udelukkende på pilsnermalt</Heading3>
            <Paragraph>
              Grøn Tuborg er en undergæret øl, som udelukkende brygges på
              pilsnermalt, der er fremstillet af vårbyg. Det giver den dens
              karakteristiske lyse gule farve. Smagen og aromaen domineres af
              Tuborgs særlige gær og humle.
            </Paragraph>
            <Paragraph>
              Humlen giver en let oplevelse af blomster i næsen. Grøn Tuborg har
              en let humlebitterhed og i eftersmagen opleves det gode bid fra
              humlen.
            </Paragraph>
          </Col>
          <Col xs={12} md={6}>
            <Heading3>Statistik</Heading3>
            <Paragraph>
              21 % af alle de fustager vi sælger er Grøn Tuborg.
            </Paragraph>
            <Paragraph>
              Næst mest solgte fadøl er Tuborg Classic med 15%
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        En undergæret øl som brygges på pilsnermalt, fremstillet af vårbyg,
        hvilket giver den lyse gule farve.
        <br />
        <br />
        Smag og aroma domineres af gær og humle.
      </React.Fragment>
    ),
  },
  {
    varenr: 4615,
    name: "Royal Pilsner",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark faxe 20l 20 l liter",
    imageSingle: "produkter-fadoel.royal-pilsner.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    fadoelsType: "Pilsner",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    // royalUnibrew: true,
    invoiceDisplay: "FustageItemRow",
    related: [3615, 4815, 2615, 8215],
    categories: ["fadoel", "fadoelPilsner", "fadoelDansk"],
    url: "/fadoel/pilsner/royal-pilsner",
    routeDef: {
      path: "/fadoel/pilsner/royal-pilsner",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: "Royal Pilsner",
      },
      meta: {
        title:
          "Royal Pilsner - God balance mellem bitter humle & aromatisk humle",
        description:
          "Royal Pilsner er brygget på den særlige Royal malt, der er karakteristisk for alle øl i Royal-serien. Royal Pilsner har en mild smag og let humlebitterhed.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    shortDescription: () => (
      <React.Fragment>Mild, let og afbalanceret.</React.Fragment>
    ),
    lavLiterpris: [3615, 4615, 25, "Grøn Tuborg"], // lav literpris
    popupDescription: () => (
      <React.Fragment>
        Brygget på den særlige Royal malt. Denne malt er mildere og lettere end
        pilsnermalt.
        <br />
        <br />
        Afbalanceret øl med en god balance mellem bitter humle og aromatisk
        humle.
      </React.Fragment>
    ),
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Brygget på den specielle Royal malt</Heading3>
        <Paragraph>
          Royal Pilsner er en del af Royal-serien, der også omfatter Royal
          Export, Royal Classic, Royal X-MAS (blå), Royal X-MAS (hvid), Royal
          Selection, Royal Stout og Royal Red. Royal Pilsner minder i smagen om
          Royal Export og brygget på den særlige Royal malt. Skummet er hvidt.
          Den har en svag aroma af malt og ristet brød.
        </Paragraph>
        <Paragraph>
          Royal Pilsner er brygget på den særlige Royal malt, der er
          karakteristisk for alle øl i Royal-serien. Royal malt er mildere og
          lettere end pilsnermalt. Royal Pilsner er en afbalanceret øl med en
          god balance mellem bitter humle og aromatisk humle. Den aromatiske
          humle gør Royal Pilsner fyldigere og giver en behagelig smag i munden.
          Den har en klar og gylden farve med et tæt, hvidt skum, der holder
          længe. Det er en øl med en mild smag og let humlebitterhed.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 2615,
    name: "Carlsberg Pilsner",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 25l 25 l liter hof",
    imageSingle: "produkter-fadoel.carlsberg-pilsner.png",
    imagePlural: "produkter-fadoel.carlsberg-fustage.png",
    fadoelsType: "Pilsner",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    related: [3615, 4615, 4815, 8215],
    url: "/fadoel/pilsner/carlsberg-pilsner",
    categories: ["fadoel", "fadoelPilsner", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/pilsner/carlsberg-pilsner",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: "Carlsberg Pilsner",
      },
      meta: {
        title: "Carlsberg Pilsner - Velegnet til en typisk dansk frokost",
        description:
          "Carlsberg Pilsner er en undergæret pilsnerøl. Den har en lys gylden farve & en god harmonisk balance mellem bitterhed og sødmefulde æbler. Køb den her",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>
          Carlsberg Laboratorium opfandt en metode til rendyrkning af gær
        </Heading3>
        <Paragraph>
          Carlsberg Pilsner er en undergæret pilsnerøl med en lys gylden farve
          og flot skum. Den blev introduceret i 1904 og har kælenavnet HOF. Den
          bliver produceret og solgt over hele verden og har også navnet
          Carlsberg Premium. Carlsberg Pilsner er det oplagte valg til en
          klassisk dansk frokost med sild og pålægsalater.
        </Paragraph>
        <Paragraph>
          Carlsberg Pilsner blev for første gang distribueret i 1904. I den
          forbindelse skabte professor Th. Bindesbøll etiketten med det kendte
          Carlsberg logo. Emil Chr. Hansen havde i 1883 på Carlsberg
          Laboratorium opfundet en metode til rendyrkning af gær, som gjorde det
          muligt at få en ensartet kvalitet og en fin smagsbalance fra bryg til
          bryg. Metoden er siden hen taget i brug af alle bryggerier i verden og
          benyttes den dag i dag stadig til Carlsberg Pilsner.
        </Paragraph>
      </React.Fragment>
    ),
    productPageXtraDescription: () => (
      <React.Fragment>
        <Row>
          <Col xs={12} md={6}>
            <Heading3>Frisk klassisk undergæret pilsnerøl</Heading3>
            <Paragraph>
              Carlsberg Pilsner er en frisk klassisk undergæret pilsnerøl. Den
              har en lys gylden farve. Der er en god harmonisk balance mellem
              bitterhed og sødmefulde æbler.
            </Paragraph>
            <Paragraph>
              Det skyldes den specielt udvalgte humle, der udelukkende stammer
              fra ubestøvede hunblomster fra humleplanten. Der anvendes kun
              malt, der er særligt fremstillet af udvalgt dansk vårbyg.
            </Paragraph>
          </Col>
          <Col xs={12} md={6}>
            <Heading3>
              Tip: Særdeles velegnet til en typisk dansk frokost
            </Heading3>
            <Paragraph>
              Carlsberg Pilsner kan med fordel serveres til det klassiske danske
              frokostbord og dansk mad generelt. Den bør nydes ved 7-10 °C.
            </Paragraph>
            <Heading3>Bestilling af Carlsberg Pilsner</Heading3>
            <Paragraph>
              Du er altid velkommen til at kontakte os på telefon 43 27 00 00
              for yderligere information, eller hvis du har spørgsmål vedrørende
              bestilling af Carlsberg Pilsner.
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Undergæret øl med en lys gylden farve og flot skum. Introduceret i 1904
        og går også under navnet HOF.
        <br />
        <br />
        Især god til det klassiske danske frokostbord. Bør nydes ved 7-10 °C.
      </React.Fragment>
    ),
  },
  {
    varenr: 4815,
    name: "Albani Odense Pilsner",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 25l 25 l liter",
    imageSingle: "produkter-fadoel.albani-odense-pilsner.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    fadoelsType: "Pilsner",
    land: "Danmark",
    landKort: "DK",
    // royalUnibrew: true,
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    related: [3615, 4615, 2615, 8215],
    url: "/fadoel/pilsner/albani-odense-pilsner",
    categories: ["fadoel", "fadoelPilsner", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/pilsner/albani-odense-pilsner",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: "Albani Odense Pilsner",
      },
      meta: {
        title: "Køb Albani Odense Pilsner fadøl. Levering eller hent i Brøndby",
        description:
          "Fynboernes foretrukne pilsner! Pilsneren har en genkendelig harmonisk, frisk og elegant smag. Køb fadøllen og lej samtidigt fadølsanlæg og kulsyre.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    shortDescription: () => (
      <React.Fragment>
        Fynboernes foretrukne pilsner! Frisk &amp; elegant.
      </React.Fragment>
    ),
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Fynboernes foretrukne pilsner! Siden 1934 har Albani Bryggerierne
          brygget Albani Odense Pilsner. Pilsneren har en genkendelig harmonisk,
          frisk og elegant smag. Øllens særlige karakter stammer fra brugen af
          to humlesorter fra Hallertau i Tyskland.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Fynboernes foretrukne pilsner! Brygget første gang i 1934.
        <br />
        <br />
        Genkendelig harmonisk, frisk og elegant smag.
      </React.Fragment>
    ),
  },
  {
    varenr: 4715,
    name: "Ceres Top",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 20l 20 l liter",
    imageSingle: "produkter-fadoel.ceres-top-glas.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "Pilsner",
    gas: "CO2",
    pressure: "2,5-3 bar",
    // royalUnibrew: true,
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/pilsner/ceres-top",
    categories: ["fadoel", "fadoelPilsner", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/pilsner/ceres-top",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: "Ceres Top",
      },
      meta: {
        title: "Køb Ceres Top fadøl her - Østjydernes favorit øl",
        description:
          "Østjydernes lokaløl i over 100 år. Der bliver ikke brugt så meget humle ved brygningen, men en høj temperatur på geringen hvilket giver en let pilsner med en frugtig aroma.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    productPageDescriptionLong: () => (
      <Paragraph>
        Østjydernes lokaløl i over 100 år. Der bliver ikke brugt så meget humle
        ved brygningen, men en høj temperatur på geringen hvilket giver en let
        pilsner med en frugtig aroma.
      </Paragraph>
    ),
    popupDescription: () => (
      <React.Fragment>
        Østjydernes lokaløl i over 100 år. Der bliver ikke brugt så meget humle
        ved brygningen, men en høj temperatur på geringen hvilket giver en let
        pilsner med en frugtig aroma.
      </React.Fragment>
    ),
  },
  {
    varenr: 21615,
    name: "Fuglsang Øko Pilsner",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 20l 20 l liter",
    imageSingle: "produkter-fadoel.fuglsang-oekologisk-pilsner-glas.png",
    imagePlural: "produkter-fadoel.fuglsang-20l.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "Pilsner",
    gas: "CO2",
    pressure: "2,5-3 bar",
    // royalUnibrew: true,
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/pilsner/fuglsang-oeko-pilsner",
    categories: ["fadoel", "fadoelPilsner", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/pilsner/fuglsang-oeko-pilsner",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: "Fuglsang Øko Pilsner",
      },
      meta: {
        title:
          "Køb Fuglsang Økologisk Pilsner Fadøl - Autentisk og Bæredygtig Smag",
        description:
          "Oplev Fuglsang Økologisk Pilsner fadøl, en øl med en forfriskende og velafbalanceret smag. Brygget med økologiske råvarer for at sikre kvalitet og bæredygtighed. Perfekt for dem, der værdsætter autentiske smagsoplevelser.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
  },
  {
    varenr: 8315,
    name: "Hancock Fadøl",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl høker bajer hancook handcok hancoock hanckock hankock handcocl hancok hankoch handcock hankok 15l 15 liter l dansk danske danmark",
    imageSingle: "produkter-fadoel.hancock-pilsner.png",
    imagePlural: "produkter-fadoel.hancock-fustage.png",
    fadoelsType: "Pilsner",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    related: [23615, 4915, 8415],
    url: "/fadoel/pilsner/hancock-fadoel",
    categories: ["fadoel", "fadoelPilsner", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/pilsner/hancock-fadoel",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: "Hancock Fadøl",
      },
      meta: {
        title:
          "Hancock fadøl på fustage, også kendt som Høker Bajer | Perfekt fadøl til dit event ",
        description:
          "Hancock fadøl - Prøv deres Pilsner, der smager som den populære Høker Bajer. Den har mere fylde end mange andre almindelige pilsnere.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    shortDescription: () => (
      <React.Fragment>En af DK&apos;s bedste pilsnere.</React.Fragment>
    ),
    notice: () => (
      <React.Fragment>
        <Paragraph red fontSize="13px">
          <HancockText />
        </Paragraph>
      </React.Fragment>
    ),
    smallDescription: () => (
      <React.Fragment>
        <Paragraph red fontSize="13px">
          <HancockText />
        </Paragraph>
      </React.Fragment>
    ),
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph fontWeight="bold">
          Meget mere end en almindelig pilsner - en vaskeægte FADØL
        </Paragraph>
        <Paragraph>
          Hancock Pilsner fadøl er den samme øl som den utroligt populære øl
          &quot;Høker Bajer&quot; der har vundet en række øl smagninger og tests
          i diverse forbrugermagasiner. Forskellen er blot at Hancock Pilsner
          fadøl er upasteuseret, hvilket blot giver endnu bedre smag. Så er du
          også vild med Høker Bajer, så er Hancock Pilsner den rigtige fadøl for
          dig.
        </Paragraph>
        <Paragraph>
          Duften af blomster, mild humle og lys malt er med til at underbygge
          den bittersøde og meget afbalancerede smag som denne pilsner har.
          Øllen er iøvrigt blevet kåret til ”Danmarks bedste pilsner” iblandt 28
          kendere i magasinet{" "}
          <LinkStyled
            external
            to="http://www.e-pages.dk/shopskive/148/14"
            title="Smag &amp; Behag"
          >
            Smag &amp; Behag
          </LinkStyled>
          .
        </Paragraph>
      </React.Fragment>
    ),
    productPageXtraDescription: () => (
      <React.Fragment>
        <Row>
          <Col xs={12} md={6}>
            <Paragraph>
              Når man for mange år siden bestilte en bajer hos høkeren, så
              skulle man drikke den stående, da det krævede en bevilling at have
              siddepladser. Heraf kommer navnet Høker Bajer.
            </Paragraph>
            <Paragraph>
              Hancock Pilsner er ligesom{" "}
              <LinkStyled
                to="/fadoel/classic/hancock-black-lager"
                title="Hancock Black Lager"
              >
                Hancock Black Lager
              </LinkStyled>{" "}
              brygget 100% på Saaz Humle. Denne fadøl har mere fylde end mange
              andre almindelige pilsnere.
            </Paragraph>
            <Paragraph>
              Hancock fadøl benytter det bedste Humle til deres øl, også Black
              Lager, nemlig SAAZ-humlen fra Tjekkiet. Saaz humle også kaldet
              Böhmisk humle er verdensberømt for den gode kvalitet og blide
              smag.
            </Paragraph>
          </Col>
          <Col xs={12} md={6}>
            <Paragraph>
              Hvis du har fået lyst til at smage øllen inden, at du bestiller
              den hos os, så kan du finde den hos:
            </Paragraph>
            <Paragraph>
              <LinkStyled
                external
                to="https://www.facebook.com/charliesbarkbh"
                title="Charlie's Bar"
              >
                Charlie&acute;s Bar
              </LinkStyled>
              <br />
              Pilestræde 33, 1112 København K
            </Paragraph>
            <Paragraph>
              <LinkStyled
                external
                to="https://da-dk.facebook.com/pages/Caf%C3%A9-Bl%C3%A5g%C3%A5rds-Apotek/127216877342401"
                title="Cafe Blågårds Apotek"
              >
                Cafe Blågårds Apotek
              </LinkStyled>
              <br />
              Blågårds Plads 2, 2200 København N
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        En upasteuriseret fadøl, brygget stort set uden brug af
        tilsætningsstoffer ud over vand, humle, malt og gær.
        <br />
        <br />
        Brygget på tjekkisk Saaz humle.
      </React.Fragment>
    ),
  },
  {
    varenr: 8375,
    name: "Hancock Guld",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl høker bajer hancook handcok hancoock hanckock hankock handcocl hancok hankoch handcock hankok 15l 15 liter l dansk danske danmark",
    imageSingle: "produkter-fadoel.hancock-pilsner.png",
    imagePlural: "produkter-fadoel.hancock-fustage.png",
    fadoelsType: "Stærk Pilsner",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/pilsner/hancock-guld",
    categories: ["fadoel", "fadoelPilsner", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/pilsner/hancock-guld",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: "Hancock Guld",
      },
      meta: {
        title:
          "Hancock guld fadøl - en fyldig og stærk pilsner med en rund smag",
        description:
          "Hancock guld er en fantastisk guldøl med en perfekt rund humlearoma og en fyldig pilsnersmag",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph fontWeight="bold">
          Hancock Guld er en fantastisk guldøl med en perfekt rund humlearoma og
          en fyldig pilsnersmag.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Hancock Guld er en fantastisk guldøl med en perfekt rund humlearoma og
        en fyldig pilsnersmag.
      </React.Fragment>
    ),
  },
  {
    varenr: 43615,
    name: "Mikkeller Iskold Pilsner",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 20l 20 l liter mikeler mikkeler mikeller",
    imageSingle: "produkter-fadoel.mikkeller-iskold-pilsner-glas.png",
    imagePlural: "produkter-fadoel.mikkeller-iskold-pilsner-fustage.png",
    fadoelsType: "Pilsner",
    land: "Danmark",
    landKort: "DK",
    // royalUnibrew: true,
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/pilsner/mikkeller-iskold-pilsner",
    categories: ["fadoel", "fadoelPilsner", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/pilsner/mikkeller-iskold-pilsner",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: "Mikkeller Pilsner",
      },
      meta: {
        title:
          "Mikkeller Iskold Pilsner – Sprød og forfriskende øl med afbalanceret smag",
        description:
          "Oplev Mikkeller Iskold Pilsner, en strågul øl med frodigt skum og en ren, afbalanceret smag. Perfekt til pizza, burger og lette snacks. Nyd den sprøde karakter af malt og korn.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading2>En sprød og forfriskende øloplevelse</Heading2>
        <Paragraph>
          Mikkeller Iskold Pilsner er en udsøgt øl, der præsenterer sig med en
          smuk strågul farve toppet af et frodigt, cremet skum. Denne pilsner
          byder på en forfriskende duft, hvor noter af malt, korn og nyslået
          græs smelter sammen i en harmonisk aroma, der vækker sanserne.
        </Paragraph>
        <Heading3>Ren og afbalanceret smag</Heading3>
        <Paragraph>
          Smagsoplevelsen er lige så imponerende som duften, med en ren og
          afbalanceret profil, der kombinerer en let sødme fra malten med
          kornets friskhed. Dette forstærkes af en subtil krydret note af
          nyslået græs, der giver øllen en sprød karakter, som kendetegner en
          klassisk pilsner.
        </Paragraph>
        <Heading3>Perfekt til hverdagens øjeblikke</Heading3>
        <Paragraph>
          Mikkeller Iskold Pilsner er den ideelle følgesvend til at slukke
          tørsten på varme dage eller som et opløftende indslag til maden. Den
          sprøde og afbalancerede smag gør den perfekt til at nyde sammen med
          pizza, burger eller lette snacks.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 8215,
    name: "Skovlyst Pilsner (Birkebryg)",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 30l 30 l liter pilsner birkebryg",
    imageSingle: "produkter-fadoel.skovlyst-birkebryg.png",
    imagePlural: "produkter-fadoel.skovlyst-fustage.png",
    fadoelsType: "Pilsner",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    related: [3615, 4615, 4815],
    url: "/fadoel/pilsner/skovlyst-pilsner",
    categories: ["fadoel", "fadoelPilsner", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/pilsner/skovlyst-pilsner",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: "Skovlyst Pilsner",
      },
      meta: {
        title:
          "Skovlyst Pilsner (Birkebryg) - Med inspiration fra den tjekkiske pilsner",
        description:
          "Skovlyst Pilsner er en helt igennem fantastisk pilsner til en meget stærk pris. Med en fyldig maltkarakter & blid bitterhed går du ikke galt i byen.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    lavLiterpris: [3615, 8215, 30, "Grøn Tuborg"], // lav literpris
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>
          Skovlyst Pilsner - med inspiration fra den tjekkiske pilsner
        </Heading3>
        <Paragraph>
          Skiftede i juli 2023 navn fra Skovlyst Birkebryg til Skovlyst Pilsner
          - indholdet er det samme.
        </Paragraph>
        <Paragraph>
          Skovlyst Pilsner er et af de bedste pristilbud vi har. Kombinationen
          af fantastisk smag og lav pris fås ikke bedre.
        </Paragraph>
        <Paragraph>
          Skovlyst Pilsner har hentet inspiration fra den tjekkiske pilsner.
          Øllet er eftergærdet på birkesirup og får deraf en flot lys gylden
          farve. Vælger du denne øl får du en fyldig maltkarakter, en blid
          bitterhed og en krydret/blomstret humleduft som kommer fra den
          klassiske tjekkiske humle Saaz.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Skovlyst Pilsner har hentet inspiration fra den tjekkiske pilsner.
        <br />
        <br />
        Flot lys gylden farve og fyldig maltkarakter
      </React.Fragment>
    ),
  },
  {
    varenr: 29615,
    name: "Skands Humlefryd",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 20l 20 l liter",
    imageSingle: "produkter-fadoel.skands-humlefryd-pilsner.png",
    imagePlural: "produkter-fadoel.skands-fustage.png",
    fadoelsType: "Pilsner",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/pilsner/skands-humlefryd",
    categories: ["fadoel", "fadoelPilsner", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/pilsner/skands-humlefryd",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: "Skands Humlefryd",
      },
      meta: {
        title:
          "Skands Humlefryd - En velafbalanceret tjekkisk-inspireret pilsner",
        description:
          "Humlefryd er en pilsner med en velafbalanceret smag af humle, der er inspireret af tjekkisk brygning. Den fremhæver en markant humlesmag, der er frisk og let bitter, med en blomsteragtig og frugtagtig duft og smag.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    productPageDescriptionLong: () => (
      <Paragraph>
        Humlefryd er en pilsner med en velafbalanceret smag af humle, der er
        inspireret af tjekkisk brygning. Den fremhæver en markant humlesmag, der
        er frisk og let bitter, med en blomsteragtig og frugtagtig duft og smag.
      </Paragraph>
    ),
  },
  {
    varenr: 4725,
    name: "Thor Pilsner",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 20l 20 l liter",
    imageSingle: "produkter-fadoel.thor-pilsner.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    fadoelsType: "Pilsner",
    land: "Danmark",
    landKort: "DK",
    // royalUnibrew: true,
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/pilsner/thor-pilsner",
    categories: ["fadoel", "fadoelPilsner", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/pilsner/thor-pilsner",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: "Thor Pilsner",
      },
      meta: {
        title: "Køb Thor Pilsner fadøl. En traditionsrig øl fra 1897",
        description:
          "Thor Pilsner har været brygget siden 1897. Øllen må betegnes som en rigtig pilsner, da den smager lidt mere af det hele.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Thor Pilsner har været brygget siden 1897. Øllen må betegnes som en
          rigtig pilsner, da den smager lidt mere af det hele. Der anvendes de
          samme ingredienser som de øvrige pilsner-fabrikanter anvender, men
          Thor Pilsneren har mere humle i sig, og er derfor en anelse mere
          bitter. Bitterheden er dog afstemt med de råvarer sådan, at balancen
          opretholdes og bitterheden ikke bliver for kraftig. Øllens skum står
          flot og hvidt, ja, nærmest flødeskumsagtigt.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Thor Pilsner har været brygget siden 1897.
        <br />
        <br />
        Thor Pilsneren har mere humle i sig, og er derfor en anelse mere bitter
        end andre pilsnere.
      </React.Fragment>
    ),
  },
  {
    varenr: 10615,
    name: "Herslev Øko Pilsner",
    generalSearchPriority: 1000,
    // herslevBryghus: true,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 20l 20 liter l økologisk økologiske herslev bryghus",
    imageSingle: "produkter-fadoel.herslev-bryghus-pilsner-glas-logo.png",
    imagePlural: "produkter-fadoel.herslev-fustage1.png",
    fadoelsType: "Pilsner",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    related: [8115, 3676, 8895, 10605],
    url: "/fadoel/pilsner/herslev-pilsner",
    categories: ["fadoel", "fadoelPilsner", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/pilsner/herslev-pilsner",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: "Herslev Pilsner",
      },
      meta: {
        title: "Køb Herslev Pilsner fadøl og få leveret lige til døren",
        description:
          "Herslev Pilsner er en økologisk pilsner brygget på økologisk malt fra bryghusets egne marker, vand fra det lokale vandværk & humle fra det sydlige Tyskland.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          En rund og smagfuld økologisk pilsner brygget på økologisk malt fra
          bryghusets egne marker, vand fra det lokale vandværk og humle fra det
          sydlige Tyskland. Pilsneren er inspireret af de klassiske tjekkiske
          pilsnertyper med en ren og god maltsmag samt en frisk bitterhed fra
          humlen, som giver en behagelig kant, uden at være påtrængende. En
          drikkevenlig pilsner både til mad og i godt selskab.
        </Paragraph>
        <Paragraph>
          Herslev Bryghus er et økologisk gårdbryggeri i landsbyen Herslev ved
          Roskilde Fjord. Her brygges øl efter økologiske foreskrifter med fokus
          på lokale råvarer. Grundstenen i alle bryghusets øl er det økologiske
          korn, som bryggers på markerne omkring gården.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        En rund og smagfuld økologisk pilsner brygget på økologisk malt fra
        bryghusets egne marker, vand fra det lokale vandværk og humle fra det
        sydlige Tyskland.
      </React.Fragment>
    ),
  },
  {
    varenr: 10605,
    name: "Herslev Øko Hoppy Pils",
    generalSearchPriority: 1000,
    // herslevBryghus: true,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 20l 20 liter l økologisk økologiske bryghus",
    imageSingle: "produkter-oelvand.10605-flaske.png",
    imagePlural: "produkter-fadoel.herslev-fustage1.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "Pilsner",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    related: [8115, 3676, 10615, 4625],
    url: "/fadoel/pilsner/herslev-oeko-hoppy-pils",
    categories: ["fadoel", "fadoelPilsner", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/pilsner/herslev-oeko-hoppy-pils",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: "Herslev Øko Hoppy Pils",
      },
      meta: {
        title: "Køb Herslev Øko Hoppy Pils fadøl online hos Skafte",
        description:
          "Herslev Øko Hoppy Pils er en tyskinspireret pilsner med mere tørhumle. Denne fadøl har en frisk og frugtig smag som læner sig imod en IPA.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Herslev Øko Hoppy Pils er en tyskinspireret pilsner med mere tørhumle.
          Denne fadøl har en frisk og frugtig smag som læner sig imod en IPA.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Herslev Øko Hoppy Pils er en tyskinspireret pilsner med mere tørhumle.
        Denne fadøl har en frisk og frugtig smag som læner sig imod en IPA.
      </React.Fragment>
    ),
  },
  {
    varenr: 18615,
    name: "Hornbeer Øko Godt Øl",
    generalSearchPriority: 1000,
    keywords:
      "engangs engangsfustager fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 30l 30 l liter økologisk økologiske keykeg keykegs pose",
    imageSingle: "produkter-fadoel.hornbeer-godt-oel.png",
    imagePlural: "produkter-fadoel.hornbeer-fustage-1.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "Pilsner",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    related: [8005, 8015, 4625, 19615],
    url: "/fadoel/pilsner/hornbeer-oeko-godt-oel",
    categories: ["fadoel", "fadoelPilsner", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/pilsner/hornbeer-oeko-godt-oel",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: "Hornbeer Øko Godt Øl",
      },
      meta: {
        title:
          "Køb Hornbeer Øko Godt Øl fadøl her - Økologisk, ufiltreret pilsner",
        description:
          "Godt Øl fra Hornbeer er en økologisk, god gammeldags pilsner. Den er ikke filtreret eller pasteuriseret hvilket giver en mere fyldig smag",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    smallDescription: () => (
      <React.Fragment>
        <Span blue block fontSize="13px" paddingTop="5px">
          Som i keykegs indeholder Hornbeer plastik engangsfustager en pose
          hvori øllet er. Kulsyre eller trykluft kommer altså ikke i direkte
          kontakt med øllet.
        </Span>
      </React.Fragment>
    ),
    notice: () => (
      <React.Fragment>
        <Paragraph blue fontSize="13px">
          Som i keykegs indeholder Hornbeer plastik engangsfustager en pose
          hvori øllet er. Kulsyre eller trykluft kommer altså ikke i direkte
          kontakt med øllet.
        </Paragraph>
      </React.Fragment>
    ),
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Godt Øl fra Hornbeer er en økologisk, god gammeldags pilsner. Den er
          ikke filtreret eller pasteuriseret hvilket giver en mere fyldig smag.
        </Paragraph>
        <Paragraph>
          Godt øl bliver lagret i mange uger og er brygget på økologisk
          pilsnermalt fra sjælland. Smagen er fyldig som frisk tjekkisk pilsner.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        <Paragraph>
          Godt Øl fra Hornbeer er en økologisk, god gammeldags pilsner. Den er
          ikke filtreret eller pasteuriseret hvilket giver en mere fyldig smag.
        </Paragraph>
        <Paragraph>
          Godt øl bliver lagret i mange uger og er brygget på økologisk
          pilsnermalt fra sjælland. Smagen er fyldig som frisk tjekkisk pilsner.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 8115,
    name: "Nørrebro Øko New York Lager",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 20l 20 liter l bryghus økologisk økologiske nørrebro øko new york lager",
    imageSingle:
      "produkter-fadoel.noerrebro-bryghus-new-york-lager-40cl-glas.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    fadoelsType: "Stærk Pilsner",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    // norrebro: true,
    // royalUnibrew: true,
    invoiceDisplay: "FustageItemRow",
    related: [8895, 3676, 10615, 10605],
    url: "/fadoel/pilsner/noerrebro-bryghus-new-york-lager",
    categories: ["fadoel", "fadoelPilsner", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/pilsner/noerrebro-bryghus-new-york-lager",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: "Nørrebro Bryghus New York Lager",
      },
      meta: {
        title: "Køb Nørrebro Bryghus New York Lager fadøl",
        description:
          "Nørrebro Bryghus New York Lager, dansk og økologisk fadøl, traditionel, amerikansk øltype fra før forbudstiden.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Dansk produceret, økologisk fadøl</Heading3>
        <Paragraph>
          Nørrebro Bryghus New York Lager er en traditionel, amerikansk
          undergæret øltype som er kendt fra før forbudstiden.
        </Paragraph>
        <Paragraph>
          New York Lager er en kraftig, fyldig og mørkgylden øl på 5,2 %
          alkohol. Smagsmæssigt har øllen en intens maltkarakter med
          karamelnoter samt markant bitterhed. Duften er blomsteragtig med et
          strejf nordamerikansk Cascade humle.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Traditionel, amerikansk undergæret øltype som er kendt fra før
        forbudstiden.
        <br />
        <br />
        Kraftig, fyldig og mørkgylden øl på 5,2 % alkohol.
      </React.Fragment>
    ),
  },
  {
    varenr: 8015,
    name: "Nørrebro Øko Pilsner",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 20l 20 liter l bryghus økologisk økologiske",
    imageSingle: "produkter-fadoel.noerrebro-bryghus-pilsner-40cl-glas.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    fadoelsType: "Pilsner",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    // norrebro: true,
    // royalUnibrew: true,
    invoiceDisplay: "FustageItemRow",
    related: [8005, 18615, 4625, 19615],
    url: "/fadoel/pilsner/noerrebro-bryghus-pilsner",
    categories: ["fadoel", "fadoelPilsner", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/pilsner/noerrebro-bryghus-pilsner",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: "Nørrebro Bryghus Pilsner",
      },
      meta: {
        title: "Køb Nørrebro Bryghus Pilsner",
        description: "Køb Nørrebro Bryghus Pilsner",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Dansk produceret, økologisk fadøl</Heading3>
      </React.Fragment>
    ),
  },
  {
    varenr: 4625,
    name: "Royal Øko Pilsner",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark faxe 20l 20 l liter økologisk økologiske",
    imageSingle: "produkter-fadoel.4635-flaske.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "Pilsner",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    // royalUnibrew: true,
    related: [8005, 8015, 18615, 19615],
    url: "/fadoel/pilsner/royal-oeko-pils",
    categories: ["fadoel", "fadoelPilsner", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/pilsner/royal-oeko-pils",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: "Royal Øko Pils",
      },
      meta: {
        title:
          "Køb Royal Øko Pils her - Testvindende pilsner kan bestilles her",
        description:
          "Økologisk pilsner, med en frisk og fyldig smag. Har vundet adskillige smagstests og er i det hele taget bare en knaldgod fadøl!",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Økologisk pilsner, med en frisk og fyldig smag.
          <br />
          <br />
          Har vundet adskillige smagstests og er i det hele taget bare en
          knaldgod fadøl!
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Økologisk pilsner, med en frisk og fyldig smag.
        <br />
        <br />
        Har vundet adskillige smagstests og er i det hele taget bare en knaldgod
        fadøl!
      </React.Fragment>
    ),
  },
  {
    varenr: 8515,
    name: "San Miguel Especial",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl spansk spanien 30l 30 liter l",
    imageSingle: "produkter-fadoel.san-miguel-glass.png",
    imagePlural: "produkter-fadoel.all-day-ipa-fustage.png",
    fadoelsType: "Pilsner",
    land: "Spanien",
    landKort: "ES",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/pilsner/san-miguel-especial",
    categories: ["fadoel", "fadoelPilsner", "fadoelUdenlandsk"],
    routeDef: {
      path: "/fadoel/pilsner/san-miguel-especial",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: "San Miguel Especial",
      },
      meta: {
        title: "San Miguel Especial Fadøl | En klar, lys og gylden pilsner",
        description:
          "San Miguel Especial har en højere alkoholprocent end den almindelige pilsner, som giver et stærkere indtryk - men er samtidig frisk og let.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>En klar, lys og gylden pilsner</Heading3>
        <Paragraph>
          San Miguel Especial har en højere alkoholprocent end den almindelige
          pilsner, som giver et stærkere indtryk - men føles samtidigt frisk og
          let.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        San Miguel Especial er en klar, lys og gylden pilsner, som har en højere
        alkoholprocent end den almindelige pilsner, hvilket giver et stærkere
        indtryk - men føles samtidigt frisk og let.
      </React.Fragment>
    ),
  },
  {
    varenr: 23215,
    name: "Stella Artois",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl belgisk belgien 20l 20 liter l",
    imageSingle: "produkter-fadoel.stella-artois-glas.png",
    imagePlural: "produkter-fadoel.20-liter-staal-uden-brand.png",
    fadoelsType: "Pilsner",
    land: "Belgien",
    landKort: "BE",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/pilsner/stella-artois",
    categories: ["fadoel", "fadoelPilsner", "fadoelUdenlandsk"],
    routeDef: {
      path: "/fadoel/pilsner/stella-artois",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: "Stella Artois",
      },
      meta: {
        title: "Stella Artois Fadøl | Belgiens mest populære pilsner",
        description:
          "Stella Artois er en lys pilsner og en blid tørstslukker med en alkoholprocent på 5,2%. Smager mere end den almindelig pilsner med en meget velbalanceret bitterhed.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Belgiens mest populære pilsner</Heading3>
        <Paragraph>
          Stella Artois er en lys pilsner og en blid tørstslukker med en
          alkoholprocent på 5,2%. Smager mere end den almindelig pilsner med en
          meget velbalanceret bitterhed. Selvom Belgien er mest kendt for sine
          Ales er Stella Artois den mest eksporterede øl i landet.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Stella Artois er en lys pilsner og en blid tørstslukker med en
        alkoholprocent på 5,2%. Smager mere end den almindelig pilsner med en
        meget velbalanceret bitterhed. Selvom Belgien er mest kendt for sine
        Ales er Stella Artois den mest eksporterede øl i landet
      </React.Fragment>
    ),
  },
  {
    varenr: 8225,
    name: "Skovlyst Øko Signatur No. 23 Traditionel Pilsner",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl bornholm svaneke svanke sveneke svanike svinike svenike bryghus dansk danske danmark 20l 20 liter l økologisk økologiske",
    imageSingle: "produkter-fadoel.skovlyst-signatur-23.png",
    imagePlural: "produkter-fadoel.skovlyst-oeko-20l.png",
    fadoelsType: "Pilsner",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    related: [8005, 8015, 4625, 8315],
    url: "/fadoel/classic/skovlyst-oeko-signatur-no-23-traditionel-pilsner",
    categories: ["fadoel", "fadoelPilsner", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/classic/skovlyst-oeko-signatur-no-23-traditionel-pilsner",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: "Skovlyst Øko Signatur No. 23 Traditionel Pilsner",
      },
      meta: {
        title:
          "Skovlyst Signaturbryg No 23 Traditionel Pilsner - Økologisk, Let og Frisk",
        description:
          "Oplev Skovlyst Signaturbryg No 23 Traditionel Pilsner, en klassisk tysk øl med en frisk smag. Økologisk og ufiltreret, perfekt balanceret med let maltet sødme og en forfriskende bitterhed.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Skovlyst Signaturbryg No 23 Traditionel Pilsner er en hyldest til den
          tyske pilsner-stil, favoriseret af danske ølentusiaster. Denne
          økologiske og ufiltreret øl skinner med sin lyse gyldne farve og
          forfriskende smag, der gør den til en ideel ledsager for enhver
          lejlighed.
        </Paragraph>
        <Paragraph>
          Denne pilsner kombinerer let maltet sødme med en moderat til høj
          bitterhed fra humlen, hvilket resulterer i en let krop og en skøn,
          forfriskende balance. Duften er en indbydende blanding af blomster,
          græs og urter, der tilføjer en ekstra dimension til den samlede
          drikkeoplevelse
        </Paragraph>
        <Paragraph>
          For at opnå den bedste smagsoplevelse, anbefales det at servere
          Skovlyst Signaturbryg No 23 ved en kølig temperatur, hvilket fremhæver
          dens forfriskende egenskaber. Denne øl er et must-try for dem, der
          søger en økologisk, let og behageligt afbalanceret pilsner.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Skovlyst Signaturbryg No 23 Traditionel Pilsner er en hyldest til den
        tyske pilsner-stil, favoriseret af danske ølentusiaster. Denne
        økologiske og ufiltreret øl skinner med sin lyse gyldne farve og
        forfriskende smag, der gør den til en ideel ledsager for enhver
        lejlighed.
      </React.Fragment>
    ),
  },
  {
    varenr: 19615,
    name: 'Svaneke Øko Pilsner "First Mate Craft"',
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl bornholm svaneke svanke sveneke svanike svinike svenike bryghus dansk danske danmark 20l 20 liter l økologisk økologiske",
    imageSingle: "produkter-fadoel.svaneke-pilsner.png",
    imagePlural: "produkter-fadoel.svaneke-fustage.png",
    fadoelsType: "Pilsner",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    related: [8005, 8015, 4625, 8315],
    url: "/fadoel/pilsner/svaneke-pilsner",
    categories: ["fadoel", "fadoelPilsner", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/pilsner/svaneke-pilsner",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: "Svaneke Øko Pilsner",
      },
      meta: {
        title:
          "Svaneke Øko Pilsner Fadøl | Læskende, lystig, let | Køb den her og afhent selv i Brøndby eller få den leveret",
        description:
          "Svaneke Pilsner er en god økologisk fadøl fra Bornholm. Denne fadøl har en smuk klar farve der går fra lys gul til strågul.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Bornholmsk fadøl i særklasse</Heading3>
        <Paragraph>
          Denne fadøl har en smuk klar farve der går fra lys gul til strågul.
          Skummet på øllen står længe og medvirker til en skøn duft og en flot
          skænket fadøl. Smagen er let maltet og har et strejf af græs og
          citrus. Eftersmagen har en let sødme og bitterhed.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Går under navnet Svaneke First Mate Craft Pilsner. Smagen er let maltet
        og har et strejf af græs og citrus. Eftersmagen har en let sødme og
        bitterhed.
      </React.Fragment>
    ),
  },
  {
    varenr: 23615,
    name: "Budweiser",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl 30l 30 l liter budwiser usa u.s.a. u.s.a amerika amerikansk amerikanske",
    imageSingle: "produkter-oelvand.23615-flaske.png",
    imagePlural: "produkter-fadoel.20-liter-staal-uden-brand.png",
    land: "USA",
    landKort: "USA",
    fadoelsType: "Pilsner",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    related: [8315, 4915, 8415],
    url: "/fadoel/pilsner/budweiser",
    categories: ["fadoel", "fadoelPilsner", "fadoelUdenlandsk"],
    routeDef: {
      path: "/fadoel/pilsner/budweiser",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: "Budweiser",
      },
      meta: {
        title:
          "Køb Budweiser her - Den mest populære luksus øl i USA kan bestilles her",
        description:
          "Budweiser er den mest populære luksus øl i USA. Hver femte øl solgt i USA er en Budweiser. Farven er gylden og smagen er sprød og ren.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Budweiser er den mest populære luksus øl i USA. Hver femte øl solgt i
          USA er en Budweiser. Farven er gylden og smagen er sprød og ren.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Budweiser er den mest populære luksus øl i USA. Hver femte øl solgt i
        USA er en Budweiser. Farven er gylden og smagen er sprød og ren.
      </React.Fragment>
    ),
  },
  {
    varenr: 4965,
    name: "Birra Moretti",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl 30l 30 l liter italien italiensk birre morreti moreti morretti",
    imageSingle: "produkter-fadoel.birra-moretti-glas.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    land: "Italien",
    landKort: "IT",
    fadoelsType: "Pilsner",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/pilsner/birra-moretti",
    categories: ["fadoel", "fadoelPilsner", "fadoelUdenlandsk"],
    routeDef: {
      path: "/fadoel/pilsner/birra-moretti",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: "Birra Moretti",
      },
      meta: {
        title:
          "Køb Birra Moretti her - En af de mest populære øl i Italien kan bestilles her",
        description:
          "Passer perfekt til italienske retter som pasta, risotto og pizza. Birra Moretti er en lys pilsner med en frisk og let smag.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>En af de mest populære fadøl i Italien</Heading3>
        <Paragraph>
          Passer perfekt til italienske retter som pasta, risotto og pizza.
          Birra Moretti er en lys pilsner med en frisk og let smag.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Passer perfekt til italienske retter som pasta, risotto og pizza. Birra
        Moretti er en lys pilsner med en frisk og let smag.
      </React.Fragment>
    ),
  },
  {
    varenr: 4915,
    name: "Heineken",
    // heinekenOffer: true,
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl haineken hainiken hainikin Heiniken hiniken haniken heniken hiniken 30l 30 l liter",
    imageSingle: "produkter-fadoel.heineken.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    fadoelsType: "Pilsner",
    land: "Holland",
    landKort: "NL",
    // royalUnibrew: true,
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    related: [23615, 8315, 8415],
    url: "/fadoel/pilsner/heineken",
    categories: ["fadoel", "fadoelPilsner", "fadoelUdenlandsk"],
    routeDef: {
      path: "/fadoel/pilsner/heineken",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: "Heineken",
      },
      meta: {
        title: "Heineken – Køb Heineken fadøl og lej et fadølsanlæg her",
        description:
          "Heineken er en hollandsk pilsner, der har en frisk og frugtagtig aroma. Smagen starter let sød og slutter med en tør eftersmag. Alkoholstyrken er 5%.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Heineken er en hollandsk pilsner, der har en frisk og frugtagtig
          aroma. Heineken er et af de mest kendte ølmærker i verden. Siden 2002
          er Heineken blevet tappet, distribueret og markedsført i Danmark af
          Royal Unibrew. Den røde stjerne er det oprindelige ikon for mærket og
          er blevet brugt siden 1863, hvor bryggeriet blev grundlagt.
        </Paragraph>
        <Paragraph>
          Frisk aroma, let sød smag og en god tørstslukker: Heineken er en
          pilsner, der brygges via en naturlig brygningsproces uden
          tilsætningsstoffer. Den indeholder vand, bygmalt, humle og det unikke
          Heineken A-gær til gæring i brygningsprocessen. Farven er strågul.
          Skummet er hvidt. Smagen starter let sød og slutter med en tør
          eftersmag. Alkoholstyrken er 5%. Den røde stjerne er det oprindelige
          ikon for mærket og er blevet brugt helt tilbage fra begyndelsen. Dens
          helt præcise oprindelse er ukendt. Opbevar Heineken på et køligt, tørt
          sted væk fra direkte sollys og varme. Servér den ved en temperatur på
          ca. 5°C og sluk tørsten.
        </Paragraph>
      </React.Fragment>
    ),
    productPageXtraDescription: () => (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <Heading3>Heineken er det tredjestørste bryggeri i verden</Heading3>
            <Paragraph>
              Heineken er i dag en meget stor hollandsk bryggerikoncern.
              Koncernen har mere end 170 ølmærker, hvor Heineken er Europas
              største ølmærke. Bryggeriet blev grundlagt tilbage i 1863 af
              Gerard Adriaan Heineken, hvor han købte bryggeriet De Hooiberg,
              der lå i Amsterdam. Det er det tredjestørste bryggeri i verden,
              når man kigger på den samlede ølproduktion, som brygges på over
              130 bryggerier. Der produceres over hundrede millioner hektoliter
              øl pr. år.
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Brygges via en naturlig brygningsproces uden tilsætningsstoffer.
        <br />
        <br />
        Farven er strågul. Skummet er hvidt. Smagen starter let sød og slutter
        med en tør eftersmag.
      </React.Fragment>
    ),
  },
  {
    varenr: 8415,
    name: "Platan Pilsner",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl 30l 30 l liter tjekkiet plantan",
    imageSingle: "produkter-fadoel.platan-pilsner.png",
    imagePlural: "produkter-fadoel.platan-fustage.png",
    fadoelsType: "Pilsner",
    land: "Tjekkiet",
    landKort: "CZ",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    related: [4915, 23615, 8776],
    url: "/fadoel/pilsner/platan-pilsner",
    categories: ["fadoel", "fadoelPilsner", "fadoelUdenlandsk"],
    routeDef: {
      path: "/fadoel/pilsner/platan-pilsner",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: "Platan Pilsner",
      },
      meta: {
        title: "Platan Pilsner – Tjekkisk fadøl af høj kvalitet",
        description:
          "Det er en dejlig tjekkisk fadøl med en pæn gylden farve og middel skum. Den er let bitter og har en fin aroma af korn. Køb Platan Pilsner her.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Smager som en pilsner skal smage</Heading3>
        <Paragraph>
          Platan pilsner er en original tjekkisk pilsnerøl af høj kvalitet. Den
          har en alkoholstyrke på 4,8%. Den har en let humle og bitterhed.
          Platan pilsner kan både drikkes alene som en kølig forfriskning og til
          mad.
        </Paragraph>
        <Paragraph>
          Denne pilsner er af typisk høj tjekkisk kvalitet. Platan Pilsner har
          en pæn gylden farve og en begrænset mængde hvidt skum. Den er let
          bitter og har en eftersmag, der er ren og klar. Aroma af korn. Nyd
          denne kølige fadøl til din kommende fest. Den smager som en pilsner
          skal smage.
        </Paragraph>
        <Paragraph>
          Platan Pilsner er et rigtig godt og billigt alternativ til den
          tjekkiske øl Pilsner Urquell.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Af typisk høj tjekkisk kvalitet. Pæn gylden farve med let bitter smag og
        en eftersmag, der er ren og klar.
        <br />
        <br />
        Godt og billigt alternativ til den tjekkiske øl Pilsner Urquell.
      </React.Fragment>
    ),
  },
  {
    varenr: 8595,
    name: "Paulaner Oktoberfest Øl",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl 30l 30 l liter paullaner oktoberfest oktoberøl tysk tyske tyskland",
    imageSingle: "produkter-fadoel.paulaner-oktober-fest.png",
    imagePlural: "produkter-fadoel.paulaner-fustage.png",
    fadoelsType: "Pilsner",
    land: "Tyskland",
    landKort: "DE",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    notice: () => (
      <React.Fragment>
        <Span blue marginTop="5px">
          Sæsonøl - Kan leveres fra <Datoer type="oktoberfest" />. Kan ikke
          returneres efter oktober måned.
        </Span>
      </React.Fragment>
    ),
    smallDescription: () => (
      <React.Fragment>
        <Span blue marginTop="5px">
          Sæsonøl - Kan leveres fra <Datoer type="oktoberfest" />. Kan ikke
          returneres efter oktober måned.
        </Span>
      </React.Fragment>
    ),
    inStockText: () => (
      <React.Fragment>
        Udsolgt. Kommer igen <Datoer type="oktoberfest" />
      </React.Fragment>
    ),
    url: "/fadoel/pilsner/paulaner-oktoberfest",
    categories: [
      "fadoel",
      "fadoelPilsner",
      "fadoelOktoberfest",
      "fadoelUdenlandsk",
    ],
    routeDef: {
      path: "/fadoel/pilsner/paulaner-oktoberfest",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: "Paulaner Oktoberfest",
      },
      meta: {
        title: "Paulaner - Oktoberfest Øl på Fad | Bestil online",
        description:
          "Køb Paulaner øl online - En af de kun 6 officielle bryggerier, der leverer fadøl til Oktoberfesten i München. Flot stærk og gylden farve – god smag! ",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Officiel øl til oktoberfest i München</Heading3>
        <Paragraph>
          Paulaner er et bayersk bryggeri i München grundlagt 1634 af munkene
          fra Neudeck ob der Au-klosteret. Bryggeriet og munke-ordenen er
          opkaldt efter Paulus. Paulaner er ét af de i alt 6 bryggerier, som
          officielt må sælge øl til Oktoberfesten i München.
        </Paragraph>
        <Paragraph>
          Bryggeriet hører til en af Tysklands største bryggerigrupper, Brau
          Holding International, der er et joint venture mellem
          Heineken-koncernen og Schörghuber.
        </Paragraph>
        <Heading3>Smag og farve</Heading3>
        <Paragraph>
          Glasset er fyldt med oktober solskin, og har en stærk og gylden farve.
          Øllen har en fin balance mellem en let smag af humle og en stærk tone
          af malt. Øllen vil passe godt til eksempelvis røget makrel og
          smørrebrød.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Paulaner er ét af de i alt 6 bryggerier, som officielt må sælge øl til
        Oktoberfesten i München. Paulaner er et bayersk bryggeri i München
        grundlagt 1634 af munkene fra Neudeck ob der Au-klosteret.
      </React.Fragment>
    ),
  },
  {
    varenr: 8265,
    name: "Skovlyst Oktoberfest",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl 20l 20 l liter oktoberfest oktoberøl",
    imageSingle: "produkter-fadoel.skovlyst-oktoberfest.png",
    imagePlural: "produkter-fadoel.skovlyst-oktoberfest-fustage.png",
    fadoelsType: "Classic",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    notice: () => (
      <React.Fragment>
        <Span blue marginTop="5px">
          Sæsonøl - Kan leveres fra <Datoer type="oktoberfest" />. Kan ikke
          returneres efter oktober måned.
        </Span>
      </React.Fragment>
    ),
    smallDescription: () => (
      <React.Fragment>
        <Span blue marginTop="5px">
          Sæsonøl - Kan leveres fra <Datoer type="oktoberfest" />. Kan ikke
          returneres efter oktober måned.
        </Span>
      </React.Fragment>
    ),
    inStockText: () => (
      <React.Fragment>
        Udsolgt. Kommer igen <Datoer type="oktoberfest" />
      </React.Fragment>
    ),
    url: "/fadoel/pilsner/skovlyst-oktoberfest",
    categories: [
      "fadoel",
      "fadoelPilsner",
      "fadoelOktoberfest",
      "fadoelUdenlandsk",
    ],
    routeDef: {
      path: "/fadoel/pilsner/skovlyst-oktoberfest",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: "Skovlyst Oktoberfest",
      },
      meta: {
        title: "Skovlyst Oktoberfest fadøl | Bestil online",
        description:
          "Skovlyst Oktoberfest - den perfekte fadøl til jeres oktoberfest",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
  },

  /* *
   *
   * Fadøl - Classic
   *
   * */

  {
    varenr: 3655,
    name: "Tuborg Classic",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl tuborg dansk danske danmark 25l 25 l liter classis classisk klassisk clasic clasig classik",
    imageSingle: "produkter-fadoel.tuborg-classic.png",
    imagePlural: "produkter-fadoel.carlsberg-fustage.png",
    fadoelsType: "Classic",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/classic/tuborg-classic",
    categories: ["fadoel", "fadoelClassic", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/classic/tuborg-classic",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelClassicBC],
        title: "Tuborg Classic",
      },
      meta: {
        title: "Tuborg Classic Fadøl | Køb den populære øl her – nemt online!",
        description:
          "Tuborg Classic Fadøl - En favorit hos mange! Smagen er ristet & har en vis sødmen til sig. Køb også Tuborg Classic på flaske her på siden - nemt og billigt.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Classic",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Lidt mere smag og aroma – lidt mere af alting</Heading3>
        <Paragraph>
          Tuborg Classic er brygget på 4 maltsorter og har en let duft af
          solbær, korn og toner af karamel. Det er en gylden, mørk pilsnerøl med
          en behagelig ristet smag og en smule sødme. Denne fadøl er let
          drikkelig og har masser af smagsindtryk. Farven er lys brun med et
          flot elfenbensfarvet skum. Den passer til næsten alle slags
          middagsmad.
        </Paragraph>
        <Paragraph>
          Tuborg Classic fadøl får sin varme glød via brugen af müncher,
          karamel- og en anelse farvemalt. Aromaen er præget af den
          karakteristiske friske Tuborg duft, der skabes af gæren samt karamel
          fra karamelmalten og ristet malt fra müncher malten. Classic har en
          anelse mere krop og humlebitterhed end Grøn Tuborg, fordi der er brugt
          mere malt og en smule mere humle. I eftersmagen mærkes
          humlebitterheden og et let maltpræg.
        </Paragraph>
      </React.Fragment>
    ),
    productPageXtraDescription: () => (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <Heading3>Tip: Servér den køleskabskold til middagsmad</Heading3>
            <Paragraph>
              Tuborg Classic passer godt til det meste kød. Lige fra kalvekød og
              svinekød til retter indeholdende kylling, stegt fisk eller retter
              med oksekød.
            </Paragraph>
            <Paragraph>
              Den går også godt til pasta og vegetarretter med syrlige
              grønsager. Den bør nydes ved ca. 5-7 °C.
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Tuborg Classic har en anelse mere krop og humlebitterhed end Grøn
        Tuborg, fordi der er brugt mere malt og en smule mere humle.
      </React.Fragment>
    ),
  },
  {
    varenr: 4655,
    name: "Royal Classic",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark faxe 20l 20 l liter classis classisk klassisk",
    imageSingle: "produkter-fadoel.royal-classic.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    fadoelsType: "Classic",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    // royalUnibrew: true,
    url: "/fadoel/classic/royal-classic",
    categories: ["fadoel", "fadoelClassic", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/classic/royal-classic",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelClassicBC],
        title: "Royal Classic",
      },
      meta: {
        title: "Royal Classic | Fadøl med en fyldig og behagelig smag | Køb nu",
        description:
          "Royal Classic er en fyldig øl, der giver en rig smagsoplevelse. Brygget på den særlige Royal malt samt karamelmalt og mørkristede malt - 4,6% alkohol.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Classic",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Vand, bygmalt, byg og humle</Heading3>
        <Paragraph>
          Royal Classic er brygget på den kendte Royal malt og derudover
          karamelmalt og mørkristet malt. Der er desuden anvendt bitterhumle.
          For at forstærke den frugtagtige duft, er der også brugt aromatisk
          humle. Dette giver en fyldigere og mere behagelig smag i munden.
        </Paragraph>
        <Paragraph>
          Royal Classic er en pilsnerøl og er lige som Royal Pilsner brygget på
          den særlige Royal malt samt karamelmalt og mørkristede malt. Der er
          tilsat både bitter humle og aromatisk humle. Alle Royal øl har en
          karakteristisk frugtagtig eller blomsteragtig aroma, som netop skyldes
          brugen af aromatisk humle. Aromatisk humle giver en rigere
          smagsoplevelse. Royal Classic har en god eftersmag.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Ligesom Royal Pilsner, brygget på den Royal malt samt karamelmalt og
        mørkristede malt.
        <br />
        <br />
        Karakteristisk frugtagtig aroma. Royal Classic har en god eftersmag.
      </React.Fragment>
    ),
  },
  {
    varenr: 4885,
    name: "Albani 1859",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 25l 25 l liter classis classisk klassisk clasic clasig classik odense albani 1859",
    imageSingle: "produkter-fadoel.4885-flaske.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "Classic",
    gas: "CO2",
    pressure: "2,5-3 bar",
    // royalUnibrew: true,
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/classic/albani-odense-1859",
    categories: ["fadoel", "fadoelClassic", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/classic/albani-odense-1859",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelClassicBC],
        title: "Albani Odense 1859",
      },
      meta: {
        title: "Køb Albani Odense 1859 her - Fyldig og kraftig dansk Classic",
        description:
          "Introduceret i 1999 i anledningen med Albani bryggeriets 140 års jubilæum. Fyldig og kraftig smag af malt, samt en karakteristisk mørk farve.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Classic",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Introduceret i 1999 i anledningen med Albani bryggeriets 140 års
          jubilæum.
          <br />
          <br />
          Fyldig og kraftig smag af malt, samt en karakteristisk mørk farve.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Introduceret i 1999 i anledningen med Albani bryggeriets 140 års
        jubilæum.
        <br />
        <br />
        Fyldig og kraftig smag af malt, samt en karakteristisk mørk farve.
      </React.Fragment>
    ),
  },
  {
    varenr: 4855,
    name: "Albani Odense Classic",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 25l 25 l liter classis classisk klassisk clasic clasig classik",
    imageSingle: "produkter-fadoel.albani-odense-classic.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    fadoelsType: "Classic",
    land: "Danmark",
    landKort: "DK",
    // royalUnibrew: true,
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/classic/albani-classic",
    categories: ["fadoel", "fadoelClassic", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/classic/albani-classic",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelClassicBC],
        title: "Albani Classic",
      },
      meta: {
        title:
          "Køb Albani Odense Classic fadøl. Kan leveres eller hent i Brøndby",
        description:
          "Albani Odense Classic fadøl har en fyldig og kraftig smag af malt samt en karakteristisk mørk farve. Lanceret i forbindelse med bryggeriets 140 års jubilæum",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Classic",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Albani Odense Classic blev i 1999 i forbindelse med Albani bryggeriets
          140 års jubilæum lanceret som deres seneste skud på stammen. Odense
          Classic har en fyldig og kraftig smag af malt, samt en karakteristisk
          mørk farve.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 12655,
    name: "Depot Classic",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 19l 19 liter l vestfyen vestfyn vestfyns willemoes willemose classis classisk klassisk clasic clasig classik depotets",
    imageSingle: "produkter-fadoel.depot-classic.png",
    imagePlural: "produkter-fadoel.20-liter-staal-uden-brand.png",
    fadoelsType: "Classic",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/classic/depot-classic",
    categories: ["fadoel", "fadoelClassic", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/classic/depot-classic",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelClassicBC],
        title: "Depot Classic",
      },
      meta: {
        title: "Køb Depot Classic fadøl her - Prisbevidst classic med god smag",
        description:
          "Depot Classic er brygget ligesom en Willemoes Classic, som er en rød-gylden øl med en sød maltet aroma, hvilket den smagmæssigt også bærer præg af kombineret med en anelse bitterhed.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Classic",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Depot Classic er brygget ligesom en Willemoes Classic, som er en
          rød-gylden øl med en sød maltet aroma, hvilket den smagmæssigt også
          bærer præg af kombineret med en anelse bitterhed.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Depot Classic er brygget ligesom en Willemoes Classic, som er en
        rød-gylden øl med en sød maltet aroma, hvilket den smagmæssigt også
        bærer præg af kombineret med en anelse bitterhed.
      </React.Fragment>
    ),
  },
  {
    varenr: 21655,
    name: "Fuglsang Øko Classic",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 19l 19 liter l vestfyen vestfyn vestfyns willemoes willemose classis classisk klassisk clasic clasig classik",
    imageSingle: "produkter-fadoel.fuglsang-oeko-classic.png",
    imagePlural: "produkter-fadoel.fuglsang-20l.png",
    fadoelsType: "Classic",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/classic/fuglsang-oeko-classic",
    categories: ["fadoel", "fadoelClassic", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/classic/fuglsang-oeko-classic",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelClassicBC],
        title: "Fuglsang Øko Classic",
      },
      meta: {
        title:
          "Køb Fuglsang Økologisk Classic Fadøl - Autentisk, Økologisk Smagsoplevelse",
        description:
          "Oplev Fuglsang Økologisk Classic Fadøl - den perfekte blanding af fyldig smag og økologiske ingredienser. Ideel til hyggelige aftener.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Classic",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Opdag den unikke charme af Fuglsang økologisk Classic, en fadøl, der
          skiller sig ud med sin autenticitet og økologiske tilgang. Denne øl er
          omhyggeligt brygget ved brug af de fineste økologiske ingredienser,
          hvilket sikrer en ren og naturlig smagsoplevelse. Kendetegnet ved sin
          dybe, velafbalancerede smagsprofil, tilbyder Fuglsang Classic en rig
          og afrundet smagsoplevelse, komplet med en fin afstemt bitterhed og en
          tilfredsstillende eftersmag.
        </Paragraph>
        <Paragraph>
          Ideel for dem der søger en økologisk øloplevelse. Fuglsang økologisk
          Classic fadøl er et fremragende valg for en aften i hyggens tegn. Dens
          engagement i bæredygtighed og kvalitet gør det til et foretrukket valg
          for ølkendere og miljøbevidste forbrugere.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Opdag den unikke charme af Fuglsang økologisk Classic, en fadøl, der
        skiller sig ud med sin autenticitet og økologiske tilgang. Denne øl er
        omhyggeligt brygget ved brug af de fineste økologiske ingredienser,
        hvilket sikrer en ren og naturlig smagsoplevelse. Kendetegnet ved sin
        dybe, velafbalancerede smagsprofil, tilbyder Fuglsang Classic en rig og
        afrundet smagsoplevelse, komplet med en fin afstemt bitterhed og en
        tilfredsstillende eftersmag.
      </React.Fragment>
    ),
  },
  {
    varenr: 21645,
    name: "Fuglsang Black Bird",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 20l 20 liter l vestfyen vestfyn vestfyns willemoes willemose classis classisk klassisk clasic clasig classik blackbird",
    imageSingle: "produkter-fadoel.fuglsang-black-bird.png",
    imagePlural: "produkter-fadoel.fuglsang-20l.png",
    fadoelsType: "Classic",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/classic/fuglsang-black-bird",
    categories: ["fadoel", "fadoelClassic", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/classic/fuglsang-black-bird",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelClassicBC],
        title: "Fuglsang Black Bird",
      },
      meta: {
        title:
          "Køb Fuglsang Black Bird Fadøl - Mørk Øl med Fyldig og Kompleks Smag",
        description:
          "Oplev Fuglsang Black Bird fadøl, en mørk øl med en fyldig og kraftig smag. Brygget på en blanding af forskellige malttyper, hvilket skaber en kompleks aroma og en let sødme. Perfekt for dem, der søger en indbydende og smagfuld øloplevelse.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Classic",
      },
    },
  },
  {
    varenr: 8365,
    name: "Hancock Black Lager",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl hancook handcok hancoock hanckock hankock handcocl hancok hankoch handcock hankok blakc sort blak 15l 15 liter l dansk danske danmark",
    imageSingle: "produkter-fadoel.hancock-black-lager.png",
    imagePlural: "produkter-fadoel.hancock-fustage.png",
    fadoelsType: "Mørk Pilsner",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/classic/hancock-black-lager",
    categories: ["fadoel", "fadoelClassic", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/classic/hancock-black-lager",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelClassicBC],
        title: "Hancock Black Lager",
      },
      meta: {
        title: "Hancock fadøl - Black Lager. Velsmagende upasteuriseret øl",
        description:
          "Har du lyst til at smage en Hancock fadøl der er brygget på de gamle metoder, så er Hancock Black Lager noget for dig.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Classic",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Hancock Black Lager er brygget på lagermalt. Dette giver en mørkere
          farve samt en mere fyldig øl, end f.eks. pilsner-udgaven. På trods af
          denne øl’s større fylde, holdes alkoholstyrken på samme niveau som
          pilsnerøllen.
        </Paragraph>
      </React.Fragment>
    ),
    productPageXtraDescription: () => (
      <React.Fragment>
        <Row>
          <Col xs={12} md={6}>
            <Paragraph fontWeight="bold">
              Har du lyst til at smage en Hancock fadøl der er brygget på de
              gamle metoder, så er Hancock fadøl noget for dig.
            </Paragraph>
            <Paragraph>
              Her er der ikke tilføjet industrielt fremstillede enzymer for at
              fremskynde processen. Dette betyder at Hancock Black Lager er en
              upasteuriseret fadøl brygget stort set uden brug af
              tilsætningsstoffer ud over vand, humle, malt og gær, der er
              fadøllets ingredienser.
            </Paragraph>
            <Paragraph>
              Denne proces gør at Black Lager er 90 dage om at blive færdig på
              bryggeriet. Bryggeriet Hancock er baseret i Skive og stammer helt
              tilbage fra 1876, hvor det startede som hjemmebryggeri med
              speciale i hvidtøl.
            </Paragraph>
          </Col>
          <Col xs={12} md={6}>
            <Paragraph>
              Hancock fadøl benytter det bedste Humle til deres øl, også Black
              Lager, nemlig SAAZ-humlen fra Tjekkiet. Saaz humle også kaldet
              Böhmisk humle er verdensberømt for den gode kvalitet og blide
              smag.
            </Paragraph>
            <Paragraph>
              Hvis du har fået lyst til at smage øllen inden, at du bestiller
              den hos os, så kan du finde den hos:
            </Paragraph>
            <Paragraph>
              <LinkStyled
                external
                to="https://www.facebook.com/charliesbarkbh"
                title="Charlie's Bar"
              >
                Charlie&acute;s Bar
              </LinkStyled>
              <br />
              Pilestræde 33, 1112 København K
            </Paragraph>
            <Paragraph>
              <LinkStyled
                external
                to="https://da-dk.facebook.com/pages/Caf%C3%A9-Bl%C3%A5g%C3%A5rds-Apotek/127216877342401"
                title="Cafe Blågårds Apotek"
              >
                Cafe Blågårds Apotek
              </LinkStyled>
              <br />
              Blågårds Plads 2, 2200 København N
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Også kaldet en Høker. Upasteuriseret fadøl brygget 100% på Saaz Humle.
        <br />
        <br />
        Denne fadøl har mere fylde end mange andre almindelige pilsnere.
      </React.Fragment>
    ),
    notice: () => (
      <React.Fragment>
        <Paragraph red fontSize="13px">
          <HancockText />
        </Paragraph>
      </React.Fragment>
    ),
    smallDescription: () => (
      <React.Fragment>
        <Paragraph red fontSize="13px">
          <HancockText />
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 8385,
    name: "Hancock Old Gambrinus Dark",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl 15l 15 liter l dansk danske danmark hancock dark lager hancook handcok hancoock hanckock hankock handcocl hancok hankoch handcock hankok grambinus grambrinus cambrinus",
    imageSingle: "produkter-fadoel.hancock-old-gambrinus-dark-glas.png",
    imagePlural: "produkter-fadoel.hancock-fustage.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "Mørk Pilsner",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/classic/hancock-old-gambrinus-dark",
    categories: ["fadoel", "fadoelClassic", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/classic/hancock-old-gambrinus-dark",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelClassicBC],
        title: "Hancock Old Gambrinus Dark",
      },
      meta: {
        title: "Køb Old Gambrinus Dark her - Meget fyldig og kraftig guldøl",
        description:
          "Old Gambrinus Dark er en meget fyldig og kraftig guldøl. Til brygningen bruges dobbelt så meget pilsnermalt som til Hancock Pilsner.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Classic",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Old Gambrinus Dark er en meget fyldig og kraftig guldøl.
          <br />
          <br />
          Til brygningen bruges dobbelt så meget pilsnermalt som til Hancock
          Pilsner.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Old Gambrinus Dark er en meget fyldig og kraftig guldøl.
        <br />
        <br />
        Til brygningen bruges dobbelt så meget pilsnermalt som til Hancock
        Pilsner.
      </React.Fragment>
    ),
  },
  {
    varenr: 10655,
    name: "Herslev Øko Wiener Classic",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 20l 20 liter l økologisk økologiske bryghus klassik klassisk",
    imageSingle: "produkter-fadoel.herslev-oeko-wiener-classic-logo.jpg",
    imagePlural: "produkter-fadoel.herslev-fustage1.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "Classic",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/classic/herslev-oeko-wiener-classic",
    categories: ["fadoel", "fadoelClassic", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/classic/herslev-oeko-wiener-classic",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelClassicBC],
        title: "Herslev Øko Wiener Classic",
      },
      meta: {
        title: "Økologisk Dansk Lagerøl - Perfekt til Sommerdage og Smørrebrød",
        description:
          "Smag en økologisk lagerøl brygget i Danmark efter wienertradition. Fyldig maltsmag, let krydret finish - ideel til varme dage eller klassisk smørrebrød.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Classic",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Oplev en økologisk lagerøl brygget i Danmark med inspiration fra den
          klassiske wienertradition (4,4%). Perfekt som en forfriskende fadøl på
          en varm sommerdag eller som ledsager til dansk smørrebrød.
        </Paragraph>
        <Paragraph>
          Denne Vienna Lager er skabt med wienermalt, münchenermalt og
          karamelmalte, hvilket giver øllen en fyldig, ristet og sødlig
          maltsmag. Farven er mørkere end en pilsner, og bitterhumlen Perle
          tilfører en afbalanceret bitterhed med en blomstret og let krydret
          afslutning. Pilsner.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 8255,
    name: "Skovlyst Egebryg Classic",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 30l 30 l liter classis classisk klassisk clasic clasig classik",
    imageSingle: "produkter-fadoel.skovlyst-egebryg.png",
    imagePlural: "produkter-fadoel.skovlyst-fustage.png",
    fadoelsType: "Classic",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/classic/skovlyst-egebryg",
    categories: ["fadoel", "fadoelClassic", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/classic/skovlyst-egebryg",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelClassicBC],
        title: "Skovlyst Egebryg Classic",
      },
      meta: {
        title: "Skovlyst Egebryg - Udsøgt gylden classic fadøl",
        description:
          "Skovlyst Egebryg er en classic til dig der ønsker en fadøl lidt udover det sædvanlige.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Classic",
      },
    },
    lavLiterpris: [3655, 8255, 30, "Tuborg Classic"], // lav literpris
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Skovlyst EgeBryg - En anderledes Classic</Heading3>
        <Paragraph>
          Skovlyst Egebryg er gylden classic, der bærer præg fra de mildt
          ristede egespåner, der benyttes under brygningen.
        </Paragraph>
        <Paragraph>
          Skovlyst EgeBryg er en Classic lagerøl, som får sin delikate karakter
          fra de let ristede egespåner som de anvender i brygningen. Øllen har
          en frisk fylde, en vis bitterhed, samt afdæmpede toner af vanilje og
          blomster, som får denne Classic til at adskille sig fra alle de andre.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        En virkelig god Classic, som er et godt alternativ til Tuborg Classic.
        <br />
        <br />
        Delikat karakter som kommer fra de let ristede egespåner som anvendes i
        brygningen.
      </React.Fragment>
    ),
  },
  {
    varenr: 29655,
    name: "Skands Classic",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 20l 20 l liter",
    imageSingle: "produkter-fadoel.skands-classic.png",
    imagePlural: "produkter-fadoel.skands-fustage.png",
    fadoelsType: "Classic",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/classic/skands-classic",
    categories: ["fadoel", "fadoelClassic", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/classic/skands-classic",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelClassicBC],
        title: "Skands Classic",
      },
      meta: {
        title: "Skands Classic - Udsøgt gylden classic fadøl",
        description:
          "Skands Classic er blank-filtreret, hvilket giver en krystalklar øl, der lever op til alle forventningerne for en klassisk øl",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    productPageDescriptionLong: () => (
      <Paragraph>
        Skands Classic er en moderne fortolkning af de velkendte bayerske og
        wiener øltyper. Den er brygget med en kombination af Wienermalt,
        Münchermalt og karamelmalt, som giver øllet en fyldig aroma og en smuk
        mørkegylden farve. Humlen er anvendt i moderat mængde, hvilket bidrager
        til en velafbalanceret bitterhed. Classic er blank-filtreret, hvilket
        giver en krystalklar øl, der lever op til alle forventningerne for en
        klassisk øl.
      </Paragraph>
    ),
  },
  {
    varenr: 4755,
    name: "Thor Classic",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 20l 20 l liter classis classisk klassisk clasic clasig classik",
    imageSingle: "produkter-fadoel.thor-classic.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    fadoelsType: "Classic",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    // royalUnibrew: true,
    url: "/fadoel/classic/thor-classic",
    categories: ["fadoel", "fadoelClassic", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/classic/thor-classic",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelClassicBC],
        title: "Thor Classic",
      },
      meta: {
        title: "Køb Thor Classic fadøl. Et godt alternativ til classic fadøl",
        description:
          "Thor Classic står flot hvidt ølskum & en smuk mørk, gylden farve. Råvarerne er ens med Thor Pilsner, men sammensætningen af dem er en anden.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Classic",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Thor Classic står flot med sin hvide ølskum og en smuk mørk, gylden
          farve. Råvarerne er præcis de samme som anvendes til Thor Pilsner, men
          sammensætningen af dem er en anden. Thor Classic er mere blid og rund
          i smagen en pilsneren. Bitterheden er blevet nedtonet, uden at der gås
          på kompromis med den karakteristiske Thor-smag.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Smuk mørk, gylden farve. Råvarerne er ens med Thor Pilsner med en anden
        sammensætning.
        <br />
        <br />
        Thor Classic er mere blid og rund i smagen end pilsneren.
      </React.Fragment>
    ),
  },
  {
    varenr: 43655,
    name: "Mikkeller Iskold Classic",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 20l 20 l liter mikeler mikkeler mikeller",
    imageSingle: "produkter-fadoel.mikkeller-iskold-classic-glas.png",
    imagePlural: "produkter-fadoel.mikkeller-iskold-classic-fustage.png",
    fadoelsType: "Classic",
    land: "Danmark",
    landKort: "DK",
    // royalUnibrew: true,
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/pilsner/mikkeller-iskold-classic",
    categories: ["fadoel", "fadoelClassic", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/pilsner/mikkeller-iskold-classic",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelClassicBC],
        title: "Mikkeller Classic",
      },
      meta: {
        title:
          "Mikkeller Iskold Classic – Ravfarvet øl med rig smag af malt og honning",
        description:
          "Oplev Mikkeller Iskold Classic, en ravfarvet øl med holdbar skumkrone og rig smag af malt og honning. Perfekt til gryderetter, frokostbordet og oste.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Classic",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading2>En klassisk og rig øloplevelse</Heading2>
        <Paragraph>
          Mikkeller Iskold Classic er en elegant øl med en dyb ravfarvet nuance
          og en klar visuel præsentation, toppet med en hvid og holdbar
          skumkrone, der tilføjer ekstra appeal til øllet. Duften er en rig
          kombination af korn, malt og honning, som skaber en kompleks, men
          behagelig aroma, der straks vækker nysgerrigheden.
        </Paragraph>
        <Heading3>Afbalanceret smag med tradition</Heading3>
        <Paragraph>
          I smagen møder du en blød sødme fra malten, der bliver harmonisk
          balanceret af en forfriskende bitterhed og blid karbonering. Denne
          Vienna lager byder på en rig og dyb smagsoplevelse, der gør den både
          læskende og fyldig. En klassiker, som altid er velkommen, uanset
          lejligheden.
        </Paragraph>
        <Heading3>Perfekt til mad og selskab</Heading3>
        <Paragraph>
          Mikkeller Iskold Classic er en alsidig øl, der passer godt til mange
          forskellige retter. Uanset om du nyder en solid gryderet, dækker
          frokostbordet eller serverer oste, så vil denne øl komplementere maden
          perfekt.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 8055,
    name: "Nørrebro Øko Classic",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 20l 20 liter l bryghus økologisk økologiske classis classisk klassisk clasic clasig classik",
    imageSingle: "produkter-fadoel.noerrebro-bryghus-classic-40cl-glas.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    fadoelsType: "Classic",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    // royalUnibrew: true,
    // norrebro: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/classic/noerrebro-bryghus-classic",
    categories: ["fadoel", "fadoelClassic", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/classic/noerrebro-bryghus-classic",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelClassicBC],
        title: "Nørrebro Bryghus Classic",
      },
      meta: {
        title: "Køb Nørrebro Bryghus Classic fadøl",
        description:
          "Til brygningen af Nørrebro Bryghus’ Classic benyttes der meget munchner malt. Dette er med til at give øllet en let sødlig og blid ristet smag.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Classic",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Dansk produceret, økologisk fadøl</Heading3>
        <Paragraph>
          Til brygningen af Nørrebro Bryghus’ Classic benyttes der meget
          munchner malt. Dette er med til at give øllet en let sødlig og blid
          ristet smag.
        </Paragraph>
        <Paragraph>
          Smagen er rund og let fyldig og har en frisk duft af humle. Denne øl
          vil passe perfekt til et godt dansk frokostbord. Vi anbefaler særligt
          at drikke denne øl til retter med kalvekød, svinekød, kylling, og
          oksekød.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Til brygningen benyttes der meget munchner malt.
        <br />
        <br />
        Dette er med til at give denne øl en let sødlig og blid ristet smag.
      </React.Fragment>
    ),
  },
  {
    varenr: 4645,
    name: "Royal Øko Classic",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 20l 20 liter l faxe økologisk økologiske classis classisk klassisk clasic clasig classik",
    imageSingle: "produkter-fadoel.royal-classic.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    fadoelsType: "Classic",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    // royalUnibrew: true,
    url: "/fadoel/classic/royal-oeko-classic",
    categories: ["fadoel", "fadoelClassic", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/classic/royal-oeko-classic",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelClassicBC],
        title: "Royal Øko Classic",
      },
      meta: {
        title: "Køb Royal Øko Classic fadøl",
        description: "Royal Øko Classic",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Classic",
      },
    },
  },
  {
    varenr: 8295,
    name: "Skovlyst Øko Signatur No. 39 Dunkel Lager",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl bryghus dansk danske danmark 20l 20 liter l økologisk økologiske classis classisk klassisk clasic clasig classik",
    imageSingle: "produkter-fadoel.skovlyst-signatur-39.png",
    imagePlural: "produkter-fadoel.skovlyst-oeko-20l.png",
    fadoelsType: "Classic",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/classic/skovlyst-oeko-signatur-no-39-dunkel-lager",
    categories: ["fadoel", "fadoelClassic", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/classic/skovlyst-oeko-signatur-no-39-dunkel-lager",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelClassicBC],
        title: "Skovlyst Øko Signatur No. 39 Dunkel Lager",
      },
      meta: {
        title:
          "Skovlyst Signaturbryg No 39 Dunkel Lager - Økologisk og Forfriskende Øl",
        description:
          "Opdag Skovlyst Signaturbryg No 39 Dunkel Lager, en dyb, mørk øl med økologiske ingredienser. Nyd den perfekte balance af sødme og lette ristede toner for en udsøgt øloplevelse.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Classic",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Skovlyst Signaturbryg No 39 Dunkel Lager bringer den traditionelle
          tyske Dunkel Lager stil til live med en økologisk vri. Denne øl
          imponerer med sin dybe mørkebrune farve og en smagsrig profil, der
          elegant forener maltsødme med en antydning af let ristet smag, hvilket
          skaber en både fyldig og forfriskende drikkeoplevelse.
        </Paragraph>
        <Paragraph>
          Den glatte og rene tekstur, sammen med et moderat kulsyreindhold,
          giver en behagelig mundfølelse, der gør denne øl ideel for dem, der
          søger en afbalanceret og velsmagende øl med økologisk integritet.
          Skovlyst Signaturbryg No 39 er perfekt til at nyde for sig selv eller
          som en del af et måltid.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        <Paragraph>
          Skovlyst Signaturbryg No 39 Dunkel Lager bringer den traditionelle
          tyske Dunkel Lager stil til live med en økologisk vri. Denne øl
          imponerer med sin dybe mørkebrune farve og en smagsrig profil, der
          elegant forener maltsødme med en antydning af let ristet smag, hvilket
          skaber en både fyldig og forfriskende drikkeoplevelse.
        </Paragraph>
        <Paragraph>
          Den glatte og rene tekstur, sammen med et moderat kulsyreindhold,
          giver en behagelig mundfølelse, der gør denne øl ideel for dem, der
          søger en afbalanceret og velsmagende øl med økologisk integritet.
          Skovlyst Signaturbryg No 39 er perfekt til at nyde for sig selv eller
          som en del af et måltid.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 19655,
    name: "Svaneke Øko Classic",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl bornholm svaneke svanke sveneke svanike svinike svenike bryghus dansk danske danmark 20l 20 liter l økologisk økologiske classis classisk klassisk clasic clasig classik",
    imageSingle: "produkter-fadoel.svaneke-classic.png",
    imagePlural: "produkter-fadoel.svaneke-fustage.png",
    fadoelsType: "Classic",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/classic/svaneke-oeko-classic",
    categories: ["fadoel", "fadoelClassic", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/classic/svaneke-oeko-classic",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelClassicBC],
        title: "Svaneke Classic",
      },
      meta: {
        title: "Køb Svaneke Classic fadøl. Kan hentes i Brøndby eller leveres",
        description:
          "Svaneke Classic er en lækker fadøl fra bornholm. Denne classic fadøl fremstår flot og gylden, grundet den længere lagringsperiode.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Classic",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Bornholmsk fadøl i særklasse</Heading3>
        <Paragraph>
          Svaneke Classic er en lækker fadøl fra bornholm. Denne classic fadøl
          fremstår flot og gylden, grundet den længere lagringsperiode. Smagen
          og duften har nuancer er græs, malt og korn.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Svaneke Classic er en lækker fadøl fra bornholm. Denne classic fadøl
        fremstår flot og gylden, grundet den længere lagringsperiode.
      </React.Fragment>
    ),
  },
  {
    varenr: 19675,
    name: "Svaneke Øko Mørk Guld",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl bornholm svaneke svanke sveneke svanike svinike svenike bryghus dansk danske danmark 20l 20 liter l økologisk økologiske",
    imageSingle: "produkter-fadoel.svaneke-moerk-guld.png",
    imagePlural: "produkter-fadoel.svaneke-fustage.png",
    fadoelsType: "Mørk Pilsner",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/classic/svaneke-moerk-guld",
    categories: ["fadoel", "fadoelClassic", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/classic/svaneke-moerk-guld",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelClassicBC],
        title: "Svaneke Mørk Guld",
      },
      meta: {
        title:
          "Køb Svaneke Mørk Guld fadøl & nyd en god mørk fadøl i særklasse",
        description:
          "Svaneke Mørk Guld er en lækker fadøl fra bornholm. Mørk Guld har en smag med nuancer af nelliker og koriander, samt en eftersmag som fremstår bittersød.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Classic",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Bornholmsk fadøl i særklasse</Heading3>
        <Paragraph>
          Svaneke Mørk Guld er en lækker fadøl fra bornholm. Denne stærke
          pilsner står flot og klar med en brun farve. Mørk Guld har en smag med
          nuancer af nelliker og koriander, samt en eftersmag som fremstår
          bittersød. Denne fadøl fra Bornholm er et virkelig godt bud på en
          dansk julebryg i høj kvalitet.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Svaneke Mørk Guld er en lækker fadøl fra bornholm. Mørk Guld har en smag
        med nuancer af nelliker og koriander, samt en eftersmag som fremstår
        bittersød.
      </React.Fragment>
    ),
  },
  {
    varenr: 8475,
    name: "Démon Semimørk",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl 30l 30 l liter demon platan",
    imageSingle: "produkter-fadoel.demon-semimoerk.png",
    imagePlural: "produkter-fadoel.platan-fustage.png",
    fadoelsType: "Mørk Pilsner",
    land: "Tjekkiet",
    landKort: "CZ",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/classic/demon-semimoerk",
    categories: ["fadoel", "fadoelClassic", "fadoelUdenlandsk"],
    routeDef: {
      path: "/fadoel/classic/demon-semimoerk",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelClassicBC],
        title: "Démon Semimørk",
      },
      meta: {
        title: "Démon Semimørk - Behagelig maltet aroma og sød karamel",
        description:
          "Démon Semimørk er en tjekkisk semimørk øl med en alkoholstyrke på 5,2%. Er du til semimørk øl, vil du helt sikkert synes om denne fadøl. Køb den her.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Classic",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Brygget efter gamle klosteropskrifter</Heading3>
        <Paragraph>
          Démon Semimørk er en tjekkisk semimørk øl med en alkoholstyrke på
          5,2%. Den er sødere end Granát Classic og er velegnet til lidt tungere
          retter. Er du til semimørk øl, vil du helt sikkert synes om denne
          fadøl.
        </Paragraph>
        <Paragraph>
          Démon Semimørk er en semimørk fadøl med en behagelig maltet aroma og
          lidt sød karamel eller honningsmag med et strejf af bitterhed. Démon
          minder mest om Granát Classic, som også er en tjekkisk fadøl, men den
          er sødere i smagen og lidt stærkere. Den har et flot og lyst skum. Den
          er brygget efter gamle klosteropskrifter fra München, som kan dateres
          tilbage til 1420. Der er en tydelig aroma af karamel.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Semimørk fadøl med en behagelig maltet aroma og lidt sød smag med et
        strejf af bitterhed.
        <br />
        <br />
        Minder mest om Granát Classic, med sødere i smagen og lidt stærkere.
      </React.Fragment>
    ),
  },
  {
    varenr: 8455,
    name: "Granát Classic",
    generalSearchPriority: 900,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl granat 30l 30 l liter tjekkiet classis classisk klassisk clasic clasig classik platan",
    imageSingle: "produkter-fadoel.granat-classic.png",
    imagePlural: "produkter-fadoel.platan-fustage.png",
    fadoelsType: "Classic",
    land: "Tjekkiet",
    landKort: "CZ",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/classic/granat-classic",
    categories: ["fadoel", "fadoelClassic", "fadoelUdenlandsk"],
    routeDef: {
      path: "/fadoel/classic/granat-classic",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelClassicBC],
        title: "Granát Classic",
      },
      meta: {
        title: "Granát Classic – Køb tjekkisk fadøl til din fest her",
        description:
          "Granát Classic har en dyb granatrød farve og en aroma af malt og karamel. Den passer godt til de fleste retter. Et fint alternativ til de ordinære classic.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Classic",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Granát classic er en tjekkisk semimørk øl med en alkoholstyrke på
          4,5%. Den er mørkere end en almindelig pilsner, men ikke så mørk som
          en lagerøl. Den kan drikkes til de fleste retter.
        </Paragraph>
        <Heading3>Meget fint alternativ til de ordinære classic</Heading3>
        <Paragraph>
          Denne fadøl er af typen Classic. Dette er en classic af høj standard
          og et meget fint alternativ til de ordinære classic. Øllen er mørkere
          end Platan Pilsner, men den er stadig let i smagen. Granát Classic har
          en dyb granatrød farve og lidt skum. Den passer godt til de fleste
          retter. Aroma af malt og karamel.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Mørkere end Platan Pilsner, men stadig let i smagen.
        <br />
        <br />
        Granát Classic har en dyb granatrød farve og lidt skum. Den passer godt
        til de fleste retter.
      </React.Fragment>
    ),
  },
  {
    varenr: 8485,
    name: "Lobkowicz Tmavy",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl 30l 30 l liter tjekkiet merlin Pivovarry Lobkowiicz tjekkisk øl",
    imageSingle: "produkter-fadoel.lobkowicz-tmavy.png",
    imagePlural: "produkter-fadoel.platan-fustage.png",
    fadoelsType: "Pilsner",
    land: "Tjekkiet",
    landKort: "CZ",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/pilsner/lobkowicz-tmavy",
    categories: ["fadoel", "fadoelPilsner", "fadoelUdenlandsk"],
    routeDef: {
      path: "/fadoel/pilsner/lobkowicz-tmavy",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: "Lobkowicz Tmavy",
      },
      meta: {
        title: "Køb Lobkowicz Tmavy fadøl. En mørk hvedeøl fra tjekkiet",
        description:
          "Lobkowicz Tmavy er en såkaldt swartzbier, kendt som sort pilsner. Når glasset fyldes ses en kulsort fadøl & smagen har klare nyancer af både lakrids og kaffe",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Classic",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Lobkowicz Tmavy er en såkaldt swartzbier, også kendt som sort pilsner.
          Når glasset fyldes vil du se en kulsort fadøl, og smagen har klare
          nyancer af både lakrids og kaffe. Den let genkendelige sorte farvet er
          opnået naturligt, ved at hårdriste det malt som anvendes til øllen.
          Lobkowicz Tmavy fås udelukkende på fad.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Også kendt som sort pilsner. Hårdristet malt giver en kulsort fadøl.
        <br />
        <br />
        Smagen har klare nyancer af både lakrids og kaffe.
      </React.Fragment>
    ),
  },

  /* *
   *
   * Fadøl - IPA
   *
   * */

  {
    varenr: 4835,
    name: "Albani Mosaic IPA",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl mosaik dansk danske danmark 20l 20 liter l india indian pale ale",
    imageSingle: "produkter-fadoel.albani-mosaic-glas.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "India Pale Ale",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    // royalUnibrew: true,
    url: "/fadoel/ipa/albani-mosaic-ipa",
    categories: ["fadoel", "fadoelIPA", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/ipa/albani-mosaic-ipa",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelIpaBC],
        title: "Albani Mosaic IPA",
      },
      meta: {
        title: "Køb Albani Mosaic IPA her - Delikat India Pale Ale",
        description:
          "Albani Mosaic IPA er en India Pale Ale er brygget med Mosaic humle, som giver øllen en delikat fornemmelse. Smagen er fyldig med et hint af mango, citrus og tropefrugter.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "IPA",
      },
    },
    productPageDescriptionLong: () => (
      <Paragraph>
        Albani Mosaic IPA er en India Pale Ale er brygget med Mosaic humle, som
        giver øllen en delikat fornemmelse. Smagen er fyldig med et hint af
        mango, citrus og tropefrugter.
      </Paragraph>
    ),
    popupDescription: () => (
      <React.Fragment>
        Albani Mosaic IPA er en India Pale Ale er brygget med Mosaic humle, som
        giver øllen en delikat fornemmelse. Smagen er fyldig med et hint af
        mango, citrus og tropefrugter.
      </React.Fragment>
    ),
  },
  {
    varenr: 40635,
    name: "Anarkist New England IPA",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl mosaik dansk danske danmark 20l 20 liter l india indian pale ale anakist anarkist",
    imageSingle: "produkter-fadoel.anarkist-new-england-ipa.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "India Pale Ale",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    // royalUnibrew: true,
    url: "/fadoel/ipa/anarkist-new-england-ipa",
    categories: ["fadoel", "fadoelIPA", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/ipa/anarkist-new-england-ipa",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelIpaBC],
        title: "Anarkist New England IPA",
      },
      meta: {
        title: "Køb Anarkist New England IPA her - India Pale Ale",
        description:
          "Anarkist New England IPA - En India Pale Ale med en cremet og frugtig smag. ",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "IPA",
      },
    },
    productPageDescriptionLong: () => (
      <Paragraph>
        Anarkist New England IPA er en India Pale Ale med en cremet og frugtig
        smag. Farven er uklar med en næsten orange farve og fadøllen står med et
        meget flot skum.
      </Paragraph>
    ),
    popupDescription: () => (
      <React.Fragment>
        Anarkist New England IPA er en India Pale Ale med en cremet og frugtig
        smag. Farven er uklar med en næsten orange farve og fadøllen står med et
        meget flot skum.
      </React.Fragment>
    ),
  },
  {
    varenr: 40855,
    name: "Anarkist Hazy Juice DIPA",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl ipa dansk danske danmark 20l 20 liter l",
    // imageSingle: "produkter-oelvand.anarkist-hazy-juice-dipa.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "India Pale Ale",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    // royalUnibrew: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/ipa/anarkist-hazy-juice-dipa",
    categories: ["fadoel", "fadoelIPA", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/ipa/anarkist-hazy-juice-dipa",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelIpaBC],
        title: "Anarkist Hazy Juice DIPA",
      },
      meta: {
        title:
          "Køb Anarkist Hazy Juice DIPA her - Intens og fyldig Double India Pale Ale",
        description:
          "Anarkist Hazy Juice DIPA er en dobbelt India Pale Ale. Smagen er læskende og forfriskende med en fyldig smag med noter af juice og slik. Denne DIPA er en sand tørstslukker.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "IPA",
      },
    },
    productPageDescriptionLong: () => (
      <Paragraph>
        Anarkist Hazy Juice DIPA er en dobbelt India Pale Ale. Smagen er
        læskende og forfriskende med en fyldig smag med noter af juice og slik.
        Denne DIPA er en sand tørstslukker.
      </Paragraph>
    ),
    popupDescription: () => (
      <React.Fragment>
        Anarkist Hazy Juice DIPA er en dobbelt India Pale Ale. Smagen er
        læskende og forfriskende med en fyldig smag med noter af juice og slik.
        Denne DIPA er en sand tørstslukker.
      </React.Fragment>
    ),
  },
  {
    varenr: 40795,
    name: "Anarkist Sunny Bunny",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl mosaik dansk danske danmark 20l 20 liter l india indian pale ale anakist anarkist",
    imageSingle: "produkter-fadoel.anarkist-vacation-forever.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "Lys ale",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    // royalUnibrew: true,
    url: "/fadoel/ipa/anarkist-vacation-forever",
    categories: ["fadoel", "fadoelIPA", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/ipa/anarkist-vacation-forever",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelIpaBC],
        title: "Anarkist Vacation Forever",
      },
      meta: {
        title:
          "Anarkist Vacation Forever - Nostalgisk tur til West Coast IPA'erne",
        description:
          "Anarkist Vacation Forever er en nostalgisk øl, der tager dig tilbage til West Coast IPA'erne med et moderne twist. Med kompleks humlearoma og kraftig smag er dette en øl, der skal prøves.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "IPA",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Anarkist Vacation Forever er en øl, der vil tage dig på en nostalgisk
          rejse tilbage til West Coast IPA&apos;erne fra fordums tid, men med et
          moderne twist. Den har en klassisk maltsammensætning og er tilsat et
          finurligt strejf af havremalt, der giver en solid bund for de
          amerikanske humlesorters indspark af det, rutinerede ølkendere ville
          beskrive som ”dank” og ”piney” smagsnoter. Den er lys og let uklar med
          et frodigt skum, og dens komplekse humlearoma har noter af citrus,
          grannåle, jordbund og grapefrugt.
        </Paragraph>
        <Paragraph>
          Smagen er kraftig med en markant bitterhed og varmende alkohol, men
          med en let maltkaraktér i baggrunden. Øllets perlende karbonering og
          rensende bitterhed gør det til en fantastisk drikkeoplevelse. Prøv
          Anarkist Vacation Forever og tag på en nostalgisk tur tilbage til West
          Coast IPA&apos;erne.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Anarkist Vacation Forever er en nostalgisk øl, der tager dig tilbage til
        West Coast IPA&apos;erne med et moderne twist. Med kompleks humlearoma
        og kraftig smag er dette en øl, der skal prøves.
      </React.Fragment>
    ),
  },
  {
    varenr: 40965,
    name: "Anarkist Fizzy Lime Fusion",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl mosaik dansk danske danmark 20l 20 liter l india indian pale ale anakist anarkist",
    imageSingle: "produkter-fadoel.anarkist-fizzy-lime-fusion-glas.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "India Pale Ale",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    // royalUnibrew: true,
    url: "/fadoel/ipa/anarkist-fizzy-lime-fusion",
    categories: ["fadoel", "fadoelIPA", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/ipa/anarkist-fizzy-lime-fusion",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelIpaBC],
        title: "Anarkist Fizzy Lime Fusion",
      },
      meta: {
        title: "Udforsk Fizzy Lime Fusion Fadøl - Enestående Lime Øloplevelse",
        description:
          "Oplev Anarkist Brewing's Fizzy Lime Fusion som fadøl og dyk ned i en verden af frisk lime og forfriskende syrlighed. Perfekt for dem, der søger en unik og uforglemmelig øloplevelse. Ideal til enhver lejlighed, hvor kvalitet og smag er i højsædet.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "IPA",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading2>
          Nyd en revolutionerende smagsoplevelse med Fizzy Lime Fusion
        </Heading2>
        <Paragraph>
          Oplev en smagsrevolution med Anarkist Brewing&apos;s Fizzy Lime Fusion
          på fad, specielt udvalgt for dem, der søger det unikke og
          eventyrlystne i deres ølvalg. Denne øl er en hyldest til de dristige
          og nysgerrige sjæle, der ønsker at udforske nye smagsdimensioner. Med
          et hjerte, der banker for innovation, tilbyder Fizzy Lime Fusion en
          ekstraordinær oplevelse for enhver ølelsker.
        </Paragraph>
        <Paragraph>
          Fizzy Lime Fusion på fad inviterer dig til en verden af eksklusive
          smagsnoter. Dens levende limearoma, kombineret med en fin syrlighed og
          en forfriskende, sprudlende effekt, lover en uforglemmelig
          smagsoplevelse. Denne fadøl er mesterligt afbalanceret for at
          tilfredsstille din tørst og overraske dine smagsløg ved enhver
          anledning.
        </Paragraph>
        <Paragraph>
          Hver servering af Fizzy Lime Fusion demonstrerer Anarkist
          Brewing&apos;s engagement i at forene tradition med avantgarde. Denne
          fadøl er nøje udvalgt for sin høje kvalitet og innovativitet, sikrende
          en unik og uforglemmelig nydelse. Vælg Fizzy Lime Fusion på fad, når
          du ønsker at imponere dine gæster eller simpelthen forkæle dig selv
          med en øl, der skiller sig ud fra mængden.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 8635,
    name: "Founder's All Day IPA",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl founders indian 30l 30 l liter ipa s3 usa u.s.a. u.s.a amerika amerikansk amerikanske",
    imageSingle: "produkter-fadoel.founders-all-day-ipa-glas.png",
    imagePlural: "produkter-fadoel.all-day-ipa-fustage.png",
    land: "USA",
    landKort: "US",
    fadoelsType: "India Pale Ale",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/ipa/founders-all-day-ipa",
    categories: ["fadoel", "fadoelIPA", "fadoelUdenlandsk"],
    routeDef: {
      path: "/fadoel/ipa/founders-all-day-ipa",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelIpaBC],
        title: "Founder's All Day IPA",
      },
      meta: {
        title: "Køb Founder's All Day IPA her | En kompleks og frugtig IPA",
        description:
          "En IPA brygget naturligt med en kompleks række af malt, korn og humle. Afbalanceret for en optimal aromatisk og ren finish.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "IPA",
      },
    },
    smallDescription: () => (
      <React.Fragment>
        <Span blue marginTop="5px" fontSize="13px">
          Ingen returret.
        </Span>
      </React.Fragment>
    ),
    notice: () => (
      <Paragraph blue fontSize="13px">
        Ingen returret.
      </Paragraph>
    ),
    productPageDescriptionLong: () => (
      <Paragraph>
        En IPA brygget naturligt med en kompleks række af malt, korn og humle.
        Afbalanceret for en optimal aromatisk og ren finish. Smagen har noter af
        grapefrugt og mandarin og hele øllen føles sprød og let med en klar
        bitterhed i eftersmagen.
      </Paragraph>
    ),
    popupDescription: () => (
      <React.Fragment>
        En IPA brygget naturligt med en kompleks række af malt, korn og humle.
        Afbalanceret for en optimal aromatisk og ren finish. Smagen har noter af
        grapefrugt og mandarin og hele øllen føles sprød og let med en klar
        bitterhed i eftersmagen.
      </React.Fragment>
    ),
  },
  {
    varenr: 8435,
    name: "Lobkowicz Flying Cloud IPA",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl 30l 30 l liter tjekkiet india indian pale ale ipa platan",
    imageSingle: "produkter-fadoel.flying-cloud-ipa.png",
    imagePlural: "produkter-fadoel.platan-fustage.png",
    fadoelsType: "India Pale Ale",
    land: "Tjekkiet",
    landKort: "CZ",
    url: "/fadoel/ipa/flying-cloud-ipa",
    categories: ["fadoel", "fadoelIPA", "fadoelUdenlandsk"],
    routeDef: {
      path: "/fadoel/ipa/flying-cloud-ipa",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelIpaBC],
        title: "Flying Cloud Indian Pale Ale",
      },
      meta: {
        title: "Køb Flying Cloud Indian Pale Ale fadøl",
        description:
          "Flying Cloud IPA er tjekkernes eget bud på en Indian Pale Ale, brygget på de amerikanske humlesorter Amarillo og Cascade.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "IPA",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Tjekkerne har også fået øjnene op for, at øltypen Indian Pale Ale
          (IPA) vinder frem, og kommer derfor nu med deres eget bud. Flying
          Cloud hentyder til ét af de skibe, der i det 19. århundrede fragtede
          øl fra England til Indien og havde krydderier med hjem igen.
        </Paragraph>
        <Paragraph>
          Flying Cloud er en overgæret øl brygget på de amerikanske humlesorter
          Amarillo og Cascade. Øllen har en fyldig smag, høj bitterhed samt en
          duft/aroma af mandariner, rosiner og blomster.
        </Paragraph>
      </React.Fragment>
    ),
    gas: "CO2",
    pressure: "2,5-3 bar",
    popupDescription: () => (
      <React.Fragment>
        Overgæret øl brygget på de ameriakanske humlesorter Amarillo og Cascade.
        <br />
        <br />
        Smagen er fyldig med høj bitterhed samt en duft/aroma af mandariner,
        rosiner og blomster.
      </React.Fragment>
    ),
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
  },
  {
    varenr: 43635,
    name: "Mikkeller Iskold IPA",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 20l 20 l liter mikeler mikkeler mikeller",
    imageSingle: "produkter-fadoel.mikkeller-iskold-IPA-glas.png",
    imagePlural: "produkter-fadoel.mikkeller-iskold-IPA-fustage.png",
    fadoelsType: "India Pale Ale",
    land: "Danmark",
    landKort: "DK",
    // royalUnibrew: true,
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/pilsner/mikkeller-iskold-IPA",
    categories: ["fadoel", "fadoelIPA", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/pilsner/mikkeller-iskold-IPA",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelIpaBC],
        title: "Mikkeller IPA",
      },
      meta: {
        title:
          "Mikkeller Iskold IPA – Forfriskende øl med perfekt balance mellem humle og sødme",
        description:
          "Nyd Mikkeller Iskold IPA, en klar og læskende Cold IPA med perfekt balance mellem sødme og mild humlesmag. Perfekt til fisk, salater og let krydret mad.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "IPA",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading2>En forfriskende og læskende øloplevelse</Heading2>
        <Paragraph>
          Mikkeller Iskold IPA er en klar og undergæret øl, der imponerer med
          sin visuelle renhed og læskende karakter. Denne øl kombinerer det
          bedste fra to verdener – pilsnerens rene smagsprofil og IPA&apos;ens
          forfriskende humlesmag, hvilket skaber en unik og behagelig
          smagsoplevelse.
        </Paragraph>
        <Heading3>Perfekt afbalanceret smag</Heading3>
        <Paragraph>
          Smagen af Iskold IPA er perfekt balanceret mellem en let sødme og en
          mild, men tydelig humlesmag. Denne Cold IPA type formår at forene
          pilsnerens renhed med IPA&apos;ens karakteristiske humletoner, hvilket
          gør den forfriskende og let drikkelig, ideel til både ølentusiaster og
          nye IPA-drikkere.
        </Paragraph>
        <Heading3>Fremragende til lettere retter</Heading3>
        <Paragraph>
          Iskold IPA er den perfekte ledsager til lettere retter som fisk og
          skaldyr, salater eller let krydret mad. Den rene og humlede smag
          komplementerer disse retter på en forfriskende måde, uden at overdøve
          smagen.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 39135,
    name: "Schiøtz Gylden IPA",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl sjøts sjøtz schøtz scgjølz schøts schøits dansk danske danmark 20l 20 liter l",
    imageSingle: "produkter-oelvand.40135-flaske.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "India Pale Ale",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    // royalUnibrew: true,
    url: "/fadoel/ipa/schioetz-gylden-ipa",
    categories: ["fadoel", "fadoelIPA", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/ipa/schioetz-gylden-ipa",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelIpaBC],
        title: "Schiøtz Gylden IPA",
      },
      meta: {
        title:
          "Køb Schiøtz Gylden IPA her - Ravfarvet IPA med en sprød, humlet smag",
        description:
          "Schiøtz Gylden IPA er brygget tre forskellige typer humle, samt en diskret mængde rosmarin og hyben, for at give en frugtig og krydret eftersmag.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "IPA",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Schiøtz Gylden IPA er brygget tre forskellige typer humle, samt en
          diskret mængde rosmarin og hyben, for at give en frugtig og krydret
          eftersmag.
        </Paragraph>
        <Paragraph>
          Denne fadøl står med et ravfarvet udseende med et let rødt strejf. Når
          du skal til at tage din første tår af Schiøtz Gylden IPA vil du blive
          mødt af en stærk og frugtig humleduft før du smager på den sprøde,
          humledrevne øl, som har et let frugtigt indslag på en solid
          maltbaggrund med let karamelkarakter.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        <Paragraph>
          Schiøtz Gylden IPA er brygget tre forskellige typer humle, samt en
          diskret mængde rosmarin og hyben, for at give en frugtig og krydret
          eftersmag.
        </Paragraph>
        <Paragraph>
          Denne fadøl står med et ravfarvet udseende med et let rødt strejf. Når
          du skal til at tage din første tår af Schiøtz Gylden IPA vil du blive
          mødt af en stærk og frugtig humleduft før du smager på den sprøde,
          humledrevne øl, som har et let frugtigt indslag på en solid
          maltbaggrund med let karamelkarakter.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 8395,
    name: "Hancock Påske Bryg",
    generalSearchPriority: 1000,
    // herslevBryghus: true,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl påske ipa påskebryg påskeøl dansk danske danmark 15l 15 liter l økologisk økologiske bryghus ipa",
    imageSingle: "produkter-fadoel.hancock-paaske-bryg-glas.png",
    imagePlural: "produkter-fadoel.hancock-fustage.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "Påskebryg",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/paaskebryg/hancock-paaske-bryg",
    categories: ["fadoel", "fadoelIPA", "fadoelDansk", "fadoelPaaskebryg"],
    routeDef: {
      path: "/fadoel/paaskebryg/hancock-paaske-bryg",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPaaskebrygBC],
        title: "Hancock Påske Bryg",
      },
      meta: {
        title: "Hancock Påske Bryg - Eksklusiv Dansk Påskeøl med 10,5% Alkohol",
        description:
          "Nyd Hancock Påske Bryg - en kraftig og lagret dansk påskeøl med 10,5% alkohol. Perfekt til påskefrokost og ølentusiaster.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "IPA",
      },
    },
    smallDescription: () => (
      <React.Fragment>
        <Span blue block fontSize="13px" paddingTop="5px">
          Kan leveres fra <Datoer type="paaskebryg" />
        </Span>
      </React.Fragment>
    ),
    notice: () => (
      <Paragraph blue fontSize="13px">
        Kan leveres fra <Datoer type="paaskebryg" />
      </Paragraph>
    ),
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading2>En kraftfuld klassiker til påskebordet</Heading2>
        <Paragraph>
          Hancock Påske Bryg er en eksklusiv dansk specialøl, der gør indtryk.
          Med sine 10,5% alkohol og mørkgyldne farve er den skabt til at nyde
          langsomt - måske til en god påskefrokost eller som et særligt indslag
          i ølglasset. Den er brygget udelukkende med 100% Saaz-humle og lagret
          i hele 2 år ved 0 grader, hvilket giver en kraftig, afrundet og
          kompleks smag, der balancerer maltens fylde med en mild bitterhed.
        </Paragraph>
        <Paragraph>
          Denne påskebryg er et traditionsrigt valg for dem, der værdsætter
          dybde og håndværk i ølbrygning - en virkelig markant øloplevelse til
          forårssæsonen.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 10695,
    name: "Herslev Øko Forårsbryg",
    generalSearchPriority: 1000,
    // herslevBryghus: true,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl påske ipa påskebryg påskeøl dansk danske danmark 20l 20 liter l økologisk økologiske bryghus ipa",
    imageSingle: "produkter-fadoel.10105-flaske.png",
    imagePlural: "produkter-fadoel.herslev-fustage1.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "India Pale Ale",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/ipa/herslev-oeko-foraarsbryg",
    categories: ["fadoel", "fadoelIPA", "fadoelDansk", "fadoelPaaskebryg"],
    routeDef: {
      path: "/fadoel/ipa/herslev-oeko-foraarsbryg",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelIpaBC],
        title: "Herslev Øko Forårsbryg",
      },
      meta: {
        title: "Køb Herslev Øko Forårsbryg her - Smagsfuld økologisk IPA",
        description:
          "Herslev Øko Forårsbryg er en økologisk fadøl som passer perfekt til det store påskebord, eller til at nyde kold på en flot forårsdag",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "IPA",
      },
    },
    smallDescription: () => (
      <React.Fragment>
        <Span blue block fontSize="13px" paddingTop="5px">
          Kan leveres fra <Datoer type="paaskebryg" />
        </Span>
      </React.Fragment>
    ),
    notice: () => (
      <Paragraph blue fontSize="13px">
        Kan leveres fra <Datoer type="paaskebryg" />
      </Paragraph>
    ),
    popupDescription: () => (
      <React.Fragment>
        Herslev Forårsbryg er en økologisk fadøl brygget på Herslev bygmalt,
        tilsat lidt hvedemalt. Farven er gylden og duften er frisk og frugtig.
        Smagen har stor fylde og har en god balance mellem sødme og bitterhed,
        med et stejf af citrus.
      </React.Fragment>
    ),
  },
  {
    varenr: 10635,
    name: "Herslev Øko India Pale Ale",
    generalSearchPriority: 1000,
    // herslevBryghus: true,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl ipa dansk danske danmark 20l 20 liter l økologisk økologiske bryghus india indian pale ale ipa",
    imageSingle: "produkter-fadoel.herslev-bryghus-ipa-glas-logo.png",
    imagePlural: "produkter-fadoel.herslev-fustage1.png",
    fadoelsType: "India Pale Ale",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/ipa/herslev-india-pale-ale",
    categories: ["fadoel", "fadoelIPA", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/ipa/herslev-india-pale-ale",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelIpaBC],
        title: "Herslev India Pale Ale",
      },
      meta: {
        title: "Køb Herslev India Pale Ale fadøl",
        description:
          "Herslev India Pale Ale er en økologisk, amerikanskinspireret IPA brygget med rigelige mængder humle, som giver øllet en god, skarp bitterhed.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "IPA",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          En amerikanskinspireret IPA brygget med rigelige mængder humle, som
          giver øllet en god, skarp bitterhed samt en frisk duft af citrus,
          blomster og frugt. Herslev India Pale Ale er økologisk og gylden, og
          humlens bitterhed balanceres med fylde fra husets egne, ristede malte
          og alkoholen fra gæren. Det er en fremragende tørstslukker, og den
          smager dejligt til snacks og krydrede retter, hvor humlens bitterhed
          giver madens kraft et godt modspil.
        </Paragraph>
        <Paragraph>
          Herslev Bryghus er et økologisk gårdbryggeri beliggende i landsbyen
          Herslev ved Roskilde Fjord. Her brygges øl efter økologiske
          foreskrifter med fokus på lokale råvarer. Grundstenen i alle
          bryghusets øl er det økologiske korn, som bryggers på markerne omkring
          gården.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Herslev IPA er økologisk og gylden og inspireret af amerikanske lyse
        pale ales.
        <br />
        <br /> God, skarp bitterhed samt en frisk duft af citrus, blomster og
        frugt.
      </React.Fragment>
    ),
  },
  {
    varenr: 10795,
    name: "Herslev Øko Påskebryg",
    generalSearchPriority: 1000,
    // herslevBryghus: true,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl påske ipa dansk danske danmark 20l 20 liter l økologisk økologiske bryghus påskeøl",
    imageSingle: "produkter-fadoel.herslev-bryghus-paaskebryg-glas-logo.png",
    imagePlural: "produkter-fadoel.10105-kasse.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "India Pale Ale",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/ipa/herslev-oeko-paaskebryg",
    categories: ["fadoel", "fadoelIPA", "fadoelDansk", "fadoelPaaskebryg"],
    routeDef: {
      path: "/fadoel/ipa/herslev-oeko-paaskebryg",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelIpaBC],
        title: "Herslev Øko Påskebryg",
      },
      meta: {
        title: "Køb Herslev Øko Påskebryg fadøl online hos Skafte",
        description:
          "Herslev Øko Påskebryg på 20L fustage. Skøn påske IPA med frisk smag af forår og citrus med en dæmpet bitterhed. Vi leverer direkte til døren - Køb her.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "IPA",
      },
    },
    inStockText: () => (
      <React.Fragment>
        Udsolgt. Kommer igen 7. februar 2024{/* <Datoer type="paaskebryg" /> */}
      </React.Fragment>
    ),
    smallDescription: () => (
      <React.Fragment>
        <Span blue block fontSize="13px" paddingTop="5px">
          Kan leveres fra 7. februar 2024 {/* <Datoer type="paaskebryg" /> */}
        </Span>
      </React.Fragment>
    ),
    notice: () => (
      <Paragraph blue fontSize="13px">
        Kan leveres fra 7. februar 2024 {/* <Datoer type="paaskebryg" /> */}
      </Paragraph>
    ),
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Herslev Påskebryg er en økologisk fadøl, som er inspireret af den
          klassiske engelske IPA. Herslev Påskebryg står med en lys ravfarve, og
          smagen er frisk med en god maltfylde.
        </Paragraph>
        <Paragraph>
          Denne fadøl har en ren, lidt dæmpet bitterhed og er frisk med fine
          nuancer af blomster og citrus. Herslev Påskebryg er den perfekte
          ledsager til de lysere tider, og som smager af forår.
        </Paragraph>
        <Paragraph>
          Se også vores andre{" "}
          <LinkStyled to="/fadoel/paaskebryg">påskebryg</LinkStyled> varianter
          på fad.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        <Paragraph>
          Herslev Påskebryg er en økologisk fadøl, som er inspireret af den
          klassiske engelske IPA. Herslev Påskebryg står med en lys ravfarve, og
          smagen er frisk med en god maltfylde.
        </Paragraph>
        <Paragraph>
          Denne fadøl har en ren, lidt dæmpet bitterhed og er frisk med fine
          nuancer af blomster og citrus. Herslev Påskebryg er den perfekte
          ledsager til de lysere tider, og som smager af forår.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 18635,
    name: "Hornbeer Øko Grisk IPA",
    generalSearchPriority: 1000,
    keywords:
      "engangs engangsfustager fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 30l 30 l liter økologisk økologiske keykeg keykegs pose india indian pale ale ipa",
    imageSingle: "produkter-fadoel.hornbeer-grisk.png",
    imagePlural: "produkter-fadoel.hornbeer-fustage-1.png",
    fadoelsType: "India Pale Ale",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/ipa/hornbeer-grisk-ipa",
    categories: ["fadoel", "fadoelIPA", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/ipa/hornbeer-grisk-ipa",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelIpaBC],
        title: "Hornbeer Grisk Indian Pale Ale",
      },
      meta: {
        title: "Køb Hornbeer Grisk Indian Pale Ale fadøl",
        description:
          "Grisk er en velsmagende krydret India Pale Ale med smagsnuancer af fyrrenåle, grape, frisk humle og citrus. Den skal opleves!",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "IPA",
      },
    },
    smallDescription: () => (
      <React.Fragment>
        <Span blue block fontSize="13px" paddingTop="5px">
          Som i keykegs indeholder Hornbeer plastik engangsfustager en pose
          hvori øllet er. Kulsyre eller trykluft kommer altså ikke i direkte
          kontakt med øllet.
        </Span>
      </React.Fragment>
    ),
    notice: () => (
      <React.Fragment>
        <Paragraph blue fontSize="13px">
          Som i keykegs indeholder Hornbeer plastik engangsfustager en pose
          hvori øllet er. Kulsyre eller trykluft kommer altså ikke i direkte
          kontakt med øllet.
        </Paragraph>
      </React.Fragment>
    ),
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Dansk produceret, økologisk fadøl</Heading3>
        <Paragraph>
          Fadøllen Grisk fra Hornbeer bryggeri er efter deres egen beskrivelse
          blevet brygget til ære for den finansielle sektor. Hornbeer bryggeri
          har nøje udvalgt alkoholprocenten på 6,4%, da den er &quot;lige mellem
          bankernes indlånsrente på 0,2% og udlånsrenten på 11% – kaldet
          rentemarginalen&quot;.
        </Paragraph>
        <Paragraph>
          Grisk er en velsmagende krydret India Pale Ale med smagsnuancer af
          fyrrenåle, grape, frisk humle og citrus. Den skal opleves! Passer
          særlig godt til frokost, grill, gris, lam og fede oste.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Grisk er en velsmagende krydret India Pale Ale med smagsnuancer af
        fyrrenåle, grape, frisk humle og citrus. Den skal opleves! Passer særlig
        godt til frokost, grill, gris, lam og fede oste.
      </React.Fragment>
    ),
  },
  {
    varenr: 4635,
    name: "Royal IPA",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark india indian pale ale lys øko faxe 20l 20 l liter",
    imageSingle: "produkter-fadoel.royal-ipa-glas.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "India Pale Ale",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    // royalUnibrew: true,
    url: "/fadoel/ipa/royal-ipa",
    categories: ["fadoel", "fadoelIPA", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/ipa/royal-ipa",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelIpaBC],
        title: "Royal IPA",
      },
      meta: {
        title: "Køb Royal IPA fadøl",
        description:
          "Royal IPA er en lækker økologisk India Pale Ale, som er perfekt for dig som elsker IPA!",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "IPA",
      },
    },
  },
  {
    varenr: 29635,
    name: "Skands My Hazy IPA",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 20l 20 l liter",
    imageSingle: "produkter-fadoel.skands-my-hazy-ipa.png",
    imagePlural: "produkter-fadoel.skands-fustage.png",
    fadoelsType: "India Pale Ale",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/ipa/skands-my-hazy-ipa",
    categories: ["fadoel", "fadoelIPA", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/ipa/skands-my-hazy-ipa",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelIpaBC],
        title: "Skands My Hazy IPA",
      },
      meta: {
        title: "Køb Skands My Hazy IPA fadøl",
        description:
          "Skands My Hazy IPA er en lækker ufiltreret India Pale Ale, som skiller sig ud med sin intense og frugtagtige humlesmag og uklare, strågule farve.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    productPageDescriptionLong: () => (
      <Paragraph>
        MY HAZY IPA er en forfriskende New England IPA, der skiller sig ud med
        sin intense og frugtagtige humlesmag og uklare, strågule farve. Den er
        brygget på en blanding af Pale Ale malt, Maris Otter malt, havre og
        hvede, der giver en let krop med en juicy og uklar konsistens. Til at
        skabe den karakteristiske smag er der anvendt en kombination af Cascade
        og Mosaic humle. Øllet er overgæret og ufilteret, hvilket betyder, at
        dets naturlige aroma og smag er bevaret på bedste vis.
      </Paragraph>
    ),
  },
  {
    varenr: 8235,
    name: "Skovlyst Øko Lumberjack IPA",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl ipa dansk danske danmark 20l 20 liter l india indian pale ale ipa lumbergjack",
    imageSingle: "produkter-fadoel.skovlyst-india-pale-ale.png",
    imagePlural: "produkter-fadoel.lumberjack-20-liter-ipa.png",
    fadoelsType: "India Pale Ale",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/ipa/skovlyst-oeko-lumberjack-ipa",
    categories: ["fadoel", "fadoelIPA", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/ipa/skovlyst-oeko-lumberjack-ipa",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelIpaBC],
        title: "Skovlyst Øko Lumberjack IPA",
      },
      meta: {
        title: "Køb Skovlyst Øko Lumberjack IPA fadøl",
        description:
          "Skovlyst Øko Lumberjack IPA giver dig en kraftig og fyldig øl men klar karakter af humle. Skovlyst har udvalgt specielle humlesorter til denne fadøl.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "IPA",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Du får en kraftig, ufiltreret og fyldig øl med en skøn karakter af
          humle. Skovlyst har udvalgt specielle humlesorter, der tilfører denne
          øl en duft af blomster, græs og citrus samtidig med at du får en
          forfriskende bitterhed.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Frisk, lækker og ufiltreret lys ale med en klar karakter af humle.
        <br />
        <br />
        Kraftig og fyldig øl brygget med specielle humlesorter, som tilfører
        denne øl en duft af blomster, græs og citrus
      </React.Fragment>
    ),
  },
  {
    varenr: 8135,
    name: "Nørrebro Øko Bombay India Pale Ale",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl ipa dansk danske danmark 20l 20 liter l bryghus økologisk økologiske",
    imageSingle:
      "produkter-fadoel.noerrebro-bryghus-bombay-pale-ale-40cl-glas.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    fadoelsType: "India Pale Ale",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    // royalUnibrew: true,
    // norrebro: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/ipa/noerrebro-bryghus-bombay-pale-ale",
    categories: ["fadoel", "fadoelIPA", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/ipa/noerrebro-bryghus-bombay-pale-ale",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelIpaBC],
        title: "Nørrebro Bryghus Bombay India Pale Ale",
      },
      meta: {
        title: "Køb Nørrebro Bryghus Bombay India Pale Ale fadøl",
        description:
          "Nørrebro Bryghus Bombay India Pale Ale, dansk og økologisk fadøl, India Pale Ale brygget på økologisk Pale Ale malt",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "IPA",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Dansk produceret, økologisk fadøl</Heading3>
        <Paragraph>
          Bombay Pale Ale er Nørrebro Bryghus’ fortolkning af alle ale-elskeres
          favorit – India Pale Ale. Du får her en kraftig øl, brygget på
          økologisk Pale Ale malt, der giver øllen en dyb og intens
          maltkarakter.
        </Paragraph>
        <Paragraph>
          Du får massere af frugtige ale aromaer, som suppleres med intense
          indslag af klassiske aromahumler, som først er blevet tilsat efter
          gæringen. Med Bombay Pale Ale får du en øl med stor fylde, bitterhed
          og sødme.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Bombay Pale Ale er Nørrebro Bryghus’ fortolkning af India Pale Ale.
        <br />
        <br />
        Kraftig øl, brygget på økologisk Pale Ale malt - en øl med stor fylde,
        bitterhed og sødme.
      </React.Fragment>
    ),
  },

  /* *
   *
   * Fadøl - Brown Ale
   *
   * */
  {
    varenr: 12345,
    name: "Ale no. 16",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl ale dansk danske danmark 20l 20 liter l refsvindinge refvindinge vestfyen vestfyn vestfyns nr 16 nr. no16 ale16 aleno16 nummer no.16 nr.16 vestfyn vestfyen",
    imageSingle: "produkter-fadoel.ale-no16.png",
    imagePlural: "produkter-fadoel.20-liter-staal-uden-brand.png",
    fadoelsType: "Brown Ale",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/ale/brown-ale/ale-no-16",
    categories: ["fadoel", "fadoelAle", "fadoelBrownAle", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/ale/brown-ale/ale-no-16",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelAleBC, FadoelBrownAleBC],
        title: "Ale no. 16",
      },
      meta: {
        title: "Ale No. 16 – Populær fadøl fra Refsvindinge Bryggeri",
        description:
          "Ale No. 16 er en mørk overgæret fadøl og blev kåret som Danmarks bedste øl i 1997. I 2000 fik den en 3. plads på verdensplan. Kan købes her.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Ale",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Let ristet aroma og en tilpas sødme</Heading3>
        <Paragraph>
          Ale No. 16 er en mørk overgæret fadøl, brygget på vand, malt, humle og
          original engelsk gær. Smagen er vinøs og sødlig. Den blev brygget
          første gang i 1995 og blev kåret som Danmarks bedste øl i 1997. I 2000
          fik den desuden en 3. plads på verdensplan.
        </Paragraph>
        <Paragraph>
          Ale No. 16 kommer fra Refsvindinge Bryggeri. Det er en fadøl med en
          maltet smag med et strejf af nødder, en let ristet aroma og en tilpas
          sødme. Den er rigtig god sammen med friskbagte grovboller, ost og
          valnødder eller en lagret spegepølse. Køb Ale no. 16 her.
        </Paragraph>
      </React.Fragment>
    ),
    productPageXtraDescription: () => (
      <React.Fragment>
        <Heading3>
          Tappeaftale mellem Bryggeriet Vestfyen og Refsvindinge Bryggeri
        </Heading3>
        <Paragraph>
          I 2001 blev der på grund af en stigende efterspørgsel indgået
          tappeaftale mellem Refsvindinge Bryggeri (Ørbæk) og Bryggeriet
          Vestfyen (Assens), der har større produktionsfaciliteter.
        </Paragraph>
        <Paragraph>
          Refsvindinge Bryggeri er et gammelt landbryggeri, som ligger på Østfyn
          syd for Nyborg. Bryggeriet blev grundlagt i 1885. Det var i
          begyndelsen et hvidtølsbryggeri. Bryggeriet er kendt for at brygge
          gamle danske øltyper, som ellers er forsvundet.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        En dansk &quot;kult-øl&quot;. Mørk overgæret fadøl med en vinøs og
        sødlig smag.
        <br />
        <br />
        Brygget første gang i 1995 og kåret som DKs bedste øl i 1997. I 2000 fik
        den en 3. plads på verdensplan.
      </React.Fragment>
    ),
  },
  {
    varenr: 18875,
    name: "Hornbeer Øko Red Cow Brown Ale",
    generalSearchPriority: 1000,
    keywords:
      "engangs engangsfustager fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 30l 30 l liter økologisk økologiske keykeg keykegs pose",
    imageSingle: "produkter-fadoel.hornbeer-red-cow.png",
    imagePlural: "produkter-fadoel.hornbeer-fustage-1.png",
    fadoelsType: "Brown Ale",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/ale/brown-ale/hornbeer-red-cow",
    categories: ["fadoel", "fadoelAle", "fadoelBrownAle", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/ale/brown-ale/hornbeer-red-cow",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelAleBC, FadoelBrownAleBC],
        title: "Hornbeer Red Cow Brown Ale",
      },
      meta: {
        title: "Køb Hornbeer Red Cow Brown Ale fadøl",
        description:
          "Hornbeers 'The Red Cow' er en fadøl af typen Brown Ale, brygget med omhu efter den traditionelle engelske brown ale.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Ale",
      },
    },
    smallDescription: () => (
      <React.Fragment>
        <Span blue block fontSize="13px" paddingTop="5px">
          Som i keykegs indeholder Hornbeer plastik engangsfustager en pose
          hvori øllet er. Kulsyre eller trykluft kommer altså ikke i direkte
          kontakt med øllet.
        </Span>
      </React.Fragment>
    ),
    notice: () => (
      <Paragraph blue fontSize="13px">
        Som i keykegs indeholder Hornbeer plastik engangsfustager en pose hvori
        øllet er. Kulsyre eller trykluft kommer altså ikke i direkte kontakt med
        øllet.
      </Paragraph>
    ),
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Dansk produceret, økologisk fadøl</Heading3>
        <Paragraph>
          Hornbeers &quot;The Red Cow&quot; er en fadøl af typen Brown Ale,
          brygget med omhu efter den traditionelle engelske brown ale.
        </Paragraph>
        <Paragraph>
          Red Cow fadøl er en Brown Ale med nuancer af chokolade og ristet malt.
          Går fantastisk til retter med vildt, frokost og det danske køkken.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Red Cow fadøl er en Brown Ale med nuancer af chokolade og ristet malt.
        Går fantastisk til retter med vildt, frokost og det danske køkken.
      </React.Fragment>
    ),
  },
  {
    varenr: 4955,
    name: "Newcastle Brown Ale",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl 30l 30 l liter new newcastle Brown Ale",
    imageSingle: "produkter-fadoel.newcastle-brown-ale.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    fadoelsType: "Brown Ale",
    land: "England",
    landKort: "UK",
    // royalUnibrew: true,
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/ale/brown-ale/newcastle-brown-ale",
    categories: ["fadoel", "fadoelAle", "fadoelBrownAle", "fadoelUdenlandsk"],
    routeDef: {
      path: "/fadoel/ale/brown-ale/newcastle-brown-ale",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelAleBC, FadoelBrownAleBC],
        title: "Newcastle Brown Ale",
      },
      meta: {
        title: "Newcastle Brown Ale - let sød og blomsteragtig aroma",
        description:
          "Newcastle Brown Ale, også kaldet Newkie Brown og The dog, er en overgæret mørk ale fra Storbritannien og er blandt de mest sælgende ales i verden.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Ale",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>En af Storbritanniens førende ales</Heading3>
        <Paragraph>
          Newcastle Brown Ale er en populær overgæret mørk ale fra
          Storbritannien. Den er blevet brygget siden 1927 og brygges på
          Newcastle Breweries i Newcastle. Det er Storbritanniens mest købte
          ale. Den har en alkoholstyrke på 4,7%. Den populære engelske ale har
          fået øgenavnet The dog, som kommer fra, at når mændene i Newcastle
          ville på pub, sagde de, at de gik en tur med hunden.
        </Paragraph>
        <Paragraph>
          Newcastle Brown Ale (også kaldet Newkie Brown og The dog) er en ale,
          der blev lanceret i 1927 af oberst Jim Porter efter tre års udvikling.
          Newcastle Brown ale er brygget med lys malt og krystal malt. Den
          populære ale har en flot nøddebrun farve og et pænt skum, der dog ikke
          varer så længe. Smagen er let sød, og aromaen er blomsteragtig.
          Eftersmagen er lidt tør. The dog er en af Storbritanniens førende ales
          på flaske og er blandt de mest sælgende ales i verden.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Overgæret mørk ale fra Storbritannien. Brygget siden 1927
        <br />
        <br />
        Går under øgenavnet &quot;The dog&quot;, da mændene i Newcastle sagde de
        at de gik tur med hunden når de ville på pub.
      </React.Fragment>
    ),
  },

  /* *
   *
   * Fadøl - Mørk Ale
   *
   * */
  {
    varenr: 8825,
    name: "Grimbergen Double Ambrée",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl 30l 30 l liter dark ipa belgien belgisk ambree duble ambre grimberger grinbergen grinberger grenberg grenberger grimbergem grindberg grimbregen grindbergen",
    imageSingle: "produkter-fadoel.grimbergen-double-ambree.png",
    imagePlural: "produkter-fadoel.kronenbourg-blanc-fustage-1.png",
    fadoelsType: "Mørk Ale",
    land: "Belgien",
    landKort: "BE",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/ale/moerk-ale/grimbergen-double-ambree",
    categories: ["fadoel", "fadoelAle", "fadoelDarkAle", "fadoelUdenlandsk"],
    routeDef: {
      path: "/fadoel/ale/moerk-ale/grimbergen-double-ambree",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelAleBC, FadoelDarkAleBC],
        title: "Grimbergen Double Ambrée",
      },
      meta: {
        title: "Grimbergen Double Ambrée ØL | Bestil den Mørke Ale her - Fadøl",
        description:
          "Køb Grimbergen Double Ambrée øl online - Populær Belgisk øl. 6,5% alkohol - En øl, der skal prøves. Køb den her og lej samtidig fadølsanlæg - Hurtig fragt.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Ale",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Legendarisk nydelse</Heading3>
        <Paragraph>
          Grimbergen Double-Ambrée har en umiddelbar lethed kombineret med
          aromatisk kompleksitet. Dette gør øllen særligt velegnet til krydrede
          retter.
        </Paragraph>
        <Paragraph>
          Duften fra øllen er fyldig med noter af velmodne pærer, vanilje og
          anis, som følges op af et strejf mørkt sukker og karamel. Øllet er
          indledningsvist sødligt med en passende bitterhed, der giver en herlig
          læskende balance. Oplevelsen er blød og rund med en let brændt
          eftersmag.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Umiddelbar lethed kombineret med aromatisk kompleksitet gør øllen
        særligt velegnet til krydrede retter.
        <br />
        <br />
        Blød og rund med en let brændt eftersmag.
      </React.Fragment>
    ),
  },
  {
    varenr: 10895,
    name: "Herslev Øko Hyben",
    generalSearchPriority: 1000,
    // herslevBryghus: true,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 20l 20 liter l økologisk økologiske bryghus",
    imageSingle: "produkter-fadoel.10895-flaske.png",
    imagePlural: "produkter-fadoel.herslev-fustage1.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "Rød Ale",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/ale/moerk-ale/herslev-oeko-hyben",
    categories: ["fadoel", "fadoelAle", "fadoelDarkAle", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/ale/moerk-ale/herslev-oeko-hyben",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelAleBC, FadoelDarkAleBC],
        title: "Herslev Øko Hyben",
      },
      meta: {
        title: "Køb Herslev Øko Hyben fadøl online hos Skafte",
        description:
          "Herslev Påskebryg er en økologisk fadøl, som er inspireret af den klassiske engelske IPA. Øllen står med en lys ravfarve og smagen er frisk med en god maltfylde.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Ale",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Herslev Øko Hyben er brygget på håndplukkede hybenblomster fra området
          omkring Lejre. Farven er rød og smagen er let bitter fra humlen
          kombineret med en god frugtig sødme.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Herslev Øko Hyben er brygget på håndplukkede hybenblomster fra området
        omkring Lejre. Farven er rød og smagen er let bitter fra humlen
        kombineret med en god frugtig sødme.
      </React.Fragment>
    ),
  },
  {
    varenr: 10875,
    name: "Herslev Øko India Dark Ale",
    generalSearchPriority: 1000,
    // herslevBryghus: true,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 20l 20 liter l økologisk økologiske dark ipa bryghus",
    imageSingle: "produkter-fadoel.herslev-india-dark-ale-logo.png",
    imagePlural: "produkter-fadoel.herslev-fustage1.png",
    fadoelsType: "Mørk Ale",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/ale/moerk-ale/herslev-india-dark-ale",
    categories: ["fadoel", "fadoelAle", "fadoelDarkAle", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/ale/moerk-ale/herslev-india-dark-ale",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelAleBC, FadoelDarkAleBC],
        title: "Herslev India Dark Ale",
      },
      meta: {
        title: "Køb Herslev India Dark Ale fadøl online hos Skafte",
        description:
          "Herslev India Dark Ale er en økologisk, mørk IPA med en behagelig maltkant, som balancerer godt med de gavmilde mængder aromatisk humle.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Ale",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          IDA, som vi kalder den, er en mørk IPA med en behagelig maltkant, som
          balancerer godt med de gavmilde mængder aromatisk humle. Duften er
          frisk og frugtig og farven er mørk kastaniefarvet. Smagen er
          afbalanceret mellem New Zealandsk humle og gode, ristede malte fra
          bryghusets egne marker - blød med med masser af kant. Fremragende til
          braserede retter, hvor humlens bitterhed kan spille op mod de tunge
          smagsnuancer - eller som en helt almindelig tørstslukker. En af vores
          meget populære øl hos både mænd og især kvinder.
        </Paragraph>
        <Paragraph>
          Herslev Bryghus er et økologisk gårdbryggeri i landsbyen Herslev ved
          Roskilde Fjord. Her brygges øl efter økologiske foreskrifter med fokus
          på lokale råvarer. Grundstenen i alle bryghusets øl er det økologiske
          korn, som bryggers på markerne omkring gården.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Mørk India Ale med frisk og frugtig duft med en mørk kastaniefarvet
        glød.
        <br />
        <br />
        Humle fra New Zealand og gode, ristede malte fra bryghusets egne marker.
      </React.Fragment>
    ),
  },
  {
    varenr: 23895,
    name: "Leffe Brune",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl 30l 30 l liter dark ipa belgien belgisk",
    imageSingle: "produkter-fadoel.leffe-brune.png",
    imagePlural: "produkter-fadoel.20-liter-staal-uden-brand.png",
    fadoelsType: "Mørk Ale",
    land: "Belgien",
    landKort: "BE",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/ale/moerk-ale/leffe-brune",
    categories: ["fadoel", "fadoelAle", "fadoelDarkAle", "fadoelUdenlandsk"],
    routeDef: {
      path: "/fadoel/ale/moerk-ale/leffe-brune",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelAleBC, FadoelDarkAleBC],
        title: "Leffe Brune",
      },
      meta: {
        title: "Leffe Brune - Værdsat for sin bløde og maltede sødme",
        description:
          "Leffe Brune er en belgisk kastanjebrun klosterøl. Den har en fyldig karamelsødme og en meget mild bitterhed. Ideel til snacks, ost, vildt eller hygge.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Ale",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>
          Leffe Brune er værdsat for sin bløde og maltede sødme
        </Heading3>
        <Paragraph>
          Leffe Brune er som Leffe Blonde en autentisk klosterøl. Den har en
          flot dyb kastanjebrun farve. Den har en fyldig karamelsødme og en
          meget mild bitterhed og er derfor overordnet sød i smagen. Der er en
          god lang eftersmag. Lidt som en stout med ristet malt. Leffe Brune
          udvikler en meget kraftig skumkrone. Skummet er meget cremet og
          stabilt. Den har en begrænset kulsyreudvikling.
        </Paragraph>
        <Paragraph>
          Leffe Brune dufter af mørkristet malt og en svag antydning af æbler,
          kryddernelliker og brun farin. Leffe Brune byder på en markant blød
          sødme fra den karamelliserede malt, og Leffe Brune er af mange netop
          værdsat for sin bløde og maltede sødme. Den har en lidt tør og en
          smule bitter eftersmag.
        </Paragraph>
      </React.Fragment>
    ),
    productPageXtraDescription: () => (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <Heading3>TIP: Ideel til snacks eller ost</Heading3>
            <Paragraph>
              Leffe Brune er en øl, som du kan nyde, mens du læser en god bog,
              foran en varm pejs i aften eller bare med vennerne. Servér den
              gerne køleskabskold eller ved 8 - 12 °C. Den er god sammen med
              snacks og ost og til krydret, karamelliserede eller søde og sure
              retter. Serveret kold er den god til flødebaserede retter og
              retter med vildt. Serverer du den ’varm’, er den god som dessert
              øl, men vær opmærksom på at bitterheden øges.
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Autentisk klosterøl med en flot dyb, kastanjebrun farve.
        <br />
        <br />
        Fyldig karamelsødme og en meget mild bitterhed og er derfor overordnet
        sød i smagen. Der er en god lang eftersmag.
      </React.Fragment>
    ),
  },
  {
    varenr: 8035,
    name: "Nørrebro Øko Ravnsborg Rød",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 20l 20 liter l bryghus ravensborg økologisk økologiske",
    imageSingle:
      "produkter-fadoel.noerrebro-bryghus-ravnsborg-roed-40cl-glas.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    fadoelsType: "Rød Ale",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    // royalUnibrew: true,
    // norrebro: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/ale/moerk-ale/noerrebro-bryghus-ravnsborg-roed",
    categories: ["fadoel", "fadoelAle", "fadoelDarkAle", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/ale/moerk-ale/noerrebro-bryghus-ravnsborg-roed",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelAleBC, FadoelDarkAleBC],
        title: "Nørrebro Bryghus Ravnsborg Rød",
      },
      meta: {
        title: "Køb Nørrebro Bryghus Ravnsborg Rød fadøl",
        description:
          "Nørrebro Bryghus Ravnsborg Rød, dansk og økologisk fadøl, rød ale med en frugtig og krydret smag",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Ale",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Dansk produceret, økologisk fadøl</Heading3>
        <Paragraph>
          Køber du Ravnsborg Rød får du en variant af de velkendte britiske
          Amber eller Red Ale. Farven er rødbrun og smagen er både rund, blød og
          fyldig på samme tid.
        </Paragraph>
        <Paragraph>
          Øllet har en tæt maltkarakter samt en duft som er rig på frugt.
          Smagsmæssigt er øllen præget af frugt med et krydret indslag af
          Amarillo humle. Bitterheden er blid og Ravnsborg Rød har en cremet
          fylde.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        En variant af de velkendte britiske Amber eller Red Ale.
        <br />
        <br />
        Farven er rødbrun og smagen er både rund, blød og fyldig på samme tid.
      </React.Fragment>
    ),
  },
  {
    varenr: 29785,
    name: "Skands Blå Chimpanse",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 20l 20 l liter",
    imageSingle: "produkter-fadoel.skands-blaa-chimpanse.png",
    imagePlural: "produkter-fadoel.skands-fustage.png",
    fadoelsType: "Mørk Ale",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/ale/moerk-ale/skands-blaa-chimpanse",
    categories: ["fadoel", "fadoelAle", "fadoelDarkAle", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/ale/moerk-ale/skands-blaa-chimpanse",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelAleBC, FadoelDarkAleBC],
        title: "Skands Blå Chimpanse",
      },
      meta: {
        title:
          "Prøv Blå Chimpanse - En Belgisk Inspireret Øl Med En Liflig Duft",
        description:
          "Skands Blå Chimpanse - en belgisk inspireret øl med en markant maltsødme og en liflig frugtagtig duft. Den har en mørk kastaniebrun farve og er perfekt til mørkt kød og desserter.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    /*
    smallDescription: () => (
      <Span blue>Kan leveres fra torsdag 15/6 2023</Span>
    ),
    notice: () => (
      <Span blue>Kan leveres fra torsdag 15/6 2023</Span>
    ),
    */
    productPageDescriptionLong: () => (
      <Paragraph>
        Blå Chimpanse er en belgisk inspireret øl, der kombinerer en markant
        maltsødme med en liflig frugtagtig duft og en lav humlebitterhed. Den er
        brygget med belgisk gær, der giver en vinøs sødme og noter af tørret
        frugt. Øllets mørke kastaniebrune farve og skønne maltfylde skaber en
        afrundet smag med diskrete sødme og toner af figner, dadler og
        chokolade. Vi har tilstræbt en velafbalanceret smagsoplevelse med høj
        drinkability. Nyd Blå Chimpanse afkølet som en forfriskning og sæt den
        på menuen til mørkt kød og desserter.
      </Paragraph>
    ),
  },
  {
    varenr: 39155,
    name: "Schiøtz Mørk Mumme",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl sjøts sjøtz schøtz scgjølz schøts schøits dansk danske danmark 20l 20 liter l dark ipa mørkmumme",
    imageSingle: "produkter-fadoel.40155-flaske.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "Mørk Ale",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    // royalUnibrew: true,
    url: "/fadoel/ale/moerk-ale/schioetz-moerk-mumme",
    categories: ["fadoel", "fadoelAle", "fadoelDarkAle", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/ale/moerk-ale/schioetz-moerk-mumme",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelAleBC, FadoelDarkAleBC],
        title: "Schiøtz Mørk Mumme",
      },
      meta: {
        title: "Schiøtz Mørk Mumme | Køb den kobberfarvede fadøl her",
        description:
          "Schiøtz Mørk Mumme har en dyb mørk farve af kobber. Smagen domineres af malt med lidt sødme, en let smag af bær og en finish af god humlebitterhed.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Craft",
      },
    },
    productPageDescriptionLong: () => (
      <Paragraph>
        Schiøtz Mørk Mumme står med en klar og ufiltreret dyb mørk farve af
        kobber. Smagen domineres af malt med lidt sødme, en let smag af bær og
        en finish af god humlebitterhed. Sødmen bliver afbalanceret af lette
        noter af mørk chokolade, kaffe og lakrids. Eftersmagen er ret lang og
        frugtig og har en god mængde bitterhed fra humlen.
      </Paragraph>
    ),
    popupDescription: () => (
      <React.Fragment>
        Schiøtz Mørk Mumme står med en klar og ufiltreret dyb mørk farve af
        kobber. Smagen domineres af malt med lidt sødme, en let smag af bær og
        en finish af god humlebitterhed. Sødmen bliver afbalanceret af lette
        noter af mørk chokolade, kaffe og lakrids. Eftersmagen er ret lang og
        frugtig og har en god mængde bitterhed fra humlen.
      </React.Fragment>
    ),
  },

  /* *
   *
   * Fadøl - Lys Ale
   *
   * */
  {
    varenr: 4995,
    name: "Affligem Blonde",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl 30l 30 l liter belgien belgisk affligen afligen afligem",
    imageSingle: "produkter-fadoel.4895-flaske.png",
    imagePlural: "produkter-fadoel.20-liter-staal-uden-brand.png",
    land: "Belgien",
    landKort: "BE",
    fadoelsType: "Lys Ale",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    // royalUnibrew: true,
    url: "/fadoel/ale/lys-ale/affligem-blonde",
    categories: ["fadoel", "fadoelAle", "fadoelLysAle", "fadoelUdenlandsk"],
    routeDef: {
      path: "/fadoel/ale/lys-ale/affligem-blonde",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelAleBC, FadoelLysAleBC],
        title: "Affligem Blonde",
      },
      meta: {
        title:
          "Køb Affligem Blonde fadøl online | Gylden lys ale med forfriskende god smag",
        description:
          "Affligem Blonde er en gylden lys ale med en afbalanceret aroma af frisk brød, banan, citrus og et hint af vanilje.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Ale",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Affligem Blonde er en gylden lys ale med en afbalanceret aroma af
          frisk brød, banan, citrus og et hint af vanilje.
        </Paragraph>
        <Paragraph>
          Smagen er glat og salt med en nøddeagtig blødhed, efterfulgt af en
          rund smag af tropisk frugt. Eftersmagen er tør og let bitter.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        <Paragraph>
          Affligem Blonde er en gylden lys ale med en afbalanceret aroma af
          frisk brød, banan, citrus og et hint af vanilje.
        </Paragraph>
        <Paragraph>
          Smagen er glat og salt med en nøddeagtig blødhed, efterfulgt af en
          rund smag af tropisk frugt. Eftersmagen er tør og let bitter.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 40795,
    name: "Anarkist Sunny Bunny",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl mosaik dansk danske danmark 20l 20 liter l india indian pale ale anakist anarkist",
    // imageSingle: "produkter-fadoel.anarkist-vacation-forever.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "Lys ale",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    // royalUnibrew: true,
    url: "/fadoel/ale/lys-ale/anarkist-sunny-bunny",
    categories: ["fadoel", "fadoelAle", "fadoelLysAle", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/ale/lys-ale/anarkist-sunny-bunny",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelIpaBC],
        title: "Anarkist Sunny Bunny",
      },
      meta: {
        title: "Anarkist Sunny Bunny Øl - En Eksotisk Smagsoplevelse",
        description:
          "Oplev Anarkist Sunny Bunny, en unik øl inspireret af østens sol. Nyd en kompleks aroma med noter af citrongræs, korianderfrø, lime, tropiske frugter, og honningmelon. Perfekt til krydret mad eller som en solo nydelse.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "IPA",
      },
    },
  },
  {
    varenr: 41625,
    name: "Fynsk Forår",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl ørbæk øko dansk danske danmark 20l 20 liter l ørbæk naturfrisk",
    imageSingle: "produkter-fadoel.fynsk-foraar-glas.png",
    imagePlural: "produkter-fadoel.fynsk-foraar-fustagel.png",
    fadoelsType: "Lys Ale",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/ale/lys-ale/fynsk-foraar",
    categories: [
      "fadoel",
      "fadoelAle",
      "fadoelLysAle",
      "fadoelPaaskebryg",
      "fadoelDansk",
    ],
    routeDef: {
      path: "/fadoel/ale/lys-ale/fynsk-foraar",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelAleBC, FadoelLysAleBC],
        title: "Fynsk Forår",
      },
      meta: {
        title: "Fynsk Forår - Køb den guldvindende lyse ale på fustage her",
        description:
          "Fynsk Forår på fad - En skøn lys, frisk og let fadøl, der emmer af forår, håb og glæde. Velbrygget med økologisk hyldeblomst. Køb din fustage her!",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Ale",
      },
    },
    smallDescription: () => <Span paddingTop="5px">Sælges året rundt</Span>,
    notice: () => <Paragraph>Sælges året rundt</Paragraph>,
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Fynsk Forår har en dejlig smag af blomster og dansk forår, da der
          tilføjes hyldeblomst under brygningen af øllen. Foruden hyldeblomst,
          dufter Fynsk Forår skønt af lette og søde frugter som druer, pærer og
          bananer samt en belgisk ale gær, der tilsættes til øllet. Tilsammen
          skabes den perfekte følelse og smag af forår.
        </Paragraph>
        <Paragraph>
          Hos Skafte finder du Fynsk Forår fadøl på fustage på 20L, perfekt til
          påskefrokosten, sommerfesten, fødselsdage og virksomhedsarrangementer.
        </Paragraph>
        <Paragraph>
          Denne øl er opkaldt efter et korværk af den fynske komponist Carl
          Nielsen (1865-1931), der selv var født og opvokset i det sydfynske. I
          korværket beskriver han hvordan det fynske forår mærkes, hvilket Ørbæk
          Bryghus lod sig inspirere af og kom på følelsen på flaske.
        </Paragraph>
        <Paragraph>
          Fynsk Forår er guldvinder ved The European Beer Star Award, i Nürnberg
          ud af 688 øl ved blindsmagning af 65 eksperter.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        <Paragraph>
          Fynsk Forår har en dejlig smag af blomster og dansk forår, da der
          tilføjes hyldeblomst under brygningen af øllen. Foruden hyldeblomst,
          dufter Fynsk Forår skønt af lette og søde frugter som druer, pærer og
          bananer samt en belgisk ale gær, der tilsættes til øllet. Tilsammen
          skabes den perfekte følelse og smag af forår.
        </Paragraph>
        <Paragraph>
          Hos Skafte finder du Fynsk Forår fadøl på fustage på 20L, perfekt til
          påskefrokosten, sommerfesten, fødselsdage og virksomhedsarrangementer.
        </Paragraph>
        <Paragraph>
          Denne øl er opkaldt efter et korværk af den fynske komponist Carl
          Nielsen (1865-1931), der selv var født og opvokset i det sydfynske. I
          korværket beskriver han hvordan det fynske forår mærkes, hvilket Ørbæk
          Bryghus lod sig inspirere af og kom på følelsen på flaske.
        </Paragraph>
        <Paragraph>
          Fynsk Forår er guldvinder ved The European Beer Star Award, i Nürnberg
          ud af 688 øl ved blindsmagning af 65 eksperter.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 10625,
    name: "Herslev Øko Høst",
    generalSearchPriority: 1000,
    // herslevBryghus: true,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 20l 20 liter l økologisk økologiske bryghus saison",
    imageSingle: "produkter-fadoel.herslev-label-hoest.jpg",
    imagePlural: "produkter-fadoel.herslev-fustage1.png",
    fadoelsType: "Lys Ale",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/ale/lys-ale/herslev-hoest",
    categories: ["fadoel", "fadoelAle", "fadoelLysAle", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/ale/lys-ale/herslev-hoest",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelAleBC, FadoelLysAleBC],
        title: "Herslev Pale Ale",
      },
      meta: {
        title: "Herslev Øko Høst fadøl leveres i hele storkøbenhavn",
        description:
          "Lækker Saison-fadøl, let humlet med en frisk syrlighed og krydret og frugtig smag.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Ale",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Lækker Saison-fadøl, let humlet med en frisk syrlighed og krydret og
          frugtig smag.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Lækker Saison-fadøl, let humlet med en frisk syrlighed og krydret og
        frugtig smag.
      </React.Fragment>
    ),
  },
  {
    varenr: 23885,
    name: "Leffe Blonde",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl 20l 20 l liter belgien belgisk",
    imageSingle: "produkter-fadoel.leffe-blonde.png",
    imagePlural: "produkter-fadoel.20-liter-staal-uden-brand.png",
    fadoelsType: "Lys Ale",
    land: "Belgien",
    landKort: "BE",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/ale/lys-ale/leffe-blonde",
    categories: ["fadoel", "fadoelAle", "fadoelLysAle", "fadoelUdenlandsk"],
    routeDef: {
      path: "/fadoel/ale/lys-ale/leffe-blonde",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelAleBC, FadoelLysAleBC],
        title: "Leffe Blonde",
      },
      meta: {
        title:
          "Leffe Blonde. Servér den køleskabskold som apéritif eller aftenøl",
        description:
          "Leffe Blonde er en autentisk belgisk klosterøl, der har rødder tilbage til 1240. Den er Belgiens mest populære klosterøl. Den har en fyldig, frugtig sødme.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Ale",
      },
    },
    /*
    smallDescription: () => (
      <React.Fragment>
        <Span bold italic>
          Begrænset antal på tilbud. Bedst før 19. maj 2023.<br />Så længe lager haves.
        </Span>
      </React.Fragment>
    ),
    notice: () => (
      <Paragraph bold italic>
        Begrænset antal på tilbud. Bedst før 19. maj 2023.<br />Så længe lager haves.
      </Paragraph>
    ),
    */
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Leffe Blonde har en fyldig, frugtig sødme</Heading3>
        <Paragraph>
          Leffe Blonde [LEF blond] er en autentisk belgisk klosterøl, der har
          rødder tilbage til 1240. Den er Belgiens mest populære klosterøl.
          Leffe Blonde er brygget efter de traditionelle opskrifter i klosteret
          Leffe. Udvalgte aromatiske malte giver øllet sin dybe gyldne farve og
          fyldig frugtagtig, let krydret smag. Den udvikler en kraftig hvid, let
          perlende og vedholdende skumkrone. Den har en sporadisk
          kulsyreudvikling.
        </Paragraph>
        <Paragraph>
          Leffe Blonde er en overgæret korngylden klosterøl med tilknytning til
          det driftige kloster Abbaye Notre-Dame de Leffe i Belgien. Den er en
          bleg blonde ale, der er fuld og cremet med antydninger af kvæde,
          stikkelsbær, bitter kirsebær og æblesmag sammen med nellike, muskatnød
          og allehånde for en sød finish. Den er brygget på gyldne malte og
          tilsat den fineste aromahumle. Leffe Blonde har en fyldig, frugtig
          sødme med en kortvarig underspillet bitterhed, der giver øllet en rund
          harmonisk fylde. En del af indtægterne fra salget af Leffe Blonde går
          til munkenes arbejde og drift af klosteret.
        </Paragraph>
      </React.Fragment>
    ),
    productPageXtraDescription: () => (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <Heading3>
              TIP: Servér den køleskabskold som apéritif eller aftenøl
            </Heading3>
            <Paragraph>
              Serverer du Leffe Blonde iskold fra køleskabet, kan du servere den
              til næsten alt. Den er velegnet som apéritif eller aftenøl. Ønsker
              du at opleve dens fulde kompleksitet, så servér den ved fx 10-12
              grader.
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Autentisk belgisk klosterøl, der har rødder tilbage til 1240. Udvalgte
        aromatiske malte giver øllet sin dybe gyldne farve og fyldig frugtagtig,
        let krydret smag.
      </React.Fragment>
    ),
  },
  /*
  {
    varenr: 22095,
    name: 'NidaBeer Pale Ale',
    generalSearchPriority: 1000,
    keywords: 'fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 30l 30 l liter',
    imageSingle: 'produkter-fadoel.22095-flaske.png',
    imagePlural: 'produkter-fadoel.hornbeer-fustage-1.png',
    land: 'Danmark',
    landKort: 'DK',
    fadoelsType: 'Lys Ale',
    gas: 'CO2',
    pressure: '2,5-3 bar',
    productTypeFadoel: true,
    invoiceDisplay: 'FustageItemRow',
    url: '/fadoel/ale/lys-ale/nidabeer-pale-ale',
    categories: ['fadoel', 'fadoelAle', 'fadoelLysAle', 'fadoelDansk'],
    routeDef: {
      path: '/fadoel/ale/lys-ale/nidabeer-pale-ale',
      component: loadable(() => import('../../../pages/produktsider/General')),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelAleBC, FadoelLysAleBC],
        title: 'NidaBeer Pale Ale',
      },
      meta: {
        title: 'Køb NidaBeer Pale Ale her - God gammeldags pale ale',
        description: 'En god dansk Pale Ale brygget hos det lokale bryggeri NidaBeer i Hvidovre. Denne Pale Ale er stærk på humle og har fået skruet en tand ned for bitterheden.',
      },
      props: {
        menuPicker: 'Fadoel',
        activeSidemenu: 'Ale',
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        En god dansk Pale Ale brygget hos det lokale bryggeri NidaBeer i Hvidovre. Denne Pale Ale er stærk på humle og har fået skruet en tand ned for bitterheden.
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        En god dansk Pale Ale brygget hos det lokale bryggeri NidaBeer i Hvidovre. Denne Pale Ale er stærk på humle og har fået skruet en tand ned for bitterheden.
      </React.Fragment>
    ),
  },
  */
  {
    varenr: 8845,
    name: "Grimbergen Blonde",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl 30l 30 l liter belgien belgisk grimberger grinbergen grinberger grenberg grenberger grimbergem grindberg grimbregen grindbergen",
    imageSingle: "produkter-fadoel.grimbergen-blonde.png",
    imagePlural: "produkter-fadoel.grimbergen-fustage.png",
    fadoelsType: "Lys Ale",
    land: "Belgien",
    landKort: "BE",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/ale/lys-ale/grimbergen-blonde",
    categories: ["fadoel", "fadoelAle", "fadoelLysAle", "fadoelUdenlandsk"],
    routeDef: {
      path: "/fadoel/ale/lys-ale/grimbergen-blonde",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelAleBC, FadoelLysAleBC],
        title: "Grimbergen Blonde",
      },
      meta: {
        title: "Køb Grimbergen Blonde fadøl og bestil online med fadølsanlæg",
        description:
          "Grimbergen Blonde har en frugtig sødme og lav bitterhed. Duften domineres af tydelige frugtige noter af banan, grønne æbler, syltede pærer og honningmelon.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Ale",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Legendarisk nydelse</Heading3>
        <Paragraph>
          Grimbergen Blonde har en frugtig sødme og lav bitterhed. Denne øl er
          derfor meget anvendelig til tilberedning af mad. Grimbergen Blonde kan
          i særdeleshed anbefales til lyst kød såsom kanin, kalkun og kylling.
          Øllen vil dog også passe godt sammen med fed fisk som eksempelvis
          laks, eller sammen med friske pil-selv rejer.
        </Paragraph>
        <Paragraph>
          Duften domineres af tydelige frugtige noter af banan, grønne æbler,
          syltede pærer og honningmelon. Vælger du en Grimbergen Blonde, så får
          du en øl med en elegant balance imellem sødme og let bitterhed.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Frugtig sødme og lav bitterhed og er derfor meget anvendelig til
        tilberedning af mad.
        <br />
        <br />
        En elegant balance imellem sødme og let bitterhed
      </React.Fragment>
    ),
  },

  /* *
   *
   * Fadøl - Porter
   *
   * */
  {
    varenr: 2745,
    name: "Guinness",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl 20l 20 liter l guiness guines ginness ginnes gines guiniss guinis guinnesss irland irsk guinees",
    imageSingle: "produkter-fadoel.guinness.png",
    imagePlural: "produkter-fadoel.20-liter-staal-uden-brand.png",
    fadoelsType: "Stout",
    land: "Irland",
    landKort: "IE",
    gas: "Blandingsgas",
    pressure: "2-2,5 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/porter/guinness",
    categories: ["fadoel", "fadoelPorter", "fadoelUdenlandsk"],
    routeDef: {
      path: "/fadoel/porter/guinness",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPorterBC],
        title: "Guinness",
      },
      meta: {
        title: "Guinness øl – Populær irsk porter brygget på ristet byg",
        description:
          "Guinness er en mørk, overgæret øl, der har en dyb sort farve med et hvidt cremet skum og en kraftig smag af afristet byg. Køb Guinness her.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Porter",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Guinness er en populær irsk øl. Den bliver brygget på malt, byg og
          ristet byg. Den stærkt ristede byg giver den meget karakteristiske
          tørre smag, der gør, at Guinness adskiller sig fra fx en tysk
          Schwartzbier, hvor der bruges ristet malt. Køb Guinness her og lej
          også et fadølsanlæg hos os.
        </Paragraph>
      </React.Fragment>
    ),
    productPageXtraDescription: () => (
      <React.Fragment>
        <Row>
          <Col xs={12} md={6}>
            <Heading3>Sådan skænkes Guinness fadøl korrekt:</Heading3>
            <TheVideoPlayer url="XNpEyrcJer8" thumbnail="guinness" />
          </Col>
          <Col xs={12} md={6}>
            <Heading3>Det tager 2 minutter at skænke en Guinness</Heading3>
            <Paragraph>
              Åben hanen hurtigt, ingen kontakt til glasset. Tap i 45°.
            </Paragraph>
            <Paragraph>Luk hurtigt hanen når glasset er 3/4 fyldt.</Paragraph>
            <Paragraph>Vent ca. 1,5 min. på at skummet falder.</Paragraph>
            <Paragraph>Top øllen op med hanen bagover.</Paragraph>
          </Col>
        </Row>
        <Row borderTop="2px #ddd solid" marginTop paddingTop>
          <Col xs={12} md={6}>
            <Heading3>Guinness er en meget mørk, overgæret øl</Heading3>
            <Paragraph>
              Guinness er en meget mørk, overgæret øl, der har en dyb sort farve
              med et hvidt cremet skum og en kraftig smag af afristet byg. Det
              er iøvrigt Guinness&acute; direktør, der i 1951 startede det vi nu
              kender som{" "}
              <LinkStyled
                external
                to="http://www.guinnessworldrecords.com/"
                title="The Guinness Book of World Records"
              >
                The Guinness Book of World Records
              </LinkStyled>
            </Paragraph>
            <Heading3>Nyd din Guinness kold</Heading3>
            <Paragraph>
              Guinness er en bitter øl med et strejf af sødme, som er velegnet
              til lidt tunge og salte retter. Den passer også godt til fx retter
              med chokolade. Guinness udvikler bedst sin fulde aroma, når den
              bliver serveret ved 6-7 C°.
            </Paragraph>
          </Col>
          <Col xs={12} md={6}>
            <Heading3>Arthur Guinness startede med at brygge ale</Heading3>
            <Paragraph>
              Arthur Guinness (1725–1803) ankom til Dublin i Irland med drømmen
              om at brygge øl, og den drøm blev til virkelighed. I starten
              bryggede Arthur ale, men senere begyndte han at brygge sin egen
              porter. Guinness-porteren blev hurtigt så populær i Dublin, at han
              stoppede med at brygge ale. Den første variant af den Guinness, vi
              kender i dag, går tilbage til 1801. Bryggeriet har haft en utrolig
              stor betydning for Dublin og Irland i det hele taget.
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Mørk, overgæret øl af typen Stout, som i populær dansk tale også går
        under navnet porter.
        <br />
        <br />
        Den stærkt ristede byg giver den meget karakteristiske tørre smag.
      </React.Fragment>
    ),
  },
  {
    varenr: 4945,
    name: "Murphy's Irish Stout",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl 30l 30 l liter irland irsk murphys murphys marphys murfys murphus murphees murfys murfy murfey irland irsk",
    imageSingle: "produkter-fadoel.4835-flaske.png",
    imagePlural: "produkter-fadoel.newcastle-fustage.png",
    land: "Irland",
    landKort: "IE",
    fadoelsType: "Stout",
    gas: "Blandingsgas",
    pressure: "2-2,5 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    // royalUnibrew: true,
    url: "/fadoel/porter/murphys-irish-stout",
    categories: ["fadoel", "fadoelPorter", "fadoelUdenlandsk"],
    routeDef: {
      path: "/fadoel/porter/murphys-irish-stout",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPorterBC],
        title: "Murphy's Irish Stout",
      },
      meta: {
        title:
          "Køb Murphy's Irish Stout her | En lettere og mindre bitter Stout",
        description:
          "Murphy's Irish Stout har en flot sort farve og er en lettere og mindre bitter Stout end konkurrenten Guiness.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Porter",
      },
    },
    productPageDescriptionLong: () => (
      <Paragraph>
        Murphy&apos;s Irish Stout er brygget med henblik på at være en lettere
        og mindre bitter Stout end konkurrenten Guiness.
        <br />
        <br />
        Med en helt sort farve og en dejlig smag af karamel og malt, får du her
        en særdeles god fadøl.
      </Paragraph>
    ),
    popupDescription: () => (
      <React.Fragment>
        Murphy&apos;s Irish Stout er brygget med henblik på at være en lettere
        og mindre bitter Stout end konkurrenten Guiness.
        <br />
        <br />
        Med en helt sort farve og en dejlig smag af karamel og malt, får du her
        en særdeles god fadøl.
      </React.Fragment>
    ),
  },

  /* *
   *
   * Fadøl - Letøl
   *
   * */
  {
    varenr: 33615,
    name: "Teedawn Gentle Lager Alkoholfri",
    generalSearchPriority: 1000,
    // herslevBryghus: true,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl letøl alkoholfri frokostøl alkoholdfri alkolholfri alkoholfrit øl uden let light ølight øllight light øl dansk danske danmark 20l 20 liter l økologisk økologiske herslev bryghus",
    imageSingle: "produkter-fadoel.teedawn-gentle-lager-glas.png",
    imagePlural: "produkter-fadoel.teedawn-fustage.png",
    fadoelsType: "Pilsner",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/letoel/teedawn-gentle-lager-alkoholfri",
    categories: ["fadoel", "fadoelLetoel", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/letoel/teedawn-gentle-lager-alkoholfri",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelLetoelBC],
        title: "Teedawn Gentle Lager Alkoholfri",
      },
      meta: {
        title:
          "Teedawn Gentle Lager Alkoholfri - En Skånsom Alkoholfri Pilsner",
        description:
          "Teedawn Gentle Lager Alkoholfri er en delikat alkoholfri pilsnerøl, brygget med omhu og præcision. Nyd den forfriskende, klassiske smag med en mild bitter eftersmag. Udforsk vores alkoholfri øl, brygget med naturhumler, sydtysk malt og bjergkildevand",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Letoel",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Gentle Lager Alkoholfri er muligvis den mest skånsomt bryggede
          alkoholfri øl med en alkoholprocent på 0,0 % i vores tid. Denne øl
          byder på en forfriskende, klassisk smag med mineralske nuancer, der
          giver dig en læskende fornemmelse og en sjældent langvarig, behagelig
          bitter eftersmag. Vores mestre inden for alkoholfri ølbrygning,
          Johannes og Hans-Martin, skaber denne øl ved hjælp af førsteklasses
          naturhumler (Hallertauer Tradition, Perle, Spalter Select), 100 %
          sydtysk Pilsnermalt, husgær og det bløde, friske kildevand fra
          Schwarzwalds bjerge.
        </Paragraph>
        <Paragraph>
          Når Johannes og Berthold har brygget grundøllen, gennemgår den en
          omhyggelig proces i vores unikke vakuumdestillator ved lave
          temperaturer og lavt tryk. Dette fjerner alkoholen, samtidig med at
          alle aromaer, mineraler og vitaminer bevares. Til sidst aftappes
          Gentle Lager Alkoholfri ved kølige forhold.
        </Paragraph>
        <Paragraph>
          Vi håber, at du vil udforske den delikate smag af Gentle Lager
          Alkoholfri og opleve den nøje udvalgte kombination af ingredienser,
          der gør den til en harmonisk fornøjelse for dine smagsløg.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Harmonisk, mineralsk, rank og humlet alkoholfri pilnser i klasse A
      </React.Fragment>
    ),
  },
  {
    varenr: 33685,
    name: "Teedawn Wheat Dreams Alkoholfri",
    generalSearchPriority: 1000,
    // herslevBryghus: true,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl letøl alkoholfri frokostøl alkoholdfri alkolholfri alkoholfrit øl uden let light ølight øllight light øl dansk danske danmark 20l 20 liter l økologisk økologiske herslev bryghus",
    imageSingle: "produkter-fadoel.teedawn-wheat-dreams-glas.png",
    imagePlural: "produkter-fadoel.teedawn-fustage.png",
    fadoelsType: "Hvedeøl",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/letoel/teedawn-wheat-dreams-alkoholfri",
    categories: ["fadoel", "fadoelLetoel", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/letoel/teedawn-wheat-dreams-alkoholfri",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelLetoelBC],
        title: "Teedawn Wheat Dreams Alkoholfri",
      },
      meta: {
        title:
          "Teedawn Wheat Dreams Alkoholfri - En Forførende Hvedeøl Uden Alkohol",
        description:
          "Teedawn Wheat Dreams Alkoholfri er en sofistikeret og cremet hvedeøl med en mild syrlig smag og en forfriskende karakter. Udforsk denne unikke alkoholfri hvedeøl, brygget med førsteklasses ingredienser og kildevand fra Schwarzwald",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Letoel",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Teedawn Wheat Dreams Alkoholfri præsenterer en hvedeøl, der imponerer
          med sin sofistikerede karakter og fyldige smag. Den byder på en mild
          syrlighed og en forfriskende smagsoplevelse, der vil glæde dine
          smagsløg. Denne hvedeøl er enestående og brygges med omhu ved hjælp af
          førsteklasses naturhumle (Hallertauer Magnum, Perle, Hallertauer
          Tradition), sydtysk bygmalt (Hvedemalt, Pilsnermalt og Wienermalt),
          husgær og det bløde, friske kildevand fra bjergene i Schwarzwald.
        </Paragraph>
        <Paragraph>
          Navnet &quot;Wheat Dreams&quot; blev valgt med omhu, da denne øl
          repræsenterer et enestående eksempel på håndværket bag en 100 %
          alkoholfri hvedeøl. Hvedeøl har en særlig plads i ølverdenen, og denne
          udgave hylder den tyske ølkulturs essens.
        </Paragraph>
        <Paragraph>
          Teedawns to verdensmestre inden for alkoholfri ølbrygning, Johannes og
          Berthold, har investeret flere år i at skabe en overgæret
          &quot;Meisterstuck&quot; af en hvedeøl, der byder på en rig duft,
          smag, farve og fylde. Efter grundbrygningen undergår øllen en nøje
          proces i en specialdesignet vakuumdestillator ved lave temperaturer og
          lavt tryk. Dette fjerner alkoholen, samtidig med at alle aromaer,
          mineraler og vitaminer bevares intakte. Derefter aftappes Wheat Dreams
          i 20 liters fustager, og resultatet er en af verdens bedste alkoholfri
          hvedeøl.
        </Paragraph>
        <Paragraph>
          Du inviteres til at udforske og nyde smagen af Teedawn Wheat Dreams
          Alkoholfri og opleve håndværket bag denne unikke hvedeøl, som er en
          kilde til stolthed.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Silkeblød, frugtig alkoholfri hvedeløl med røgnoter
      </React.Fragment>
    ),
  },
  {
    varenr: 10665,
    name: "Herslev Øko Landøl",
    generalSearchPriority: 1000,
    // herslevBryghus: true,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl letøl alkoholfri frokostøl alkoholdfri alkolholfri alkoholfrit øl uden let light ølight øllight light øl dansk danske danmark 20l 20 liter l økologisk økologiske herslev bryghus",
    imageSingle: "produkter-fadoel.herslev-label-landoel.jpg",
    imagePlural: "produkter-fadoel.herslev-fustage1.png",
    fadoelsType: "Lys Ale",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/letoel/herslev-oeko-landoel",
    categories: ["fadoel", "fadoelLetoel", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/letoel/herslev-oeko-landoel",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelLetoelBC],
        title: "Herslev Landøl",
      },
      meta: {
        title: "Køb Herslev Landøl fadøl online og få leveret eller hent selv",
        description:
          "Herslev Landøl er en ravfarvet økologisk dansk øl, inspireret af gammel dansk bondeøl. En drikkevenlig øl med lav alkoholprocent, som passer godt til mad.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Letoel",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          En ravfarvet dansk fadøl, som er inspireret af gammel dansk bondeøl.
          En drikkevenlig øl med lav alkoholprocent, som passer godt til mad.
        </Paragraph>
        <Paragraph>
          Økologisk bondeøl er overgæret og brygget med rigelige mængder malt,
          som holdes i god balance af en moderat humlebitterhed. Der er desuden
          tilsat frisk hø fra bryghusets økologiske marker, som bidrager til
          øllets let frugtige smag.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Ravfarvet dansk, økologisk fadøl, som er inspireret af gammel dansk
        bondeøl.
        <br />
        <br />
        En drikkevenlig øl med lav alkoholprocent, som passer godt til mad.
      </React.Fragment>
    ),
  },
  {
    varenr: 12515,
    name: "Willemoes Light",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl letøl alkoholfri frokostøl alkoholdfri alkolholfri alkoholfrit øl uden let light ølight øllight light øl dansk danske danmark 20l 20 liter l vestfyn vestfyen willemose",
    imageSingle: "produkter-fadoel.willemoes-light-glas.png",
    imagePlural: "produkter-fadoel.vestfyen-fustage1.png",
    fadoelsType: "Pilsner",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/letoel/willemoes-light",
    categories: ["fadoel", "fadoelLetoel", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/letoel/willemoes-light",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelLetoelBC],
        title: "Vestfyen Light",
      },
      meta: {
        title: "Willemoes Light Fadøl | Køb den gode, lette pilsner her!",
        description:
          "Willemoes Light Fadøl er god for dig som ønsker en let øl, der stadig har den gode pilsner smag.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Letoel",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Willemoes Light er en god og let pilsner. Ønsker du en let øl som
          stadig har den gode pilsner smag er dette øllen for dig.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 8425,
    name: "Platan Alkoholfri Pilsner",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl letøl alkoholfri frokostøl alkoholdfri alkoholfrit øl uden let light ølight øllight light øl 30l 30 l liter tjekkiet",
    imageSingle: "produkter-fadoel.platan-pilsner.png",
    imagePlural: "produkter-fadoel.platan-fustage.png",
    fadoelsType: "Pilsner",
    land: "Tjekkiet",
    landKort: "CZ",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/letoel/platan-alkoholfri",
    categories: ["fadoel", "fadoelLetoel", "fadoelUdenlandsk"],
    routeDef: {
      path: "/fadoel/letoel/platan-alkoholfri",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelLetoelBC],
        title: "Platan Alkoholfri Pilsner",
      },
      meta: {
        title:
          "Køb Platan Alkoholfri Pilsner fadøl online. En tjekkisk fadøl uden alkohol.",
        description:
          "Platan Alkoholfri Pilsner fadøl - En semimørk classic fadøl helt uden alkohol. En vellykket 'alkoholfri' classic. Fås ikke bedre i Danmark.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Letoel",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Næsten alkoholfri</Heading3>
        <Paragraph>
          En velsmagende pilsner fadøl som officielt indeholder max 0,5 procent
          alkohol, men prøver viser at den rent faktisk er meget tæt på 0,0 %
          alkohol. En vellykket &quot;alkoholfri&quot; fadøl med en lækker smag.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        En velsmagende pilsner fadøl helt uden alkohol. En vellykket
        &quot;alkoholfri&quot; classic.
      </React.Fragment>
    ),
  },

  /* *
   *
   * Fadøl - Hvedeøl
   *
   * */
  {
    varenr: 2485,
    name: "Kronenbourg 1664 Blanc",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl blanck 20l 20 liter l kronenburg blanc blank weissbier weisbier weisbeer kronenberg kronborg 1669 1764 1864 1686",
    imageSingle: "produkter-fadoel.8855-flaske.png",
    imagePlural: "produkter-fadoel.kronenbourg-blanc-fustage-1.png",
    land: "Frankrig",
    landKort: "FR",
    fadoelsType: "Hvedeøl",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/hvedeoel/kronenbourg-blanc",
    categories: [
      "fadoel",
      "fadoelHvedeoel",
      "fadoelPaaskebryg",
      "fadoelUdenlandsk",
    ],
    routeDef: {
      path: "/fadoel/hvedeoel/kronenbourg-blanc",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelHvedeBC],
        title: "Kronenbourg 1664 Blanc",
      },
      meta: {
        title: "Køb Kronenbourg 1664 Blanc fadøl - Perfekt til sommerfesten!",
        description:
          "Kronenbourg Blanc er en Fransk hvedeøl i belgisk stil. Dens friske og runde smag og lyse natur, gør den til en favorit øl i sommervarmen - Køb online!",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Hvedeoel",
      },
    },
    productPageMaxImageHeight: "280px",
    popupDescription: () => (
      <React.Fragment>
        Kronenbourg 1664 Blanc er brygget i stil med de belgiske hvedeøl, der på
        fransk kaldes blanc. Smagen er sød med en rund fylde og et frisk hint af
        citrus.
      </React.Fragment>
    ),
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Kronenbourg 1664 Blanc er brygget i stil med de belgiske hvedeøl, der
          på fransk kaldes blanc. Smagen er sød med en rund fylde og et frisk
          hint af citrus.
        </Paragraph>
      </React.Fragment>
    ),
    productPageXtraDescription: () => (
      <React.Fragment>
        <Row>
          <Col xs={12} paddingTop="0px">
            <Heading2>Kronenbourg Blanc - den perfekte sommerøl</Heading2>
            <Paragraph>
              Sommeren har meldt sin ankomst - og det samme har de mange
              sommerfester! Ingen sommerfester uden en iskold fadøl, og hvilken
              øl skriger “sommer” bedre end en Kronenbourg 1664 Blanc? De
              friske, gyldne dråber er altid garanti for en god tørstslukker,
              der får dig til at sige “ahh”, mens du læner dig tilbage i
              solstolen og vipper solbrillerne ned.
            </Paragraph>
            <Paragraph>
              Det er ikke helt tilfældigt, at Kronenbourg Blanc er kendt som en
              af de bedste sommerøl. Det er nemlig en frisk og let øl, der byder
              på syrlige citrus noter og en sødlig fylde. Øllen fremstår
              naturlig uklar og gylden, og når den skænkes, skabes der en tæt og
              meget fyldig skumkrone, der på de gode sommeraftener sagtens kan
              efterlade et lille mælkeskæg. Når du nyder en Kronenbourg 1664
              Blanc, lægger du hurtigt mærke til den sødmefulde fylde, der
              efterfølges af et frisk og syrligt, men behageligt, bid af citrus
              og frugtighed. Alkoholprocenten ligger på 5% og øllen nydes bedst,
              når den serveres afkølet med en temperatur på mellem 10 og 12
              grader.
            </Paragraph>
            <Paragraph>
              1664 Kronenbourg Blanc egner sig især godt til fyldige salater,
              sushi eller let sommermad. Den kan desuden også nydes som en
              aperitif eller i forbindelse med snacks, små sommerlige
              anretninger eller sammen med bær og frugt.
            </Paragraph>
          </Col>
        </Row>
        <Row greyLight>
          <Col xs={12} paddingBottom="0" paddingTop="10px">
            <Spacer size="0" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <Heading2>Kronenbourg 1664 Blanc Danmarks nye favorit?</Heading2>
            <Paragraph>
              Hvis du opholder dig i Danmark i de skønne sommermåneder, er du
              nok ikke i tvivl: Kronenbourg Blanc er en ægte favorit blandt
              danskerne.
            </Paragraph>
            <Paragraph>
              Øllen nydes af mange - faktisk også af dem, der normalt ikke
              drikker øl. Den runde smag og friskheden fra citrus er med til at
              skabe en helt ny oplevelse, der adskiller sig fra mange andre øl.
            </Paragraph>
            <Heading2>
              Kronenbourg 1664 Blanc er både historisk og nytænkende
            </Heading2>
            <Paragraph>
              Inspirationen til en Kronenbourg Blanc stammer oprindeligt fra en
              belgisk hvedeøl, kaldet wit, der oversættes til blanc på fransk. I
              dag er øllen kendt som en af de bedste sommerøl på markedet, men
              er faktisk også anerkendt som værende i toppen, når det gælder
              hvedeøl.
            </Paragraph>
            <Paragraph>
              Det franske bryggeri Kronenbourg har en lang historie bag sig, og
              blev grundlagt helt tilbage i 1664 af Jérôme Hatt i den franske by
              Strasbourg. Siden hen er bryggeriet blevet Frankrigs største.
            </Paragraph>
            <Paragraph>
              En Kronenbourg 1664 Blanc adskiller sig fra de klassiske øl,
              grundet dens præg af citrus, hvilket gør den ekstra sommerlig (men
              den kan naturligvis stadig nydes året rundt). Øllen har en blød
              bitterhed fra citrus, men også fra koriander, der skaber en
              perfekt balance. Koriander er ofte en krydderurt der deler
              vandene, men du bør bestemt ikke blive afskrækket af den. Smagen
              er nemlig ikke gennemtrængende, men bringer blot bitterheden frem
              i øllen, uden at tilføre smag.
            </Paragraph>
          </Col>
          <Col xs={12} sm={6}>
            <Heading2>Dansk Kronenbourg Blanc forhandler</Heading2>
            <Paragraph>
              Skafte forhandler Kronenbourg 1664 Blanc på fad til din næste fest
              eller arrangement. Skal du have frisk øl på fad, så finder du det
              hos os. Vi forhandler både til privat og erhverv, så du altid har
              mulighed for at nyde en frisk skænket fadøl, uanset begivenheden.
            </Paragraph>
            <Paragraph>
              Har du brug for et fadølsanlæg eller en bar i forbindelse med dit
              Kronenbourg 1664 Blanc køb? Det finder du naturligvis også lige
              her! Du kan let leje nøjagtig det antal anlæg som du har brug for,
              så du kan servere frisk fadøl til dine gæster. Prisen for leje er
              ikke blot for en enkelt dag, men derimod en hel lejeperiode på op
              til 5 dage. Det giver dig også mulighed for at kunne nyde festen,
              slappe af dagen efter og få ryddet op, helt uden at skulle føle
              dig presset over tilbagelevering.
            </Paragraph>
            <Paragraph>
              Nogle vælger at køre en tur over grænsen, når der skal handles ind
              til fest, men et Kronenbourg Blanc Tyskland køb i grænsehandlen er
              ikke nødvendigvis det rette. Hos Skafte forhandler vi øllen i en
              fustage på 20 liter, modsat i enkelte flasker. Fadøl egner sig
              perfekt til fester og større arrangementer, og kræver desuden
              mindre oprydning af de mange flasker og dåser. Det er også langt
              mere behageligt at drikke af et glas en hel aften, frem for
              flasker eller dåser.
            </Paragraph>
            <Paragraph>
              Bestil din fadøl direkte fra Skafte og gør det perfekte
              Kronenbourg 1664 Blanc køb - måske i forbindelse med leje af anlæg
              og hane, hvis du ikke allerede er den heldige ejer.
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
  },
  {
    varenr: 8185,
    name: "Nørrebro Øko Blanche",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl ipa dansk danske danmark 20l 20 liter l bryghus økologisk økologiske",
    imageSingle: "produkter-fadoel.noerrebro-oeko-blanche-glas.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    fadoelsType: "Hvedeøl",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    // norrebro: true,
    // royalUnibrew: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/hvedeoel/noerrebro-bryghus-oeko-blanche",
    categories: ["fadoel", "fadoelHvedeoel", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/hvedeoel/noerrebro-bryghus-oeko-blanche",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelHvedeBC],
        title: "Nørrebro Øko Blanche",
      },
      meta: {
        title: "Køb NNørrebro Øko Blanche fadøl",
        description:
          "Nørrebro Øko Blanche, dansk og økologisk fadøl, hvedeøl med et strejf af citrus",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Hvedeoel",
      },
    },
  },
  {
    varenr: 4685,
    name: "Royal Blanche",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl 20l 20 liter l weissbier weisbier weisbeer blanc blank",
    imageSingle: "produkter-fadoel.royal-blanche-glas.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "Hvedeøl",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/hvedeoel/royal-blanche",
    categories: ["fadoel", "fadoelHvedeoel", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/hvedeoel/royal-blanche",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelHvedeBC],
        title: "Royal Blanche",
      },
      meta: {
        title:
          "Køb Royal Blanche fadøl her - Let dansk hvedeøl med et friskt twist af citrus",
        description:
          "Royal Blanche er en dansk brygget hvedeøl med et twist af citrus. En hyldest til moderne dansk ølbryggeri og en perfekt øl til sommerens fester.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Hvedeoel",
      },
    },
    popupDescription: () => (
      <React.Fragment>
        Royal Blanche er en dansk brygget hvedeøl med et twist af citrus. En
        hyldest til moderne dansk ølbryggeri og en perfekt øl til sommerens
        fester.
      </React.Fragment>
    ),
    productPageDescriptionLong: () => (
      <Paragraph>
        Royal Blanche er en dansk brygget hvedeøl med et twist af citrus. En
        hyldest til moderne dansk ølbryggeri og en perfekt øl til sommerens
        fester.
      </Paragraph>
    ),
  },
  {
    varenr: 40685,
    name: "Anarkist Bloody Weizen",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl mosaik dansk danske danmark 20l 20 liter l india indian pale ale anakist anarkist",
    imageSingle: "produkter-fadoel.anarkist-bloody-weizen-glas.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "Hvedeøl",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/hvedeoel/anarkist-bloody-weizen",
    categories: ["fadoel", "fadoelHvedeoel", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/hvedeoel/anarkist-bloody-weizen",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelHvedeBC],
        title: "Anarkist Bloody Weizen",
      },
      meta: {
        title:
          "Oplev Anarkist Brewing Bloody Weizen på Fad - En Revolutionerende Weizen Øloplevelse",
        description:
          "Dyk ned i en verden af unik smag med Anarkist Brewing's Bloody Weizen på fad. En perfekt blanding af traditionel weizen og friske blodappelsinnoter tilbyder en uforglemmelig og forfriskende øloplevelse. Ideel for eventyrlystne ølentusiaster, der værdsætter kvalitet og innovation.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Hvedeoel",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Oplev Anarkist Brewing&apos;s Bloody Weizen, en fadøl der bryder
          grænser og udfordrer det konventionelle. Denne øl er en dristig
          fortolkning af den klassiske weizen med en unik twist, der appellerer
          til de eventyrlystne ølentusiaster. Med en passion for det
          ekstraordinære, er Bloody Weizen det ideelle valg for dem, der søger
          at opleve nye højder inden for ølverdenen.
        </Paragraph>
        <Paragraph>
          Bloody Weizen på fad er en sand smagseksplosion. Denne øl kombinerer
          den bløde og frugtige karakter af traditionel weizen med en frisk og
          nyskabende blodappelsinnoter, der tilfører en uventet og forfriskende
          dimension til din øloplevelse. Den perfekte balance mellem sødme og
          syrlighed, sammen med en forfriskende karbonering, gør denne øl til en
          favorit ved enhver lejlighed.
        </Paragraph>
        <Paragraph>
          Når du vælger Bloody Weizen på fad, får du mere end blot en øl; du får
          en oplevelse. Brygget med omhu og dedikation af Anarkist Brewing, står
          denne øl som et vidnesbyrd om bryggeriets evne til at fusionere
          tradition med innovation. Gør din næste sammenkomst uforglemmelig ved
          at tilbyde dine gæster en fadøl, der både overrasker og imponerer.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 10685,
    name: "Herslev Øko Hvedeøl",
    generalSearchPriority: 1000,
    // herslevBryghus: true,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark hvede 20l 20 l liter økologisk økologiske weissbier weisbier weisbeer bryghus",
    imageSingle: "produkter-fadoel.herslev-bryghus-hvedeoel-glas-logo.png",
    imagePlural: "produkter-fadoel.herslev-fustage1.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "Hvedeøl",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/hvedeoel/herslev-oeko-hvede",
    categories: ["fadoel", "fadoelHvedeoel", "fadoelPaaskebryg", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/hvedeoel/herslev-oeko-hvede",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelHvedeBC],
        title: "Herslev Øko Hvede",
      },
      meta: {
        title: "Køb Herslev Øko Hvede - Lækker hvedeøl på fustage hos Skafte",
        description:
          "Herslev Øko Hvede på 20L fustage er en fugtig og fløjlsblød smagsoplevelse - Vi leverer den velsmagende hvedeøl direkte til døren - Køb online hos Skafte.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Hvedeoel",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Herslev Øko Hvede er brygget med inspiration fra belgisk hvedeøl. Der
          er tale om en skøn, frisk og frugtig hvedeøl, der både er blød og sød.
        </Paragraph>
        <Paragraph>
          Herslev Øko Hvede har en blid syre og cremet skum, og er brygget på
          halvt økologisk bygmalt og halvt økologisk hvedemalt.
        </Paragraph>
        <Paragraph>
          En god hvedeøl kendetegnes ved at være fyldig og rig samt have en
          silkeblød konsistens og cremet skum, men alligevel være frisk. Alt
          dette vil du opleve i Herslev Øko Hvede, hvor den lette syre skærer
          igennem blødheden og skaber en nuanceret oplevelse, der er både
          frugtig og fløjlsagtig.
        </Paragraph>
        <Paragraph>
          Herslev Øko Hvede er brygget efter en traditionel sydfynsk metode og
          er ufiltreret.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        <Paragraph>
          Herslev Øko Hvede er brygget med inspiration fra belgisk hvedeøl. Der
          er tale om en skøn, frisk og frugtig hvedeøl, der både er blød og sød.
        </Paragraph>
        <Paragraph>
          Herslev Øko Hvede har en blid syre og cremet skum, og er brygget på
          halvt økologisk bygmalt og halvt økologisk hvedemalt.
        </Paragraph>
        <Paragraph>
          En god hvedeøl kendetegnes ved at være fyldig og rig samt have en
          silkeblød konsistens og cremet skum, men alligevel være frisk. Alt
          dette vil du opleve i Herslev Øko Hvede, hvor den lette syre skærer
          igennem blødheden og skaber en nuanceret oplevelse, der er både
          frugtig og fløjlsagtig.
        </Paragraph>
        <Paragraph>
          Herslev Øko Hvede er brygget efter en traditionel sydfynsk metode og
          er ufiltreret.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 8085,
    name: "Nørrebro Øko Stuykman Hvede",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 20l 20 liter l bryghus økologisk økologiske weissbier weisbier weisbeer",
    imageSingle:
      "produkter-fadoel.noerrebro-bryghus-stuykman-hvede-40cl-glas.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    fadoelsType: "Hvedeøl",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    // royalUnibrew: true,
    // norrebro: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/hvedeoel/noerrebro-bryghus-stuykman-hvede",
    categories: ["fadoel", "fadoelHvedeoel", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/hvedeoel/noerrebro-bryghus-stuykman-hvede",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelHvedeBC],
        title: "Nørrebro Bryghus Stuykman Hvede",
      },
      meta: {
        title: "Køb Nørrebro Bryghus Stuykman Hvede fadøl",
        description:
          "Nørrebro Bryghus Stuykman Hvede, dansk og økologisk fadøl, inspireret af belgisk hvedeøl (Witbier)",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Hvedeoel",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Dansk produceret, økologisk fadøl</Heading3>
        <Paragraph>
          Stuykman Hvede er inspireret af den klassiske belgiske Witbier.
          Nørrebro Bryghus’ version er brygget på økologisk hvede. Øllen er
          fyldig, sødmefuld og krydret – da den er tilsat koriander og pomerans.
        </Paragraph>
        <Paragraph>
          Med sin lette syrlighed og lave bitterhed er denne ufiltrerede øl
          utrolig forfriskende og perfekt til dem, der ikke er til de kraftige
          og bitre øltyper.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Inspireret af den klassiske belgiske Witbier, brygget på økologisk
        hvede.
        <br />
        <br />
        Fyldig, sødmefuld og krydret – da øllen er tilsat koriander og pomerans.
      </React.Fragment>
    ),
  },
  {
    varenr: 8685,
    name: "Weihenstephaner Hefe",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl 30l 30 l liter oktoberfest weisbier weisbeer weissbier oktoberøl tysk tyske tyskland",
    imageSingle: "produkter-fadoel.weihenstephaner-glas.png",
    imagePlural: "produkter-fadoel.paulaner-fustage.png",
    land: "Tyskland",
    landKort: "DE",
    fadoelsType: "Hvedeøl",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/hvedeoel/weihenstephaner-hefe",
    categories: [
      "fadoel",
      "fadoelHvedeoel",
      "fadoelOktoberfest",
      "fadoelUdenlandsk",
    ],
    routeDef: {
      path: "/fadoel/hvedeoel/weihenstephaner-hefe",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelHvedeBC],
        title: "Weihenstephaner Hefe-Weissbier Hefe",
      },
      meta: {
        title:
          "Køb Weihenstephaner Hefe her | Lys hvedeøl med cremet hvidt skum",
        description: "",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Hvedeoel",
      },
    },
  },
  {
    varenr: 8695,
    name: "Weihenstephaner Dunkel",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl 30l 30 l liter oktoberfest weisbier weisbeer weissbier oktoberøl tysk tyske tyskland",
    imageSingle: "produkter-fadoel.weihenstephaner-dunkel-glas.png",
    imagePlural: "produkter-fadoel.paulaner-fustage.png",
    land: "Tyskland",
    landKort: "DE",
    fadoelsType: "Hvedeøl",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/hvedeoel/weihenstephaner-dunkel",
    categories: [
      "fadoel",
      "fadoelHvedeoel",
      "fadoelOktoberfest",
      "fadoelUdenlandsk",
    ],
    routeDef: {
      path: "/fadoel/hvedeoel/weihenstephaner-dunkel",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelHvedeBC],
        title: "Weihenstephaner Hefe-Weissbier Dunkel",
      },
      meta: {
        title:
          "Køb Weihenstephaner Hefe-Weissbier Dunkel her | Mørk hvedeøl med cremet hvidt skum",
        description:
          "Denne mørke hvedeøl står imponerende med dens cremede hvide skum. Smagen har en frugtagtig frisk sødme med en antydning af moden banan, som harmonerer med en lækker smag af ristet malt.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Hvedeoel",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Denne mørke hvedeøl står imponerende med dens cremede hvide skum.
          Smagen har en frugtagtig frisk sødme med en antydning af moden banan,
          som harmonerer med en lækker smag af ristet malt.
        </Paragraph>
        <Paragraph>
          Weihenstephaner Dunkel er mousserende og fyldig med en let
          karamelsmag, der mærkes ved første slurk og er et fremragende valg til
          solide måltider og vildt, samt chokolade- og nødderesserter. Øllen
          brygges i henhold til de århundreder gamle brygningstraditioner på
          Weihenstephan-bakken.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        <Paragraph>
          Denne mørke hvedeøl står imponerende med dens cremede hvide skum.
          Smagen har en frugtagtig frisk sødme med en antydning af moden banan,
          som harmonerer med en lækker smag af ristet malt.
        </Paragraph>
        <Paragraph>
          Weihenstephaner Dunkel er mousserende og fyldig med en let
          karamelsmag, der mærkes ved første slurk og er et fremragende valg til
          solide måltider og vildt, samt chokolade- og nødderesserter. Øllen
          brygges i henhold til de århundreder gamle brygningstraditioner på
          Weihenstephan-bakken.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 4985,
    name: "Edelweiss Wheat Beer",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl snov snovfresh 25l 25 l liter eddelweis",
    imageSingle: "produkter-fadoel.edelweiss-snowfresh.png",
    imagePlural: "produkter-fadoel.edelweiss-fustage.png",
    fadoelsType: "Hvedeøl",
    land: "Østrig",
    landKort: "AT",
    // royalUnibrew: true,
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/edelweiss-wheat-beer",
    categories: ["fadoel", "fadoelHvedeoel", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/edelweiss-wheat-beer",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelHvedeBC],
        title: "Edelweiss Wheat Beer",
      },
      meta: {
        title: "Edelweiss Wheat Beer fadøl - Østrigsk hvedeøl",
        description:
          "Edelweiss Wheat Beer er en hvedeøl af fineste kvalitet. Øllen produceres med Alpernes egne ingredienser. Køb fadøl hos Skafte!",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Hvedeoel",
      },
    },
    productPageDescription: () => (
      <React.Fragment>
        <Paragraph>
          Edelweiss Wheat Beer er en hvedeøl af fineste kvalitet. Øllen
          produceres med Alpernes egne ingredienser. Denne fadøl har en
          forfriskende og naturlig smag. Selvom øllen som udgangspunkt passer
          bedst til afterskiing, så vil en kold Edelweiss Wheat Beer også være
          et perfekt valg som tørstslukker på en varm sommerdag.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Hvedeøl af fineste kvalitet, produceret med Alpernes egne ingredienser.
        <br />
        <br />
        En kold Edelweiss Wheat Beer er et perfekt valg som tørstslukker på en
        varm sommerdag.
      </React.Fragment>
    ),
  },
  {
    varenr: 8585,
    name: "Paulaner Hefe-Weissbier Naturtrüb",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl 30l 30 l liter oktoberfest paullaner weisbier weisbeer weissbier oktoberøl tysk tyske tyskland",
    imageSingle: "produkter-fadoel.8495-flaske.png",
    imagePlural: "produkter-fadoel.paulaner-fustage.png",
    land: "Tyskland",
    landKort: "DK",
    fadoelsType: "Hvedeøl",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    notice: () => (
      <Paragraph fontSize="14px">
        Paulaner Hefe kan skumme mere end andre fadøl. Løs dette ved at anvende
        en længere bremseslange og et tryk på 3,3 bar. Bestiller du Paulaner
        Hefe og fadølsanlæg hos os, sørger vi for at udstyret er korrekt
        indstillet.
      </Paragraph>
    ),
    url: "/fadoel/hvedeoel/paulaner-hefe-weissbier-naturtrub",
    categories: [
      "fadoel",
      "fadoelHvedeoel",
      "fadoelOktoberfest",
      "fadoelUdenlandsk",
    ],
    routeDef: {
      path: "/fadoel/hvedeoel/paulaner-hefe-weissbier-naturtrub",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelHvedeBC],
        title: "Paulaner Hefe-Weissbier Naturtrüb",
      },
      meta: {
        title:
          "Køb Paulaner Hefe-Weissbier Naturtrüb her | En af tysklands mest populære hvedeøl",
        description:
          "Paulaner Hefe-Weissbier Naturtrüb er en af tysklands mest populære hvedeøl. Farven er naturlig sløret og gylden med et flot skum og smagen er mild og frugtig.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Hvedeoel",
      },
    },
    productPageDescriptionLong: () => (
      <Paragraph>
        Paulaner Hefe-Weissbier Naturtrüb er en af tysklands mest populære
        hvedeøl. Farven er naturlig sløret og gylden med et flot skum og smagen
        er mild og frugtig.
      </Paragraph>
    ),
    popupDescription: () => (
      <React.Fragment>
        Paulaner Hefe-Weissbier Naturtrüb er en af tysklands mest populære
        hvedeøl. Farven er naturlig sløret og gylden med et flot skum og smagen
        er mild og frugtig.
      </React.Fragment>
    ),
  },
  {
    varenr: 8785,
    name: "Erdinger Hefe Weissbier",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl 20l 20 liter l oktoberfest weisbier weisbeer weissbier oktoberøl tysk tyske tyskland",
    imageSingle: "produkter-fadoel.erdinger-hefe-weissbier.png",
    imagePlural: "produkter-fadoel.erdinger-fustage-20l.png",
    fadoelsType: "Hvedeøl",
    land: "Tyskland",
    landKort: "DE",
    notice: () => (
      <Paragraph fontSize="14px">
        Erdinger kan skumme mere end andre fadøl. Løs dette ved at anvende en
        længere bremseslange og et tryk på 3,5 bar. Bestiller du Erdinger og
        fadølsanlæg hos os, sørger vi for at udstyret er korrekt indstillet.
      </Paragraph>
    ),
    gas: "CO2",
    pressure: "3,5 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/hvedeoel/erdinger-hefe-weissbier",
    categories: [
      "fadoel",
      "fadoelHvedeoel",
      "fadoelOktoberfest",
      "fadoelUdenlandsk",
    ],
    routeDef: {
      path: "/fadoel/hvedeoel/erdinger-hefe-weissbier",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelHvedeBC],
        title: "Erdinger Hefe Weissbier",
      },
      meta: {
        title: "Erdinger Hefe Weissbier - Køb den her!",
        description:
          "Erdinger Hefe Weissbier fadøl - Lys, bayersk kvalitets hvedeøl fra Erdinger.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Hvedeoel",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Erdinger Hefe Weissbier er en af Erdinger bryggeriets absolutte
          klassikere. Denne hvedeøl er naturligt brygget med fint gær efter en
          traditionel bayersk opskrift.
        </Paragraph>
        <Paragraph>
          Kun de fineste råvarer bliver brugt og mange års erfaring og konstant
          kvalitetskontrol sikrer Erdinger den karakteristiske smag.
        </Paragraph>
        <Paragraph>
          En hvede øl til dem, der elsker den uforglemmelige god smag. Erdinger
          Hefe Weissbier er et godt valg til oktoberfest arrangementer.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Erdinger kan skumme mere end andre fadøl. Løs dette ved at anvende en
        længere bremseslange og et tryk på 3,5 bar. Bestiller du Erdinger og
        fadølsanlæg hos os, sørger vi for at udstyret er korrekt indstillet.
      </React.Fragment>
    ),
  },
  {
    varenr: 8795,
    name: "Erdinger Dunkel Weissbier",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl 20l 20 liter l oktoberfest weisbier weisbeer weissbier oktoberøl tysk tyske tyskland",
    imageSingle: "produkter-fadoel.erdinger-dunkel-weissbier.png",
    imagePlural: "produkter-fadoel.erdinger-fustage-20l.png",
    fadoelsType: "Hvedeøl",
    land: "Tyskland",
    landKort: "DE",
    gas: "CO2",
    pressure: "3,5 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    notice: () => (
      <Paragraph fontSize="14px">
        Erdinger kan skumme mere end andre fadøl. Løs dette ved at anvende en
        længere bremseslange og et tryk på 3,5 bar. Bestiller du Erdinger og
        fadølsanlæg hos os, sørger vi for at udstyret er korrekt indstillet.
      </Paragraph>
    ),
    url: "/fadoel/hvedeoel/erdinger-dunkel-weissbier",
    categories: [
      "fadoel",
      "fadoelHvedeoel",
      "fadoelOktoberfest",
      "fadoelUdenlandsk",
    ],
    routeDef: {
      path: "/fadoel/hvedeoel/erdinger-dunkel-weissbier",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelHvedeBC],
        title: "Erdinger Dunkel Weissbier",
      },
      meta: {
        title: "Erdinger Dunkel Weissbier - Køb den her!",
        description:
          "Erdinger Dunkel Weissbier er en mørk hvedeøl i en særlig kraftig og velsmagende version af hjemmet Erdinger.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Hvedeoel",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Erdinger Dunkel Weissbier er en mørk hvedeøl i en særlig kraftig og
          velsmagende version af hjemmet Erdinger.
        </Paragraph>
        <Paragraph>
          Denne karakteristiske hvedeøl får sin fyldige smag fra sin fine humle
          og mørke malt og er brygget efter en gammel opskrift. Den krydrede
          smag kommer fra den øgede andel af urt med næsten 13%.
        </Paragraph>
        <Paragraph>
          En rigtig specialitet for kender og særdeles god til oktoberfest
          arrangementer.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Erdinger kan skumme mere end andre fadøl. Løs dette ved at anvende en
        længere bremseslange og et tryk på 3,5 bar. Bestiller du Erdinger og
        fadølsanlæg hos os, sørger vi for at udstyret er korrekt indstillet.
      </React.Fragment>
    ),
  },

  /* *
   *
   * Fadøl - Juleøl
   *
   * */
  {
    varenr: 3755,
    name: "Tuborg Julebryg",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl julebryg juleøl tuborg dansk danske danmark 25l 25 l liter sneøl sne julbryg j-dag j dag",
    imageSingle: "produkter-fadoel.tuborg-julebryg.png",
    imagePlural: "produkter-fadoel.carlsberg-fustage.png",
    fadoelsType: "Mørk Pilsner",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/juleoel/tuborg-julebryg",
    categories: ["fadoel", "fadoelJuleoel", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/juleoel/tuborg-julebryg",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelJuleoelBC],
        title: "Tuborg Julebryg",
      },
      meta: {
        title: "Tuborg julebryg - Den perfekte fadøl til din julefrokost",
        description:
          "Tuborg Julebryg udbringes i København og omegn. Julebryg er fyldig og sødmefuld med en god krop og behagelig bitterhed med en anelse af engelsk lakrids.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Jul",
      },
    },
    inStockText: () => (
      <React.Fragment>
        Udsolgt. Kommer igen <Datoer type="julebrygNextYear" />
      </React.Fragment>
    ),
    /*
    smallDescription: () => (
      <Span blue block fontSize="13px" paddingTop="5px">
        Kan leveres fra <Datoer type="julebryg" />
      </Span>
    ),
    notice: () => (
      <Paragraph blue fontSize="13px">
        Kan leveres fra <Datoer type="julebryg" />
      </Paragraph>
    ),
    */
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Fyldig og sødmefuld</Heading3>
        <Paragraph>
          Tuborg Julebryg er øllen der startede den populære J-dag, som i år
          ligger fra <Datoer type="julebryg" />
          !. Den første J-dag blev markedsført af Tuborg i 1990. Efterfølgende
          er J-dag blevet en tradition som finder sted hvert år 1. fredag i
          november kl. 20:59. Tuborg Julebryg er Danmarks mest populære juleøl
          siden 1981. Leveres på 25 liters fustager, perfekt til større
          julefrokoster.
        </Paragraph>
        <Paragraph>
          Tuborg Julebryg har en mørkgylden farve. Hertil kommer smagen som er
          fyldig og sødmefuld. Denne øl egner sig perfekt til en traditionel,
          dansk julefrokost.
        </Paragraph>
        <Paragraph>
          Skal du holde julefrokost, kan du med fordel læse mere om vores andre
          juleøl på siden om{" "}
          <LinkStyled to="/juleoel" title="Julefrokost &amp; Juleøl">
            Julefrokost &amp; Juleøl
          </LinkStyled>
          .
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Danmarks mest populære Julebryg, som har sin egen dag - J-dag. J-dag
        ligger den 1. fredag i november og sådan har det været siden 1981.
        <br />
        <br />
        Julebryg kaldes også for sneøl.
      </React.Fragment>
    ),
  },
  {
    varenr: 12375,
    name: "Jule Ale no. 24",
    generalSearchPriority: 450,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl julebryg juleøl dansk danske danmark 19l 19 l liter dark ipa refsvindinge refvindinge ale no 24 vestfyn vestfyen julbryg",
    imageSingle: "produkter-fadoel.4575-h400.png",
    imagePlural: "produkter-fadoel.20-liter-staal-uden-brand.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "Mørk Ale",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/juleoel/ale-no-24",
    categories: ["fadoel", "fadoelJuleoel", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/juleoel/ale-no-24",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelJuleoelBC],
        title: "Ale no. 24",
      },
      meta: {
        title:
          "Ale no. 24 | Karakterfuld juleøl til din julefest - Bestil online!",
        description:
          "Ale no. 24 som fadøl er en lækker juleøl med en karakterfuld og vinøs smag",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Jul",
      },
    },
    inStockText: () => (
      <React.Fragment>
        Udsolgt. Kommer igen <Datoer type="julebrygNextYear" />
      </React.Fragment>
    ),
    smallDescription: () => (
      <Span blue block fontSize="13px" paddingTop="5px">
        Kan leveres fra <Datoer type="julebryg" />
      </Span>
    ),
    notice: () => (
      <Paragraph blue fontSize="13px">
        Kan leveres fra <Datoer type="julebryg" />
      </Paragraph>
    ),
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          En lækker juleøl med en karakterfuld og vinøs smag med undertoner af
          mørke frugter som bl.a. blommer samt en anelse lakrids.
        </Paragraph>
        <Paragraph>
          Denne overgærede juleøl har en smuk mahognifarve og en dejlig sødelig
          duft.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        En lækker juleøl med en karakterfuld og vinøs smag med undertoner af
        mørke frugter som bl.a. blommer samt en anelse lakrids.
        <br />
        <br />
        Denne overgærede juleøl har en smuk mahognifarve og en dejlig sødelig
        duft.
      </React.Fragment>
    ),
  },
  {
    varenr: 8865,
    name: "Grimbergen Noël",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl julebryg juleøl noel nöel 20l 20 l liter dark ipa belgien belgisk grimberger grinbergen grinberger grenberg grenberger grimbergem grindberg grimbregen grindbergen julbryg",
    imageSingle: "produkter-fadoel.grimbergen-noel.png",
    imagePlural: "produkter-fadoel.grimbergen-fustage-20liter.png",
    fadoelsType: "Mørk Ale",
    land: "Belgien",
    landKort: "BE",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/juleoel/grimbergen-noel",
    categories: ["fadoel", "fadoelJuleoel", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/juleoel/grimbergen-noel",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelJuleoelBC],
        title: "Grimbergen Noël",
      },
      meta: {
        title: "Grimbergen Noël | Klar og kobberbrun juleale - Bestil online!",
        description:
          "Grimbergen Noël fadøl er særdeles velegnet til julemad - Perfekt til jeres julefrokost",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Jul",
      },
    },
    inStockText: () => (
      <React.Fragment>
        Udsolgt. Kommer igen <Datoer type="julebrygNextYear" />
      </React.Fragment>
    ),
    /*
    smallDescription: () => (
      <Span blue block fontSize="13px" paddingTop="5px">
        Kan leveres fra <Datoer type="julebryg" />
      </Span>
    ),
    notice: () => (
      <Paragraph blue fontSize="13px">
        Kan leveres fra <Datoer type="julebryg" />
      </Paragraph>
    ),
    */
    productPageDescriptionLong: () => (
      <Paragraph>
        Grimbergen Noëls farve er klar og kobberbrun. Mundfylden er blød og
        denne mørke ale har en eftersmag med noter af sød lakrids. Særdeles
        velegnet til julemad.
      </Paragraph>
    ),
    popupDescription: () => (
      <React.Fragment>
        Grimbergen Noëls farve er klar og kobberbrun. Mundfylden er blød og
        denne mørke ale har en eftersmag med noter af sød lakrids. Særdeles
        velegnet til julemad.
      </React.Fragment>
    ),
  },
  {
    varenr: 10675,
    name: "Herslev Øko Juleøl",
    generalSearchPriority: 1000,
    // herslevBryghus: true,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl julebryg juleøl dansk danske danmark 20l 20 l liter økologisk økologiske bryghus julbryg",
    imageSingle: "produkter-fadoel.herslev-label-juleoel.jpg",
    imagePlural: "produkter-fadoel.herslev-fustage1.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "Rød Ale",
    gas: "CO2",
    pressure: "2,5-3 bar",
    url: "/fadoel/juleoel/herslev-oeko-juleoel",
    categories: ["fadoel", "fadoelJuleoel", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/juleoel/herslev-oeko-juleoel",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelJuleoelBC],
        title: "Herslev Øko Juleøl",
      },
      meta: {
        title:
          "Herslev Øko Juleøl | Sød og krydret fadøl til din julefest - Køb den her!",
        description:
          "Økologisk Juleøl med en god balance mellem sødme, bitterhed og maltsmag. Krydret med økologiske appelsiner | Denne juleøl har en flot mørk farve og er karakterfuld og let drikkelig.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Jul",
      },
    },
    inStockText: () => (
      <React.Fragment>
        Udsolgt. Kommer igen <Datoer type="julebrygNextYear" />
      </React.Fragment>
    ),
    smallDescription: () => (
      <React.Fragment>
        <Span blue block fontSize="13px" paddingTop="5px">
          Kan leveres fra <Datoer type="julebrygLastYear" />
        </Span>
      </React.Fragment>
    ),
    notice: () => (
      <Paragraph blue fontSize="13px">
        Kan leveres fra <Datoer type="julebrygLastYear" />
      </Paragraph>
    ),
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Økologisk Juleøl med en god balance mellem sødme, bitterhed og
          maltsmag. Krydret med økologiske appelsiner.
        </Paragraph>
        <Paragraph>
          Denne juleøl har en flot mørk farve og er karakterfuld og let
          drikkelig.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Økologisk Juleøl med en god balance mellem sødme, bitterhed og maltsmag.
        Krydret med økologiske appelsiner.
        <br />
        <br />
        Denne juleøl har en flot mørk farve og er karakterfuld og let drikkelig.
      </React.Fragment>
    ),
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
  },
  {
    varenr: 8075,
    name: "Nørrebro Jule IPA",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl julebryg juleøl dansk danske danmark 20l 20 liter l bryghus julbryg",
    imageSingle: "produkter-fadoel.noerrebro-bryghus-jule-ipa-glas.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "India Pale Ale",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/juleoel/noerrebro-bryghus-jule-ipa",
    categories: ["fadoel", "fadoelJuleoel", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/juleoel/noerrebro-bryghus-jule-ipa",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelJuleoelBC],
        title: "Nørrebro Jule IPA",
      },
      meta: {
        title: "Nørrebro Jule IPA | En juleøl af høj kvalitet - Få din her!",
        description:
          "Nørrebro Jule IPA er en fyldig India Pale Ale med en styrke på 6,3%. Smagen er frugtig og har en god bitterhed med toner af appelsin.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Jul",
      },
    },
    inStockText: () => (
      <React.Fragment>
        Udsolgt. Kommer igen <Datoer type="julebrygNextYear" />
      </React.Fragment>
    ),
    /*
    smallDescription: () => (
      <Span blue block fontSize="13px" paddingTop="5px">
        Kan leveres fra <Datoer type="julebryg" />
      </Span>
    ),
    notice: () => (
      <Paragraph blue fontSize="13px">
        Kan leveres fra <Datoer type="julebryg" />
      </Paragraph>
    ),
    */
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Nørrebro Bryghus Jule IPA</Heading3>
        <Paragraph>
          Denne fadøl er af typen India Pale Ale. Det er en god og fyldig IPA
          med en styrke på 6,3%. Smagen er frugtig og har en god bitterhed med
          toner af appelsin.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Denne fadøl er af typen India Pale Ale. Det er en god og fyldig IPA med
        en styrke på 6,3%. Smagen er frugtig og har en god bitterhed med toner
        af appelsin.
      </React.Fragment>
    ),
  },
  {
    varenr: 4675,
    name: "Royal Xmas Blågran",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl julebryg juleøl dansk danske danmark faxe 20l 20 l liter x-mas julbryg",
    imageSingle: "produkter-fadoel.royal-xmas-blaagran.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    fadoelsType: "Pilsner",
    land: "Danmark",
    landKort: "DK",
    // royalUnibrew: true,
    // royalUnibrewJulekit: true,
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/juleoel/royal-xmas-blaagran",
    categories: ["fadoel", "fadoelJuleoel", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/juleoel/royal-xmas-blaagran",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelJuleoelBC],
        title: "Royal Xmas Blågran",
      },
      meta: {
        title:
          "Royal Xmas Øl - Blågran | Julebryggen er fyldig med smag af lakrids",
        description:
          "Royal Xmas er en god Pilser, der passer perfekt til julebordet og julefrokosten. Den er fyldig og sødmefuld med lakrids. Xmas Royal - en fantastisk juleøl.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Jul",
      },
    },
    inStockText: () => (
      <React.Fragment>
        Udsolgt. Kommer igen <Datoer type="julebrygNextYear" />
      </React.Fragment>
    ),
    smallDescription: () => (
      <React.Fragment>
        <Span blue block fontSize="13px" paddingTop="5px">
          Kan leveres fra <Datoer type="julebryg" />
        </Span>
      </React.Fragment>
    ),
    notice: () => (
      <Paragraph blue fontSize="13px">
        Kan leveres fra <Datoer type="julebryg" />
      </Paragraph>
    ),
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Den mørke jule pilsner er gennem årene blevet et klart signal om, at
          højtiden står for døren. Julebryg er fyldig og sødmefuld med en god
          krop og behagelig bitterhed med en anelse af engelsk lakrids. Royal
          Xmas Blågran har en varm og mørk farve, der står fint til årstiden, og
          duften er både ristet karamelagtig og frisk. Nyd Royal Xmas Blågran
          til julens røgede og fede retter, og glem den ikke mindst på bordet
          til julefrokosten. Den mørke farve finder sig til rette i øllen, da
          der er benyttet en snert af farvemalt – dette gør også smagen mere
          fyldig og komplementerer desuden humlen i øllen. Vil du have et{" "}
          <LinkStyled to="/fadoel/juleoel">bredt udvalg af juleøl</LinkStyled>{" "}
          til julefrokosten, så kan du bestille flere varianter, så I kan opleve
          de mange forskellige smagsnoter. Vi vil blandt andet anbefale{" "}
          <LinkStyled to="/fadoel/juleoel/jacobsen-christmas-ale">
            Jacobsen Christmas Ale
          </LinkStyled>{" "}
          og{" "}
          <LinkStyled to="/fadoel/juleoel/skovlyst-julebryg">
            Skovlyst Julebryg
          </LinkStyled>
          .
        </Paragraph>
        <Paragraph>
          Velskænket Royal Xmas Blågran fås bedst fra fad, og denne har
          fadkobling af typen S. Som beskrevet er smagen af denne øl meget
          fyldig og har en god karakter, men dette er ikke ensbetydende med, at
          øllen er tung, som man ellers kender fra mange andre fadøl. Gennemse
          vores site og udvalg af juleøl, og find de øl, som du finder bedst til
          dine julefrokoster og arrangementer. Vi står både til rådighed for
          vejledning, levering, opsætning og returtagning.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 40675,
    name: "Anarkist Santas Fruit",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl julebryg juleøl dansk danske danmark 20l 20 l liter dark ipa anakist julbryg santa's fruitl",
    imageSingle: "produkter-fadoel.anarkist-santas-fruit.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    fadoelsType: "India Pale Ale",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    // royalUnibrew: true,
    // royalUnibrewJulekit: true,
    url: "/fadoel/juleoel/anarkist-santas-fruit",
    categories: ["fadoel", "fadoelJuleoel", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/juleoel/anarkist-santas-fruit",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelJuleoelBC],
        title: "Anarkist Santas Fruit",
      },
      meta: {
        title:
          "Køb Anarkist Santas Fruit IPA fadøl her - En hazy (uklar) IPA, skabt til julebordet",
        description:
          "Anarkist Santas Fruit IPA er brygget med fokus på at skabe en øl som passer perfekt til julemad. Det er en gylden, uklar øl med en dyb smag med noter af mandarin.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Jul",
      },
    },
    inStockText: () => (
      <React.Fragment>
        Udsolgt. Kommer igen <Datoer type="julebrygNextYear" />
      </React.Fragment>
    ),
    smallDescription: () => (
      <React.Fragment>
        <Span blue block fontSize="13px" paddingTop="5px">
          Kan leveres fra <Datoer type="julebryg" />
        </Span>
      </React.Fragment>
    ),
    notice: () => (
      <Paragraph blue fontSize="13px">
        Kan leveres fra <Datoer type="julebryg" />
      </Paragraph>
    ),
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Anarkist Santas Fruit IPA er brygget med fokus på at skabe en øl som
          passer perfekt til julemad. Det er en gylden, uklar øl med en dyb smag
          med noter af mandarin.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        <Paragraph>
          Anarkist Santas Fruit IPA er brygget med fokus på at skabe en øl som
          passer perfekt til julemad. Det er en gylden, uklar øl med en dyb smag
          med noter af mandarin.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 8275,
    name: "Skovlyst Øko Julebryg",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl julebryg juleøl dansk danske danmark 30l 30 l liter dark ipa julbryg",
    imageSingle: "produkter-fadoel.skovlyst-julebryg.png",
    imagePlural: "produkter-fadoel.skovlyst-oeko-julebryg-20l.png",
    fadoelsType: "Mørk Ale",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/juleoel/skovlyst-julebryg",
    categories: ["fadoel", "fadoelJuleoel", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/juleoel/skovlyst-julebryg",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelJuleoelBC],
        title: "Skovlyst Julebryg",
      },
      meta: {
        title:
          "Skovlyst Julebryg | Frisk juleøl - Er godt valg til Julefrokosten",
        description:
          "Skovlyst Julebryg er en klassisk juleøl, der smager krydret. Derfor passer den også rigtig godt til ethvert julebord, og er en del af vores julesortiment.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Jul",
      },
    },
    smallDescription: () => (
      <React.Fragment>
        <Span blue block fontSize="13px" paddingTop="5px">
          Kan leveres fra <Datoer type="julebryg" />
        </Span>
      </React.Fragment>
    ),
    notice: () => (
      <Paragraph blue fontSize="13px">
        Kan leveres fra <Datoer type="julebryg" />
      </Paragraph>
    ),
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Skovlyst Julebryg er en mørk ale med en krydret og smagfuld karakter.
          I eftergæringen er der blevet tilføjet ekstrakt fra grankviste, så
          øllet får en ret frisk smag, der passer til ethvert julebord. Øllets
          mørke og gyldne farve fås ved at anvende forskellige sukkerstoffer,
          heriblandt brun farin, der er med til at skabe denne. Udover en flot
          farve, så er sukkerstofferne også med til at tilføre øllet en
          karamelagtig karakter, der er særligt kendetegnet for de gode juleøl.
          Selvom øllen er ledt bitter, så har øllen også en helt særlig sødme
          til sig, der letter bitterheden. Den er desuden fyldt med aroma.
        </Paragraph>
        <Paragraph>
          Denne let bitre juleøl afbalanceres af et klassisk julekrydderi -
          nemlig det kendte kanel. De udvalgte ingredienser, der er særlige for
          denne øl er blandt andet den omtalte kanel. Udover denne har øllen
          også et granskud og golding humle samt rug malt.
        </Paragraph>
        <Paragraph>
          Se også{" "}
          <LinkStyled to="/fadoel/juleoel">alle vores juleøl her</LinkStyled>!
          Udvalget af juleøl er stort og vi har mange forskellige varianter, som
          du kan bestille hjem til alle julens begivenheder og arrangementer. Du
          kan sagtens bestille nogle forskellige varianter, så du kan prøve de
          forskellige smagsoplevelser. Vi anbefaler, at du prøver denne Skovlyst
          Julebryg! Du kan også læse om{" "}
          <LinkStyled to="/forbrug-af-fadoel">
            hvad andre kunder drak til deres fester og begivenheder
          </LinkStyled>
          .
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Mørk ale med en krydret karakter. Ekstrakt fra grankviste i eftergæring,
        så øllet får en ret frisk smag.
        <br />
        <br />
        Den mørkgyldne farve fås ved at anvende forskellige sukkerstoffer,
        heriblandt brun farin.
      </React.Fragment>
    ),
    inStockText: () => (
      <React.Fragment>
        Udsolgt. Kommer igen <Datoer type="julebrygNextYear" />
      </React.Fragment>
    ),
  },
  {
    varenr: 19775,
    name: "Svaneke Øko Julebryg",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl julebryg juleøl bornholm svaneke svanke sveneke svanike svinike svenike bryghus dansk danske danmark 20l 20 l liter økologisk økologiske julbryg",
    imageSingle: "produkter-fadoel.svaneke-julebryg.png",
    imagePlural: "produkter-fadoel.svaneke-fustage.png",
    fadoelsType: "Mørk Pilsner",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/juleoel/svaneke-julebryg",
    categories: ["fadoel", "fadoelJuleoel", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/juleoel/svaneke-julebryg",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelJuleoelBC],
        title: "Svaneke Julebryg",
      },
      meta: {
        title:
          "Svaneke Julebryg | En helt særlig Juleøl fra den Bornholm - Køb her",
        description:
          "Svaneke Julebryg - en klassisk Juleøl til mad og de festlige begivenheder, som julen medbringer. Øllen er meget smagfuld og har en skøn mørk farve og skum.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Jul",
      },
    },
    inStockText: () => (
      <React.Fragment>
        Udsolgt. Kommer igen <Datoer type="julebrygNextYear" />
      </React.Fragment>
    ),
    smallDescription: () => (
      <React.Fragment>
        <Span blue block fontSize="13px" paddingTop="5px">
          Kan leveres fra <Datoer type="julebryg" />
        </Span>
      </React.Fragment>
    ),
    notice: () => (
      <Paragraph blue fontSize="13px">
        Kan leveres fra <Datoer type="julebryg" />
      </Paragraph>
    ),
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Bornholmsk fadøl i særklasse</Heading3>
        <Paragraph>
          Svaneke Julebryg er en lækker fadøl fra Bornholm. Denne julebryg fadøl
          fremstår flot brun med et tykt og smagsrigt skum. Smagen og duften har
          ristede nuancer sammen med karamel og krokant. I eftersmagen opleves
          en diskret sødme i fin balance med humlen. Hvis du vil prøve en mere
          krydret variant med en snert af kanel, så prøv{" "}
          <LinkStyled to="/fadoel/skovlyst-julebryg">
            julebryggen fra Skovlyst
          </LinkStyled>
          . Du kan bestille dem begge til julens arrangementer, så I kan prøve
          de forskellige noter hos juleøllene.
        </Paragraph>
        <Paragraph>
          Denne øl er, som vores andre{" "}
          <LinkStyled to="/juleoel">juleøl</LinkStyled>, særligt egnet til den
          danske julemad, men fungerer i høj grad også godt i samspil med mørkt
          kød, stuvninger og retter der har en saltet nuance. Vi anbefaler, at
          denne øl serveres ved omkring 8 grader, for at få den optimale
          smagsoplevelse.
        </Paragraph>
        <Paragraph>
          Denne julebryg fra Svaneke Bryghus er en klassisk dansk jule øl.
          Svaneke Bryghus er et ældre bryggeri, der er place draughtmasterret i
          byen Svaneke på Bornholm. Bryggeriet åbnede tilbage i år 2000, som et
          af Danmarks første mikrobryggerier. Åbningen skete faktisk efter en
          tid på Bornholm, hvor der ikke tidligere havde været bryggeri på øen.
          Svaneke Julebryg, og de andre øl fra Svaneke Bryghus, er alle
          ufiltrerede og upasteuriserede. Svaneke Bryghus er efterhånden et
          stort bryggeri, der brygger mere end 20 specialøl, der alle har
          forskellige formål og smagsoplevelser – heriblandt findes denne
          Julebryg selvfølgelig. Udover den gode smag øllet har, så er designet
          på de mange ølflasker desuden også specielt og pænt.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Svaneke Julebryg er en lækker fadøl fra bornholm. Denne julebryg fadøl
        fremstår flot brun med et tykt og smagsrigt skum. Smagen og duften har
        ristede nuancer sammen karamel og krokant.
      </React.Fragment>
    ),
  },
  {
    varenr: 4875,
    name: "Albani Rødhætte",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl julebryg juleøl dansk danske danmark 25l 25 l liter julbryg",
    imageSingle: "produkter-fadoel.albani-roedhaette-glas.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    fadoelsType: "Stærk Pilsner",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    // royalUnibrew: true,
    // royalUnibrewJulekit: true,
    url: "/fadoel/juleoel/albani-roedhaette",
    categories: ["fadoel", "fadoelJuleoel", "fadoelDansk"],
    routeDef: {
      path: "/fadoel/juleoel/albani-roedhaette",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelJuleoelBC],
        title: "Albani Rødhætte",
      },
      meta: {
        title:
          "Køb Albani Rødhætte fadøl her - En rødgylden juleøl til din julefest",
        description:
          "Albani Rødhætte er en klar, rødgylden julebryg med en sød smag, som er er fyldig og indeholder toner af karamel. Eftersmagen har en kort bitterhed.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Jul",
      },
    },
    inStockText: () => (
      <React.Fragment>
        Udsolgt. Kommer igen <Datoer type="julebrygNextYear" />
      </React.Fragment>
    ),
    /*
    smallDescription: () => (
      <React.Fragment>
        <Span blue block fontSize="13px" paddingTop="5px">
          Kan leveres fra <Datoer type="julebryg" />
        </Span>
      </React.Fragment>
    ),
    notice: () => (
      <Paragraph blue fontSize="13px">
        Kan leveres fra <Datoer type="julebryg" />
      </Paragraph>
    ),
    */
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Albani Rødhætte er en klar, rødgylden julebryg med en sød smag, som er
          er fyldig og indeholder toner af karamel. Eftersmagen har en kort
          bitterhed.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Albani Rødhætte er en klar, rødgylden julebryg med en sød smag, som er
        er fyldig og indeholder toner af karamel. Eftersmagen har en kort
        bitterhed.
      </React.Fragment>
    ),
  },

  /* *
   *
   * Fadøl - Modular 20
   *
   * */
  {
    varenr: 2556,
    name: "Carlsberg Nordic Pilsner Modular 20 0,0%",
    generalSearchPriority: -100,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl letøl alkoholfri frokostøl alkoholdfri alkolholfri alkoholfrit øl uden let light ølight øllight light øl modular 20 modular20 new dansk danske danmark 20l 20 liter l engangsfustager",
    modular: true,
    showModularText: true,
    usePluralImage: true,
    imageSingle: "produkter-fadoel.carlsberg-nordic-glas.png",
    imagePlural: "produkter-fadoel.carlsberg-nordic-modular20.png",
    fadoelsType: "Pilsner",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/modular-20/carlsberg-nordic",
    categories: ["fadoelModular20"],
    routeDef: {
      path: "/fadoel/modular-20/carlsberg-nordic",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelModular20BC],
        title: "Carlsberg Nordic Modular 20",
      },
      meta: {
        title:
          "Carlsberg Nordic Pilsner | Køb den tørstslukkende alkoholfrie pilsner her!",
        description:
          "Carlsberg Nordic er en klar alkoholfri pilsner som har en krystalklar og gylden farve. Smagen er i god balance, som på trods af at den er alkoholfri, har en god fornemmelse af fylde og som let slukker tørsten som en normal pilsner",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Letoel",
      },
    },
    productPageDescriptionLong: () => (
      <Paragraph>
        Carlsberg Nordic er en klar alkoholfri pilsner som har en krystalklar og
        gylden farve. Smagen er i god balance, som på trods af at den er
        alkoholfri, har en god fornemmelse af fylde og som let slukker tørsten
        som en normal pilsner.
      </Paragraph>
    ),
    popupDescription: () => (
      <React.Fragment>
        Carlsberg Nordic er en klar alkoholfri pilsner som har en krystalklar og
        gylden farve. Smagen er i god balance, som på trods af at den er
        alkoholfri, har en god fornemmelse af fylde og som let slukker tørsten
        som en normal pilsner.
      </React.Fragment>
    ),
  },
  {
    varenr: "3675",
    name: "Tuborg Guld Modular 20",
    generalSearchPriority: -100,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl modular 20 modular20 ace draughtmaster draught draugt draftmaster matser master fadøl tuborg gyldne dame damer gyldnedamer dansk danske danmark 20l 20 liter l engangsfustager guldtuborg",
    modular: true,
    showModularText: true,
    usePluralImage: true,
    imageSingle: "produkter-fadoel.tuborg-guld.png",
    imagePlural: "produkter-fadoel.guld-tuborg-modular20.png",
    fadoelsType: "Stærk Pilsner",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    related: [8115, 8895, 10615, 10605],
    url: "/fadoel/modular-20/tuborg-guld",
    categories: ["fadoelModular20"],
    routeDef: {
      path: "/fadoel/modular-20/tuborg-guld",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelModular20BC],
        title: "Tuborg Guld",
      },
      meta: {
        title: "Tuborg Guld - Også kendt som 'Den gyldne dame'",
        description:
          "Tuborg Guld er en elegant luksusøl med smag og kraft. Farven er lys gylden med hvidt skum og en dejlig frugtagtig duft. Køb Tuborg Guld her.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Modular20",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>
          Frisk Tuborg aroma og et godt bid af humle i eftersmagen
        </Heading3>
        <Paragraph>
          Tuborg Guld eller Guld Tuborg blev lanceret i Danmark i 1895. Den er
          kendt som ”Den gyldne dame”. Det er en elegant luksusøl med smag og
          kraft. Der er en god balance mellem tør fylde og livlighed. Farven er
          lys gylden med hvidt skum og en dejlig frugtagtig duft.
        </Paragraph>
        <Paragraph>
          Tuborg Gulds gyldne farve opnås ved udelukkende at bruge pilsnermalt i
          rigelige mængder. Aromaen er frugtagtig med en kraftig note af den
          friske Tuborg aroma, der skabes af gæren. Tuborg Guld har en anelse
          mere maltkarakter end Grøn Tuborg. Den gyldne dame har desuden lidt
          mere krop end Grøn Tuborg, hvilket står i en god overensstemmelse med
          bitterheden fra humlen. Den gyldne dame har et godt bid af humle i
          eftersmagen.
        </Paragraph>
      </React.Fragment>
    ),
    productPageXtraDescription: () => (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <Heading3>
              Tip: Servér den let tempereret til kød, sovs og kartofler
            </Heading3>
            <Paragraph>
              Tuborg Guld bør serveres ved 7-10 °C. Den passer godt til dansk
              mad som fx krebinetter eller bare kød serveret med sovs og
              kartofler.
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Tuborg Guld, også kaldet en gylden dame, er en stærk pilsner.
        <br />
        <br />I eftersmagen kan man mærke et godt bid af humle.
      </React.Fragment>
    ),
  },
  {
    varenr: 8885,
    name: "Jacobsen Brown Ale Modular 20",
    generalSearchPriority: -100,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl modular 20 modular20 ace draughtmaster draught draugt draftmaster matser master fadøl dansk danske danmark 20l 20 liter l engangsfustager",
    modular: true,
    showModularText: true,
    usePluralImage: true,
    imageSingle: "produkter-fadoel.jacobsen-brown-ale-1.png",
    imagePlural: "produkter-fadoel.jacobsen-brown-ale-modular20.png",
    fadoelsType: "Brown Ale",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/modular-20/jacobsen-brown-ale",
    categories: ["fadoelModular20"],
    routeDef: {
      path: "/fadoel/modular-20/jacobsen-brown-ale",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelModular20BC],
        title: "Jacobsen Brown Ale Modular 20",
      },
      meta: {
        title: "Jacobsen Brown Ale – Inspireret af de bedste engelske ales",
        description:
          "Jacobsen Brown Ale er en anderledes fuld-aromatiseret øl. Den kraftige karamel- og maltsødme giver en fyldig rund smag med let bitterhed. Køb den her.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Ale",
      },
    },
    popupDescription: () => (
      <React.Fragment>
        Inspireret af de bedste engelske ales.
        <br />
        <br />
        Flot, dyb brun farve og en fyldig rund smag med både sødme og bitterhed.
      </React.Fragment>
    ),
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Anderledes, frugtagtig, fuld-aromatiseret øl</Heading3>
        <Paragraph>
          Jacobsen Brown Ale har været på markedet siden 2005 og er inspireret
          af de bedste engelske ales. en har en flot dyb brun farve og en fyldig
          rund smag med både sødme og bitterhed. Den er brygget på flere
          forskellige malte, som giver den en ristet kompleksitet og en aroma af
          chokolade, kaffe og tørret frugt.
        </Paragraph>
        <Paragraph>
          Jacobsen Brown Ale er en anderledes, frugtagtig, fuld-aromatiseret øl,
          som har en alkoholstyrke på 6,0%. Den dybe brune farve kommer fra den
          eksklusive engelske malt Maris Otter Ale i kombination med ambermalt,
          carafa special-malt, karamelmalt og chokolademalt. Der er desuden
          tilføjet Nelson Sauvin aromahumle fra New Zealand. Den kraftige
          karamel- og maltsødme giver den en fyldig rund smag med en let
          bitterhed.
        </Paragraph>
      </React.Fragment>
    ),
    productPageXtraDescription: () => (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <Paragraph>
              Jacobsen Brown Ale er en øl, der bør nydes ved ca. 6-10 °C. De
              meget kraftige karamelliserede aromaer passer særdeles godt til
              grillet oksekød, stegt kylling, sprængt kalvebryst, kalvelever og
              koldt kød fx hamburgerryg samt postejer. Den er også velegnet til
              oste som fx Taleggio, en dejlig cremet rødkitost fra Norditalien
              eller Reblochon, som er en halvfast komælksost fra de franske
              alper.
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
  },
  {
    varenr: 8895,
    name: "Jacobsen Yakima IPA Modular 20",
    generalSearchPriority: -100,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl modular 20 modular20 ace draughtmaster draught draugt draftmaster matser master fadøl dansk danske danmark 20l 20 liter l engangsfustager jakima jakobsen",
    modular: true,
    showModularText: true,
    usePluralImage: true,
    imageSingle: "produkter-fadoel.jacobsen-yakima-ipa.png",
    imagePlural: "produkter-fadoel.jacobsen-yakima-ipa-modular20.png",
    fadoelsType: "Indian Pale Ale",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/modular-20/jacobsen-yakima-ipa",
    categories: ["fadoelModular20"],
    routeDef: {
      path: "/fadoel/modular-20/jacobsen-yakima-ipa",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelModular20BC],
        title: "Jacobsen Yakima IPA Modular 20",
      },
      meta: {
        title: "Jacobsen Yakima IPA Modular 20 | Køb her",
        description: "Køb Jacobsen Yakima IPA som Modular 20 fadøl her",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "IPA",
      },
    },
  },
  {
    varenr: 8815,
    name: "Grimbergen Blanche Modular 20",
    generalSearchPriority: -100,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl modular 20 modular20 ace draughtmaster draught draugt draftmaster matser master fadøl 20l 20 liter l weissbier weisbier weisbeer belgien belgisk engangsfustager grimberger grinbergen grinberger grenberg grenberger grimbergem grindberg grimbregen grindbergen",
    imageSingle: "produkter-fadoel.grimbergen-blanche-glas.png",
    imagePlural: "produkter-fadoel.grimbergen-blanche-modular20.png",
    modular: true,
    showModularText: true,
    fadoelsType: "Hvedeøl",
    land: "Belgien",
    landKort: "BE",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/modular-20/grimbergen-blanche",
    categories: ["fadoelModular20"],
    routeDef: {
      path: "/fadoel/modular-20/grimbergen-blanche",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelModular20BC],
        title: "Grimbergen Blanche Modular 20",
      },
      meta: {
        title:
          "Køb Grimbergen Blanche her | Forfriskende og med nok krop til at erstatte vinen ved middagsbordet",
        description:
          "Grimbergen Blanche Modular 20 er en klassisk ufiltreret, krydret hvedeøl. En legendarisk hvedeøl fra Grimbergen.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Hvedeoel",
      },
    },
  },
  {
    varenr: 8805,
    name: "Kronenbourg 1664 Modular 20",
    generalSearchPriority: -100,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl modular 20 modular20 ace draughtmaster draught draugt draftmaster matser master fadøl 20l 20 liter l kronenburg blank kronenberg kronborg engangsfustager 1669 1764 1864 1686",
    modular: true,
    showModularText: true,
    usePluralImage: true,
    imageSingle: "produkter-fadoel.kronenbourg-1664.png",
    imagePlural: "modular20.kronenbourg-1664.png",
    fadoelsType: "Pilsner",
    land: "Frankrig",
    landKort: "FR",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    related: [4915, 23615, 8415],
    url: "/fadoel/pilsner/kronenbourg-1664",
    categories: ["fadoelModular20"],
    routeDef: {
      path: "/fadoel/pilsner/kronenbourg-1664",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelModular20BC],
        title: "Kronenbourg 1664 Modular 20",
      },
      meta: {
        title: "Kronenbourg 1664 - Frisk hvedeøl - Perfekt sommerøl",
        description:
          "Kronenbourg 1664 er en fransk hvedeøl & meget populær i Frankrig. Den har en sød og rund fylde, som måske appellerer til kvinder og det unge publikum.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Frisk pilsner med duft af citrus</Heading3>
        <Paragraph>
          Kronenbourg 1664 er en fransk pilsner og en meget populær øl i
          Frankrig. Den har en sød og rund fylde, som måske især appellerer til
          kvinder og det unge publikum. Det er en perfekt sommerøl med et friskt
          syrligt bid og skøn duft af citrus. Brasseries Kronenbourg er
          Frankrigs største bryggeri. Det blev grundlagt i 1664 af Jérôme Hatt i
          Strasbourg. Det ejes i dag af Carlsberg Breweries. Kronenbourg 1664
          sælges i over 50 lande.
        </Paragraph>
        <Paragraph>
          Kronenbourg 1664 blev lanceret første gang i 1952 i anledningen af
          kroningen af den engelske Dronning Elizabeth II og blev omgående en
          stor succes i Frankrig. Smagen har en blød, rund fylde og byder på en
          fin balance mellem delikat maltsødme og afdæmpet humlebitterhed.
          Kronenbourg 1664 har samtidig et friskt syrligt bid og en skøn duft af
          citrus. Den har en dejlig gylden farve med et livligt spil af kulsyre.
        </Paragraph>
      </React.Fragment>
    ),
    productPageXtraDescription: () => (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <Heading3>TIP: God til tysk og fransk inspireret mad</Heading3>
            <Paragraph>
              Carlsberg anbefaler en servering ved 5° C. Kronenbourg 1664 er
              velegnet til retter inspireret af det tyske og franske køkken.
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Lanceret første gang i 1952 i anledningen af kroningen af den engelske
        Dronning Elizabeth II.
        <br />
        <br />
        Sød og rund fylde, som måske især appellerer til kvinder og det unge
        publikum.
      </React.Fragment>
    ),
  },
  {
    varenr: 8715,
    name: "Brooklyn Lager Modular 20",
    generalSearchPriority: -100,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl modular 20 modular20 ace draughtmaster draught draugt draftmaster matser master 20l 20 liter l brocklyn engangsfustager usa u.s.a. u.s.a amerika amerikansk amerikanske",
    imageSingle: "produkter-fadoel.brooklyn-lager.png",
    // imagePlural: 'produkter-fadoel.carlsberg-fustage.png',
    imagePlural: "produkter-fadoel.brooklyn-lager-modular20.png",
    modular: true,
    showModularText: true,
    fadoelsType: "Pilsner",
    land: "USA",
    landKort: "USA",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/pilsner/brooklyn-lager",
    categories: ["fadoelModular20"],
    routeDef: {
      path: "/fadoel/pilsner/brooklyn-lager",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelModular20BC],
        title: "Brooklyn Lager",
      },
      meta: {
        title: "Køb Brooklyn Lager fadøl og lej fadølsanlæg inklusiv kulsyre",
        description:
          "Brooklyn Lager fadøl har en rav/gylden farve og er særdeles forfriskende. Smagen af malt støttes op af en forfriskende bitterhed.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>En kraftfuld øl</Heading3>
        <Paragraph>
          Brooklyn Lager har en rav/gylden farve. Smagen af malt støttes op af
          en forfriskende bitterhed. Øllen er både kraftfuld, forfriskende og er
          meget alsidig i forhold til servering sammen med mad.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Brooklyn Lager har en rav/gylden farve. Smagen af malt støttes op af en
        forfriskende bitterhed.
        <br />
        <br />
        Meget alsidig i forhold til servering sammen med mad
      </React.Fragment>
    ),
  },
  {
    varenr: 3635,
    name: "Rød Tuborg Modular 20",
    generalSearchPriority: -100,
    keywords:
      "fustage fustager fustace fus rød tuborg röd fad fadølsfustage fadøl modular 20 modular20 ace draughtmaster draught draugt draftmaster matser master dansk danske danmark 20l 20 liter l engangsfustager",
    modular: true,
    showModularText: true,
    imageSingle: "produkter-fadoel.roed-tuborg.png",
    imagePlural: "produkter-fadoel.roed-tuborg-modular20.png",
    fadoelsType: "Mørk Pilsner",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    // notice: () => 'Sæson: Maj',
    url: "/fadoel/classic/roed-tuborg",
    categories: ["fadoelModular20"],
    routeDef: {
      path: "/fadoel/classic/roed-tuborg",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelModular20BC],
        title: "Rød Tuborg",
      },
      meta: {
        title: "Rød Tuborg – Den originale – Tuborgs første øl fra 1875",
        description:
          "Rød Tuborg er en fyldig all-malt øl med rigt skum med en alkoholstyrke på 4,3%. Den har en flot rødbrun farve. Køb den populære sæsonøl her.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Classic",
      },
    },
    smallDescription: () => (
      <React.Fragment>
        <Span blue block fontSize="13px" paddingTop="5px">
          Kan leveres fra <Datoer type="roedtuborg" />
        </Span>
      </React.Fragment>
    ),
    notice: () => (
      <Paragraph blue fontSize="13px">
        Kan leveres fra <Datoer type="roedtuborg" />
      </Paragraph>
    ),
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Mørk, undergæret lagerøl eller bajersk øl</Heading3>
        <Paragraph>
          Rød Tuborg – den originale - er Tuborg bryggeriets allerførste øl fra
          1875. Den er brygget på fire malttyper, der giver den en flot rødbrun
          farve. Det er en fyldig all- malt øl med rigt skum med en
          alkoholstyrke på 4,3%. Den blev markedsført under navnet Lagerøl, men
          den blev hurtigt kaldt Tuborger, senere Rød, og er endt med at hedde
          Rød Tuborg.
        </Paragraph>
        <Paragraph>
          Rød Tuborg er en mørk, undergæret lagerøl eller bajersk øl. Den er
          brygget på malttyperne: Münchenermalt, pilsnermalt, karamel- og
          farvemalt. Münchenermalt bruges som basismalt i flere tyske lagerøl.
          Malten er mørkere end fx wienermalten og giver en fyldig, maltet og
          sødlig øl. De fire malttyper giver sammen med Tuborg-gæren den
          karakteristiske rødbrune farve. Rød Tuborg hører til pilsnerklassen.
        </Paragraph>
      </React.Fragment>
    ),
    productPageXtraDescription: () => (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <Heading3>Rød Tuborg er en sæsonøl for maj måned</Heading3>
            <Paragraph>
              Rød Tuborg har været fjernet helt fra sortimentet, da den ikke
              solgte nok, men i 2007 blev den relanceret efter et massivt
              forbrugerpres. Rød Tuborg er ikke en del af det faste sortiment.
              Den bliver kun brygget én gang om året og kommer i butikkerne i
              maj måned.
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
  },
  {
    varenr: 3795,
    name: "Tuborg Påskebryg Modular 20",
    generalSearchPriority: -100,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl modular 20 modular20 ace draughtmaster draught draugt draftmaster matser master dansk danske danmark kylle kylle påskeøl engangsfustager",
    imageSingle: "produkter-fadoel.tuborg-paaskebryg.png",
    imagePlural: "produkter-fadoel.tuborg-paaskebryg-modular20.png",
    fadoelsType: "Mørk Pilsner",
    land: "Danmark",
    landKort: "DK",
    modular: true,
    showModularText: true,
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/modular-20/tuborg-paaskebryg",
    categories: ["fadoelPaaskebryg", "fadoelModular20"],
    routeDef: {
      path: "/fadoel/modular-20/tuborg-paaskebryg",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelModular20BC],
        title: "Tuborg Påskebryg",
      },
      meta: {
        title: "Tuborg Påskebryg - Den bedste fadøl til foråret og påsken",
        description:
          "Tuborg Påskebryg / Kylle Kylle leveres på 25 l fustager. Lej også fadølsanlæg og bar. Velegnet til påskefrokoster. Køb Danmarks mest populære påskeøl her.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Modular20",
      },
    },
    inStockText: () => (
      <React.Fragment>
        Udsolgt. Kommer igen <Datoer type="paaskebryg" />
      </React.Fragment>
    ),
    smallDescription: () => (
      <React.Fragment>
        <Span blue block fontSize="13px" paddingTop="5px">
          Kan leveres fra <Datoer type="paaskebryg" />
        </Span>
      </React.Fragment>
    ),
    notice: () => (
      <Paragraph blue fontSize="13px">
        Kan leveres fra <Datoer type="paaskebryg" />
      </Paragraph>
    ),
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Køb Danmarks mest populære påskeøl her</Heading3>
        <Paragraph>
          Tuborg Påskebryg er en sæsonøl med sin helt egen historie og
          traditioner. Tuborg Påskebryg, også kaldet Kylle Kylle, er en
          ravgylden og let sødlig øl med en alkoholstyrke på 5,7%. Den udgives
          på P-dag (påskebrygdag).
        </Paragraph>
        <Paragraph>
          Tuborg Påskebryg er en af Danmarks mest populære påskeøl. Lej også
          fadølsanlæg og bar for at give den optimale stemning til festen.
          Tuborg Påskebryg er ravgylden med et flot hvidt skum. Den ligger
          smagsmæssigt imellem en pilsner og en julebryg.
        </Paragraph>
      </React.Fragment>
    ),
    productPageXtraDescription: () => (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <Heading3>Påskebryggen blev første gang solgt i 1906</Heading3>
            <Paragraph>
              Tuborg Påskebryg er en populær og velkendt fadøl med en spændende
              historie bag sig. Den blev første gang solgt til påsken i 1906. I
              perioder har påskebryggen af forskellige årsager ikke været i
              produktion fx i forbindelse med 2. verdenskrig blev det forbudt at
              sælge luksusøl. I 1931 blev Tuborg Påskebryg lanceret på flasker,
              den var indtil da kun blevet solgt på fustager.
            </Paragraph>
            <Heading4>
              Stærk mørk øl ændrede farve, skatteklasse og fik navnet Kylle
              Kylle
            </Heading4>
            <Paragraph>
              Påskebryggen var en meget stærk øl (klasse B), og allerede i
              slutningen af 1930&apos;erne blev det bestemt, at den kun måtte
              sælges omkring påske. En ny lys ølsort så dagens lys i 1953, og
              Tuborg Påskebyg blev for en kort stund lys, men allerede i 1956
              blev den igen mørk. Igen i 1973 forsøgte Tuborg at lancere en
              lysere Tuborg Påskebryg. I 1994 blev den igen ændret nu til et
              produkt i samme skatteklasse om Guld Tuborg. Allerede i 1965 var
              kyllingemotivet kommet på etiketten, men det blev først i 1994, at
              Tuborg Påskebryg fik det officielle navn Kylle Kylle.
            </Paragraph>
            <Heading4>Tuborg Påskebryg har sin helt egen P-dag</Heading4>
            <Paragraph>
              Historik for P-dag:
              <br />
              2023: 24. februar
              <br />
              2022: 4. marts
              <br />
              2021: 14. marts
              <br />
              2020: 28. februar
              <br />
              2019: 08. marts
              <br />
              2018: 23. februar
              <br />
              2017: 16. marts
              <br />
              <br />
              P-dag, er dagen hvor påskebryggen kommer i handlen. Det er blevet
              fejret i rigtig mange år. Traditionen går helt tilbage til 1952,
              men i årenes løb har interessen for påskebryg været svingende.
              Ifølge traditionen frigives den en fredag aften præcis kl. 20:59
              på udskænkningssteder. P-dag når ikke samme højder som J- dag
              (julebrygdag eller snestorm), men i mange hjem står Kylle Kylle
              fast hvert år på påskebordet, når påskefrokosten skal indtages
              sammen med familie og venner.
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        En af Danmarks mest populære påskeøl. Udkommer på &quot;P-dag&quot; og
        bliver også kaldt Kylle kylle.
        <br />
        <br />
        Ravgylden og let sødlig øl med en alkoholstyrke på 5,7%
      </React.Fragment>
    ),
  },
  {
    varenr: 8875,
    name: "Jacobsen Christmas Ale Modular 20",
    generalSearchPriority: -100,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl julebryg juleøl modular 20 modular20 dansk danske danmark 20l 20 l liter dark ipa jakobsen engangsfustager",
    imageSingle: "produkter-fadoel.jacobsen-christmas-ale.png",
    imagePlural: "produkter-fadoel.jacobsen-modular20.png",
    fadoelsType: "Mørk Ale",
    land: "Danmark",
    landKort: "DK",
    modular: true,
    showModularText: true,
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/modular-20/jacobsen-christmas-ale",
    categories: ["fadoelModular20"],
    routeDef: {
      path: "/fadoel/modular-20/jacobsen-christmas-ale",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelModular20BC],
        title: "Jacobsen Christmas Ale",
      },
      meta: {
        title:
          "Jacobsen Christmas Ale | Rund og cremet Skandinavisk Juleøl med Kanel",
        description:
          "Jacobsen Christmas Ale er en skøn klassisk øl til julen. Denne ale har en meget sødlig smag med fornemmelser af mange gode julesmage som appelsin og kanel.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Modular20",
      },
    },
    inStockText: () => (
      <React.Fragment>
        Udsolgt. Kommer igen <Datoer type="julebrygNextYear" />
      </React.Fragment>
    ),
    smallDescription: () => (
      <React.Fragment>
        <Span blue block fontSize="13px" paddingTop="5px">
          Kan leveres fra <Datoer type="julebryg" />
        </Span>
      </React.Fragment>
    ),
    notice: () => (
      <Paragraph blue fontSize="13px">
        Kan leveres fra <Datoer type="julebryg" />
      </Paragraph>
    ),
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Navnet Jacobsen Christmas Ale klinger internationalt, men når man
          smager den fornemmer man tydeligt, at den er inspireret af den typiske
          skandinaviske jul med alt der hører sig til. Øllets smag er sødlig på
          grund af en dobbelt gæring og en tilsat sveskejuice. Duften giver en
          fornemmelse af af elementer som appelsin, citron, kanel, lakridsrod og
          karamel.
        </Paragraph>
        <Paragraph>
          Smagen er rund, cremet og bitterheden er velafstemt. Øllen har en flot
          gyldenbrun farve og en tæt, samt næsten flødefarvet skum. Vil du prøve
          en krydret juleøl med en snert af kanel, så kan du også se vores
          variant fra{" "}
          <LinkStyled to="/fadoel/juleoel/skovlyst-julebryg">
            Skovlyst Julebryg
          </LinkStyled>
          . Er du derimod mere til at prøve en øl med smagsnuancer af karamel og
          krokant, så kan du prøve den søde{" "}
          <LinkStyled to="/fadoel/juleoel/svaneke-julebryg">
            Svaneke Julebryg
          </LinkStyled>{" "}
          fra Bornholm.
        </Paragraph>
        <Paragraph>
          Denne type af øl er en mørk ale, der desuden også er klassisk for
          julens øltyper. Alkoholprocenten for Jacobsen Christmas Ale er på 7.5
          procent. Fadkoblingen er desuden af typen S. Husbryggeriet Jacobsen
          blev grundlagt tilbage i år 1847, men fornyer stadig sine metoder og
          perfektionering af de mange forskellige øl, som de brygger til hverdag
          på bryggeriet. Mottoet for bryggeriet er, at Jacobsen er brygget på
          lidenskab. Det er denne lidenskab der i samspil med videnskaben skaber
          den fantastiske Jacobsen Christmas Ale. Bestil øllen hjem til julens
          mange arrangementer og nyd de fantastiske noter af julen i din øl, der
          er perfekt til den klassiske skandinaviske julemad, som hører sig til
          ethvert julebord – helt som denne øl fra Jacobsen.
        </Paragraph>
      </React.Fragment>
    ),
  },

  /* *
   *
   * Fadøl - Flex 20
   *
   * */
  {
    varenr: 2614,
    name: "Carlsberg Pilsner Flex 20",
    generalSearchPriority: -150,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl druaug fadøl dansk danske danmark flex 20 flex20 ace draughtmaster draught draugt draftmaster matser master 20l 20 liter l engangsfustager hof m med slange",
    usePluralImage: true,
    imageSingle: "produkter-fadoel.carlsberg-pilsner.png",
    imagePlural: "flex20.carlsberg-pilsner.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "Pilsner",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    flex: true,
    showFlexText: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/flex-20/carlsberg-pilsner",
    categories: ["fadoelFlex20"],
    routeDef: {
      path: "/fadoel/flex-20/carlsberg-pilsner",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelFlex20BC],
        title: "Carlsberg Pilsner Flex 20",
      },
      meta: {
        title: "Carlsberg Pilsner Flex 20 | Køb her",
        description: "Køb Carlsberg Pilsner som Flex 20 fadøl her",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    popupDescription: () => (
      <React.Fragment>
        Undergæret øl med en lys gylden farve og flot skum. Introduceret i 1904
        og går også under navnet HOF.
        <br />
        <br />
        Især god til det klassiske danske frokostbord. Bør nydes ved 7-10 °C.
      </React.Fragment>
    ),
  },
  {
    varenr: 3614,
    name: "Grøn Tuborg Flex 20",
    generalSearchPriority: -150,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl druaug fadøl dansk danske danmark flex 20 flex20 ace draughtmaster draught draugt draftmaster matser master 20l 20 liter l pilsner engangsfustager m med slange",
    usePluralImage: true,
    imageSingle: "produkter-fadoel.groen-tuborg.png",
    imagePlural: "flex20.groen-tuborg.png",
    fadoelsType: "Pilsner",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    flex: true,
    showFlexText: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/flex-20/groen-tuborg",
    categories: ["fadoelFlex20"],
    routeDef: {
      path: "/fadoel/flex-20/groen-tuborg",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelFlex20BC],
        title: "Grøn Tuborg Flex 20",
      },
      meta: {
        title: "Grøn Tuborg Flex 20 | Køb her",
        description: "Køb Grøn Tuborg som Flex 20 fadøl her",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Pilsner",
      },
    },
    popupDescription: () => (
      <React.Fragment>
        En undergæret øl som brygges på pilsnermalt, fremstillet af vårbyg,
        hvilket giver den lyse gule farve.
        <br />
        <br />
        Smag og aroma domineres af gær og humle.
      </React.Fragment>
    ),
  },
  {
    varenr: 3654,
    name: "Tuborg Classic Flex 20",
    generalSearchPriority: -150,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl druaug fadøl dansk danske danmark flex 20 flex20 ace draughtmaster draught draugt draftmaster matser master 20l 20 liter l clasic clasig classik engangsfustager m med slange",
    usePluralImage: true,
    imagePlural: "modular20.tuborg-classic.png",
    imageSingle: "produkter-fadoel.tuborg-classic.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "Pilsner",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    flex: true,
    showFlexText: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/flex-20/tuborg-classic",
    categories: ["fadoelFlex20"],
    routeDef: {
      path: "/fadoel/flex-20/tuborg-classic",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelFlex20BC],
        title: "Tuborg Classic Flex 20",
      },
      meta: {
        title: "Tuborg Classic Flex 20 | Køb her",
        description: "Køb Tuborg Classic som Flex 20 fadøl her",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Classic",
      },
    },
    popupDescription: () => (
      <React.Fragment>
        Tuborg Classic har en anelse mere krop og humlebitterhed end Grøn
        Tuborg, fordi der er brugt mere malt og en smule mere humle.
      </React.Fragment>
    ),
  },

  /* *
   *
   * Cocktails, vin & cider
   *
   * */
  {
    varenr: 17865,
    name: "Serena Vino Frizzante",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl 24l 24 l liter serana prosecco prossecco proseco prosseco cocktail cocktails cocktaildrinks coctail coktail coktaildrinks coctaildrinks drinks",
    heading: "Serena Vino Frizzante - Perfekt til velkomstdrinken",
    serena: true,
    imageSingle: "produkter-fadoel.sparkling-wine.png",
    imagePlural: "produkter-fadoel.serena-vino-fustage.png",
    land: "Italien",
    landKort: "IT",
    fadoelsType: "Vin",
    gas: "CO2",
    pressure: "2,5-3 bar",
    url: "/fadoel/cocktails-vin-cider/serena-vino-frizzante",
    categories: ["fadoelCocktailVinCider"],
    routeDef: {
      path: "/fadoel/cocktails-vin-cider/serena-vino-frizzante",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelCocktailsCiderVinBC],
        title: "Serena Vino Frizzante",
      },
      meta: {
        title: "Vino Frizzante | En skøn italiensk mousserende vin – Skafte.dk",
        description:
          "Køb Serena Vino Frizzante - En mousserende vin, der er god til både velkomstdrinks eller andre blandede drinks. Smagen er frisk og god til enhver lejlighed!",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Cocktails",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>
          Server Aperol Spritz samt flere lækre Spritzer cocktails til din fest!
        </Heading3>
        <Heading4>Få lækre italienske bobler på fustage</Heading4>
        <Paragraph>
          Serena Vino Frizzante kommer fra Prosecco, hvor den for længst har
          indtaget en fast plads på disken i barer og restauranter i hjemlandet
          Italien.
        </Paragraph>
        <Paragraph>
          Serena Vino Frizzante er en frisk, ”næsten tør” mousserende hvidvin
          med noter af blomster og citron, der egner sig perfekt til enhver
          festlig lejlighed.
        </Paragraph>
        <Paragraph>
          Den nydes godt som velkomstdrink, til fisk og skaldyr eller til brug i
          cocktails. Drop besværet med nedkøling og afskaffelse af tunge
          glasflasker. Med Serena Vino Frizzante serveres boblerne hurtigt og
          tappes dejlig afkølet med det samme og hver gang.
        </Paragraph>
      </React.Fragment>
    ),
    productPageXtraDescription: () => (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <Paragraph>
              Serena Vino Frizzante fremstilles efter traditionel Prosecco
              metode - Charmat metoden - hvor vinen gennem 2. gæring på tryktank
              udvikler bobler, derefter tappes den på fustage.
            </Paragraph>
            <Paragraph>
              Fustagen er lavet af miljøvenligt og genanvendelig plastik, der
              sparer produktion af over 26 vinflasker, etiketter, propper,
              papkasser osv. – altså vœsentlig lavere miljøbelastning end ved
              distribution på flaske.
            </Paragraph>
            <Paragraph>
              Producenten, Vinicola Serena har produceret italiensk vin siden
              1881 og er Italiens største producent af vin på fustage.
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Server Aperol Spritz samt flere lækre Spritzercocktails til din fest!
        Serena Vino Frizzante kommer fra Prosecco, hvor den for længst har
        indtaget en fast plads på disken i barer og restauranter i hjemlandet
        Italien.
      </React.Fragment>
    ),
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
  },
  {
    varenr: 17875,
    name: "Vino Rosato",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl 24l 24 l liter vin wine",
    imageSingle: "produkter-fadoel.vino-rosato-logo.png",
    imagePlural: "produkter-fadoel.serena-vino-fustage.png",
    land: "Italien",
    landKort: "IT",
    fadoelsType: "Vin",
    gas: "CO2",
    pressure: "2,5-3 bar",
    url: "/fadoel/cocktails-vin-cider/vino-rosato",
    categories: ["fadoelCocktailVinCider"],
    routeDef: {
      path: "/fadoel/cocktails-vin-cider/vino-rosato",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelCocktailsCiderVinBC],
        title: "Vino Rosato",
      },
      meta: {
        title: "Vino Rosato | En skøn italiensk rosé – Skafte.dk",
        description:
          "Køb Vino Rosato - En rosévin, der er god til både velkomstdrinks eller til dem som foretrækker en rosé over en drink. Smagen er frisk og god til enhver lejlighed!",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Cocktails",
      },
    },
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
  },
  {
    varenr: 4925,
    name: "Happy Joe Dry Apple Cider",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl happe dansk danske danmark somersby cider sommersby sommersbye sommersbi somersbi somersbi summersbi sumersby summerby summersby sumerby sommerby 30l 30 l liter",
    imageSingle: "produkter-fadoel.happy-joe-cider.png",
    imagePlural: "produkter-fadoel.royal-fustage.png",
    fadoelsType: "Cider",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    relatedHeadline: "Andre kunder så også på:",
    // royalUnibrew: true,
    related: [17865, 24025, 2815],
    url: "/fadoel/cocktails-vin-cider/happy-joe-dry-apple-cider",
    categories: ["fadoelCocktailVinCider"],
    routeDef: {
      path: "/fadoel/cocktails-vin-cider/happy-joe-dry-apple-cider",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelCocktailsCiderVinBC],
        title: "Happy Joe Dry Apple Cider",
      },
      meta: {
        title:
          "Køb Happy Joe Dry Apple Cider på fad her - Det bedste alternativ til Somersby Cider",
        description:
          "Happy Joe Dry er det bedste alternativ til Somersby Cider og et populært alternativ til øl og vin. En ægte smag af æble i en tør og forfriskende cider.",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Cocktails",
      },
    },
    smallDescription: () => (
      <Span paddingTop="10px">
        Det bedste alternativ til Somersby Cider. Populært alternativ til øl og
        vin.
      </Span>
    ),
    notice: () => (
      <Paragraph>
        Det bedste alternativ til Somersby Cider. Populært alternativ til øl og
        vin.
      </Paragraph>
    ),
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Happy Joe Dry er det bedste alternativ til Somersby Cider og et
          populært alternativ til øl og vin. En ægte smag af æble i en tør og
          forfriskende cider.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Happy Joe Dry er det bedste alternativ til Somersby Cider og et populært
        alternativ til øl og vin. En ægte smag af æble i en tør og forfriskende
        cider.
      </React.Fragment>
    ),
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
  },
  {
    varenr: 28114,
    name: "Excellent Tap Aperol Spritz Cocktail",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 12l 12 liter l cocktail cocktails cocktaildrinks coctail coktail coktaildrinks coctaildrinks drinks aporol aparol",
    imageSingle: "produkter-fadoel.excellent-tap-aperol-spritz-logo.png",
    imagePlural: "produkter-fadoel.excellent-tap-aperol-spritz-fustage.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "Cocktail",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/cocktails-vin-cider/excellent-tap-aperol-spritz-cocktail",
    categories: ["fadoelCocktailVinCider"],
    routeDef: {
      path: "/fadoel/cocktails-vin-cider/excellent-tap-aperol-spritz-cocktail",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelCocktailsCiderVinBC],
        title: "Excellent Tap Aperol Spritz Cocktail",
      },
      meta: {
        title: "Køb Excellent Tap Aperol Spritz Cocktail på fad her",
        description: "Køb Excellent Tap Aperol Spritz Cocktail på fustage her",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Cocktails",
      },
    },
  },
  {
    varenr: 24115,
    name: "Nohrlund Øko | Gin & Mango Smash",
    generalSearchPriority: 1000,
    smag: "Gin, mango og citron",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl type k keykeg polykeg polykegs keykegs dansk danske danmark 20l 20 liter l økologisk økologiske norlund nordlund engangsfustager gin cocktail cocktails cocktaildrinks coctail coktail coktaildrinks coctaildrinks drinks",
    imageSingle: "produkter-oelvand.nohrlund-mango-smash.png",
    imagePlural: "produkter-fadoel.nohrlund-fustage.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "Cocktail",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/cocktails-vin-cider/nohrlund-oeko-mango-smash",
    categories: ["fadoelCocktailVinCider"],
    routeDef: {
      path: "/fadoel/cocktails-vin-cider/nohrlund-oeko-mango-smash",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelCocktailsCiderVinBC],
        title: "Nohrlund Øko | Mango Smash",
      },
      meta: {
        title: "Nohrlund Øko | Mango Smash | Køb her",
        description: "Køb Nohrlund Øko | Mango Smash på fustage her",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Cocktails",
      },
    },
  },
  {
    varenr: 24145,
    name: "Nohrlund Øko | Gin & Tonic",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl type k keykeg polykeg polykegs keykegs dansk danske danmark 20l 20 liter l økologisk økologiske norlund nordlund engangsfustager gin cocktail cocktails cocktaildrinks coctail coktail coktaildrinks coctaildrinks drinks",
    imageSingle: "produkter-oelvand.nohrlund-gin-tonic.png",
    imagePlural: "produkter-fadoel.nohrlund-fustage.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "Cocktail",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/cocktails-vin-cider/nohrlund-oeko-gin-and-tonic",
    categories: ["fadoelCocktailVinCider"],
    routeDef: {
      path: "/fadoel/cocktails-vin-cider/nohrlund-oeko-gin-and-tonic",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelCocktailsCiderVinBC],
        title: "Nohrlund Øko | Gin & Tonic",
      },
      meta: {
        title: "Nohrlund Øko | Gin & Tonic | Køb her",
        description: "Køb Nohrlund Øko | Gin & Tonic på fustage her",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Cocktails",
      },
    },
  },
  {
    varenr: 24085,
    name: "Nohrlund Øko | Passion Martini",
    generalSearchPriority: 1000,
    smag: "Vodka, passionsfrugt, vaniljesirup og lime",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl type k keykeg polykeg polykegs keykegs dansk danske danmark 20l 20 liter l økologisk økologiske norlund nordlund engangsfustager cocktail cocktails cocktaildrinks coctail coktail coktaildrinks coctaildrinks drinks",
    imageSingle: "produkter-oelvand.nohrlund-passion-martini.png",
    imagePlural: "produkter-fadoel.nohrlund-fustage.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "Cocktail",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/cocktails-vin-cider/nohrlund-oeko-passion-martini",
    categories: ["fadoelCocktailVinCider"],
    routeDef: {
      path: "/fadoel/cocktails-vin-cider/nohrlund-oeko-passion-martini",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelCocktailsCiderVinBC],
        title: "Nohrlund Øko | Passion Martini",
      },
      meta: {
        title: "Nohrlund Øko | Passion Martini | Køb her",
        description: "Køb Nohrlund Øko | Passion Martini på fustage her",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Cocktails",
      },
    },
  },
  {
    varenr: 24075,
    name: "Nohrlund Øko | Mojito",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl type k keykeg polykeg polykegs keykegs dansk danske danmark 20l 20 liter l økologisk økologiske norlund nordlund engangsfustager gin cocktail cocktails cocktaildrinks coctail coktail coktaildrinks coctaildrinks drinks",
    imageSingle: "produkter-oelvand.nohrlund-mojito.png",
    imagePlural: "produkter-fadoel.nohrlund-fustage.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "Cocktail",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/cocktails-vin-cider/nohrlund-oeko-mojito",
    categories: ["fadoelCocktailVinCider"],
    routeDef: {
      path: "/fadoel/cocktails-vin-cider/nohrlund-oeko-mojito",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelCocktailsCiderVinBC],
        title: "Nohrlund Øko | Mojito",
      },
      meta: {
        title: "Nohrlund Øko | Mojito | Køb her",
        description: "Køb Nohrlund Øko | Mojito på fustage her",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Cocktails",
      },
    },
  },
  {
    varenr: 24105,
    name: "Nohrlund Øko | Raspberry & Peach Collins",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl type k keykeg polykeg polykegs keykegs dansk danske danmark 20l 20 liter l økologisk økologiske norlund nordlund engangsfustager gin cocktail cocktails cocktaildrinks coctail coktail coktaildrinks coctaildrinks drinks",
    imageSingle: "produkter-oelvand.nohrlund-raspberry-peach-collins.png",
    imagePlural: "produkter-fadoel.nohrlund-fustage.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "Cocktail",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/cocktails-vin-cider/nohrlund-oeko-raspberry-peach-collins",
    categories: ["fadoelCocktailVinCider"],
    routeDef: {
      path: "/fadoel/cocktails-vin-cider/nohrlund-oeko-raspberry-peach-collins",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelCocktailsCiderVinBC],
        title: "Nohrlund Øko | Raspberry & Peach Collins",
      },
      meta: {
        title: "Nohrlund Øko | Raspberry & Peach Collins | Køb her",
        description:
          "Køb Nohrlund Øko | Raspberry & Peach Collins på fustage her",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Cocktails",
      },
    },
  },
  {
    varenr: 27035,
    name: "Urban Cocktails Champagne Brus",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl type k keykeg polykeg polykegs keykegs dansk danske danmark 10l 10 liter l engangsfustager cocktail cocktails cocktaildrinks coctail coktail coktaildrinks coctaildrinks drinks",
    imageSingle: "produkter-fadoel.urban-cocktails-champagne-brus-glas.png",
    imagePlural: "produkter-fadoel.urban-fustage.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "Cocktail",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/cocktails-vin-cider/urban-cocktails-champagne-brus",
    categories: ["fadoelCocktailVinCider"],
    routeDef: {
      path: "/fadoel/cocktails-vin-cider/urban-cocktails-champagne-brus",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelCocktailsCiderVinBC],
        title: "Urban Cocktails Champagne Brus",
      },
      meta: {
        title: "Urban Cocktails Champagne Brus | Køb her",
        description: "Køb Urban Cocktails Champagne Brus på fustage her",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Cocktails",
      },
    },
  },
  {
    varenr: 27075,
    name: "Urban Cocktails Brandbil",
    generalSearchPriority: 1000,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl type k keykeg polykeg polykegs keykegs dansk danske danmark 10l 10 liter l engangsfustager gin cocktail cocktails cocktaildrinks coctail coktail coktaildrinks coctaildrinks drinks",
    imageSingle: "produkter-fadoel.urban-cocktails-brandbil-glas.png",
    imagePlural: "produkter-fadoel.urban-fustage.png",
    land: "Danmark",
    landKort: "DK",
    fadoelsType: "Cocktail",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    url: "/fadoel/cocktails-vin-cider/urban-cocktails-brandbil",
    categories: ["fadoelCocktailVinCider"],
    routeDef: {
      path: "/fadoel/cocktails-vin-cider/urban-cocktails-brandbil",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelCocktailsCiderVinBC],
        title: "Urban Cocktails Brandbil",
      },
      meta: {
        title: "Urban Cocktails Brandbil | Køb her",
        description: "Køb Urban Cocktails Brandbil på fustage her",
      },
      props: {
        menuPicker: "Fadoel",
        activeSidemenu: "Cocktails",
      },
    },
  },
  /* *
   *
   * Fadølsanlæg
   *
   * */
  {
    varenr: "pack_1",
    name: "Basis: Leje af fadølsanlæg pr. hane",
    fadoelsanlaeg: true,
    keywords:
      "ølanlæg fadølanlæg køler fadølsanlæg fadølshaner anlæg ølhanke anker leje af fadølsanker fadølanker ølanker",
    invoiceDisplay: "SimpleItemRow",
    betingerFustager: true,
    packageNoun: "hane",
    productTypePack: true,
    udsolgtAnlaeg: true,
    searchDisplay: BigView,

    recieptItemView: RecieptPackEntryView,
    draughtmasterStkWithPackageNoun: true,
    url: "/fadoelsanlaeg/fadoelshane",
    categories: ["fadoelsanlaeg"],
    routeDef: {
      path: "/fadoelsanlaeg/fadoelshane",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelsanlaegBC],
        title: "Basis: Leje af fadølsanlæg pr. hane",
      },
      meta: {
        title: "Leje af fadølsanlæg pr. hane",
        description: "Lej fadølsanlæg pr. hane her",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Fadoelsanlaeg",
      },
    },
    imageSingle: "packs.basis-5.png",
    packImages: [
      {
        name: "packs.basis-5.png",
        imageDescription: () =>
          "Eksempel på fadølsanlæg bestående af hane, køleanlæg og kulsyre. Fadølshanen skal monteres på dit eget bord.",
        imageMaxHeight: "246px",
      },
      {
        name: "packs.bordkantsojle-med-maal.png",
        imageDescription: () =>
          "Fadølshanen skal monteres på egen bordplade på op til 60mm i tykkelse. Hanen går 35mm ind over bordet og skruetvingen er 125mm lang.",
      },
    ],
    description: () => (
      <React.Fragment>
        <Paragraph fontSize="12px" marginTop="-10px">
          <KulsyreMedfoelger />
        </Paragraph>
        <HideAtDate date="25/8/2019 6:00">
          <Paragraph fontSize="18px" red>
            Fadølsanlæg udsolgt fredag 23/8 og lørdag 24/8 2019.
          </Paragraph>
        </HideAtDate>
        <List check fontSize="14px">
          <li>
            <TooltipModalLink title="Fadølshane">
              <Row>
                <Col xs={12} paddingBottom="0px">
                  <Heading4>Fadølshane</Heading4>
                </Col>
                <ScreenSizeDetector
                  paddingBottom={[[767, "0px"], "15px"]}
                  paddingTop={[[767, "0px"], "15px"]}
                >
                  <Col sm={12} md={6}>
                    <Paragraph>
                      Bestil så mange haner du ønsker. Lejeprisen er{" "}
                      <u>pr. hane</u> - ikke pr. anlæg.
                    </Paragraph>
                    <Paragraph>
                      Alternativt til flere enkelthaner er nedenstående
                      dobbeltanlæg. Bemærk dog at et dobbeltanlæg kun køler
                      ligeså godt som et enkeltanlæg. Du skal altså ikke vælge
                      et dobbeltanlæg for at kunne tappe øllet hurtigere.
                      Dobbeltanlæg anbefales til højest 4 - 6 fustager, afhængig
                      af arrangementets længde.
                    </Paragraph>
                  </Col>
                </ScreenSizeDetector>
                <ScreenSizeDetector paddingTop={[[767, "0px"], "15px"]}>
                  <Col sm={12} md={6}>
                    <Paragraph>
                      Yderligere fordele ved 2 enkeltanlæg fremfor 1
                      dobbeltanlæg er sikkerheden hvis der skulle opstå et
                      teknisk problem med køleren. Opstår et problem på et
                      enkeltanlæg påvirker det ikke den anden hane. Sker det på
                      et dobbeltanlæg er begge haner ude af funktion.
                    </Paragraph>
                    <Paragraph>
                      Eneste ulempe ved 2 enkeltanlæg overfor et dobbeltanlæg er
                      at 2 enkelte anlæg fylder og vejer mere.
                    </Paragraph>
                    <Paragraph bold>
                      Fadølsanlægget leveres med koblinger der passer til det øl
                      du har bestilt.
                    </Paragraph>
                  </Col>
                </ScreenSizeDetector>
              </Row>
            </TooltipModalLink>
          </li>
          <li>
            <TooltipModalLink title="Køleanlæg og kulsyre">
              <Row>
                <Col xs={12} paddingBottom="0px">
                  <Heading4>Køleanlæg og kulsyre</Heading4>
                </Col>
                <ScreenSizeDetector
                  paddingBottom={[[767, "0px"], "15px"]}
                  paddingTop={[[767, "0px"], "15px"]}
                >
                  <Col sm={12} md={6}>
                    <Paragraph>
                      Der er mulighed for tre forskellige størrelser køleanlæg.
                    </Paragraph>
                    <Paragraph>
                      Vi medleverer automatisk det køleanlæg, der bedst passer
                      til det antal fustager du bestiller.
                    </Paragraph>
                    <Paragraph>
                      Fadølsanlægget har isbank og køler øllet ved
                      gennemstrømning umiddelbart før det tappes.
                    </Paragraph>
                  </Col>
                </ScreenSizeDetector>
                <ScreenSizeDetector paddingTop={[[767, "0px"], "15px"]}>
                  <Col sm={12} md={6}>
                    <Paragraph>
                      Skal tilsluttes almindelig 230v strøm mindst 3 timer før
                      brug.
                    </Paragraph>
                    <Paragraph>
                      Skal øllet tappes meget hurtgt, så giv os besked i
                      kommentarfeltet eller når vi ringer, så vi kan finde den
                      bedste løsning til dig.
                    </Paragraph>
                    <Paragraph>
                      Vi medleverer naturligvis kulsyre nok til de bestilte
                      fustager.
                    </Paragraph>
                  </Col>
                </ScreenSizeDetector>
              </Row>
            </TooltipModalLink>
          </li>
          <li>
            <MonteringPaaEgetBordModal />
          </li>
        </List>
      </React.Fragment>
    ),
    IconBoxes: () => (
      <React.Fragment>
        <IconBox storage>
          Altid ledige anlæg
          <Span block fontSize="12px">
            <Sikrest10DageFoerText />
          </Span>
        </IconBox>
        <IconBox calendar>
          <Lejeperiode days />
          <Lejeperiode />
        </IconBox>
        <IconBox dimension>
          Mål &amp; vægt
          <Span block fontSize="12px">
            Anlæg: 45 kg - Mål: B 60cm | H 40cm | D 40cm
          </Span>
        </IconBox>
      </React.Fragment>
    ),
  },
  {
    varenr: "pack_13",
    name: "Dobbeltanlæg",
    fadoelsanlaeg: true,
    udsolgtAnlaeg: true,
    keywords:
      "ølanlæg fadølanlæg køler fadølsanlæg fadølshaner anlæg ølhanke anker leje af fadølsanker fadølanker ølanker",
    invoiceDisplay: "SimpleItemRow",
    searchDisplay: BigView,
    url: "/fadoelsanlaeg/dobbeltanlaeg",
    categories: ["fadoelsanlaeg"],
    routeDef: {
      path: "/fadoelsanlaeg/dobbeltanlaeg",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelsanlaegBC],
        title: "Dobbeltanlæg",
      },
      meta: {
        title: "Leje af dobbeltanlæg",
        description: "Lej dobbeltanlæg her",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Fadoelsanlaeg",
      },
    },
    description: () => (
      <React.Fragment>
        <Paragraph fontSize="12px" marginTop="-10px">
          <KulsyreMedfoelger />
        </Paragraph>
        <HideAtDate date="25/8/2019 6:00">
          <Paragraph fontSize="18px" red>
            Fadølsanlæg udsolgt fredag 23/8 og lørdag 24/8 2019.
          </Paragraph>
        </HideAtDate>
        <List check fontSize="14px">
          <li>
            <TooltipModalLink title="2 Fadølshaner">
              <Row>
                <Col xs={12} paddingBottom="0px">
                  <Heading4>2 Fadølshaner</Heading4>
                </Col>
                <ScreenSizeDetector
                  paddingBottom={[[767, "0px"], "15px"]}
                  paddingTop={[[767, "0px"], "15px"]}
                >
                  <Col sm={12} md={6}>
                    <Paragraph>Vælg dobbeltanlæg hvis:</Paragraph>
                    <List>
                      <li>Du har mere end 1 slags øl</li>
                      <li>Du ønsker at tappe øllene samtidigt</li>
                      <li>Du har ikke mere end 4 - 6 fustager i alt</li>
                      <li>
                        Du ønsker at spare på pladsen, under transport og
                        opstillingen
                      </li>
                    </List>
                    <Paragraph>
                      Bemærk dog at et dobbeltanlæg kun køler ligeså godt som et
                      enkeltanlæg. Du skal altså ikke vælge et dobbeltanlæg for
                      at kunne tappe øllet hurtigere. Dobbeltanlæg anbefales til
                      højest 4 - 6 fustager, afhængig af arrangementets længde.
                    </Paragraph>
                  </Col>
                </ScreenSizeDetector>
                <ScreenSizeDetector paddingTop={[[767, "0px"], "15px"]}>
                  <Col sm={12} md={6}>
                    <Paragraph>
                      Alternativt til dobbeltanlæg er 2 enkeltanlæg. Det giver
                      større driftsikkerhed hvis der skulle opstå et teknisk
                      problem med køleren. Opstår et problem på et enkeltanlæg
                      påvirker det ikke den anden hane. Sker det på et
                      dobbeltanlæg er begge haner ude af funktion.
                    </Paragraph>
                    <Paragraph>
                      Eneste ulempe ved 2 enkeltanlæg overfor et dobbeltanlæg er
                      at 2 enkelte anlæg fylder og vejer mere.
                    </Paragraph>
                    <Paragraph bold>
                      Fadølsanlægget leveres med koblinger der passer til det øl
                      du har bestilt.
                    </Paragraph>
                  </Col>
                </ScreenSizeDetector>
              </Row>
            </TooltipModalLink>
          </li>
          <li>
            <TooltipModalLink title="Køleanlæg og kulsyre">
              <Row>
                <Col xs={12} paddingBottom="0px">
                  <Heading4>Køleanlæg og kulsyre</Heading4>
                </Col>
                <ScreenSizeDetector
                  paddingBottom={[[767, "0px"], "15px"]}
                  paddingTop={[[767, "0px"], "15px"]}
                >
                  <Col sm={12} md={6}>
                    <Paragraph>
                      Der er mulighed for tre forskellige størrelser køleanlæg.
                    </Paragraph>
                    <Paragraph>
                      Vi medleverer automatisk det køleanlæg, der bedst passer
                      til det antal fustager du bestiller.
                    </Paragraph>
                    <Paragraph>
                      Fadølsanlægget har isbank og køler øllet ved
                      gennemstrømning umiddelbart før det tappes.
                    </Paragraph>
                  </Col>
                </ScreenSizeDetector>
                <ScreenSizeDetector paddingTop={[[767, "0px"], "15px"]}>
                  <Col sm={12} md={6}>
                    <Paragraph>
                      Skal tilsluttes almindelig 230v strøm mindst 3 timer før
                      brug.
                    </Paragraph>
                    <Paragraph>
                      Skal øllet tappes meget hurtgt, så giv os besked i
                      kommentarfeltet eller når vi ringer, så vi kan finde den
                      bedste løsning til dig.
                    </Paragraph>
                    <Paragraph>
                      Vi medleverer naturligvis kulsyre nok til de bestilte
                      fustager.
                    </Paragraph>
                  </Col>
                </ScreenSizeDetector>
              </Row>
            </TooltipModalLink>
          </li>
          <li>
            <MonteringPaaEgetBordModal />
          </li>
          <li>
            <TooltipModalLink title="Tap 2 forskellige fadøl samtidigt">
              <Row>
                <Col xs={12} paddingBottom="0px">
                  <Heading4>
                    Vi sørger for at fadølsanlægget passer til de øl du
                    bestiller!
                  </Heading4>
                </Col>
                <ScreenSizeDetector
                  paddingBottom={[[767, "0px"], "15px"]}
                  paddingTop={[[767, "0px"], "15px"]}
                >
                  <Col sm={12} md={12}>
                    <Paragraph>
                      Med to haner kan du tappe to forskellige fadøl samtidigt
                      og du kan vælge fadøl med op til to forskellige
                      tilkoblinger.
                    </Paragraph>
                    <Paragraph>
                      Når du vælger dit fadøl kan du se hvilken tilkobling øllet
                      har ved hver enkelt øl.
                    </Paragraph>
                    <Paragraph>
                      Hver opmærksom på at hvis du vælger øl med forskellige
                      koblinger leverer vi hanerne med de tilhørende koblinger
                      (op til to forskellige). Det vil altså sige at vælger du
                      f.eks. 5 x Grøn Tuborg (S-kobling) og 1 x Kronenbourg 1664
                      Blanc (A-kobling), så kan du ikke bruge hanen med
                      A-kobling når din Blanc fustage er tom.
                    </Paragraph>
                  </Col>
                </ScreenSizeDetector>
              </Row>
            </TooltipModalLink>
          </li>
        </List>
      </React.Fragment>
    ),
    IconBoxes: () => (
      <React.Fragment>
        <IconBox storage>
          Altid ledige anlæg
          <Span block fontSize="12px">
            <Sikrest10DageFoerText />
          </Span>
        </IconBox>
        <IconBox calendar>
          <Lejeperiode days />
          <Lejeperiode />
        </IconBox>
        <IconBox dimension>
          Mål &amp; vægt
          <Span block fontSize="12px">
            Anlæg: 45 kg - Mål: B 60cm | H 40cm | D 40cm
          </Span>
        </IconBox>
      </React.Fragment>
    ),
    betingerFustager: true,
    packageNoun: "hane",
    packImages: [
      {
        name: "packs.dobbeltanlaeg-2.png",
        imageDescription: () =>
          "Eksempel på dobbeltanlæg bestående af 2 haner, køleanlæg og 2 kulsyre. Fadølshanerne skal monteres på dit eget bord.",
        imageMaxHeight: "246px",
      },
      {
        name: "packs.bordkantsojle-med-maal.png",
        imageDescription: () =>
          "Fadølshanen skal monteres på egen bordplade på op til 60mm i tykkelse. Hanen går 35mm ind over bordet og skruetvingen er 125mm lang.",
      },
    ],
    imageSingle: "packs.dobbeltanlaeg-2.png",
    productTypePack: true,

    recieptItemView: RecieptPackEntryView,
  },
  {
    varenr: "pack_3",
    name: "Deluxe: Fadølsanlæg og bar",
    fadoelsanlaeg: true,
    udsolgtAnlaeg: true,
    // udsolgtKlapbar: true,
    keywords:
      "ølanlæg fadølanlæg køler fadølsanlæg fadølshaner rullebar anlæg anker leje af fadølsanker fadølanker ølanker",
    invoiceDisplay: "SimpleItemRow",
    searchDisplay: BigView,
    url: "/fadoelsanlaeg/anlaeg-og-bar",
    categories: ["fadoelsanlaeg"],
    routeDef: {
      path: "/fadoelsanlaeg/anlaeg-og-bar",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelsanlaegBC],
        title: "Deluxe: Fadølsanlæg og bar",
      },
      meta: {
        title: "Leje af fadølsanlæg og bar",
        description: "Lej fadølsanlæg og bar her",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Fadoelsanlaeg",
      },
    },
    description: () => (
      <React.Fragment>
        <Paragraph fontSize="12px" marginTop="-10px">
          <KulsyreMedfoelger />
        </Paragraph>
        <HideAtDate date="25/8/2019 6:00">
          <Paragraph fontSize="18px" red>
            Fadølsanlæg udsolgt fredag 23/8 og lørdag 24/8 2019.
          </Paragraph>
        </HideAtDate>
        <List check fontSize="14px">
          <li>
            <TooltipModalLink title="1 fadølshane" maxWidth="280px">
              <Row>
                <Col xs={12} paddingBottom="0px">
                  <Heading4>1 fadølshane</Heading4>
                </Col>
                <ScreenSizeDetector
                  paddingBottom={[[767, "0px"], "15px"]}
                  paddingTop={[[767, "0px"], "15px"]}
                >
                  <Col sm={12} md={12}>
                    <Paragraph>
                      Tappehanen kan monteres som på billedet eller til
                      selvbetjening på ydersiden af baren.
                    </Paragraph>
                    <Paragraph bold>
                      Fadølsanlægget leveres med koblinger der passer til det øl
                      du har bestilt.
                    </Paragraph>
                  </Col>
                </ScreenSizeDetector>
              </Row>
            </TooltipModalLink>
          </li>
          <li>
            <TooltipModalLink title="Køleanlæg og kulsyre">
              <Row>
                <Col xs={12} paddingBottom="0px">
                  <Heading4>Køleanlæg og kulsyre</Heading4>
                </Col>
                <ScreenSizeDetector
                  paddingBottom={[[767, "0px"], "15px"]}
                  paddingTop={[[767, "0px"], "15px"]}
                >
                  <Col sm={12} md={6}>
                    <Paragraph>
                      Der er mulighed for tre forskellige størrelser køleanlæg.
                    </Paragraph>
                    <Paragraph>
                      Vi medleverer automatisk det køleanlæg, der bedst passer
                      til det antal fustager du bestiller.
                    </Paragraph>
                    <Paragraph>
                      Fadølsanlægget har isbank og køler øllet ved
                      gennemstrømning umiddelbart før det tappes.
                    </Paragraph>
                  </Col>
                </ScreenSizeDetector>
                <ScreenSizeDetector paddingTop={[[767, "0px"], "15px"]}>
                  <Col sm={12} md={6}>
                    <Paragraph>
                      Skal tilsluttes almindelig 230v strøm mindst 3 timer før
                      brug.
                    </Paragraph>
                    <Paragraph>
                      Skal øllet tappes meget hurtgt, så giv os besked i
                      kommentarfeltet eller når vi ringer, så vi kan finde den
                      bedste løsning til dig.
                    </Paragraph>
                    <Paragraph>
                      Vi medleverer naturligvis kulsyre nok til de bestilte
                      fustager.
                    </Paragraph>
                  </Col>
                </ScreenSizeDetector>
              </Row>
            </TooltipModalLink>
          </li>
          <li>Praktisk bardisk inkluderet</li>
        </List>
      </React.Fragment>
    ),
    IconBoxes: () => (
      <React.Fragment>
        <IconBox storage>
          Altid ledige anlæg
          <Span block fontSize="12px">
            <Sikrest10DageFoerText />
          </Span>
        </IconBox>
        <IconBox calendar>
          <Lejeperiode days />
          <Lejeperiode />
        </IconBox>
        <IconBox dimension>
          Mål bardisk
          <Span block fontSize="12px">
            B 120cm | H 100cm | D 60cm
          </Span>
        </IconBox>
      </React.Fragment>
    ),
    betingerFustager: true,
    productTypePack: true,

    recieptItemView: RecieptPackEntryView,
    packImages: [
      {
        name: "packs.klapbar-1-hane.png",
        imageDescription: () => "Fadølsbar med 1 hane",
      },
      {
        name: "packs.klapbar-bagside-1hane-1.png",
        imageDescription: () => "Bagside af baren med 1 hane",
      },
      {
        name: "packs.klapbar-klappet-sammen.png",
        imageDescription: () => "Bardisk sammenklappet",
        imageMaxHeight: "300px",
      },
    ],
    imageSingle: "packs.klapbar-1-hane.png",
  },
  {
    varenr: "pack_4",
    name: "High-end: Fadølsanlæg m. 2 haner og bar",
    fadoelsanlaeg: true,
    udsolgtAnlaeg: true,
    // udsolgtKlapbar: true,
    keywords: "ølanlæg fadølanlæg køler fadølsanlæg fadølshaner rullebar",
    invoiceDisplay: "SimpleItemRow",
    searchDisplay: BigView,
    url: "/fadoelsanlaeg/anlaeg-2-haner-og-bar",
    categories: ["fadoelsanlaeg"],
    routeDef: {
      path: "/fadoelsanlaeg/anlaeg-2-haner-og-bar",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelsanlaegBC],
        title: "High-end: Fadølsanlæg m. 2 haner og bar",
      },
      meta: {
        title: "Leje af fadølsanlæg med to haner og bar",
        description: "Lej fadølsanlæg med to haner og bar her",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Fadoelsanlaeg",
      },
    },
    description: () => (
      <React.Fragment>
        <Paragraph fontSize="12px" marginTop="-10px">
          <KulsyreMedfoelger />
        </Paragraph>
        <HideAtDate date="25/8/2019 6:00">
          <Paragraph fontSize="18px" red>
            Fadølsanlæg udsolgt fredag 23/8 og lørdag 24/8 2019.
          </Paragraph>
        </HideAtDate>
        <List check fontSize="14px">
          <li>
            <TooltipModalLink title="2 fadølshaner" maxWidth="280px">
              <Row>
                <Col xs={12} paddingBottom="0px">
                  <Heading4>2 fadølshaner</Heading4>
                </Col>
                <ScreenSizeDetector
                  paddingBottom={[[767, "0px"], "15px"]}
                  paddingTop={[[767, "0px"], "15px"]}
                >
                  <Col sm={12} md={12}>
                    <Paragraph>
                      Tappehanerne kan monteres som på billedet eller til
                      selvbetjening på ydersiden af baren.
                    </Paragraph>
                    <Paragraph bold>
                      Fadølsanlægget leveres med koblinger der passer til det øl
                      du har bestilt.
                    </Paragraph>
                  </Col>
                </ScreenSizeDetector>
              </Row>
            </TooltipModalLink>
          </li>
          <li>
            <TooltipModalLink title="Køleanlæg og kulsyre">
              <Row>
                <Col xs={12} paddingBottom="0px">
                  <Heading4>Køleanlæg og kulsyre</Heading4>
                </Col>
                <ScreenSizeDetector
                  paddingBottom={[[767, "0px"], "15px"]}
                  paddingTop={[[767, "0px"], "15px"]}
                >
                  <Col sm={12} md={6}>
                    <Paragraph>
                      Der er mulighed for tre forskellige størrelser køleanlæg.
                    </Paragraph>
                    <Paragraph>
                      Vi medleverer automatisk det køleanlæg, der bedst passer
                      til det antal fustager du bestiller.
                    </Paragraph>
                    <Paragraph>
                      Fadølsanlægget har isbank og køler øllet ved
                      gennemstrømning umiddelbart før det tappes.
                    </Paragraph>
                  </Col>
                </ScreenSizeDetector>
                <ScreenSizeDetector paddingTop={[[767, "0px"], "15px"]}>
                  <Col sm={12} md={6}>
                    <Paragraph>
                      Skal tilsluttes almindelig 230v strøm mindst 3 timer før
                      brug.
                    </Paragraph>
                    <Paragraph>
                      Skal øllet tappes meget hurtgt, så giv os besked i
                      kommentarfeltet eller når vi ringer, så vi kan finde den
                      bedste løsning til dig.
                    </Paragraph>
                    <Paragraph>
                      Vi medleverer naturligvis kulsyre nok til de bestilte
                      fustager.
                    </Paragraph>
                  </Col>
                </ScreenSizeDetector>
              </Row>
            </TooltipModalLink>
          </li>
          <li>
            <TooltipModalLink title="Tap 2 forskellige fadøl samtidigt">
              <Row>
                <Col xs={12} paddingBottom="0px">
                  <Heading4>
                    Vi sørger for at fadølsanlægget passer til de øl du
                    bestiller!
                  </Heading4>
                </Col>
                <ScreenSizeDetector
                  paddingBottom={[[767, "0px"], "15px"]}
                  paddingTop={[[767, "0px"], "15px"]}
                >
                  <Col sm={12} md={12}>
                    <Paragraph>
                      Med to haner kan du tappe to forskellige fadøl samtidigt
                      og du kan vælge fadøl med op til to forskellige
                      tilkoblinger.
                    </Paragraph>
                    <Paragraph>
                      Når du vælger dit fadøl kan du se hvilken tilkobling øllet
                      har ved hver enkelt øl.
                    </Paragraph>
                    <Paragraph>
                      Hver opmærksom på at hvis du vælger øl med forskellige
                      koblinger leverer vi hanerne med de tilhørende koblinger
                      (op til to forskellige). Det vil altså sige at vælger du
                      f.eks. 5 x Grøn Tuborg (S-kobling) og 1 x Kronenbourg 1664
                      Blanc (A-kobling), så kan du ikke bruge hanen med
                      A-kobling når din Blanc fustage er tom.
                    </Paragraph>
                  </Col>
                </ScreenSizeDetector>
              </Row>
            </TooltipModalLink>
          </li>
          <li>Praktisk bardisk inkluderet</li>
        </List>
      </React.Fragment>
    ),
    IconBoxes: () => (
      <React.Fragment>
        <IconBox storage>
          Altid ledige anlæg
          <Span block fontSize="12px">
            <Sikrest10DageFoerText />
          </Span>
        </IconBox>
        <IconBox calendar>
          <Lejeperiode days />
          <Lejeperiode />
        </IconBox>
        <IconBox dimension>
          Mål bardisk
          <Span block fontSize="12px">
            B 120cm | H 100cm | D 60cm
          </Span>
        </IconBox>
      </React.Fragment>
    ),
    betingerFustager: true,
    productTypePack: true,

    recieptItemView: RecieptPackEntryView,
    packImages: [
      {
        name: "packs.klapbar-2-haner.png",
        imageDescription: () => "Fadølsbar med 2 haner",
      },
      {
        name: "packs.klapbar-bagside-2haner-1.png",
        imageDescription: () => "Bagside af baren med 2 haner",
      },
      {
        name: "fadoel.fadoelsbar-2-haner-foran-1000x1161.jpg",
        imageDescription: () => "Tappehaner monteret til selvbetjening",
      },
      {
        name: "packs.klapbar-klappet-sammen.png",
        imageDescription: () => "Bardisk sammenklappet",
        imageMaxHeight: "300px",
      },
    ],
    imageSingle: "packs.klapbar-2-haner.png",
  },
  {
    varenr: "pack_ultimate",
    name: "Ultimate: Fadølsanlæg m. 3 haner og bar",
    udsolgtAnlaeg: true,
    fadoelsanlaeg: true,
    // udsolgtKlapbar: true,
    keywords:
      "ølanlæg fadølanlæg køler fadølsanlæg fadølshaner rullebar anlæg anker leje af fadølsanker fadølanker ølanker",
    invoiceDisplay: "SimpleItemRow",
    searchDisplay: BigView,
    url: "/fadoelsanlaeg/anlaeg-3-haner-og-bar",
    categories: ["fadoelsanlaeg"],
    routeDef: {
      path: "/fadoelsanlaeg/anlaeg-3-haner-og-bar",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelsanlaegBC],
        title: "High-end: Fadølsanlæg m. 3 haner og bar",
      },
      meta: {
        title: "Leje af fadølsanlæg med tre haner og bar",
        description: "Lej fadølsanlæg med tre haner og bar her",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Fadoelsanlaeg",
      },
    },
    description: () => (
      <React.Fragment>
        <Paragraph fontSize="12px" marginTop="-10px">
          <KulsyreMedfoelger />
        </Paragraph>
        <HideAtDate date="25/8/2019 6:00">
          <Paragraph fontSize="18px" red>
            Fadølsanlæg udsolgt fredag 23/8 og lørdag 24/8 2019.
          </Paragraph>
        </HideAtDate>
        <List check fontSize="14px">
          <li>
            <TooltipModalLink title="3 fadølshaner" maxWidth="280px">
              <Row>
                <Col xs={12} paddingBottom="0px">
                  <Heading4>3 fadølshaner</Heading4>
                </Col>
                <ScreenSizeDetector
                  paddingBottom={[[767, "0px"], "15px"]}
                  paddingTop={[[767, "0px"], "15px"]}
                >
                  <Col sm={12} md={12}>
                    <Paragraph>
                      Tappehanerne kan monteres som på billedet eller til
                      selvbetjening på ydersiden af baren.
                    </Paragraph>
                    <Paragraph bold>
                      Fadølsanlægget leveres med koblinger der passer til det øl
                      du har bestilt.
                    </Paragraph>
                  </Col>
                </ScreenSizeDetector>
              </Row>
            </TooltipModalLink>
          </li>
          <li>
            <TooltipModalLink title="Køleanlæg og kulsyre">
              <Row>
                <Col xs={12} paddingBottom="0px">
                  <Heading4>Køleanlæg og kulsyre</Heading4>
                </Col>
                <ScreenSizeDetector
                  paddingBottom={[[767, "0px"], "15px"]}
                  paddingTop={[[767, "0px"], "15px"]}
                >
                  <Col sm={12} md={6}>
                    <Paragraph>
                      Der er mulighed for tre forskellige størrelser køleanlæg.
                    </Paragraph>
                    <Paragraph>
                      Vi medleverer automatisk det køleanlæg, der bedst passer
                      til det antal fustager du bestiller.
                    </Paragraph>
                    <Paragraph>
                      Fadølsanlægget har isbank og køler øllet ved
                      gennemstrømning umiddelbart før det tappes.
                    </Paragraph>
                  </Col>
                </ScreenSizeDetector>
                <ScreenSizeDetector paddingTop={[[767, "0px"], "15px"]}>
                  <Col sm={12} md={6}>
                    <Paragraph>
                      Skal tilsluttes almindelig 230v strøm mindst 3 timer før
                      brug.
                    </Paragraph>
                    <Paragraph>
                      Skal øllet tappes meget hurtgt, så giv os besked i
                      kommentarfeltet eller når vi ringer, så vi kan finde den
                      bedste løsning til dig.
                    </Paragraph>
                    <Paragraph>
                      Vi medleverer naturligvis kulsyre nok til de bestilte
                      fustager.
                    </Paragraph>
                  </Col>
                </ScreenSizeDetector>
              </Row>
            </TooltipModalLink>
          </li>
          <li>
            <TooltipModalLink title="Tap 3 forskellige fadøl samtidigt">
              <Row>
                <Col xs={12} paddingBottom="0px">
                  <Heading4>
                    Vi sørger for at fadølsanlægget passer til de øl du
                    bestiller!
                  </Heading4>
                </Col>
                <ScreenSizeDetector
                  paddingBottom={[[767, "0px"], "15px"]}
                  paddingTop={[[767, "0px"], "15px"]}
                >
                  <Col sm={12} md={12}>
                    <Paragraph>
                      Med tre haner kan du tappe tre forskellige fadøl samtidigt
                      og du kan vælge fadøl med op til tre forskellige
                      tilkoblinger.
                    </Paragraph>
                    <Paragraph>
                      Når du vælger dit fadøl kan du se hvilken tilkobling øllet
                      har ved hver enkelt øl.
                    </Paragraph>
                    <Paragraph>
                      Hver opmærksom på at hvis du vælger øl med forskellige
                      koblinger leverer vi hanerne med de tilhørende koblinger
                      (op til tre forskellige). Det vil altså sige at vælger du
                      f.eks. 4 x Grøn Tuborg (S-kobling), 3 x Tuborg Classic
                      (S-kobling) og 1 x Kronenbourg 1664 Blanc (A-kobling), så
                      kan du ikke bruge hanen med A-kobling når din Blanc
                      fustage er tom.
                    </Paragraph>
                  </Col>
                </ScreenSizeDetector>
              </Row>
            </TooltipModalLink>
          </li>
          <li>Praktisk bardisk inkluderet</li>
        </List>
      </React.Fragment>
    ),
    IconBoxes: () => (
      <React.Fragment>
        <IconBox storage>
          Altid ledige anlæg
          <Span block fontSize="12px">
            <Sikrest10DageFoerText />
          </Span>
        </IconBox>
        <IconBox calendar>
          <Lejeperiode days />
          <Lejeperiode />
        </IconBox>
        <IconBox dimension>
          Mål bardisk
          <Span block fontSize="12px">
            B 120cm | H 100cm | D 60cm
          </Span>
        </IconBox>
      </React.Fragment>
    ),
    betingerFustager: true,
    productTypePack: true,

    recieptItemView: RecieptPackEntryView,
    packImages: [
      {
        name: "packs.klapbar-3-haner.png",
        imageDescription: () => "Fadølsbar med 3 haner",
      },
      {
        name: "packs.klapbar-klappet-sammen.png",
        imageDescription: () => "Bardisk sammenklappet",
        imageMaxHeight: "300px",
      },
    ],
    imageSingle: "packs.klapbar-3-haner.png",
  },

  /* *
   *
   * Fadølsbar
   *
   * */
  {
    varenr: "pack_2",
    name: "Ekstra bardisk",
    keywords: "fadølsbar rullebar klapbar 120x60cm 3906 bar",
    udsolgtKlapbar: true,
    betingerFustagerUdstyr: true,
    generalSearchPriority: 600,
    searchSignificance: 10,
    invoiceDisplay: "SimpleItemRow",
    productTypePack: true,
    recieptItemView: RecieptPackEntryView,
    searchDisplay: BigView,
    url: "/fadoelsbar/klapbar",
    categories: ["fadoelsbar"],
    routeDef: {
      path: "/fadoelsbar/klapbar",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelsBarBC],
        title: "Klapbar",
      },
      meta: {
        title: "Leje af klapbar til fadølsanlæg",
        description: "Lej en bar til dit arrangement her",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Fadoelsbar",
      },
    },
    description: () => (
      <React.Fragment>
        <Paragraph>
          Bardisken er nem at sætte op og har en god arbejdshøjde. Fin plads til
          en, to eller tre haner. Har du brug for flere haner, anbefaler vi at
          leje flere barer. Logoet på baren kan afvige fra billederne. Vi har
          også barer med Carlsberg/Tuborg, Skovlyst og neutralt logo.
        </Paragraph>
        <Paragraph>
          Skåner dit eget bord og skjuler anlæg og fustager.
        </Paragraph>
      </React.Fragment>
    ),
    IconBoxes: () => (
      <React.Fragment>
        <IconBox calendar>
          <Lejeperiode days />
          <Lejeperiode />
        </IconBox>
        <IconBox dimension>
          Mål:
          <Span block fontSize="12px">
            Udslået: B 120cm | H 100cm | D 60cm
            <br />
            Bordpladen: 61,5cm x 121cm x 15mm
            <br />
            Sammenklappet bar: 107cm x 101cm x 4,5cm
          </Span>
        </IconBox>
      </React.Fragment>
    ),
    packImages: [
      {
        name: "packs.klapbar.png",
        imageDescription: () => "Bardisk uden fadølshane",
      },
      {
        name: "packs.klapbar-klappet-sammen.png",
        imageDescription: () => "Bardisk sammenklappet",
        imageMaxHeight: "300px",
      },
    ],
    imageSingle: "packs.klapbar.png",
    productPageXtraDescription: () => (
      <React.Fragment>
        <Heading3>Klapbar</Heading3>
        <Paragraph>
          En klapbar er særligt egnet til mindre fester og arrangementer,
          grundet dens størrelse. Den kan også bruges som supplement til andre
          typer af barer, for at skabe mere plads. Klapbarer er geniale til
          fester, hvor du ønsker at beskytte dine egne borde og dermed undgår
          rander fra øl og glas, men også eventuelle mærker i bordpladen efter
          hanen. Ingen klapbar, uden <LinkStyled to="/fadoel">fadøl</LinkStyled>{" "}
          - og Skafte er det perfekte sted at gå opdagelse i spændende øl på
          fad. Her finder du nemlig både de klassiske varianter, men også
          specialøl på fad, der alle er klar til at blive leveret sammen med en
          klapbar og hane, så du kan servere lækker og kold øl til din næste
          fest eller arrangement. Husk også at{" "}
          <LinkStyled to="/fadoelsanlaeg">leje et fadølsanlæg</LinkStyled> til
          klapbaren.
        </Paragraph>
        <Paragraph>
          En klapbar har følgende mål:
          <br />
          Bordpladen: bredde: 61,5 cm | længde: 121 cm | tykkelse: 15 mm
          <br />
          Udslået: bredde: 120 cm | højde: 100 cm | dybde: 60 cm
          <br />
          Sammenklappet: bredde: 107 cm | længde: 101 cm | tykkelse: 4,5 cm
        </Paragraph>
        <Hr />
        <Heading3>Hvad er en klapbar?</Heading3>
        <Paragraph>
          Som navnet antyder, er en klapbar en bar, der kan klappes sammen.
          Dette betyder, at den fylder minimalt, når den ikke er slået ud, men
          giver den nødvendige plads, så snart den er i brug.
        </Paragraph>
        <Paragraph>
          Der er plads til en, to eller tre haner på en klapbar, hvilket
          betyder, at den egner sig bedst til mindre fester eller arrangementer.
          Dette er naturligvis kun hvis du ønsker en enkelt klapbar, men der kan
          også vælges at gøres brug af flere, så de tilsammen giver den rette
          kapacitet. Vær dog opmærksom på, at de ikke kan sættes sammen i
          forlængelse af hinanden, men de egner sig derimod utroligt godt til at
          kunne placeres strategisk på hele arealet. Dette kan eksempelvis være
          en fordel, hvis der er tale om et større lokale, hvor de kan placeres
          i hvert hjørne eller på langsiderne.
        </Paragraph>
        <Paragraph>
          En klapbar er let at slå ud og pakke sammen selv, og fylder desuden
          minimalt, når den er slået sammen. Dette gør den også let at
          transportere eller flytte rundt på, hvis du skulle få brug for at
          flytte den før, under eller efter festen.
        </Paragraph>
        <Paragraph>
          Du kan med fordel vælge at leje en klapbar til din næste fest, så du
          kan skåne dine egne borde. Uanset hvor meget man forsøger at passe på
          til selve arrangementet, så kommer man sjældent udenom at der spildes
          lidt øl eller andre drinks. En klapbar er specielt lavet til formålet
          og tåler derfor, at der spildes øl eller at der sættes våde glas på
          overfladen. Hvis der kommer afmærkninger eller rander på bordpladen,
          bliver man heller ikke ærgerlig, som hvis det var eget bord.
        </Paragraph>
        <Hr />
        <Heading3>Klapbar til erhverv</Heading3>
        <Paragraph>
          Du kan med fordel vælge at leje en klapbar til den næste firmafest
          eller -arrangement. Dette kan eksempelvis være i forbindelse med:
        </Paragraph>
        <List>
          <li>Firmafest</li>
          <li>Fødselsdag</li>
          <li>Åbent hus</li>
          <li>Jubilæum</li>
          <li>Fredagsbar</li>
        </List>
        <Paragraph>
          Den kan naturligvis bruges til meget andet og det er kun fantasien der
          sætter grænser. Hvis der skal holdes større arrangementer, anbefaler
          vi enten en stilladsbar eller en udskænkningsvogn, frem for en
          klapbar.
        </Paragraph>
        <Hr />
        <Heading3>Klapbar til private</Heading3>
        <Paragraph>
          Hvis du skal holde fødselsdag, fest eller lignende, kan du vælge at
          byde dine gæster på en frisk skænket fadøl. Dette kan gøres ved hjælp
          af et fadølsanlæg placeret på en klapbar. En klapbar erstatter brugen
          af dine egne borde, så du undgår eventuelle skader eller afmærkninger
          efter festen.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 3907,
    name: "Stilladsbar",
    betingerFustagerUdstyr: true,
    generalSearchPriority: 500,
    searchSignificance: 10,
    keywods: "fadølsbar rullebar klapbar 120x60cm 3906 bar",
    invoiceDisplay: "SimpleItemRow",
    searchDisplay: BigView,
    url: "/fadoelsbar/stilladsbar",
    categories: ["fadoelsbar"],
    routeDef: {
      path: "/fadoelsbar/stilladsbar",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelsBarBC],
        title: "Stilladsbar",
      },
      meta: {
        title: "Leje af Stilladsbar til fadølsanlæg",
        description: "Lej en Stilladsbar til dit arrangement her",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Fadoelsbar",
      },
    },
    description: () => (
      <React.Fragment>
        <List check fontSize="14px">
          <li>God til større arrangementer</li>
          <li>God plads til 3 haner</li>
        </List>
        <Paragraph>
          Stilladsbarer er hovedsageligt beregnet til udendørs brug eller i
          telte og lagerhaller. De egner sig ikke til brug hvor der er sart
          gulvbelægning. Barerne bærer præg af at blive brugt til festivaler og
          lignende store arrangementer.
        </Paragraph>
      </React.Fragment>
    ),
    IconBoxes: () => (
      <React.Fragment>
        <IconBox calendar>
          <Lejeperiode days />
          <Lejeperiode />
        </IconBox>
        <IconBox dimension>
          Mål bar
          <Span block fontSize="12px">
            B 260cm | H 104cm | D 75cm
          </Span>
          <Span block fontSize="12px">
            Banner: Uden kroge 220 x 85 cm. Krogene stikker 7 cm ud
          </Span>
        </IconBox>
      </React.Fragment>
    ),
    packImages: [
      {
        name: "packs.stilladsbar.png",
        imageDescription: () => "Stilladsbar opslået",
      },
      {
        name: "packs.stilladsbar-sammenklappet1.png",
        imageDescription: () => "Stilladsbar sammenklappet",
      },
    ],
    imageSingle: "packs.stilladsbar.png",
    productTypePack: true,

    recieptItemView: RecieptPackEntryView,
    productPageXtraDescription: () => (
      <React.Fragment>
        <Heading3>Stilladsbar</Heading3>
        <Paragraph>
          En stilladsbar er hovedsageligt beregnet til udendørs brug, i telte
          eller i lagerhaller, grundet den opbygning. Den egner sig ikke til
          lokaler med sart gulvbelægning, da det i værste fald kan resultere i
          ridser og mærker efter endt arrangement. En stilladsbar egner sig til
          gengæld sublimt til brug på festivaler, store havefester samt andre
          lignende store arrangementer. Husk også at leje et{" "}
          <LinkStyled to="/fadoelsanlaeg">fadølsanlæg</LinkStyled> i samme
          omgang, så du er klar til at servere lækker og kold fadøl for dine
          gæster eller de deltagende ved arrangementet.
        </Paragraph>
        <Hr />
        <Heading3>Hvad er en stilladsbar?</Heading3>
        <Paragraph>
          En stilladsbar er, som navnet antyder, opbygget af dele fra et
          stillads. Den har således et råt og industrielt look, der egner sig
          særligt godt til festivaler, arrangementer i lagerhaller eller
          udendørs fester, hvor det kræves, at der er en robust og solid bar
          tilstede.
        </Paragraph>
        <Paragraph>
          Stilladsbaren leveres usamlet, men er heldigvis let at samle selv. Den
          består af to sider, to afstivere, selve bardisken og et banner, der
          sættes på foran. Banneret bruges bl.a. til at skjule fustager og anlæg
          med, samt andre genstande, der opbevares under stilladsbaren. Du
          samler let en stilladsbar ved at sætte delene sammen direkte ved hjælp
          af de allerede monterede hager. Disse hager passer perfekt rundt om
          rørene fra stilladset, så du slipper for at skulle tilpasse dem selv.
          Banneret sættes på til sidst, som prikken over i&apos;et.
        </Paragraph>
        <Paragraph>
          Vores stilladsbar har små plader under hvert ben, hvilket forhindrer
          baren i at synke ned i græsset, hvis den er placeret udendørs. De
          skaber desuden en god stabilitet, så din stilladsbar står sikkert og
          fast, når der er monteret fadølshaner og serveringen er i gang. Vær
          opmærksom på, at fødderne på en stilladsbar kan efterlade ridser i
          sarte gulve og bør derfor benyttes til udendørs arrangementer, i telte
          eller i lagerhaller/større lokaler med grove gulve.
        </Paragraph>
        <Hr />
        <Heading3>Stilladsbar til erhverv</Heading3>
        <Paragraph>
          Du kan med fordel vælge at leje en stilladsbar i forbindelse med
          erhverv. En stilladsbar egner sig godt til større fester og
          arrangementer, der holdes udendørs, i telte eller i haller, hvor
          gulvet ikke er sart. Du kan eksempel vælge at bruge en stilladsbar til
          erhverv til følgende:
        </Paragraph>
        <List>
          <li>Festival</li>
          <li>Jubilæum</li>
          <li>Sommerfest</li>
          <li>Gilder</li>
          <li>Serveringer</li>
        </List>
        <Paragraph>
          Det er naturligvis kun et lille udpluk og det er kun fantasien der
          sætter grænser, når det kommer til brugen af en stilladsbar til
          erhverv.
        </Paragraph>
        <Hr />
        <Heading3>Stilladsbar til private</Heading3>
        <Paragraph>
          En stilladsbar er ikke kun til store festivaler og arrangementer for
          erhverv. Det er også til private fester og arrangementer i større
          omfang. Du kan bl.a. vælge at bruge en stilladsbar til følgende
        </Paragraph>
        <List>
          <li>Bryllup</li>
          <li>Store fødselsdage</li>
          <li>Havefester</li>
          <li>Jubilæum</li>
          <li>Gilder</li>
        </List>
        <Paragraph>
          Det er naturligvis kun fantasien der sætter grænser, og du kan derfor
          bruge en stilladsbar til nøjagtigt det arrangement, som du har lyst
          til. Vær dog opmærksom på, at en stilladsbar ikke bør placeres
          indendørs på almindelige gulve, da der med stor sandsynlighed vil
          opstå ridser og mærker efter barens fødder.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: "pack_wagon",
    name: "Stor udskænkningsvogn",
    keywords: "barvogn vogn ølvogn fadølsvogn",
    betingerFustager: true,
    invoiceDisplay: "SimpleItemRow",
    url: "/fadoelsbar/udskaenkningsvogn",
    searchDisplay: BigView,
    categories: ["fadoelsbar"],
    routeDef: {
      path: "/fadoelsbar/udskaenkningsvogn",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelsBarBC],
        title: "Udskænkningsvogn",
      },
      meta: {
        title: "Udskænkningsvogn udlejes - 4 haner til større arangementer",
        description:
          "God til større fester som f.eks. en markedsplads, festival og lignende. Vognen har halvtag så den kan bruges i regnvejr",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Fadoelsbar",
      },
    },
    description: () => (
      <React.Fragment>
        <Paragraph>
          Vognen har 2 kraftige fadølskølere, hver med 2 haner. Kapaciteten er
          80 liter øl i timen pr. køler. Det vil sige at med 4 haner (400 V) kan
          man tappe 7 fustager i timen{" "}
          <Span fontSize="12px">(Når tønderne er 25&deg;C)</Span>.
        </Paragraph>
        <List check fontSize="14px">
          <li>Vognen skal afleveres rengjort</li>
          <li>4 fadølshaner*</li>
          <li>Køleanlæg og kulsyreflaske</li>
          <li>13,60m barplads (omkreds)</li>
        </List>
        <Paragraph fontSize="12px">
          Vognen forhandles af det tyske firma peki.com. Nypris ca. DKK 200.000.
        </Paragraph>
      </React.Fragment>
    ),
    IconBoxes: () => (
      <React.Fragment>
        <Paragraph fontSize="12px">
          * For at bruge alle 4 haner skal vognen tilsluttes 400 volt. Ellers
          tilsluttes til 230 volt og der kan bruges 2 af hanerne. Leje af udstyr
          betinger køb af øl.
        </Paragraph>
        <IconBox calendar>
          <Lejeperiode days />
          <Lejeperiode />
        </IconBox>
        <IconBox dimension>
          Mål og vægt
          <Span block fontSize="12px">
            Udslået inkl. tag og lys: H: 3,10m - L: 6,25m - B: 5,80m
            <br />
            Sammenslået: H: 3,10m - L: 3,50m + 90cm trækstang - B: 2,16m
            <br />
            Tilladt totalvægt: 1.500 kg.
          </Span>
        </IconBox>
      </React.Fragment>
    ),
    packImages: [
      {
        name: "packs.udskaenkningsvogn-aaben1.png",
        imageDescription: () => "Udskænkningsvogn opslået",
      },
      {
        name: "packs.udskaenkningsvogn-lukket1.png",
        imageDescription: () => "Udskænkningsvogn sammenklappet",
      },
    ],
    imageSingle: "packs.udskaenkningsvogn-aaben1.png",
    productTypePack: true,

    recieptItemView: RecieptPackEntryView,

    productPageXtraDescription: () => (
      <React.Fragment>
        <Heading3>Udskænkningsvogn</Heading3>
        <Paragraph>
          En udskænkningsvogn er perfekt, hvis der skal holdes store fester
          eller arrangementer, hvor der er brug for at kunne servere store
          mængder fadøl. Der er plads til at en eller to personer kan stå i
          vognen og skænke fadøl til de tørstige gæster. Der medfølger
          kulsyreflaske, men skulle du have brug for ekstra kulsyre, finder du
          det også hos Skafte. Vi kan levere det hele samlet - naturligvis også
          skøn, smagfuld og forfriskende fadøl. Når der er tale om fadøl, er
          kulsyre en nødvendighed. Det varierer hvor meget der skal bruges, men
          som tommelfingerregel bruges der 200 g{" "}
          <LinkStyled to="/kulsyre">kulsyre</LinkStyled> pr. fustage.
        </Paragraph>
        <Hr />
        <Heading3>Hvad er en udskænkningsvogn?</Heading3>
        <Paragraph>
          En udskænkningsvogn egner sig særligt godt til større arrangementer og
          fester, hvor der er brug for at der udskænkes meget øl til alle de
          skønne, tørstige gæster eller deltagere.
        </Paragraph>
        <Paragraph>
          Udskænkningsvognen har 2 kraftige fadølskølere, der hver har 2 haner.
          Der er altså 4 haner i alt i en udskænkningsvogn. Hver køler har en
          kapacitet på 80 liter øl i timen, hvilket vil sige, at man med 4 haner
          kan tappe 7 fustager på en enkelt time (når tønderne er 25°C).
        </Paragraph>
        <Paragraph>
          Denne udskænkningsvogn er yderst praktisk at have til større
          arrangementer, netop på grund af den høje kapacitet i timen. Vognen
          bør placeres udendørs, grundet størrelsen. Den har nemlig en stor
          barplads - hele 13,60 m (i omkreds).
        </Paragraph>
        <Paragraph>
          En udskænkningsvogn har følgende mål:
          <br />
          Udslået inkl. tag og lys: højde: 3,10 m | længde: 6,25 m | bredde:
          5,80 m<br />
          Sammenslået: højde: 3,10 m | længde: 3,50 m | bredde: 2,16 m<br />
          Den tilladte totalvægt er 1.500 kg
        </Paragraph>
        <Paragraph>
          Grundet størrelsen og kapaciteten på denne udskænkningsvogn, kræver
          det en tilslutning til strøm. Hvis du kun ønsker at anvende 2 haner,
          kræver det at der tilsluttes til 230 volt. Hvis du derimod ønsker at
          bruge alle 4 haner, kræver det, at der tilsluttes til 400 volt.
        </Paragraph>
        <Paragraph>
          Vognen skal afleveres rengjort.
          <br />
          Leje af udstyret betinger køb af øl.
        </Paragraph>
        <Hr />
        <Heading3>Udskænkningsvogn til erhverv</Heading3>
        <Paragraph>
          Du kan med fordel vælge at leje en udskænkningsvogn til erhvervsbrug,
          hvis der skal afholdes større arrangementer, festivaler eller
          firmafester. Der kan eksempelvis lejes en udskænkningsvogn i
          forbindelse med:
        </Paragraph>
        <List>
          <li>Sommerfest</li>
          <li>Festival</li>
          <li>Stor firmafest</li>
          <li>Større event</li>
        </List>
        <Paragraph>
          Der kan også lejes en udskænkningsvogn, hvis der skal trækkes
          opmærksomhed fra forbipasserende. Den fungerer dermed som glimrende
          blikfang, så der skabes interaktioner med arrangementets eller
          eventens formål.
        </Paragraph>
        <Hr />
        <Heading3>Udskænkningsvogn til private</Heading3>
        <Paragraph>
          Selvom en udskænkningsvogn fylder meget, behøver du ikke blive
          afskrækket af at leje en til den næste store fest. En udskænkningsvogn
          vil skabe en særlig stemning blandt gæsterne og der vil sandsynligt
          blive husket tilbage på netop denne dag som noget særligt og som en
          god oplevelse. Du kan eksempelvis vælge at leje en udskænkningsvogn
          til følgende:
        </Paragraph>
        <List>
          <li>Bryllup</li>
          <li>Havefest</li>
          <li>Stor fødselsdag</li>
          <li>Jubilæum</li>
          <li>Bryllupsdag</li>
          <li>Store fejringer</li>
        </List>
      </React.Fragment>
    ),
  },

  /* *
   *
   * Ølglas
   *
   * */
  {
    varenr: 7773,
    name: "Fadøls-krus 30cl",
    glass: true,
    SizeGlass: 30,
    generalSearchPriority: 200,
    searchSignificance: 10,
    keywords:
      "krus glas ølkrus fadølsglas bægre plast plastkrus plastølglas ølglas øl fadølglas fadølskrus glas plastikglas plastglas plastik plastic plasticglas plastickrus kop kopper plastikkrus",
    imageSingle: "produkter-oelvand.7773-flaske.png",
    imagePlural: "produkter-oelvand.plastikglas-rulle.png",
    description: () => (
      <Paragraph>
        Splintfri engangs fadølskrus i tyk, blød plastik. Nem at hælde op i,
        uden riller - skrummer ikke. Gode til fester med fri bar.
      </Paragraph>
    ),
    smallPrint: "Uåbnede pakker med fadølskrus tages retur.",
    extendedAmountDescriptor: true,
    url: "/oelglas/fadoels-krus-30cl",
    categories: ["oelglas", "huskGlas"],
    productTypeGlass: true,
    showOnlyName: true,
    routeDef: {
      path: "/oelglas/fadoels-krus-30cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelsGlasBC],
        title: "Fadøls-krus 30cl",
      },
      meta: {
        title: "Køb Fadøls-krus 30cl",
        description: "Fadøls-krus 30cl - Perfekt til fadøl",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Oelglas",
      },
    },
  },
  {
    varenr: 7774,
    name: "Fadøls-krus 40cl",
    generalSearchPriority: 200,
    searchSignificance: 10,
    glass: true,
    SizeGlass: 40,
    keywords:
      "krus glas ølkrus fadølsglas bægre plast plastkrus plastølglas ølglas øl fadølglas fadølskrus glas plastikglas plastglas plastik plastic plasticglas plastickrus kop kopper plastikkrus",
    imageSingle: "produkter-oelvand.7774-flaske.png",
    imagePlural: "produkter-oelvand.plastikglas-rulle.png",
    description: () => (
      <React.Fragment>
        <Paragraph>
          Splintfri fadølskrus i tyk, blød plastik. Nem at hælde op i, uden
          riller - skrummer ikke. Gode hvor fadøllet sælges. 30 cl. anbefales
          til alle fester med fri bar. Når gæsterne kan tage, så tit de ønsker,
          er det en fordel med et mindre fadølsglas, de giver friskere øl og
          mindre spild.
          <br />
          <Span fontSize="12px">
            H: 110 mm. Ø top: 98,6 mm. Ø bund: 57,5 mm. Indhold til øverste
            kant: 47 cl.
          </Span>
        </Paragraph>
      </React.Fragment>
    ),
    smallPrint: "Uåbnede pakker med fadølskrus tages retur.",
    extendedAmountDescriptor: true,
    url: "/oelglas/fadoels-krus-40cl",
    categories: ["oelglas", "huskGlas"],
    productTypeGlass: true,
    showOnlyName: true,
    routeDef: {
      path: "/oelglas/fadoels-krus-40cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelsGlasBC],
        title: "Fadøls-krus 40cl",
      },
      meta: {
        title: "Køb Fadøls-krus 40cl",
        description: "Fadøls-krus 40cl - Perfekt til fadøl",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Oelglas",
      },
    },
  },
  {
    varenr: 3776,
    name: "Bærehank til 5 glas",
    keywords: "holdere bærehåndtag",
    SizeGlass: 0,
    simpleItem: true,
    invoiceDisplay: "SimpleItemRow",
    imageSingle: "diverse.baerehank.png",
    description: () => (
      <Paragraph>
        Bærehanke i pap med Tuborg/Carlsberg logo. Plads til 5 plastikglas
        plastglas. Gode til fester, hvor der er langt til baren.
      </Paragraph>
    ),
    simpleAmountDescriptor: true,
    url: "/oelglas/baerehank-5-glas",
    categories: ["oelglas"],
    productTypeGlass: true,
    showOnlyName: true,
    routeDef: {
      path: "/oelglas/baerehank-5-glas",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelsGlasBC],
        title: "Bærehank til 5 glas",
      },
      meta: {
        title: "Køb Bærehank til 5 glas",
        description:
          "Bærehank til 5 glas - Perfekt til større arrangementer med massere af fadøl",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Oelglas",
      },
    },
  },
  {
    varenr: 3904,
    name: "Ølkande 2 liter",
    SizeGlass: 2000,
    keywords: "kander",
    simpleItem: true,
    invoiceDisplay: "SimpleItemRow",
    imageSingle: "produkter-fadoel.kande-tuborg-logo.png",
    description: () => (
      <Paragraph>
        Ølkande på 2 liter i hård plast med Tuborg logo og låg. God til fester,
        hvor mange gæster skal betjenes samtidigt.
      </Paragraph>
    ),
    smallPrint: "Prisen er for køb, kanden skal ikke returneres.",
    url: "/oelglas/oelkande-2-liter",
    categories: ["oelglas"],
    productTypeGlass: true,
    showOnlyName: true,
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/oelglas/oelkande-2-liter",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelsGlasBC],
        title: "Ølkande 2 liter",
      },
      meta: {
        title: "Køb Ølkande 2 liter",
        description: "Ølkande 2 liter - Perfekt til store events",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Oelglas",
      },
    },
  },
  {
    varenr: 3901,
    name: "Leje 1 liters ølglas",
    SizeGlass: 1000,
    glass: true,
    generalSearchPriority: 180,
    searchSignificance: 8,
    keywords:
      "ølkrus fadølsglas fadølglas fadølskrus ølglas øl fadølglas fadølskrus glas oktoberfest oktoberøl",
    imageSingle: "produkter-fadoel.1-liter-oelglas.png",
    imagePlural: "produkter-fadoel.1-liter-oelglas-kasse.png",
    invoiceDisplay: "SimpleItemRow",
    url: "/oelglas/oelglas-1-liter",
    categories: ["oelglas", "fadoelOktoberfest"],
    productTypeGlass: true,
    showOnlyName: true,
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/oelglas/oelglas-1-liter",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, FadoelsGlasBC],
        title: "1 liters ølglas",
      },
      meta: {
        title: "Køb 1 liters ølglas",
        description: "1 liters ølglas - Perfekt til oktoberfest",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Oelglas",
      },
    },
    description: () => (
      <React.Fragment>
        <Paragraph>
          Rigtigt oktoberfest fadølsglas i ægte glas. God til temafester.
        </Paragraph>
        <Paragraph>
          <Lejeperiode pureText />
        </Paragraph>
      </React.Fragment>
    ),
    smallDescription: () => (
      <React.Fragment>
        <Paragraph>
          Lejes 6 af gangen.
          <br />
          Skal returneres rengjorte.
          <br />
          Lavet af ægte glas
          <br />
          Erstatningspris pr. glas: <Price varenr="3903" momsIndication />
        </Paragraph>
      </React.Fragment>
    ),
    notice: () => (
      <React.Fragment>
        <Paragraph marginTop="10px" fontSize="13px">
          Skal lejes 6 af gangen. Skal returneres rengjorte. Lavet af ægte glas.
          Erstatningspris pr. glas: <Price varenr="3903" momsIndication />
        </Paragraph>
      </React.Fragment>
    ),
  },

  /* *
   *
   * Reservedele
   *
   * */

  /* *
   *
   * Rensning af fadølsanlæg
   *
   * */
  {
    varenr: 8975,
    name: "Rensedunk 5 liter S-kob",
    keywords:
      "rens clean beer beerclean rensning fadølsrens renseanlæg resning rengøring anlæg fadøl 5l 5 l anstikker s-anstikker s kobling s-kobling m med",
    description: () => (
      <Paragraph>Passer til f.eks. Carlsberg/Tuborg fadølsanlæg</Paragraph>
    ),
    descriptionBestil: () => (
      <Paragraph>Passer til f.eks. Carlsberg/Tuborg fadølsanlæg</Paragraph>
    ),
    imageSingle: "reservedele.rensedunk-5-liter-sort.png",
    productTypeReservedel: true,
    postnord: true,
    showOnlyPluralPrice: true,
    url: "/reservedele/rensning-fadoelsanlaeg/rensedunk-5-liter-s-kob",
    categories: ["reservedele", "rensning"],
    routeDef: {
      path: "/reservedele/rensning-fadoelsanlaeg/rensedunk-5-liter-s-kob",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleRensningBC],
        title: "Rensedunk 5 liter S-kob",
      },
      meta: {
        title: "Køb Rensedunk 5 liter S-kob",
        description: "Rensedunk 5 liter S-kob",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20820,
    name: "Rensedunk 30 liter S-kob",
    keywords:
      "rens clean beer beerclean rensning fadølsrens renseanlæg resning rengøring anlæg fadøl",
    imageSingle: "reservedele.rensedunk30literSkobling-stor.png",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/rensning-fadoelsanlaeg/rensedunk-30-liter-s-kob",
    categories: ["reservedele", "rensning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/rensning-fadoelsanlaeg/rensedunk-30-liter-s-kob",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleRensningBC],
        title: "Rensedunk 30 liter S-kob",
      },
      meta: {
        title: "Køb Rensedunk 30 liter S-kob",
        description: "Rensedunk 30 liter S-kob",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 8965,
    name: "Rensevæske 5 liter",
    keywords:
      "rens clean beer line cleaner beerclean rensning fadølsrens renseanlæg resning rengøring anlæg fadøl",
    imageSingle: "reservedele.rensevaeske5liter-stor.png",
    productTypeReservedel: true,
    postnord: true,
    // farligtGods: true,
    url: "/reservedele/rensning-fadoelsanlaeg/rensevaeske-5-liter",
    categories: ["reservedele", "rensning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/rensning-fadoelsanlaeg/rensevaeske-5-liter",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleRensningBC],
        title: "Rensevæske 5 liter",
      },
      meta: {
        title: "Køb Rensevæske 5 liter",
        description: "Rensevæske 5 liter",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
    descriptionBestil: () => (
      <React.Fragment>
        <Paragraph>
          Rensevæske til rengøring af fadølsanlæg og slanger.
        </Paragraph>
        <Paragraph>
          Dunken indeholder 5 liter koncentreret rensevæske og rækker til ca. 25
          rensninger.
        </Paragraph>
        <Paragraph>
          Denne væske er af typen der skifter farve, når rensning er fuldført.
        </Paragraph>
        <Paragraph>Opblandes 2-5% i lunkent vand</Paragraph>
        <Paragraph>
          Koster <Money money="30000" momsIndication /> ekstra hvis den skal
          sendes med posten, da det er farligt gods.
        </Paragraph>
      </React.Fragment>
    ),
    description: () => (
      <React.Fragment>
        <Paragraph>
          Rensevæske til rengøring af fadølsanlæg og slanger.
        </Paragraph>
        <Paragraph>
          Dunken indeholder 5 liter koncentreret rensevæske og rækker til ca. 25
          rensninger.
        </Paragraph>
        <Paragraph>
          Denne væske er af typen der skifter farve, når rensning er fuldført.
        </Paragraph>
        <Paragraph>Opblandes 2-5% i lunkent vand</Paragraph>
        <Paragraph>
          Koster <Money money="30000" momsIndication /> ekstra hvis den skal
          sendes med posten, da det er farligt gods.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 20800,
    name: "Rensedunk 5l uden anstikker",
    keywords:
      "rens clean beer beerclean rensning fadølsrens renseanlæg resning rengøring anlæg fadøl",
    imageSingle: "reservedele.rensedunk-uden-anstikker-stor.png",
    productTypeReservedel: true,
    postnord: true,
    farligtGods: true,
    url: "/reservedele/rensning-fadoelsanlaeg/rensedunk-5l-uden-anstikker",
    categories: ["reservedele", "rensning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/rensning-fadoelsanlaeg/rensedunk-5l-uden-anstikker",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleRensningBC],
        title: "Rensedunk 5l uden anstikker",
      },
      meta: {
        title: "Køb Rensedunk 5l uden anstikker",
        description: "Rensedunk 5l uden anstikker",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20805,
    name: "Anstikker S-kob. til rensedunk",
    imageSingle: "reservedele.anstikker-rensedunk-s-stor.png",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/rensning-fadoelsanlaeg/anstikker-s-kob-til-rensedunk",
    categories: ["reservedele", "rensning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/rensning-fadoelsanlaeg/anstikker-s-kob-til-rensedunk",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleRensningBC],
        title: "Anstikker S-kob. til rensedunk",
      },
      meta: {
        title: "Køb Anstikker S-kob. til rensedunk",
        description: "Anstikker S-kob. til rensedunk",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20810,
    name: "Anstikker A-kob. til rensedunk",
    imageSingle: "reservedele.anstikker-rensedunk-a-stor.png",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/rensning-fadoelsanlaeg/anstikker-a-kob-til-rensedunk",
    categories: ["reservedele", "rensning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/rensning-fadoelsanlaeg/anstikker-a-kob-til-rensedunk",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleRensningBC],
        title: "Anstikker A-kob. til rensedunk",
      },
      meta: {
        title: "Køb Anstikker A-kob. til rensedunk",
        description: "Anstikker A-kob. til rensedunk",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20815,
    name: "Anstikker G-kob. til rensedunk",
    imageSingle: "reservedele.anstikker-rensedunk-g-stor.png",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/rensning-fadoelsanlaeg/anstikker-g-kob-til-rensedunk",
    categories: ["reservedele", "rensning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/rensning-fadoelsanlaeg/anstikker-g-kob-til-rensedunk",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleRensningBC],
        title: "Anstikker G-kob. til rensedunk",
      },
      meta: {
        title: "Køb Anstikker G-kob. til rensedunk",
        description: "Anstikker G-kob. til rensedunk",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },

  /* *
   *
   * Kulsyre tilkobling
   *
   * */
  {
    varenr: 20600,
    name: "Trykregulator til øl",
    keywords: "kulsyre regulator manometer trykmåler kulsyreregulator",
    url: "/reservedele/kulsyre-tilkobling/trykregulator-til-oel",
    categories: ["reservedele", "kulsyretilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/kulsyre-tilkobling/trykregulator-til-oel",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleKulsyreTilkoblingBC],
        title: "Trykregulator til øl",
      },
      meta: {
        title: "Køb Trykregulator til øl",
        description: "Trykregulator til øl",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
    tooltip: () => (
      <Paragraph>
        Regulatoren skrues direkte på kulsyreflasken (køb evt. kulsyrenøgle).
        Regulerer trykket mellem 1 og 6 bar.
        <br />
        <br />
        Plastslange mellem fadkobling og regulator skubbes ud over studsen og
        fastgøres med spændebånd (medfølger ikke).
      </Paragraph>
    ),
    smallDescription: () => (
      <Span paddingTop="5px" fontSize="12px">
        Tilslutning: Studs til 10mm gummislange medfølger. Andre
        tilslutningsmuligheder på forespørgsel.
      </Span>
    ),
    description: () => (
      <Paragraph>
        Tilslutning: Studs til 10mm gummislange medfølger. Andre
        tilslutningsmuligheder på forespørgsel.
      </Paragraph>
    ),
    imageSingle: "reservedele.trykregulator-uden-studs-stor.png",
    productTypeReservedel: true,
    postnord: true,
  },
  {
    varenr: 20601,
    name: "Trykregulator til danskvand kulsyreregulator",
    keywords: "kulsyre regulator manometer trykmåler co2 flaske",
    url: "/reservedele/kulsyre-tilkobling/trykregulator-til-danskvand",
    categories: ["reservedele", "kulsyretilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/kulsyre-tilkobling/trykregulator-til-danskvand",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleKulsyreTilkoblingBC],
        title: "Trykregulator til danskvand ",
      },
      meta: {
        title: "Køb Trykregulator til danskvand ",
        description: "Trykregulator til danskvand ",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
    tooltip: () => (
      <Paragraph>
        Passer til langt de fleste danskvandsanlæg, herunder Quooker.
        Regulatoren skrues direkte på kulsyreflasken (kulsyrenøgle medfølger).
        Regulerer trykket mellem 1 og 10 bar. Til danskvand bruges normalt
        mellem 5 og 6 bar.
        <br />
        <br />
        Kulsyreslangen til danskvandskøleren skubbes direkte ind i John Guest
        studsen uden brug af værktøj eller spændebånd.
      </Paragraph>
    ),
    productPageXtraDescription: () => (
      <React.Fragment>
        <Heading3>Quooker med trykregulator</Heading3>
        <Paragraph>
          Sådan tilkobles kulsyre til Quooker med en lavtryks-plastikslange,
          samt trykregulator
        </Paragraph>
        <TheVideoPlayer url="wDw6fDvnqDc" thumbnail="quooker" />
      </React.Fragment>
    ),
    smallDescription: () => (
      <Span paddingTop="5px" fontSize="12px">
        Tilslutning: John Guest bøsning til 1/4&quot; (6,3mm) slange medfølger.
        Andre bøsningsstørrelser på forespørgsel.
      </Span>
    ),
    description: () => (
      <Paragraph>
        Tilslutning: John Guest bøsning til 1/4&quot; (6,3mm) slange medfølger.
        Andre bøsningsstørrelser på forespørgsel.
      </Paragraph>
    ),
    imageSingle: "reservedele.trykregulator-danskvand.png",
    productTypeReservedel: true,
    postnord: true,
  },
  {
    varenr: 20942,
    name: 'Kulsyreslange 1/4" (6,3mm)',
    keywords: "kulsyre slanger",
    url: "/reservedele/kulsyre-tilkobling/kulsyreslange-1-4-tomme",
    categories: ["reservedele", "kulsyretilkobling"],
    priceSuffix: " / meter",
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/kulsyre-tilkobling/kulsyreslange-1-4-tomme",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleKulsyreTilkoblingBC],
        title: 'Kulsyreslange 1/4" (6,3mm)',
      },
      meta: {
        title: 'Køb Kulsyreslange 1/4" (6,3mm)',
        description: 'Kulsyreslange 1/4" (6,3mm)',
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
    smallDescription: () => (
      <Span paddingTop="5px" fontSize="12px">
        Passer f.eks. direkte ind i f.eks. Quookeranlæg
      </Span>
    ),
    description: () => <Paragraph>Passer direkte ind i Quooker</Paragraph>,
    imageSingle: "reservedele.kulsyreslange-1-4-6,3mm.png",
    productTypeReservedel: true,
    postnord: true,
  },
  {
    varenr: 20615,
    name: "Kulsyrenøgle",
    url: "/reservedele/kulsyre-tilkobling/kulsyrenoegle",
    categories: ["reservedele", "kulsyretilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/kulsyre-tilkobling/kulsyrenoegle",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleKulsyreTilkoblingBC],
        title: "Kulsyrenøgle",
      },
      meta: {
        title: "Køb Kulsyrenøgle",
        description: "Kulsyrenøgle",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
    description: () => <Paragraph>Nøglevidde: 30mm</Paragraph>,
    smallDescription: () => <Span paddingTop="5px">Nøglevidde: 30mm</Span>,
    imageSingle: "reservedele.kulsyrenoegle-stor.png",
    productTypeReservedel: true,
    postnord: true,
  },
  {
    varenr: 21130,
    name: "Sodastream Tilkobling",
    keywords: "sodastreem kulsyre",
    url: "/reservedele/kulsyre-tilkobling/sodastream-tilkobling",
    categories: ["reservedele", "kulsyretilkobling", "kulsyreSodastream"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/kulsyre-tilkobling/sodastream-tilkobling",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleKulsyreTilkoblingBC],
        title: "Sodastream Tilkobling",
      },
      meta: {
        title: "Køb Sodastream Tilkobling",
        description: "Sodastream Tilkobling",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
    tooltip: () => (
      <Paragraph>
        Slangen er 2 meter og skrues direkte på kulsyreflasken. Ingen
        trykregulator nødvendig.
        <br />
        <br />
        Du skal bruge ventilen fra en af dine egne, brugte små kulsyreflasker
        fra SodaStream. Ventilen skrues ud af flasken og monteres i
        tilkoblingen. Denne modifikation er permanent og skal kun foretages en
        gang.
        <br />
        <br />
        Hvis du afleverer en brugt sodastream flaske til os, skruer vi gerne
        ventilen af og monterer den i din nyindkøbte slange.
      </Paragraph>
    ),
    imageSingle: "reservedele.sodastreamKulsyre-stor.png",
    productTypeReservedel: true,
    postnord: true,
  },
  {
    varenr: 20630,
    name: "Manometer - Arbejdstryk 0 - 6 bar",
    imageSingle: "reservedele.manometer-0-6-bar-stor.png",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/kulsyre-tilkobling/manometer-arbejdstryk-0-6-bar",
    categories: ["reservedele", "kulsyretilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/kulsyre-tilkobling/manometer-arbejdstryk-0-6-bar",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleKulsyreTilkoblingBC],
        title: "Manometer - Arbejdstryk 0 - 6 bar",
      },
      meta: {
        title: "Køb Manometer - Arbejdstryk 0 - 6 bar",
        description: "Manometer - Arbejdstryk 0 - 6 bar",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20631,
    name: "Manometer - Flasketryk 0 - 200 bar",
    imageSingle: "reservedele.manometer-0-200-bar-stor.png",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/kulsyre-tilkobling/manometer-flasketryk-0-200-bar",
    categories: ["reservedele", "kulsyretilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/kulsyre-tilkobling/manometer-flasketryk-0-200-bar",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleKulsyreTilkoblingBC],
        title: "Manometer - Flasketryk 0 - 200 bar",
      },
      meta: {
        title: "Køb Manometer - Flasketryk 0 - 200 bar",
        description: "Manometer - Flasketryk 0 - 200 bar",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20605,
    name: "Blindprop uden pakning til trykregulator",
    imageSingle: "reservedele.blindprop-uden-pakning-stor.png",
    productTypeReservedel: true,
    postnord: true,
    url:
      "/reservedele/kulsyre-tilkobling/blindprop-uden-pakning-til-trykregulator",
    categories: ["reservedele", "kulsyretilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path:
        "/reservedele/kulsyre-tilkobling/blindprop-uden-pakning-til-trykregulator",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleKulsyreTilkoblingBC],
        title: "Blindprop uden pakning til trykregulator",
      },
      meta: {
        title: "Køb Blindprop uden pakning til trykregulator",
        description: "Blindprop uden pakning til trykregulator",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
    smallDescription: () => (
      <Span paddingTop="5px">
        Nøglevide: 30mm. Indvendigt gevind: 20mm. Tykkelse: 16,5mm
      </Span>
    ),
    description: () => (
      <Paragraph>
        Nøglevide: 30mm. Indvendigt gevind: 20mm. Tykkelse: 16,5mm
      </Paragraph>
    ),
  },
  {
    varenr: 20610,
    name: "Pakning til blindprop trykregulator",
    keywords: "pakninger",
    imageSingle: "reservedele.pakning-til-blindprop-stor.png",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/kulsyre-tilkobling/pakning-til-blindprop-trykregulator",
    categories: ["reservedele", "kulsyretilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path:
        "/reservedele/kulsyre-tilkobling/pakning-til-blindprop-trykregulator",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleKulsyreTilkoblingBC],
        title: "Pakning til blindprop trykregulator",
      },
      meta: {
        title: "Køb Pakning til blindprop trykregulator",
        description: "Pakning til blindprop trykregulator",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
    smallDescription: () => (
      <Span paddingTop="5px">Diameter: 18,3mm. Tykkelse: 3mm</Span>
    ),
    description: () => <Paragraph>Diameter: 18,3mm. Tykkelse: 3mm</Paragraph>,
  },
  {
    varenr: 20500,
    name: "Kulsyre studs m. sut",
    imageSingle: "reservedele.kulsyre-studs-med-sut-stor.png",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/kulsyre-tilkobling/kulsyre-studs-m-sut",
    categories: ["reservedele", "kulsyretilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/kulsyre-tilkobling/kulsyre-studs-m-sut",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleKulsyreTilkoblingBC],
        title: "Kulsyre studs m. sut",
      },
      meta: {
        title: "Køb Kulsyre studs m. sut",
        description: "Kulsyre studs m. sut",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20620,
    name: "Kulsyrepakning hvid",
    keywords: "pakninger",
    imageSingle: "reservedele.kulsyre-pakning-stor.png",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/kulsyre-tilkobling/kulsyrepakning-hvid",
    categories: ["reservedele", "kulsyretilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/kulsyre-tilkobling/kulsyrepakning-hvid",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleKulsyreTilkoblingBC],
        title: "Kulsyrepakning hvid",
      },
      meta: {
        title: "Køb Kulsyrepakning hvid",
        description: "Kulsyrepakning hvid",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20625,
    name: "Skrue til kulsyrepakning",
    keywords: "pakninger",
    imageSingle: "reservedele.skrue-til-kulsyrepakning-stor.png",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/kulsyre-tilkobling/skrue-til-kulsyrepakning",
    categories: ["reservedele", "kulsyretilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/kulsyre-tilkobling/skrue-til-kulsyrepakning",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleKulsyreTilkoblingBC],
        title: "Skrue til kulsyrepakning",
      },
      meta: {
        title: "Køb Skrue til kulsyrepakning",
        description: "Skrue til kulsyrepakning",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20640,
    name: "Studs til kulsyreslange trykregulator",
    imageSingle: "reservedele.studs-kulsyreslange-trygregulator-stor.png",
    productTypeReservedel: true,
    postnord: true,
    url:
      "/reservedele/kulsyre-tilkobling/studs-til-kulsyreslange-trykregulator",
    categories: ["reservedele", "kulsyretilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path:
        "/reservedele/kulsyre-tilkobling/studs-til-kulsyreslange-trykregulator",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleKulsyreTilkoblingBC],
        title: "Studs til kulsyreslange trykregulator",
      },
      meta: {
        title: "Køb Studs til kulsyreslange trykregulator",
        description: "Studs til kulsyreslange trykregulator",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20940,
    name: "Kulsyreslange forstærket, pr. meter",
    keywords: "slanger",
    imageSingle: "reservedele.kulsyreslange-forstaerket.png",
    url: "/reservedele/kulsyre-tilkobling/kulsyreslange-forstaerket-pr-meter",
    categories: ["reservedele", "kulsyretilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path:
        "/reservedele/kulsyre-tilkobling/kulsyreslange-forstaerket-pr-meter",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleKulsyreTilkoblingBC],
        title: "Kulsyreslange forstærket, pr. meter",
      },
      meta: {
        title: "Køb Kulsyreslange forstærket, pr. meter",
        description: "Kulsyreslange forstærket, pr. meter",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
    smallDescription: () => (
      <Span fontSize="12px" marginTop="5px">
        Ø10x16 / Ø10x3 | (EU) N&deg;10/2011 Sim. A,B,C,D1 - 20 bar
      </Span>
    ),
    description: () => (
      <Paragraph>
        Ø10x16 / Ø10x3 | (EU) N&deg;10/2011 Sim. A,B,C,D1 - 20 bar
      </Paragraph>
    ),
    productTypeReservedel: true,
    postnord: true,
  },
  {
    varenr: 20902,
    name: "Spændebånd",
    imageSingle: "reservedele.spaendebaand-kulsyreslange-stor.png",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/kulsyre-tilkobling/spaendebaand",
    categories: ["reservedele", "kulsyretilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/kulsyre-tilkobling/spaendebaand",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleKulsyreTilkoblingBC],
        title: "Spændebånd",
      },
      meta: {
        title: "Køb Spændebånd",
        description: "Spændebånd",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 21140,
    name: "Studs til trykregulator danskvands tilslutning",
    imageSingle:
      "reservedele.studs-trygregulator-danskvand-tilslutning-stor.png",
    productTypeReservedel: true,
    postnord: true,
    url:
      "/reservedele/kulsyre-tilkobling/studs-til-trykregulator-danskvands-tilslutning",
    categories: ["reservedele", "kulsyretilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path:
        "/reservedele/kulsyre-tilkobling/studs-til-trykregulator-danskvands-tilslutning",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleKulsyreTilkoblingBC],
        title: "Studs til trykregulator danskvands tilslutning",
      },
      meta: {
        title: "Køb Studs til trykregulator danskvands tilslutning",
        description: "Studs til trykregulator danskvands tilslutning",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },

  /* *
   *
   * Øl tilkobling
   *
   * */
  {
    varenr: 20400,
    name: "Fadkobling S-system m. studs",
    keywords:
      "s-kobling anstikker s kobling fadølskobling fadølskoblinger s-kobling",
    imageSingle: "reservedele.fadkobling-s-mm.png",
    smallDescription: () => (
      <React.Fragment>
        <Paragraph fontSize="12px" marginTop="5px">
          &frac12;&quot; gevind på øl- og kulsyretilslutning. Udvendigt mål:
          20,8 mm.
        </Paragraph>
        <Paragraph fontSize="12px" marginTop="5px">
          Leveres med slangestuds på 8mm til øl og 10mm til kulsyre - eller
          efter kundens valg - skriv i kommentarfeltet hvad du ønsker.
        </Paragraph>
        <Paragraph fontSize="12px">
          Passer til f.eks. Carlsberg, Tuborg, Royal, Nørrebro, Svaneke og
          Herslev fustager
        </Paragraph>
      </React.Fragment>
    ),
    description: () => (
      <React.Fragment>
        <Paragraph>
          &frac12;&quot; gevind på øl- og kulsyretilslutning. Udvendigt mål:
          20,8 mm.
        </Paragraph>
        <Paragraph>
          Leveres med slangestuds på 8mm til øl og 10mm til kulsyre - eller
          efter kundens valg - skriv i kommentarfeltet hvad du ønsker.
        </Paragraph>
        <Paragraph>
          Passer til f.eks. Carlsberg, Tuborg, Royal, Nørrebro, Svaneke og
          Herslev fustager
        </Paragraph>
      </React.Fragment>
    ),
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-tilkobling/fadkobling-s-system-m-studs",
    categories: ["reservedele", "oeltilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-tilkobling/fadkobling-s-system-m-studs",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelTilkoblingBC],
        title: "Fadkobling S-system m. studs",
      },
      meta: {
        title: "Køb Fadkobling S-system m. studs",
        description: "Fadkobling S-system m. studs",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20405,
    name: "Fadkobling A-system m. studs",
    keywords:
      "a-kobling anstikker a kobling skovlyst fadølskobling fadølskoblinger fadkobling type a",
    imageSingle: "reservedele.fadkobling-a-system-studs-stor.png",
    smallDescription: () => (
      <React.Fragment>
        <Paragraph fontSize="12px" marginTop="5px">
          &frac12;&quot; gevind på øl- og kulsyretilslutning. Udvendigt mål:
          20,8 mm.
        </Paragraph>
        <Paragraph fontSize="12px" marginTop="5px">
          Leveres med slangestuds på 8mm til øl og 10mm til kulsyre - eller
          efter kundens valg - skriv i kommentarfeltet hvad du ønsker.
        </Paragraph>
        <Paragraph fontSize="12px">
          Passer til f.eks. Skovlyst, Paulaner, Franziskaner, Grimbergen,
          Hoegaarden fustager
        </Paragraph>
      </React.Fragment>
    ),
    description: () => (
      <React.Fragment>
        <Paragraph>
          &frac12;&quot; gevind på øl- og kulsyretilslutning. Udvendigt mål:
          20,8 mm.
        </Paragraph>
        <Paragraph>
          Leveres med slangestuds på 8mm til øl og 10mm til kulsyre - eller
          efter kundens valg - skriv i kommentarfeltet hvad du ønsker.
        </Paragraph>
        <Paragraph>
          Passer til f.eks. Skovlyst, Paulaner, Franziskaner, Grimbergen,
          Hoegaarden fustager
        </Paragraph>
      </React.Fragment>
    ),
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-tilkobling/fadkobling-a-system-m-studs",
    categories: ["reservedele", "oeltilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-tilkobling/fadkobling-a-system-m-studs",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelTilkoblingBC],
        title: "Fadkobling A-system m. studs",
      },
      meta: {
        title: "Køb Fadkobling A-system m. studs",
        description: "Fadkobling A-system m. studs",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20406,
    name: "Fadkobling A-system John Guest bøsning",
    keywords:
      "a-kobling anstikker a kobling skovlyst fadølskobling fadølskoblinger fadkobling type a",
    imageSingle: "reservedele.fadkobling-a-system-jg-boesning-1.png",
    smallDescription: () => (
      <React.Fragment>
        <Paragraph fontSize="12px" marginTop="5px">
          &frac12;&quot; gevind på øl- og kulsyretilslutning. Udvendigt mål:
          20,8 mm.
        </Paragraph>
        <Paragraph fontSize="12px" marginTop="5px">
          Leveres med John Guest slangetilslutning på 3/8&quot; ≈ 9,4mm - eller
          efter kundens valg - skriv i kommentarfeltet hvad du ønsker.
        </Paragraph>
        <Paragraph fontSize="12px">
          Passer til f.eks. Skovlyst, Paulaner, Franziskaner, Grimbergen,
          Hoegaarden fustager
        </Paragraph>
      </React.Fragment>
    ),
    description: () => (
      <React.Fragment>
        <Paragraph>
          &frac12;&quot; gevind på øl- og kulsyretilslutning. Udvendigt mål:
          20,8 mm.
        </Paragraph>
        <Paragraph>
          Leveres med John Guest slangetilslutning på 3/8&quot; ≈ 9,4mm - eller
          efter kundens valg - skriv i kommentarfeltet hvad du ønsker.
        </Paragraph>
        <Paragraph>
          Passer til f.eks. Skovlyst, Paulaner, Franziskaner, Grimbergen,
          Hoegaarden fustager
        </Paragraph>
      </React.Fragment>
    ),
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-tilkobling/fadkobling-a-system-JG-boesning",
    categories: ["reservedele", "oeltilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-tilkobling/fadkobling-a-system-JG-boesning",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelTilkoblingBC],
        title: "Fadkobling A-system JG-bøsning",
      },
      meta: {
        title: "Køb Fadkobling A-system JG-bøsning",
        description: "Fadkobling A-system JG-bøsning",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20410,
    name: "Fadkobling G-system m. studs",
    keywords:
      "g-kobling anstikker g kobling fadølskobling fadølskoblinger g-kobling",
    imageSingle: "reservedele.g-kobling.jpg",
    productTypeReservedel: true,
    postnord: true,
    smallDescription: () => (
      <React.Fragment>
        <Paragraph fontSize="12px" marginTop="5px">
          &frac12;&quot; gevind på øl- og kulsyretilslutning. Udvendigt mål:
          20,8 mm.
        </Paragraph>
        <Paragraph fontSize="12px" marginTop="5px">
          Leveres med slangestuds på 8mm til øl og 10mm til kulsyre - eller
          efter kundens valg - skriv i kommentarfeltet hvad du ønsker.
        </Paragraph>
        <Paragraph fontSize="12px">
          Passer til f.eks. Happy Joe fustager
        </Paragraph>
      </React.Fragment>
    ),
    description: () => (
      <React.Fragment>
        <Paragraph>
          &frac12;&quot; gevind på øl- og kulsyretilslutning. Udvendigt mål:
          20,8 mm.
        </Paragraph>
        <Paragraph>
          Leveres med slangestuds på 8mm til øl og 10mm til kulsyre - eller
          efter kundens valg - skriv i kommentarfeltet hvad du ønsker.
        </Paragraph>
        <Paragraph>Passer til f.eks. Happy Joe fustager</Paragraph>
      </React.Fragment>
    ),
    url: "/reservedele/oel-tilkobling/fadkobling-g-system-m-studs",
    categories: ["reservedele", "oeltilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-tilkobling/fadkobling-g-system-m-studs",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelTilkoblingBC],
        title: "Fadkobling G-system m. studs",
      },
      meta: {
        title: "Køb Fadkobling G-system m. studs",
        description: "Fadkobling G-system m. studs",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20415,
    name: "Fadkobling U-system m. studs",
    imageSingle: "reservedele.fadkobling-u.png",
    keywords:
      "u-kobling anstikker u kobling fadølskobling fadølskoblinger u-kobling",
    productTypeReservedel: true,
    postnord: true,
    smallDescription: () => (
      <React.Fragment>
        <Paragraph fontSize="12px" marginTop="5px">
          &frac12;&quot; gevind på øl- og kulsyretilslutning. Udvendigt mål:
          20,8 mm.
        </Paragraph>
        <Paragraph fontSize="12px" marginTop="5px">
          Leveres med slangestuds på 8mm til øl og 10mm til kulsyre - eller
          efter kundens valg - skriv i kommentarfeltet hvad du ønsker.
        </Paragraph>
        <Paragraph fontSize="12px">
          Passer til f.eks. Guinness fustager
        </Paragraph>
      </React.Fragment>
    ),
    description: () => (
      <React.Fragment>
        <Paragraph>
          &frac12;&quot; gevind på øl- og kulsyretilslutning. Udvendigt mål:
          20,8 mm.
        </Paragraph>
        <Paragraph>
          Leveres med slangestuds på 8mm til øl og 10mm til kulsyre - eller
          efter kundens valg - skriv i kommentarfeltet hvad du ønsker.
        </Paragraph>
        <Paragraph>Passer til f.eks. Guinness fustager</Paragraph>
      </React.Fragment>
    ),
    url: "/reservedele/oel-tilkobling/fadkobling-u-system-m-studs",
    categories: ["reservedele", "oeltilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-tilkobling/fadkobling-u-system-m-studs",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelTilkoblingBC],
        title: "Fadkobling U-system m. studs",
      },
      meta: {
        title: "Køb Fadkobling U-system m. studs",
        description: "Fadkobling U-system m. studs",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20439,
    name: "Fadkobling D-system m. studs",
    keywords:
      "d-kobling anstikker d kobling fadølskobling fadølskoblinger d-kobling",
    imageSingle: "reservedele.d-kobling.jpg",
    productTypeReservedel: true,
    postnord: true,
    smallDescription: () => (
      <React.Fragment>
        <Paragraph fontSize="12px" marginTop="5px">
          &frac12;&quot; gevind på øl- og kulsyretilslutning. Udvendigt mål:
          20,8 mm.
        </Paragraph>
        <Paragraph fontSize="12px" marginTop="5px">
          Leveres med slangestuds på 8mm til øl og 10mm til kulsyre - eller
          efter kundens valg - skriv i kommentarfeltet hvad du ønsker.
        </Paragraph>
      </React.Fragment>
    ),
    description: () => (
      <React.Fragment>
        <Paragraph>
          &frac12;&quot; gevind på øl- og kulsyretilslutning. Udvendigt mål:
          20,8 mm.
        </Paragraph>
        <Paragraph>
          Leveres med slangestuds på 8mm til øl og 10mm til kulsyre - eller
          efter kundens valg - skriv i kommentarfeltet hvad du ønsker.
        </Paragraph>
      </React.Fragment>
    ),
    url: "/reservedele/oel-tilkobling/fadkobling-d-system",
    categories: ["reservedele", "oeltilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-tilkobling/fadkobling-d-system",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelTilkoblingBC],
        title: "Fadkobling D-system",
      },
      meta: {
        title: "Køb Fadkobling D-system",
        description: "Fadkobling D-system",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20437,
    name: "Fadkobling S3-system m. studs",
    keywords:
      "s3-kobling anstikker s3 kobling fadølskobling fadølskoblinger s3-kobling",
    imageSingle: "reservedele.s3-kobling.jpg",
    productTypeReservedel: true,
    postnord: true,
    smallDescription: () => (
      <React.Fragment>
        <Paragraph fontSize="12px" marginTop="5px">
          &frac12;&quot; gevind på øl- og kulsyretilslutning. Udvendigt mål:
          20,8 mm.
        </Paragraph>
        <Paragraph fontSize="12px" marginTop="5px">
          Leveres med slangestuds på 8mm til øl og 10mm til kulsyre - eller
          efter kundens valg - skriv i kommentarfeltet hvad du ønsker.
        </Paragraph>
      </React.Fragment>
    ),
    description: () => (
      <React.Fragment>
        <Paragraph>
          &frac12;&quot; gevind på øl- og kulsyretilslutning. Udvendigt mål:
          20,8 mm.
        </Paragraph>
        <Paragraph>
          Leveres med slangestuds på 8mm til øl og 10mm til kulsyre - eller
          efter kundens valg - skriv i kommentarfeltet hvad du ønsker.
        </Paragraph>
      </React.Fragment>
    ),
    url: "/reservedele/oel-tilkobling/fadkobling-s3-system",
    categories: ["reservedele", "oeltilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-tilkobling/fadkobling-s3-system",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelTilkoblingBC],
        title: "Fadkobling S3-system",
      },
      meta: {
        title: "Køb Fadkobling S3-system",
        description: "Fadkobling S3-system",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20420,
    name: "Fadkobling M-system m. studs",
    keywords:
      "m-kobling anstikker m kobling fadølskobling fadølskoblinger m-kobling",
    imageSingle: "reservedele.m-kobling.jpg",
    productTypeReservedel: true,
    postnord: true,
    smallDescription: () => (
      <React.Fragment>
        <Paragraph fontSize="12px" marginTop="5px">
          &frac12;&quot; gevind på øl- og kulsyretilslutning. Udvendigt mål:
          20,8 mm.
        </Paragraph>
        <Paragraph fontSize="12px" marginTop="5px">
          Leveres med slangestuds på 8mm til øl og 10mm til kulsyre - eller
          efter kundens valg - skriv i kommentarfeltet hvad du ønsker.
        </Paragraph>
      </React.Fragment>
    ),
    description: () => (
      <React.Fragment>
        <Paragraph>
          &frac12;&quot; gevind på øl- og kulsyretilslutning. Udvendigt mål:
          20,8 mm.
        </Paragraph>
        <Paragraph>
          Leveres med slangestuds på 8mm til øl og 10mm til kulsyre - eller
          efter kundens valg - skriv i kommentarfeltet hvad du ønsker.
        </Paragraph>
      </React.Fragment>
    ),
    url: "/reservedele/oel-tilkobling/fadkobling-m-system",
    categories: ["reservedele", "oeltilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-tilkobling/fadkobling-m-system",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelTilkoblingBC],
        title: "Fadkobling M-system",
      },
      meta: {
        title: "Køb Fadkobling M-system",
        description: "Fadkobling M-system",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20430,
    name: "Cornelius kobling m. studs",
    keywords:
      "cornelius-kobling anstikker fadølskobling fadølskoblinger kornelius cornelius-kobling",
    imageSingle: "reservedele.cornelius-kobling.jpg",
    productTypeReservedel: true,
    postnord: true,
    smallDescription: () => (
      <React.Fragment>
        <Paragraph fontSize="12px" marginTop="5px">
          Leveres med slangestuds på 7mm til øl og 10mm til kulsyre - eller
          efter kundens valg - skriv i kommentarfeltet hvad du ønsker.
        </Paragraph>
        <Paragraph fontSize="12px" marginTop="5px">
          Bruges hovedsageligt til hjemmebryg og craft beer. Grå er gas ind,
          sort er øl ud.
        </Paragraph>
      </React.Fragment>
    ),
    description: () => (
      <React.Fragment>
        <Paragraph>
          Leveres med slangestuds på 7mm til øl og 10mm til kulsyre - eller
          efter kundens valg - skriv i kommentarfeltet hvad du ønsker.
        </Paragraph>
        <Paragraph>
          Bruges hovedsageligt til hjemmebryg og craft beer. Grå er gas ind,
          sort er øl ud.
        </Paragraph>
      </React.Fragment>
    ),
    url: "/reservedele/oel-tilkobling/cornelius-kobling",
    categories: ["reservedele", "oeltilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-tilkobling/cornelius-kobling",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelTilkoblingBC],
        title: "Cornelius kobling",
      },
      meta: {
        title: "Køb Cornelius kobling",
        description: "Cornelius kobling",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20435,
    name: "Fadkobling K-system m. studs (Keykeg kobling)",
    keywords:
      "keykeg-kobling anstikker fadølskobling fadølskoblinger k-kobling",
    imageSingle: "fadoel.keykeg-kobling.png",
    productTypeReservedel: true,
    postnord: true,
    smallDescription: () => (
      <React.Fragment>
        <Paragraph fontSize="12px" marginTop="5px">
          &frac12;&quot; gevind på øl- og kulsyretilslutning. Udvendigt mål:
          20,8 mm.
        </Paragraph>
        <Paragraph fontSize="12px" marginTop="5px">
          Leveres med slangestuds på 8mm til øl og 10mm til kulsyre - eller
          efter kundens valg - skriv i kommentarfeltet hvad du ønsker.
        </Paragraph>
      </React.Fragment>
    ),
    description: () => (
      <React.Fragment>
        <Paragraph>
          &frac12;&quot; gevind på øl- og kulsyretilslutning. Udvendigt mål:
          20,8 mm.
        </Paragraph>
        <Paragraph>
          Leveres med slangestuds på 8mm til øl og 10mm til kulsyre - eller
          efter kundens valg - skriv i kommentarfeltet hvad du ønsker.
        </Paragraph>
      </React.Fragment>
    ),
    url: "/reservedele/oel-tilkobling/keykeg-kobling",
    categories: ["reservedele", "oeltilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-tilkobling/keykeg-kobling",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelTilkoblingBC],
        title: "Keykeg kobling",
      },
      meta: {
        title: "Køb Keykeg kobling",
        description: "Keykeg kobling",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20819,
    name: "Adapter S-kobling til Keykeg",
    smallDescription: () => (
      <React.Fragment>
        <Span fontSize="12px" marginTop="5px">
          Bruges til fustager eller rensedunke med S-kobling
        </Span>
      </React.Fragment>
    ),
    description: () => (
      <Paragraph>Bruges til fustager eller rensedunke med S-kobling</Paragraph>
    ),
    productTypeReservedel: true,
    postnord: true,
    imageSingle: "reservedele.adapter-s-til-keykeg.jpg",
    url: "/reservedele/oel-tilkobling/adapter-s-kobling-til-keykeg",
    categories: ["reservedele", "oeltilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-tilkobling/adapter-s-kobling-til-keykeg",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelTilkoblingBC],
        title: "Adapter S-kobling til Keykeg",
      },
      meta: {
        title: "Køb Adapter S-kobling til Keykeg",
        description: "Adapter S-kobling til Keykeg",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20985,
    name: "Klemmering 7,0 mm",
    keywords: "klemring klemmebånd klemmeringe",
    imageSingle: "reservedele.klemmering-7-0mm-1.jpg",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-tilkobling/klemmering-7-0-mm",
    categories: ["reservedele", "oeltilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-tilkobling/klemmering-7-0-mm",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelTilkoblingBC],
        title: "Klemmering 7,0 mm",
      },
      meta: {
        title: "Køb Klemmering 7,0 mm",
        description: "Klemmering 7,0 mm",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20986,
    name: "Klemmering 10,5 mm",
    keywords: "klemring klemmebånd klemmeringe",
    imageSingle: "reservedele.klemmering-10-5mm-1.jpg",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-tilkobling/klemmering-10-5-mm",
    categories: ["reservedele", "oeltilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-tilkobling/klemmering-10-5-mm",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelTilkoblingBC],
        title: "Klemmering 10,5 mm",
      },
      meta: {
        title: "Køb Klemmering 10,5 mm",
        description: "Klemmering 10,5 mm",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20987,
    name: "Klemmering 12,3 mm",
    keywords: "klemring klemmebånd klemmeringe",
    imageSingle: "reservedele.klemmering-12-3mm-1.jpg",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-tilkobling/klemmering-12-3-mm",
    categories: ["reservedele", "oeltilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-tilkobling/klemmering-12-3-mm",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelTilkoblingBC],
        title: "Klemmering 12,3 mm",
      },
      meta: {
        title: "Køb Klemmering 12,3 mm",
        description: "Klemmering 12,3 mm",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20988,
    name: "Klemmering 13,3 mm",
    keywords: "klemring klemmebånd klemmeringe",
    imageSingle: "reservedele.klemmering-13-3mm-1.jpg",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-tilkobling/klemmering-13-3-mm",
    categories: ["reservedele", "oeltilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-tilkobling/klemmering-13-3-mm",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelTilkoblingBC],
        title: "Klemmering 13,3 mm",
      },
      meta: {
        title: "Køb Klemmering 13,3 mm",
        description: "Klemmering 13,3 mm",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20989,
    name: "Klemmering 14,0 mm",
    keywords: "klemring klemmebånd klemmeringe",
    imageSingle: "reservedele.klemmering-14-0mm-1.jpg",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-tilkobling/klemmering-14-0-mm",
    categories: ["reservedele", "oeltilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-tilkobling/klemmering-14-0-mm",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelTilkoblingBC],
        title: "Klemmering 14,0 mm",
      },
      meta: {
        title: "Køb Klemmering 14,0 mm",
        description: "Klemmering 14,0 mm",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20990,
    name: "Klemmering 14,8 mm",
    keywords: "klemring klemmebånd klemmeringe",
    imageSingle: "reservedele.klemmering-14-8mm-1.jpg",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-tilkobling/klemmering-14-8-mm",
    categories: ["reservedele", "oeltilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-tilkobling/klemmering-14-8-mm",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelTilkoblingBC],
        title: "Klemmering 14,8 mm",
      },
      meta: {
        title: "Køb Klemmering 14,8 mm",
        description: "Klemmering 14,8 mm",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20900,
    name: "Klemmering alle størrelser",
    keywords: "klemring klemmebånd klemmeringe",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-tilkobling/klemmering-alle-stoerrelser",
    categories: ["reservedele", "oeltilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-tilkobling/klemmering-alle-stoerrelser",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelTilkoblingBC],
        title: "Klemmering alle størrelser",
      },
      meta: {
        title: "Køb Klemmering alle størrelser",
        description: "Klemmering alle størrelser",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20505,
    name: "Øl studs",
    imageSingle: "reservedele.oel-studs.jpg",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-tilkobling/oel-studs",
    categories: ["reservedele", "oeltilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-tilkobling/oel-studs",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelTilkoblingBC],
        title: "Øl studs",
      },
      meta: {
        title: "Køb Øl studs",
        description: "Øl studs",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20510,
    name: "Gummisut / Tilbageløbspakning",
    keywords: "gummi sut tilbageløbspakning pakninger",
    imageSingle: "reservedele.gummi-sut-tilbageloebspak.jpg",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-tilkobling/gummisut-tilbageloebspakning",
    categories: ["reservedele", "oeltilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-tilkobling/gummisut-tilbageloebspakning",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelTilkoblingBC],
        title: "Gummisut / Tilbageløbspakning",
      },
      meta: {
        title: "Køb Gummisut / Tilbageløbspakning",
        description: "Gummisut / Tilbageløbspakning",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20515,
    name: "Nedre stor pak.fadkobling",
    keywords: "pakning pakninger",
    imageSingle: "reservedele.nedre-stor-pakning-fadkobling-stor.png",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-tilkobling/nedre-stor-pakfadkobling",
    categories: ["reservedele", "oeltilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-tilkobling/nedre-stor-pakfadkobling",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelTilkoblingBC],
        title: "Nedre stor pak.fadkobling",
      },
      meta: {
        title: "Køb Nedre stor pak.fadkobling",
        description: "Nedre stor pak.fadkobling",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20520,
    name: "Nedre lille pak.fadkobling",
    keywords: "pakning pakninger",
    imageSingle: "reservedele.nedre-lille-pakning-fadkobling-stor.png",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-tilkobling/nedre-lille-pakfadkobling",
    categories: ["reservedele", "oeltilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-tilkobling/nedre-lille-pakfadkobling",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelTilkoblingBC],
        title: "",
      },
      meta: {
        title: "Køb Nedre lille pak.fadkobling",
        description: "Nedre lille pak.fadkobling",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20525,
    name: "O-ring fadkobling",
    keywords:
      "pakning pakninger lynkobliner kobling fadølskobling fadølskoblinger",
    imageSingle: "reservedele.o-ring-indvendig-fadkobling-stor.png",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-tilkobling/o-ring-fadkobling",
    categories: ["reservedele", "oeltilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-tilkobling/o-ring-fadkobling",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelTilkoblingBC],
        title: "O-ring fadkobling",
      },
      meta: {
        title: "Køb O-ring fadkobling",
        description: "O-ring fadkobling",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20530,
    name: "Tilbageløbsventil fadkobling",
    imageSingle: "reservedele.tilbageloebsventil-fadkobling.jpg",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-tilkobling/tilbageloebsventil-fadkobling",
    categories: ["reservedele", "oeltilkobling"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-tilkobling/tilbageloebsventil-fadkobling",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelTilkoblingBC],
        title: "Tilbageløbsventil fadkobling",
      },
      meta: {
        title: "Køb Tilbageløbsventil fadkobling",
        description: "Tilbageløbsventil fadkobling",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },

  /* *
   *
   * Ølaftapning
   *
   * */
  {
    varenr: 21100,
    name: "Plastikskaller til topring sæt | Alle typer",
    imageSingle: "reservedele.skaller.jpg",
    keywords: "skilt topskilt topskilte ølhanke",
    smallDescription: () => (
      <React.Fragment>
        <Paragraph fontSize="12px">
          Kommenter hvilken type og med hvilken grafik du ønsker eller tag
          kontakt til os efter bestilling.
        </Paragraph>
        <Paragraph fontSize="12px">
          Fås i rund (74x72mm) og oval i begrænset udvalg i to størrelser (69x85
          og 77x95). Nogle skaller, som Tuborg, kan leveres til Tuborgs
          specielle topring med plads til kronen.
        </Paragraph>
        <Paragraph fontSize="12px">
          Kommer i sæt á 2 skaller (en til hver side af topringen). Vi
          producerer selv de runde skaller og printer grafikken på professionelt
          vinylpapir. Herefter lamineres papiret med en speciel lamineringsfilm,
          så det bliver meget slidstærkt. Slutteligt monteres dette på to
          gennemsigtige plastikskaller.
        </Paragraph>
      </React.Fragment>
    ),
    description: () => (
      <React.Fragment>
        <Paragraph>
          Kommenter hvilken type og med hvilken grafik du ønsker eller tag
          kontakt til os efter bestilling.
        </Paragraph>
        <Paragraph>
          Fås i rund (74x72mm) og oval i begrænset udvalg i to størrelser (69x85
          og 77x95). Nogle skaller, som Tuborg, kan leveres til Tuborgs
          specielle topring med plads til kronen.
        </Paragraph>
        <Paragraph>
          Kommer i sæt á 2 skaller (en til hver side af topringen). Vi
          producerer selv de runde skaller og printer grafikken på professionelt
          vinylpapir. Herefter lamineres papiret med en speciel lamineringsfilm,
          så det bliver meget slidstærkt. Slutteligt monteres dette på to
          gennemsigtige plastikskaller.
        </Paragraph>
      </React.Fragment>
    ),
    productTypeReservedel: true,
    postnord: true,
    url:
      "/reservedele/oel-aftapning/plastikskaller-til-topring-saet-alle-typer",
    categories: ["reservedele", "oelAftapning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path:
        "/reservedele/oel-aftapning/plastikskaller-til-topring-saet-alle-typer",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelAftapningBC],
        title: "Plastikskaller til topring sæt | Alle typer",
      },
      meta: {
        title: "Køb Plastikskaller til topring sæt | Alle typer",
        description: "Plastikskaller til topring sæt | Alle typer",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 21110,
    name: "Topring oval",
    productTypeReservedel: true,
    postnord: true,
    imageSingle: "reservedele.topring-oval.jpg",
    url: "/reservedele/oel-aftapning/topring-oval",
    categories: ["reservedele", "oelAftapning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-aftapning/topring-oval",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelAftapningBC],
        title: "Topring oval",
      },
      meta: {
        title: "Køb Topring oval",
        description: "Topring oval",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 21111,
    name: "Topring rund",
    productTypeReservedel: true,
    postnord: true,
    imageSingle: "reservedele.topring-rund.jpg",
    url: "/reservedele/oel-aftapning/topring-rund",
    categories: ["reservedele", "oelAftapning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-aftapning/topring-rund",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelAftapningBC],
        title: "Topring rund",
      },
      meta: {
        title: "Køb Topring rund",
        description: "Topring rund",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20707,
    name: "Møtrik hane",
    productTypeReservedel: true,
    postnord: true,
    imageSingle: "reservedele.moetrik-hane.jpg",
    url: "/reservedele/oel-aftapning/moetrik-hane",
    categories: ["reservedele", "oelAftapning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-aftapning/moetrik-hane",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelAftapningBC],
        title: "Møtrik hane",
      },
      meta: {
        title: "Køb Møtrik hane",
        description: "Møtrik hane",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20708,
    name: "Hane med indsats krom",
    imageSingle: "reservedele.hane-med-indsats-krom.jpg",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-aftapning/hane-med-indsats-krom",
    categories: ["reservedele", "oelAftapning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-aftapning/hane-med-indsats-krom",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelAftapningBC],
        title: "Hane med indsats krom",
      },
      meta: {
        title: "Køb Hane med indsats krom",
        description: "Hane med indsats krom",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20709,
    name: "Indsats til hane",
    productTypeReservedel: true,
    postnord: true,
    imageSingle: "reservedele.indsats-til-hane.jpg",
    url: "/reservedele/oel-aftapning/indsats-til-hane",
    categories: ["reservedele", "oelAftapning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-aftapning/indsats-til-hane",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelAftapningBC],
        title: "Indsats til hane",
      },
      meta: {
        title: "Køb Indsats til hane",
        description: "Indsats til hane",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20710,
    name: "Plastik drypbakke m rist",
    keywords: "spildbakke",
    smallDescription: () => (
      <React.Fragment>
        <Span fontSize="12px" marginTop="5px">
          Mål: 208x135x70 mm
        </Span>
      </React.Fragment>
    ),
    description: () => <Paragraph>Mål: 208x135x70 mm</Paragraph>,
    productTypeReservedel: true,
    postnord: true,
    imageSingle: "reservedele.plastik-drypbakke-med-rist1.png",
    url: "/reservedele/oel-aftapning/plastik-drypbakke-m-rist",
    categories: ["reservedele", "oelAftapning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-aftapning/plastik-drypbakke-m-rist",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelAftapningBC],
        title: "Plastik drypbakke m rist",
      },
      meta: {
        title: "Køb Plastik drypbakke m rist",
        description: "Plastik drypbakke m rist",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20715,
    name: "Rist til plastik drypbakke",
    keywords: "spildbakke",
    smallDescription: () => (
      <React.Fragment>
        <Span fontSize="12px" marginTop="5px">
          Mål: 195x121x1 mm
        </Span>
      </React.Fragment>
    ),
    description: () => <Paragraph>Mål: 195x121x1 mm</Paragraph>,
    productTypeReservedel: true,
    postnord: true,
    imageSingle: "reservedele.rist-til-plastik-drypbakke.jpg",
    url: "/reservedele/oel-aftapning/rist-til-plastik-drypbakke",
    categories: ["reservedele", "oelAftapning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-aftapning/rist-til-plastik-drypbakke",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelAftapningBC],
        title: "Rist til plastik drypbakke",
      },
      meta: {
        title: "Køb Rist til plastik drypbakke",
        description: "Rist til plastik drypbakke",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20720,
    name: "Plastik drypbakke u rist",
    smallDescription: () => (
      <React.Fragment>
        <Span fontSize="12px" marginTop="5px">
          Mål: 208x135x70 mm
        </Span>
      </React.Fragment>
    ),
    description: () => <Paragraph>Mål: 208x135x70 mm</Paragraph>,
    keywords: "spildbakke",
    productTypeReservedel: true,
    postnord: true,
    imageSingle: "reservedele.plastik-drypbakke-uden-rist.jpg",
    url: "/reservedele/oel-aftapning/plastik-drypbakke-u-rist",
    categories: ["reservedele", "oelAftapning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-aftapning/plastik-drypbakke-u-rist",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelAftapningBC],
        title: "Plastik drypbakke u rist",
      },
      meta: {
        title: "Køb Plastik drypbakke u rist",
        description: "Plastik drypbakke u rist",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 21115,
    name: "Skål til topring",
    smallDescription: () => (
      <React.Fragment>
        <Span fontSize="12px" marginTop="5px">
          Passer til rør med diameter 72mm
        </Span>
      </React.Fragment>
    ),
    description: () => <Paragraph>Passer til rør med diameter 72mm</Paragraph>,
    productTypeReservedel: true,
    postnord: true,
    imageSingle: "reservedele.skaal-til-topring.jpg",
    url: "/reservedele/oel-aftapning/skaal-til-topring",
    categories: ["reservedele", "oelAftapning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-aftapning/skaal-til-topring",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelAftapningBC],
        title: "Skål til topring",
      },
      meta: {
        title: "Køb Skål til topring",
        description: "Skål til topring",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 21117,
    name: "Gevind stk. m møtrik topring",
    imageSingle: "reservedele.gevind-med-moetrik-topring.jpg",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-aftapning/gevind-stk-m-moetrik-topring",
    categories: ["reservedele", "oelAftapning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-aftapning/gevind-stk-m-moetrik-topring",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelAftapningBC],
        title: "Gevind stk. m møtrik topring",
      },
      meta: {
        title: "Køb Gevind stk. m møtrik topring",
        description: "Gevind stk. m møtrik topring",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 21210,
    name: "Pibehoved med omløber og pakning",
    keywords: "pakning pakninger",
    imageSingle: "reservedele.pibehoved-med-omloeber-pakning.jpg",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-aftapning/pibehoved-med-omloeber-og-pakning",
    categories: ["reservedele", "oelAftapning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-aftapning/pibehoved-med-omloeber-og-pakning",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelAftapningBC],
        title: "Pibehoved med omløber og pakning",
      },
      meta: {
        title: "Køb Pibehoved med omløber og pakning",
        description: "Pibehoved med omløber og pakning",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20930,
    name: "Ølslange, pr. meter",
    keywords: "slange til fadøl slanger",
    imageSingle: "reservedele.oelslange.png",
    smallDescription: () => (
      <React.Fragment>
        <Span fontSize="12px" marginTop="5px">
          Valpar Superflexmaster | SK274-012 | I.D. 6.8mm x 12mm | 170817/141CD
        </Span>
      </React.Fragment>
    ),
    description: () => (
      <Paragraph>
        Valpar Superflexmaster | SK274-012 | I.D. 6.8mm x 12mm | 170817/141CD
      </Paragraph>
    ),
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-aftapning/oelslange-pr-meter",
    categories: ["reservedele", "oelAftapning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-aftapning/oelslange-pr-meter",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelAftapningBC],
        title: "Ølslange, pr. meter",
      },
      meta: {
        title: "Køb Ølslange, pr. meter",
        description: "Ølslange, pr. meter",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20955,
    name: "Bremseslange, pr. meter",
    keywords: "dobbeltslange slange til fadøl slanger",
    imageSingle: "reservedele.bremseslange.png",
    smallDescription: () => (
      <React.Fragment>
        <Span fontSize="12px" marginTop="5px">
          Valpar | 070317/2045E | 8mm x 3,5mm | EA / Green
        </Span>
      </React.Fragment>
    ),
    description: () => (
      <Paragraph>Valpar | 070317/2045E | 8mm x 3,5mm | EA / Green</Paragraph>
    ),
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-aftapning/bremseslange-pr-meter",
    categories: ["reservedele", "oelAftapning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-aftapning/bremseslange-pr-meter",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelAftapningBC],
        title: "Bremseslange, pr. meter",
      },
      meta: {
        title: "Køb Bremseslange, pr. meter",
        description: "Bremseslange, pr. meter",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20905,
    name: "Y-rør til dobbeltslange",
    imageSingle: "reservedele.y-roer-til-dobbeltslange.jpg",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-aftapning/y-roer-til-dobbeltslange",
    categories: ["reservedele", "oelAftapning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-aftapning/y-roer-til-dobbeltslange",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelAftapningBC],
        title: "Y-rør til dobbeltslange",
      },
      meta: {
        title: "Køb Y-rør til dobbeltslange",
        description: "Y-rør til dobbeltslange",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20910,
    name: "Lynkobling hun 5/16",
    keywords: "lynkobliner kobling fadølskobling fadølskoblinger",
    imageSingle: "reservedele.hun-lynkobling-stor.png",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-aftapning/lynkobling-hun-5-16",
    categories: ["reservedele", "oelAftapning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-aftapning/lynkobling-hun-5-16",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelAftapningBC],
        title: "Lynkobling hun 5/16",
      },
      meta: {
        title: "Køb Lynkobling hun 5/16",
        description: "Lynkobling hun 5/16",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20915,
    name: "Lynkobling han 5/16",
    keywords: "lynkobliner kobling fadølskobling fadølskoblinger",
    imageSingle: "reservedele.han-lynkobling-uden-shutoff-stor.png",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-aftapning/lynkobling-han-5-16",
    categories: ["reservedele", "oelAftapning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-aftapning/lynkobling-han-5-16",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelAftapningBC],
        title: "Lynkobling han 5/16",
      },
      meta: {
        title: "Køb Lynkobling han 5/16",
        description: "Lynkobling han 5/16",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20920,
    name: "Lynkobling han m shut off 5/16",
    keywords: "lynkobliner kobling fadølskobling fadølskoblinger",
    imageSingle: "reservedele.han-lynkobling-med-shutoff-stor.png",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-aftapning/lynkobling-han-m-shut-off-5-16",
    categories: ["reservedele", "oelAftapning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-aftapning/lynkobling-han-m-shut-off-5-16",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelAftapningBC],
        title: "Lynkobling han m shut off 5/16",
      },
      meta: {
        title: "Køb Lynkobling han m shut off 5/16",
        description: "Lynkobling han m shut off 5/16",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 20925,
    name: "O-ring lynkobling han",
    keywords: "pakning lynkobliner",
    smallDescription: () => (
      <React.Fragment>
        <Span fontSize="12px" marginTop="5px">
          Ø11 mm | Tykkelse 1,8mm
        </Span>
      </React.Fragment>
    ),
    description: () => <Paragraph>Ø11 mm | Tykkelse 1,8mm</Paragraph>,
    imageSingle: "reservedele.pakning-til-han-lynkobling-stor.png",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-aftapning/o-ring-lynkobling-han",
    categories: ["reservedele", "oelAftapning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-aftapning/o-ring-lynkobling-han",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelAftapningBC],
        title: "O-ring lynkobling han",
      },
      meta: {
        title: "Køb O-ring lynkobling han",
        description: "O-ring lynkobling han",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 22120,
    name: "Plastkasse til udstyr",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-aftapning/plastkasse-til-udstyr",
    categories: ["reservedele", "oelAftapning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-aftapning/plastkasse-til-udstyr",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelAftapningBC],
        title: "Plastkasse til udstyr",
      },
      meta: {
        title: "Køb Plastkasse til udstyr",
        description: "Plastkasse til udstyr",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 21251,
    name: 'John Guest 1/2"=12,7mm gevind - 3/8"=9,5mm slange',
    imageSingle: "reservedele.JG-1-2-bsp-3-8-tube.png",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-aftapning/john-guest-12-7mm-gevind-9-5mm-slange",
    categories: ["reservedele", "oelAftapning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-aftapning/john-guest-12-7mm-gevind-9-5mm-slange",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelAftapningBC],
        title: 'John Guest 1/2"=12,7mm gevind - 3/8"=9,5mm slange',
      },
      meta: {
        title: 'Køb John Guest 1/2"=12,7mm gevind - 3/8"=9,5mm slange',
        description: 'John Guest 1/2"=12,7mm gevind - 3/8"=9,5mm slange',
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 21252,
    name: 'John Guest 5/8"=15,9mm gevind - 3/8"=9,5mm slange',
    imageSingle: "reservedele.JG-female-adaptor-5-8-bsp-3-8.png",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-aftapning/john-guest-15-9mm-gevind-9-5mm-slange",
    categories: ["reservedele", "oelAftapning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-aftapning/john-guest-15-9mm-gevind-9-5mm-slange",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelAftapningBC],
        title: 'John Guest 5/8"=15,9mm gevind - 3/8"=9,5mm slange',
      },
      meta: {
        title: 'Køb John Guest 5/8"=15,9mm gevind - 3/8"=9,5mm slange',
        description: 'John Guest 5/8"=15,9mm gevind - 3/8"=9,5mm slange',
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 21253,
    name: 'John Guest 3/4"=19mm gevind - 3/8"=9,5mm slange',
    imageSingle: "reservedele.JG-female-adaptor-3-4-bsp-3-8.png",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-aftapning/john-guest-19mm-gevind-9-5mm-slange",
    categories: ["reservedele", "oelAftapning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-aftapning/john-guest-19mm-gevind-9-5mm-slange",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelAftapningBC],
        title: 'John Guest 3/4"=19mm gevind - 3/8"=9,5mm slange',
      },
      meta: {
        title: 'Køb John Guest 3/4"=19mm gevind - 3/8"=9,5mm slange',
        description: 'John Guest 3/4"=19mm gevind - 3/8"=9,5mm slange',
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 21254,
    name: 'John Guest vinkel 5/16"=8mm studs - 5/16"=8mm slange',
    imageSingle: "reservedele.JG-l-5-16-stem-5-16.png",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/oel-aftapning/john-guest-vinkel-8mm-studs-8mm-slange",
    categories: ["reservedele", "oelAftapning"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/oel-aftapning/john-guest-vinkel-8mm-studs-8mm-slange",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleOelAftapningBC],
        title: 'John Guest vinkel 5/16"=8mm studs - 5/16"=8mm slange',
      },
      meta: {
        title: 'Køb John Guest vinkel 5/16"=8mm studs - 5/16"=8mm slange',
        description: 'John Guest vinkel 5/16"=8mm studs - 5/16"=8mm slange',
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  /* *
   *
   * Reservedele - Køleanlæg
   *
   * */
  {
    varenr: 21000,
    name: "Termostat",
    imageSingle: "reservedele.termostat.jpg",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/koeleanlaeg/termostat",
    categories: ["reservedele", "koeleanlaeg"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/koeleanlaeg/termostat",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleKoeleanlaegBC],
        title: "Termostat",
      },
      meta: {
        title: "Køb Termostat",
        description: "Termostat",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 21005,
    name: "Omrøremotor Dankøler",
    keywords: "omrører",
    imageSingle: "reservedele.omreremotor-dan-koeler.jpg",
    url: "/reservedele/koeleanlaeg/omroeremotor-dankoeler",
    categories: ["reservedele", "koeleanlaeg"],
    showOnlyPluralPrice: true,
    dontShowColli: true,
    routeDef: {
      path: "/reservedele/koeleanlaeg/omroeremotor-dankoeler",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleKoeleanlaegBC],
        title: "Omrøremotor Dankøler",
      },
      meta: {
        title: "Køb Omrøremotor Dankøler",
        description: "Omrøremotor Dankøler",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
    smallDescription: () => (
      <React.Fragment>
        <Paragraph fontSize="12px" marginTop="5px" marginBottom="0px">
          Betinger indbytning af den defekte / gamle omrører.
        </Paragraph>
        <Paragraph fontSize="12px" marginTop="5px" marginBottom="0px">
          2 - 3 dages leveringstid
        </Paragraph>
      </React.Fragment>
    ),
    description: () => (
      <React.Fragment>
        <Paragraph>
          Betinger indbytning af den defekte / gamle omrører.
        </Paragraph>
        <Paragraph>2 - 3 dages leveringstid</Paragraph>
      </React.Fragment>
    ),
    productTypeReservedel: true,
    postnord: true,
  },
  {
    varenr: 21010,
    name: "Omrøremotor Eventkøler",
    keywords: "omrører",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/koeleanlaeg/omroeremotor-eventkoeler",
    categories: ["reservedele", "koeleanlaeg"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/koeleanlaeg/omroeremotor-eventkoeler",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleKoeleanlaegBC],
        title: "Omrøremotor Eventkøler",
      },
      meta: {
        title: "Køb Omrøremotor Eventkøler",
        description: "Omrøremotor Eventkøler",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 21015,
    name: "Omrøremotor trope m cirk",
    keywords: "omrører",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/koeleanlaeg/omroeremotor-trope-m-cirk",
    categories: ["reservedele", "koeleanlaeg"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/koeleanlaeg/omroeremotor-trope-m-cirk",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleKoeleanlaegBC],
        title: "Omrøremotor trope m cirk",
      },
      meta: {
        title: "Køb Omrøremotor trope m cirk",
        description: "Omrøremotor trope m cirk",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },
  {
    varenr: 21020,
    name: "Omrøremotor C17",
    keywords: "omrører",
    productTypeReservedel: true,
    postnord: true,
    url: "/reservedele/koeleanlaeg/omroeremotor-c17",
    categories: ["reservedele", "koeleanlaeg"],
    showOnlyPluralPrice: true,
    routeDef: {
      path: "/reservedele/koeleanlaeg/omroeremotor-c17",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, ReservedeleBC, ReservedeleKoeleanlaegBC],
        title: "Omrøremotor C17",
      },
      meta: {
        title: "Køb Omrøremotor C17",
        description: "Omrøremotor C17",
      },
      props: {
        menuPicker: "Anlaeg",
        activeSidemenu: "Reservedele",
      },
    },
  },

  /* *
   *
   * Øl
   *
   * */

  /* *
   *
   * Dåseøl
   *
   * */
  {
    varenr: 3613,
    name: "Grøn Tuborg Dåse",
    generalSearchPriority: 500,
    oelType: "Pilsner",
    keywords:
      "alu aluminium dåse øl dåseøl dåser 33cl 33 cl 24x33cl 24x33 ramme",
    imageSingle: "produkter-oelvand.groen-tuborg-daase.png",
    imagePlural: "produkter-oelvand.3613-kasse.png",
    url: "/oel/daaseoel/groen-tuborg-daase",
    categories: ["oelcideralkohol", "daaseoel"],
    routeDef: {
      path: "/oel/daaseoel/groen-tuborg-daase",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, OelDaaseOelBC],
        title: "Grøn Tuborg Dåse",
      },
      meta: {
        title: "Køb Grøn Tuborg Dåse",
        description: "Grøn Tuborg Dåse",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Daaseoel",
      },
    },
  },
  {
    varenr: 2613,
    name: "Carlsberg Pilsner Dåse",
    generalSearchPriority: 500,
    oelType: "Pilsner",
    keywords:
      "dåseøl dåse øl alu aluminium dåser 33cl 33 cl 24x33cl 24x33 ramme hof",
    imageSingle: "produkter-oelvand.carlsberg-daase.png",
    imagePlural: "produkter-oelvand.2613-kasse.png",
    url: "/oel/daaseoel/carlsberg-pilsner-daase",
    categories: ["oelcideralkohol", "daaseoel"],
    routeDef: {
      path: "/oel/daaseoel/carlsberg-pilsner-daase",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, OelDaaseOelBC],
        title: "Carlsberg Pilsner Dåse",
      },
      meta: {
        title: "Køb Carlsberg Pilsner Dåse",
        description: "Carlsberg Pilsner Dåse",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Daaseoel",
      },
    },
  },
  {
    varenr: 3653,
    name: "Tuborg Classic Dåse",
    generalSearchPriority: 500,
    oelType: "Classic",
    keywords:
      "dåseøl dåse øl alu aluminium dåser 33cl 33 cl 24x33cl 24x33 ramme classis classisk klassisk clasic clasig classik",
    imageSingle: "produkter-oelvand.tuborg-classic-daase.png",
    imagePlural: "produkter-oelvand.3653-kasse.png",
    url: "/oel/daaseoel/tuborg-classic-daase",
    categories: ["oelcideralkohol", "daaseoel"],
    routeDef: {
      path: "/oel/daaseoel/tuborg-classic-daase",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, OelDaaseOelBC],
        title: "Tuborg Classic Dåse",
      },
      meta: {
        title: "Køb Tuborg Classic Dåse",
        description: "Tuborg Classic Dåse",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Daaseoel",
      },
    },
  },
  {
    varenr: 2653,
    name: "Carl's Special Dåse",
    generalSearchPriority: 500,
    oelType: "Classic",
    keywords:
      "carls dåseøl dåse øl alu aluminium dåser 33cl 33 cl 24x33cl 24x33 ramme classis classisk klassisk clasic clasig classik",
    imageSingle: "produkter-oelvand.carls-special-daase.png",
    imagePlural: "produkter-oelvand.2653-kasse.png",
    url: "/oel/daaseoel/carls-special-daase",
    categories: ["oelcideralkohol", "daaseoel"],
    routeDef: {
      path: "/oel/daaseoel/carls-special-daase",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, OelDaaseOelBC],
        title: "Carl's Special Dåse",
      },
      meta: {
        title: "Køb Carl's Special Dåse",
        description: "Carl's Special Dåse",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Daaseoel",
      },
    },
  },
  {
    varenr: 2683,
    name: "Carlsberg 1883 Dåse",
    generalSearchPriority: 500,
    oelType: "Mørk Pilsner",
    keywords:
      "dåseøl dåse øl alu aluminium dåser 33cl 33 cl 24x33cl 24x33 ramme",
    imageSingle: "produkter-oelvand.carlsberg-1883-daase.png",
    imagePlural: "produkter-oelvand.carlsberg-1883-daase-ramme.png",
    url: "/oel/daaseoel/carlsberg-1883-daase",
    categories: ["oelcideralkohol", "daaseoel"],
    routeDef: {
      path: "/oel/daaseoel/carlsberg-1883-daase",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, OelDaaseOelBC],
        title: "Carlsberg 1883 Dåse",
      },
      meta: {
        title: "Køb Carlsberg 1883 Dåse",
        description: "Carlsberg 1883 Dåse",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Daaseoel",
      },
    },
  },
  {
    varenr: 3753,
    name: "Tuborg Julebryg Dåse",
    generalSearchPriority: 500,
    oelType: "Juleøl / Mørk Pilsner",
    keywords:
      "julebryg juleøl dåseøl dåse øl alu aluminium dåser 33cl 33 cl 20x33cl 20x33 ramme sneøl sne julbryg j-dag j dag",
    imageSingle: "produkter-oelvand.tuborg-julebryg-daase.png",
    imagePlural: "produkter-oelvand.3753-kasse.png",
    url: "/oel/daaseoel/tuborg-julebryg-daase",
    categories: ["oelcideralkohol", "daaseoel"],
    routeDef: {
      path: "/oel/daaseoel/tuborg-julebryg-daase",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, OelDaaseOelBC],
        title: "Tuborg Julebryg Dåse",
      },
      meta: {
        title: "Køb Tuborg Julebryg Dåse",
        description: "Tuborg Julebryg Dåse",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Daaseoel",
      },
    },
    smallDescription: () => (
      <React.Fragment>
        <Span blue block paddingTop="5px">
          Kan leveres fra <Datoer type="julebryg" />
        </Span>
      </React.Fragment>
    ),
    description: () => (
      <Paragraph blue>
        Kan leveres fra <Datoer type="julebryg" />
      </Paragraph>
    ),
    inStockText: () => (
      <React.Fragment>
        Udsolgt. Kommer igen <Datoer type="julebrygNextYear" />
      </React.Fragment>
    ),
  },

  /* *
   *
   * Flaskeøl
   *
   * */

  /* *
   *
   * Pilsner
   *
   * */
  {
    varenr: 2610,
    name: "Carlsberg Pilsner",
    generalSearchPriority: 500,
    oelType: "Pilsner",
    keywords:
      "flaskeøl øl flasker glasflasker 33cl 33 cl 24x33cl 24x33 papkasser hof",
    imageSingle: "produkter-oelvand.carlsberg-pilsner-flaske.png",
    imagePlural: "produkter-oelvand.2610-kasse.png",
    url: "/oel/flaskeoel/carlsberg-pilsner",
    categories: ["oelcideralkohol", "flaskeoel", "pilsner"],
    routeDef: {
      path: "/oel/flaskeoel/carlsberg-pilsner",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Carlsberg Pilsner",
      },
      meta: {
        title: "Køb Carlsberg Pilsner",
        description: "Carlsberg Pilsner",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },
  {
    varenr: 23110,
    name: "Corona Extra",
    generalSearchPriority: 500,
    oelType: "Pilsner",
    keywords:
      "flaskeøl øl flasker glasflasker 35,5cl 35,5 cl 24x35,5cl 24x35,5 papkasser",
    imageSingle: "produkter-oelvand.corona-extra-flaske.png",
    imagePlural: "produkter-oelvand.8610-kasse.png",
    url: "/oel/flaskeoel/corona-extra",
    categories: ["oelcideralkohol", "flaskeoel", "pilsner"],
    routeDef: {
      path: "/oel/flaskeoel/corona-extra",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Corona Extra",
      },
      meta: {
        title: "Køb Corona Extra",
        description: "Corona Extra",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },
  {
    varenr: 3610,
    name: "Grøn Tuborg",
    generalSearchPriority: 500,
    oelType: "Pilsner",
    keywords: "flaskeøl øl flasker glasflasker 33cl 33 cl 30x33cl 30x33 kasse",
    imageSingle: "produkter-oelvand.groen-tuborg-flaske.png",
    imagePlural: "produkter-oelvand.3610-kasse.png",
    url: "/oel/flaskeoel/groen-tuborg",
    categories: ["oelcideralkohol", "flaskeoel", "pilsner"],
    routeDef: {
      path: "/oel/flaskeoel/groen-tuborg",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Grøn Tuborg",
      },
      meta: {
        title: "Køb Grøn Tuborg",
        description: "Grøn Tuborg",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },
  {
    varenr: 8310,
    name: "Hancock Økologisk Pils",
    generalSearchPriority: 500,
    oelType: "Pilsner",
    keywords:
      "hancook handcok hancoock hanckock hankock handcocl hancok hankoch handcock hankok flaskeøl øl flasker glasflasker 33cl 33 cl 24x33cl 24x33 papkasser økologiske",
    imageSingle: "produkter-oelvand.hancock-oeko-pils-1.png",
    imagePlural: "produkter-oelvand.hancock-oekologisk-pils-kasse.png",
    url: "/oel/flaskeoel/hancock-oekologisk-pils",
    categories: ["oelcideralkohol", "flaskeoel", "pilsner"],
    routeDef: {
      path: "/oel/flaskeoel/hancock-oekologisk-pils",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Hancock Økologisk Pils",
      },
      meta: {
        title: "Køb Hancock Økologisk Pils",
        description: "Hancock Økologisk Pils",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },
  {
    varenr: 4910,
    name: "Heineken",
    oelType: "Pilsner",
    keywords:
      "haineken hainiken hainikin Heiniken hiniken haniken heniken hiniken flaskeøl øl flasker glasflasker 33cl 33 cl 24x33cl 24x33 papkasser",
    imageSingle: "produkter-oelvand.heineken-flaske.png",
    imagePlural: "produkter-oelvand.heineken-papkasse-2023.png",
    url: "/oel/flaskeoel/heineken",
    categories: ["oelcideralkohol", "flaskeoel", "pilsner"],
    routeDef: {
      path: "/oel/flaskeoel/heineken",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Heineken",
      },
      meta: {
        title: "Køb Heineken",
        description: "Heineken",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },
  {
    varenr: 4900,
    name: "Heineken Silver",
    generalSearchPriority: 500,
    oelType: "Pilsner",
    keywords:
      "flaskeøl øl flasker glasflasker 33cl 33 cl 24x33cl 24x33 papkasser haineken hainiken hainikin Heiniken hiniken haniken heniken hiniken",
    imageSingle: "produkter-oelvand.heineken-silver-flaske.png",
    imagePlural: "produkter-oelvand.heineken-silver-kasse.png",
    url: "/oel/flaskeoel/heineken-silver",
    categories: ["oelcideralkohol", "flaskeoel", "pilsner"],
    routeDef: {
      path: "/oel/flaskeoel/heineken-silver",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Heineken Silver",
      },
      meta: {
        title: "Køb Heineken Silver",
        description: "Heineken Silver",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },
  {
    varenr: 8770,
    name: "Kronenbourg 1664",
    generalSearchPriority: 500,
    oelType: "Pilsner",
    keywords:
      "flaskeøl øl flasker glasflasker 33cl 33 cl 24x33cl 24x33 papkasser kronenburg blank kronenberg kronborg 1669 1764 1864 1686",
    imageSingle: "produkter-oelvand.kronenbourg-1664-flaske.png",
    imagePlural: "produkter-oelvand.8770-kasse.png",
    url: "/oel/flaskeoel/kronenbourg-1664",
    categories: ["oelcideralkohol", "flaskeoel", "pilsner"],
    routeDef: {
      path: "/oel/flaskeoel/kronenbourg-1664",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Kronenbourg 1664",
      },
      meta: {
        title: "Køb Kronenbourg 1664",
        description: "Kronenbourg 1664",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },
  {
    varenr: 4630,
    name: "Royal Øko Pilsner",
    oelType: "Pilsner",
    keywords:
      "økologisk flaskeøl øl flasker glasflasker 33cl 33 cl 30x33cl 30x33 kasse økologisk økologiske",
    imageSingle: "produkter-oelvand.royal-oeko-pilsner-flaske.png",
    imagePlural: "produkter-oelvand.4630-kasse.png",
    url: "/oel/flaskeoel/royal-oeko-pilsner",
    categories: ["oelcideralkohol", "flaskeoel", "pilsner"],
    routeDef: {
      path: "/oel/flaskeoel/royal-oeko-pilsner",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Royal Øko Pilsner",
      },
      meta: {
        title: "Køb Royal Øko Pilsner",
        description: "Royal Øko Pilsner",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },
  {
    varenr: 1670,
    name: "Thy Øko Humle",
    oelType: "Pilsner",
    keywords:
      "thisted bryghus økologisk flaskeøl øl flasker glasflasker 33cl 33 cl 30x33cl 30x33 kasse økologisk økologiske",
    imageSingle: "produkter-oelvand.thy-oeko-humle-flaske.png",
    imagePlural: "produkter-oelvand.thy-kasse.png",
    url: "/oel/flaskeoel/thy-oeko-humle",
    categories: ["oelcideralkohol", "flaskeoel", "pilsner"],
    routeDef: {
      path: "/oel/flaskeoel/thy-oeko-humle",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Thy Øko Humle",
      },
      meta: {
        title: "Køb Thy Øko Humle",
        description: "Thy Øko Humle",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },
  {
    varenr: 1630,
    name: "Thy Øko Pilsner",
    generalSearchPriority: 500,
    oelType: "Pilsner",
    keywords:
      "thisted bryghus økologisk flaskeøl øl flasker glasflasker 33cl 33 cl 30x33cl 30x33 kasse økologisk økologiske",
    imageSingle: "produkter-oelvand.thy-oeko-pilsner-flaske.png",
    imagePlural: "produkter-oelvand.thy-kasse.png",
    url: "/oel/flaskeoel/thy-oeko-pilsner",
    categories: ["oelcideralkohol", "flaskeoel", "pilsner"],
    routeDef: {
      path: "/oel/flaskeoel/thy-oeko-pilsner",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Thy Øko Pilsner",
      },
      meta: {
        title: "Køb Thy Øko Pilsner",
        description: "Thy Øko Pilsner",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },
  {
    varenr: 8570,
    name: "Tiger Beer",
    generalSearchPriority: 500,
    oelType: "Pilsner",
    keywords:
      "flaskeøl øl flasker glasflasker 33cl 33 cl 24x33cl 24x33 papkasser",
    imageSingle: "produkter-oelvand.tiger-flaske.png",
    imagePlural: "produkter-oelvand.8570-kasse.png",
    url: "/oel/flaskeoel/tiger-beer",
    categories: ["oelcideralkohol", "flaskeoel", "pilsner"],
    routeDef: {
      path: "/oel/flaskeoel/tiger-beer",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Tiger Beer",
      },
      meta: {
        title: "Køb Tiger Beer",
        description: "Tiger Beer",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },

  /* *
   *
   * Stærk Pilsner
   *
   * */
  {
    varenr: 2710,
    name: "Elephant",
    generalSearchPriority: 500,
    oelType: "Stærk Pilsner",
    keywords:
      "elefant elefantøl flaskeøl øl flasker glasflasker 33cl 33 cl 30x33cl 30x33 kasse",
    imageSingle: "produkter-oelvand.carlsberg-elephant-flaske.png",
    imagePlural: "produkter-oelvand.2710-kasse.png",
    url: "/oel/flaskeoel/elephant",
    categories: ["oelcideralkohol", "flaskeoel", "staerkPilsner"],
    routeDef: {
      path: "/oel/flaskeoel/elephant",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Elephant",
      },
      meta: {
        title: "Køb Elephant",
        description: "Elephant",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },
  {
    varenr: 3710,
    name: "Fine Festival",
    generalSearchPriority: 500,
    oelType: "Stærk Pilsner",
    keywords: "flaskeøl øl flasker glasflasker 33cl 33 cl 30x33cl 30x33 kasse",
    imageSingle: "produkter-oelvand.tuborg-fine-festival-flaske.png",
    url: "/oel/flaskeoel/fine-festival",
    categories: ["oelcideralkohol", "flaskeoel", "staerkPilsner"],
    routeDef: {
      path: "/oel/flaskeoel/fine-festival",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Fine Festival",
      },
      meta: {
        title: "Køb Fine Festival",
        description: "Fine Festival",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },
  {
    varenr: 3670,
    name: "Guld Tuborg",
    generalSearchPriority: 500,
    oelType: "Stærk Pilsner",
    keywords:
      "flaskeøl øl flasker glasflasker 33cl 33 cl 30x33cl 30x33 kasse gyldne dame damer gyldnedamer guldtuborg",
    imageSingle: "produkter-oelvand.guld-tuborg-flaske.png",
    imagePlural: "produkter-oelvand.3670-kasse.png",
    url: "/oel/flaskeoel/guld-tuborg",
    categories: ["oelcideralkohol", "flaskeoel", "staerkPilsner"],
    routeDef: {
      path: "/oel/flaskeoel/guld-tuborg",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Guld Tuborg",
      },
      meta: {
        title: "Køb Guld Tuborg",
        description: "Guld Tuborg",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },
  {
    varenr: 3790,
    name: "Tuborg Påskebryg",
    generalSearchPriority: 500,
    oelType: "Stærk Pilsner",
    keywords:
      "flaskeøl øl flasker glasflasker 33cl 33 cl 30x33cl 30x33 kasse kylle kylle påskeøl",
    imageSingle: "produkter-oelvand.tuborg-paaskebryg-flaske.png",
    imagePlural: "produkter-oelvand.3790-kasse.png",
    inStockText: () => (
      <React.Fragment>
        Udsolgt. Kommer igen <Datoer type="paaskebryg" />
      </React.Fragment>
    ),
    url: "/oel/flaskeoel/tuborg-poaaskebryg",
    categories: ["oelcideralkohol", "flaskeoel", "staerkPilsner"],
    routeDef: {
      path: "/oel/flaskeoel/tuborg-poaaskebryg",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Tuborg Påskebryg",
      },
      meta: {
        title: "Køb Tuborg Påskebryg",
        description: "Tuborg Påskebryg",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },

  /* *
   *
   * Classic
   *
   * */
  {
    varenr: 2630,
    name: "Carlsberg 1883",
    generalSearchPriority: 500,
    oelType: "Classic",
    keywords:
      "gammel gamle carlsberg lager flaskeøl øl flasker glasflasker 33cl 33 cl 30x33cl 30x33 kasse classis classisk klassisk clasic clasig classik",
    imageSingle: "produkter-oelvand.carlsberg-1883-flaske.png",
    imagePlural: "produkter-oelvand.carlsberg-1883-33cl-kasse.png",
    popupDescription: () => (
      <React.Fragment>
        Øllen &quot;Gamle Carlsberg&quot; blev i 2018 erstattet af Carlsberg
        1883.
        <br />
        <br />
        Tilbage i 2016 kom øllen Rebrew ud af Carlsbergs bryghus i en begrænset
        produktion. Denne øl var var brygget på den gamle gær fra 1883.
        Carlsberg bryggerierne har nu lanceret Carlsberg 1883, som er en udsøgt
        lagerøl brygget på den original gær og opskrift fra 1883.
      </React.Fragment>
    ),
    url: "/oel/flaskeoel/carlsberg-1883",
    categories: ["oelcideralkohol", "flaskeoel", "classic"],
    routeDef: {
      path: "/oel/flaskeoel/carlsberg-1883",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Carlsberg 1883",
      },
      meta: {
        title: "Køb Carlsberg 1883",
        description: "Carlsberg 1883",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },
  {
    varenr: 4650,
    name: "Royal Classic",
    generalSearchPriority: 500,
    oelType: "Classic",
    keywords:
      "flaskeøl øl flasker glasflasker 33cl 33 cl 30x33cl 30x33 kasse classis classisk klassisk clasic clasig classik",
    imageSingle: "produkter-oelvand.royal-classic-33cl-flaske.png",
    // imagePlural: 'produkter-oelvand.royal-classic-33cl-kasse.png',
    url: "/oel/flaskeoel/royal-classic",
    categories: ["oelcideralkohol", "flaskeoel", "classic"],
    routeDef: {
      path: "/oel/flaskeoel/royal-classic",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Royal Classic",
      },
      meta: {
        title: "Køb Royal Classic",
        description: "Royal Classic",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },
  {
    varenr: 3630,
    name: "Rød Tuborg",
    generalSearchPriority: 500,
    oelType: "Classic",
    keywords:
      "flaskeøl øl flasker glasflasker 33cl 33 cl 30x33cl 30x33 kasse rød tuborg röd classis classisk klassisk clasic clasig classik",
    imageSingle: "produkter-oelvand.roed-tuborg-flaske.png",
    imagePlural: "produkter-oelvand.roed-tuborg-kasse.png",
    inStockText: () => (
      <React.Fragment>
        Udsolgt. Kommer igen <Datoer type="roedtuborg" />.
      </React.Fragment>
    ),
    // seasonText: '01 maj 2019',
    // inStockText: () => 'Udsolgt. Kommer igen i maj.',
    url: "/oel/flaskeoel/roed-tuborg",
    categories: ["oelcideralkohol", "flaskeoel", "classic"],
    routeDef: {
      path: "/oel/flaskeoel/roed-tuborg",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Rød Tuborg",
      },
      meta: {
        title: "Køb Rød Tuborg",
        description: "Rød Tuborg",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
    smallDescription: () => (
      <React.Fragment>
        <Span blue block fontSize="13px" paddingTop="5px">
          Kan leveres fra <Datoer type="roedtuborg" />
        </Span>
      </React.Fragment>
    ),
    description: () => (
      <Paragraph blue fontSize="13px">
        Kan leveres fra <Datoer type="roedtuborg" />
      </Paragraph>
    ),
  },
  {
    varenr: 1640,
    name: "Thy Øko Classic",
    generalSearchPriority: 500,
    oelType: "Classic",
    keywords:
      "thisted bryghus økologisk flaskeøl øl flasker glasflasker 33cl 33 cl 30x33cl 30x33 kasse økologisk økologiske classis classisk klassisk clasic clasig classik",
    imageSingle: "produkter-oelvand.thy-oeko-classic-flaske.png",
    imagePlural: "produkter-oelvand.thy-kasse.png",
    url: "/oel/flaskeoel/thy-oeko-classic",
    categories: ["oelcideralkohol", "flaskeoel", "classic"],
    routeDef: {
      path: "/oel/flaskeoel/thy-oeko-classic",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Thy Øko Classic",
      },
      meta: {
        title: "Køb Thy Øko Classic",
        description: "Thy Øko Classic",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },
  {
    varenr: 3650,
    name: "Tuborg Classic",
    generalSearchPriority: 500,
    oelType: "Classic",
    keywords:
      "flaskeøl øl flasker glasflasker 33cl 33 cl 30x33cl 30x33 kasse classis classisk klassisk clasic clasig classik",
    imageSingle: "produkter-oelvand.tuborg-classic-flaske.png",
    imagePlural: "produkter-oelvand.3650-kasse.png",
    url: "/oel/flaskeoel/tuborg-classic",
    categories: ["oelcideralkohol", "flaskeoel", "classic"],
    routeDef: {
      path: "/oel/flaskeoel/tuborg-classic",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Tuborg Classic",
      },
      meta: {
        title: "Køb Tuborg Classic",
        description: "Tuborg Classic",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },

  /* *
   *
   * Hvedeøl
   *
   * */
  {
    varenr: 8690,
    name: "Erdinger Dunkel",
    generalSearchPriority: 500,
    oelType: "Hvedeøl",
    keywords:
      "flaskeøl øl flasker glasflasker 50cl 50 cl 12x50cl 12x50 papkasser",
    imageSingle: "produkter-oelvand.erdinger-dunkel-flaske.png",
    imagePlural: "produkter-oelvand.erdinger-dunkel-kasse.png",
    url: "/oel/flaskeoel/hvedeoel/erdinger-dunkel",
    categories: ["oelcideralkohol", "flaskeoel", "hvedeoel"],
    routeDef: {
      path: "/oel/flaskeoel/hvedeoel/erdinger-dunkel",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Erdinger Dunkel",
      },
      meta: {
        title: "Køb Erdinger Dunkel",
        description: "Erdinger Dunkel",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },
  {
    varenr: 8760,
    name: "Erdinger Hefe",
    generalSearchPriority: 500,
    oelType: "Hvedeøl",
    keywords:
      "flaskeøl øl flasker glasflasker 50cl 50 cl 12x50cl 12x50 papkasser weisbier weisbeer weissbier",
    imageSingle: "produkter-oelvand.erdinger-hefe-flaske.png",
    imagePlural: "produkter-oelvand.erdinger-weissbier-kasse.png",
    url: "/oel/flaskeoel/hvedeoel/erdinger-hefe",
    categories: ["oelcideralkohol", "flaskeoel", "hvedeoel"],
    routeDef: {
      path: "/oel/flaskeoel/hvedeoel/erdinger-hefe",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Erdinger Hefe",
      },
      meta: {
        title: "Køb Erdinger Hefe",
        description: "Erdinger Hefe",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },
  {
    varenr: 8670,
    name: "Hoegaarden",
    generalSearchPriority: 500,
    oelType: "Hvedeøl",
    keywords:
      "flaskeøl øl flasker glasflasker 33cl 33 cl 24x33cl 24x33 papkasser hoegarten hoegarden hogaarden hogarden",
    imageSingle: "produkter-oelvand.hoegaarden-flaske.png",
    imagePlural: "produkter-oelvand.8670-kasse.png",
    url: "/oel/flaskeoel/hvedeoel/hoegaarden",
    categories: ["oelcideralkohol", "flaskeoel", "hvedeoel"],
    routeDef: {
      path: "/oel/flaskeoel/hvedeoel/hoegaarden",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Hoegaarden",
      },
      meta: {
        title: "Køb Hoegaarden",
        description: "Hoegaarden",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },
  {
    varenr: 2480,
    name: "Kronenbourg 1664 Blanc",
    generalSearchPriority: 500,
    oelType: "Hvedeøl",
    keywords:
      "flaskeøl øl flasker glasflasker 33cl 33 cl 24x33cl 24x33 papkasser kronenburg blank kronenberg kronborg 1669 1764 1864 1686",
    imageSingle: "produkter-oelvand.kronenbourg-1664-blanc-flaske.png",
    imagePlural: "produkter-oelvand.kronenbourg-1664-blanc-kasse-ny.png",
    url: "/oel/flaskeoel/hvedeoel/kronenbourg-1664-blanc",
    categories: ["oelcideralkohol", "flaskeoel", "hvedeoel"],
    routeDef: {
      path: "/oel/flaskeoel/hvedeoel/kronenbourg-1664-blanc",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Kronenbourg 1664 Blanc",
      },
      meta: {
        title: "Køb Kronenbourg 1664 Blanc",
        description: "Kronenbourg 1664 Blanc",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },

  /* *
   *
   * Lys Ale
   *
   * */
  {
    varenr: 8700,
    name: "Leffe Blond",
    generalSearchPriority: 500,
    oelType: "Lys Ale",
    keywords:
      "flaskeøl øl flasker glasflasker 33cl 33 cl 24x33cl 24x33 papkasser blonde",
    imageSingle: "produkter-oelvand.leffe-blond-flaske.png",
    imagePlural: "produkter-oelvand.8700-kasse.png",
    url: "/oel/flaskeoel/lys-ale/leffe-blond",
    categories: ["oelcideralkohol", "flaskeoel", "lysale"],
    routeDef: {
      path: "/oel/flaskeoel/lys-ale/leffe-blond",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Leffe Blond",
      },
      meta: {
        title: "Køb Leffe Blond",
        description: "Leffe Blond",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },
  {
    varenr: 8840,
    name: "Grimbergen Blonde",
    generalSearchPriority: 500,
    oelType: "Lys Ale",
    keywords:
      "flaskeøl øl flasker glasflasker 33cl 33 cl 24x33cl 24x33 papkasser blonde grimberger grinbergen grinberger grenberg grenberger grimbergem grindberg grimbregen grindbergen",
    imageSingle: "produkter-oelvand.grimbergen-blonde-flaske.png",
    imagePlural: "produkter-oelvand.grimbergen-blonde-kasse.png",
    url: "/oel/flaskeoel/lys-ale/grimbergen-blonde",
    categories: ["oelcideralkohol", "flaskeoel", "lysale"],
    routeDef: {
      path: "/oel/flaskeoel/lys-ale/grimbergen-blonde",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Grimbergen Blonde",
      },
      meta: {
        title: "Køb Grimbergen Blonde",
        description: "Grimbergen Blonde",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },
  {
    varenr: 8532,
    name: "Skovlyst Humlebryg IPA",
    generalSearchPriority: 500,
    oelType: "IPA",
    keywords:
      "flaskeøl øl flasker glasflasker 50cl 50 cl 15x50cl 15x50 papkasser",
    imageSingle: "produkter-oelvand.skovlyst-humlebryg-50cl-flaske.png",
    // imagePlural: "produkter-oelvand.8700-kasse.png",
    url: "/oel/flaskeoel/ipa/skovlyst-humlebryg-ipa",
    categories: ["oelcideralkohol", "flaskeoel", "lysale"],
    routeDef: {
      path: "/oel/flaskeoel/ipa/skovlyst-humlebryg-ipa",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Skovlyst Humlebryg IPA",
      },
      meta: {
        title: "Køb Skovlyst Humlebryg IPA",
        description: "Skovlyst Humlebryg IPA",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },

  /* *
   *
   * Mørk Ale
   *
   * */

  {
    varenr: 8720,
    name: "Leffe Brune",
    generalSearchPriority: 500,
    oelType: "Mørk Ale",
    keywords:
      "flaskeøl øl flasker glasflasker 33cl 33 cl 24x33cl 24x33 papkasser",
    imageSingle: "produkter-oelvand.leffe-brune-flaske.png",
    imagePlural: "produkter-oelvand.8720-kasse.png",
    url: "/oel/flaskeoel/moerk-ale/leffe-brune",
    categories: ["oelcideralkohol", "flaskeoel", "moerkale"],
    routeDef: {
      path: "/oel/flaskeoel/moerk-ale/leffe-brune",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Leffe Brune",
      },
      meta: {
        title: "Køb Leffe Brune",
        description: "Leffe Brune",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },
  {
    varenr: 8780,
    name: "Grimbergen Double Ambrée",
    generalSearchPriority: 500,
    oelType: "Mørk Ale",
    keywords:
      "flaskeøl øl flasker glasflasker 33cl 33 cl 24x33cl 24x33 papkasser ambre ambree grimberger grinbergen grinberger grenberg grenberger grimbergem grindberg grimbregen grindbergen",
    imageSingle: "produkter-oelvand.grimbergen-double-ambree-flaske.png",
    imagePlural: "produkter-oelvand.grimbergen-double-ambree-kasse.png",
    url: "/oel/flaskeoel/moerk-ale/grimbergen-double-ambree",
    categories: ["oelcideralkohol", "flaskeoel", "moerkale"],
    routeDef: {
      path: "/oel/flaskeoel/moerk-ale/grimbergen-double-ambree",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Grimbergen Double Ambrée",
      },
      meta: {
        title: "Køb Grimbergen Double Ambrée",
        description: "Grimbergen Double Ambrée",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },

  /* *
   *
   * Brown Ale
   *
   * */
  {
    varenr: 8820,
    name: "Jacobsen Brown Ale",
    generalSearchPriority: 500,
    oelType: "Brown Ale",
    keywords:
      "flaskeøl øl flasker glasflasker 33cl 33 cl 24x33cl 24x33 papkasser jakobsen",
    imageSingle: "produkter-oelvand.jacobsen-brown-ale-flaske.png",
    imagePlural: "produkter-oelvand.8870-kasse.png",
    url: "/oel/flaskeoel/brown-ale/jacobsen-brown-ale",
    categories: ["oelcideralkohol", "flaskeoel", "brownale"],
    routeDef: {
      path: "/oel/flaskeoel/brown-ale/jacobsen-brown-ale",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Jacobsen Brown Ale",
      },
      meta: {
        title: "Køb Jacobsen Brown Ale",
        description: "Jacobsen Brown Ale",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },
  {
    varenr: 8262,
    name: "Skovlyst Bøgebryg",
    oelType: "Brown Ale",
    keywords:
      "flaskeøl øl flasker glasflasker 50cl 50 cl 15x50cl 15x50 papkasser",
    imageSingle: "produkter-oelvand.skovlyst-boegebryg-flaske.png",
    imagePlural: "produkter-oelvand.skovlyst-boegebryg-kasse.png",
    url: "/oel/flaskeoel/brown-ale/skovlyst-boegebryg",
    categories: ["oelcideralkohol", "flaskeoel", "brownale"],
    routeDef: {
      path: "/oel/flaskeoel/brown-ale/skovlyst-boegebryg",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Skovlyst Bøgebryg",
      },
      meta: {
        title: "Køb Skovlyst Bøgebryg",
        description: "Skovlyst Bøgebryg",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },
  {
    varenr: 12340,
    name: "Ale no. 16",
    generalSearchPriority: 500,
    oelType: "Brown Ale",
    keywords:
      "flaskeøl ale øl flasker glasflasker 33cl 33 cl 30x33cl 30x33 kasse nr 16 nr. no16 ale16 aleno16 nummer no.16 nr.16 refsvindinge refvindinge vestfyn vestfyen",
    imageSingle: "produkter-oelvand.refsvindinge-ale-16-flaske.png",
    imagePlural: "produkter-oelvand.8340-kasse.png",
    url: "/oel/flaskeoel/brown-ale/refsvindinge-ale-no-16",
    categories: ["oelcideralkohol", "flaskeoel", "brownale"],
    routeDef: {
      path: "/oel/flaskeoel/brown-ale/refsvindinge-ale-no-16",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Refsvindinge Ale no. 16",
      },
      meta: {
        title: "Køb Refsvindinge Ale no. 16",
        description: "Refsvindinge Ale no. 16",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },

  /* *
   *
   * Porter | Stout
   *
   * */
  {
    varenr: 2730,
    name: "Gl. Carlsberg Porter",
    generalSearchPriority: 500,
    oelType: "Porter",
    keywords:
      "gammel flaskeøl øl flasker glasflasker 33cl 33 cl 30x33cl 30x33 kasse",
    imageSingle: "produkter-oelvand.gl-carlsberg-porter-flaske.png",
    imagePlural: "produkter-oelvand.2730-kasse.png",
    url: "/oel/flaskeoel/porter-stout/gl-carlsberg-porter",
    categories: ["oelcideralkohol", "flaskeoel", "porter"],
    routeDef: {
      path: "/oel/flaskeoel/porter-stout/gl-carlsberg-porter",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Gl. Carlsberg Porter",
      },
      meta: {
        title: "Køb Gl. Carlsberg Porter",
        description: "Gl. Carlsberg Porter",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },

  /* *
   *
   * Juleøl
   *
   * */
  {
    varenr: 8252,
    name: "Skovlyst Julebryg",
    generalSearchPriority: 500,
    oelType: "Juleøl / Mørk Ale",
    keywords:
      "julebryg juleøl flaskeøl øl flasker glasflasker 50cl 50 cl 15x50cl 15x50 papkasser",
    imageSingle: "produkter-oelvand.skovlyst-julebryg-flaske.png",
    imagePlural: "produkter-oelvand.skovlyst-julebryg-kasse.png",
    inStockText: () => (
      <React.Fragment>
        Udsolgt. Kommer igen <Datoer type="julebrygNextYear" />
      </React.Fragment>
    ),
    url: "/oel/flaskeoel/juleoel/skovlyst-julebryg",
    categories: ["oelcideralkohol", "flaskeoel", "juleoel"],
    routeDef: {
      path: "/oel/flaskeoel/juleoel/skovlyst-julebryg",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Skovlyst Julebryg",
      },
      meta: {
        title: "Køb Skovlyst Julebryg",
        description: "Skovlyst Julebryg",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },
  {
    varenr: 3750,
    name: "Tuborg Julebryg",
    generalSearchPriority: 500,
    oelType: "Juleøl / Mørk Pilsner",
    keywords:
      "julebryg juleøl flaskeøl øl flasker glasflasker 33cl 33 cl 30x33cl 30x33 kasse sneøl sne j-dag j dag",
    imageSingle: "produkter-oelvand.tuborg-julebryg-flaske.png",
    imagePlural: "produkter-oelvand.3770-kasse.png",
    inStockText: () => (
      <React.Fragment>
        Udsolgt. Kommer igen <Datoer type="julebrygNextYear" />
      </React.Fragment>
    ),
    smallDescription: () => (
      <React.Fragment>
        <Span blue block fontSize="13px" paddingTop="5px">
          Kan leveres fra <Datoer type="julebryg" />
        </Span>
      </React.Fragment>
    ),
    description: () => (
      <Span blue fontSize="13px">
        Kan leveres fra <Datoer type="julebryg" />
      </Span>
    ),
    url: "/oel/flaskeoel/juleoel/tuborg-julebryg",
    categories: ["oelcideralkohol", "flaskeoel", "juleoel"],
    routeDef: {
      path: "/oel/flaskeoel/juleoel/tuborg-julebryg",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: "Tuborg Julebryg",
      },
      meta: {
        title: "Køb Tuborg Julebryg",
        description: "Tuborg Julebryg",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Flaskeoel",
      },
    },
  },

  /* *
   *
   * Alkoholfri Øl
   *
   * */
  {
    varenr: 2550,
    name: "Carlsberg Nordic Pilsner 0,0%",
    showAlcPct: true,
    generalSearchPriority: 500,
    keywords:
      "letøl alkoholfri frokostøl alkoholdfri alkolholfri alkoholfrit øl uden let light ølight øllight light øl new flaskeøl øl flasker glasflasker 33cl 33 cl 24x33cl 24x33 papkasser",
    imageSingle: "produkter-oelvand.carlsberg-nordic-flaske.png",
    imagePlural: "produkter-oelvand.2550-kasse.png",
    url: "/oel/alkoholfri-oel/carlsberg-nordic-pilsner",
    categories: ["oelcideralkohol", "alkoholfrioel"],
    routeDef: {
      path: "/oel/alkoholfri-oel/carlsberg-nordic-pilsner",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholFriOelBC],
        title: "Carlsberg Nordic Pilsner 0,0%",
      },
      meta: {
        title: "Køb Carlsberg Nordic Pilsner 0,0%",
        description: "Carlsberg Nordic Pilsner 0,0%",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Alkoholfri",
      },
    },
  },
  {
    varenr: 2490,
    name: "Kronenbourg 1664 Blanc 0,0%",
    showAlcPct: true,
    generalSearchPriority: 500,
    keywords:
      "letøl alkoholfri frokostøl alkoholdfri alkolholfri alkoholfrit øl uden let light ølight øllight light øl new flaskeøl øl flasker glasflasker 33cl 33 cl 24x33cl 24x33 papkasser",
    imageSingle: "produkter-oelvand.kronenbourg-1664-blanc-00.png",
    imagePlural: "produkter-oelvand.kronenbourg-1664-blanc-00-kasse.png",
    url: "/oel/alkoholfri-oel/kronenbourg-1664-blanc-00",
    categories: ["oelcideralkohol", "alkoholfrioel"],
    routeDef: {
      path: "/oel/alkoholfri-oel/kronenbourg-1664-blanc-00",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholFriOelBC],
        title: "Kronenbourg 1664 Blanc 0,0%",
      },
      meta: {
        title: "Køb Kronenbourg 1664 Blanc 0,0%",
        description: "Kronenbourg 1664 Blanc 0,0%",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Alkoholfri",
      },
    },
  },
  {
    varenr: 8650,
    name: "Heineken 0,0 %",
    showAlcPct: true,
    generalSearchPriority: 500,
    keywords:
      "letøl alkoholfri frokostøl alkoholdfri alkoholfrit øl uden let light ølight øllight light øl haineken hainiken hainikin Heiniken hiniken haniken heniken hiniken øl flasker glasflasker 33cl 33 cl 24x33cl 24x33 papkasser",
    imageSingle: "produkter-oelvand.8650-flaske.png",
    imagePlural: "produkter-oelvand.heineken-00-kasse.png",
    url: "/oel/alkoholfri-oel/heineken-00",
    categories: ["oelcideralkohol", "alkoholfrioel"],
    routeDef: {
      path: "/oel/alkoholfri-oel/heineken-00",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholFriOelBC],
        title: "Heineken 0,0 %",
      },
      meta: {
        title: "Køb Heineken 0,0 %",
        description: "Heineken 0,0 %",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Alkoholfri",
      },
    },
  },
  {
    varenr: 8212,
    name: "Skovlyst Ellebryg 0,5%",
    generalSearchPriority: 500,
    keywords:
      "letøl alkoholfri frokostøl alkoholdfri alkolholfri alkoholfrit let light ølight øllight light øl flaskeøl øl flasker glasflasker 50cl 50 cl 12x50cl 12x50 papkasser",
    imageSingle: "produkter-oelvand.skovlyst-ellebryg-50cl-flaske.png",
    imagePlural: "produkter-oelvand.skovlyst-ellebryg-kasse.png",
    url: "/oel/alkoholfri-oel/skovlyst-ellebryg",
    categories: ["oelcideralkohol", "alkoholfrioel"],
    routeDef: {
      path: "/oel/alkoholfri-oel/skovlyst-ellebryg",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholFriOelBC],
        title: "Skovlyst Ellebryg 0,5%",
      },
      meta: {
        title:
          "Skovlyst Ellebryg 0,5% - Prisvindende Alkoholfri Ale | Bedst i Test 2019",
        description:
          "Opdag Skovlyst Ellebryg, den mørkgyldne alkoholfri ale, kåret som nr. 1 af forbrugerrådet TÆNK i 2019. Brygget på det familieejede Egger-bryggeri, tilbyder denne alkoholfri ale en rig og smagsfuld oplevelse, inspireret af elletræets styrke. Nyd en unik øloplevelse uden alkohol, men med fuld smag.",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Alkoholfri",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading2>
          Skovlyst Ellebryg 0,5%: En Alkoholfri Ale, Der Skilller Sig Ud
        </Heading2>
        <Paragraph>
          Skovlyst Ellebryg 0,5% er en enestående alkoholfri ale, der skiller
          sig ud med sin dybe, mørkgyldne farve og rig smagsprofil. Denne
          prisvindende øl, anerkendt som den bedste i Forbrugerrådet TÆNK&apos;s
          &quot;Bedst i Test&quot; i 2019, er et bevis på, at alkoholfri øl kan
          være lige så fyldig og indbydende som deres alkoholiske modstykker.
        </Paragraph>
        <Paragraph>
          Brygget på det familieejede Egger-bryggeri, kombinerer Skovlyst
          Ellebryg traditionel brygkunst med en moderne tilgang til alkoholfri
          øl. Dens smag er karakteriseret ved en behagelig balance mellem sødme
          og bitterhed, hvilket gør den til en perfekt drik for dem, der søger
          en fuldendt øloplevelse uden alkohol.
        </Paragraph>
        <Paragraph>
          Nyd Skovlyst Ellebryg 0,5% som en del af et måltid eller som en
          forfriskende drik alene. Dens unikke karakter og kvalitet gør den til
          et fremragende valg for enhver lejlighed.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        <Paragraph>
          Opdag Skovlyst Ellebryg, den mørkgyldne alkoholfri ale, kåret som nr.
          1 af forbrugerrådet TÆNK i 2019. Brygget på det familieejede
          Egger-bryggeri, tilbyder denne alkoholfri ale en rig og smagsfuld
          oplevelse, inspireret af elletræets styrke. Nyd en unik øloplevelse
          uden alkohol, men med fuld smag.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 8322,
    name: "Skovlyst Alkoholfri IPA 0,5%",
    generalSearchPriority: 500,
    keywords:
      "letøl alkoholfri frokostøl alkoholdfri alkolholfri alkoholfrit øl uden let light ølight øllight light øl flaskeøl øl flasker glasflasker 50cl 50 cl 15x50cl 15x50 papkasser special effects",
    imageSingle: "produkter-oelvand.skovlyst-alkoholfri-ipa-flaske.png",
    imagePlural: "produkter-oelvand.skovlyst-alkoholfri-ipa-kasse.png",
    url: "/oel/alkoholfri-oel/skovlyst-alkoholfri-ipa",
    categories: ["oelcideralkohol", "alkoholfrioel"],
    routeDef: {
      path: "/oel/alkoholfri-oel/skovlyst-alkoholfri-ipa",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholFriOelBC],
        title: "Skovlyst Alkoholfri IPA 0,5%",
      },
      meta: {
        title: "Køb Skovlyst Alkoholfri IPA 0,5%",
        description: "Skovlyst Alkoholfri IPA 0,5%",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Alkoholfri",
      },
    },
  },
  {
    varenr: 8210,
    name: "Skovlyst Ellebryg 0,5%",
    generalSearchPriority: 500,
    keywords:
      "letøl alkoholfri frokostøl alkoholdfri alkolholfri alkoholfrit øl uden let light ølight øllight light øl flaskeøl øl flasker glasflasker 33cl 33 cl 24x33cl 24x33 papkasser",
    imageSingle: "produkter-oelvand.skovlyst-ellebryg-33cl.png",
    imagePlural: "produkter-oelvand.skovlyst-ellebryg-33cl-ramme.png",
  },
  {
    varenr: 3550,
    name: "Tuborg Classic 0,0%",
    showAlcPct: true,
    generalSearchPriority: 500,
    keywords:
      "letøl alkoholfri frokostøl alkoholdfri alkoholfrit øl uden let light ølight øllight light øl flaskeøl øl flasker glasflasker 33cl 33 cl 30x33cl 30x33 kasse superlight tuborg 0",
    imageSingle: "produkter-oelvand.tuborg-classic-33cl.png",
    imagePlural: "produkter-oelvand.tuborg-classic-33cl-kasse.png",
    url: "/oel/alkoholfri-oel/tuborg-classic",
    categories: ["oelcideralkohol", "alkoholfrioel"],
    routeDef: {
      path: "/oel/alkoholfri-oel/tuborg-classic",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholFriOelBC],
        title: "Tuborg Classic 0,0%",
      },
      meta: {
        title: "Køb Tuborg Classic 0,0%",
        description: "Tuborg Classic 0,0%",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Alkoholfri",
      },
    },
  },
  {
    varenr: 2553,
    name: "Carlsberg Nordic Pilsner 0,0% Dåse",
    generalSearchPriority: 400,
    keywords:
      "letøl alkoholfri frokostøl alkoholdfri alkoholfrit øl uden let light ølight øllight light øl new dåseøl dåse øl alu aluminium dåser 33cl 33 cl 24x33cl 24x33 ramme",
    imageSingle: "produkter-oelvand.carlsberg-nordic-33cl-daase.png",
    imagePlural: "produkter-oelvand.carlsberg-nordic-33cl-daase-ramme.png",
    url: "/oel/alkoholfri-oel/carlsberg-nordic-pilsner-daase",
    categories: ["oelcideralkohol", "alkoholfrioel"],
    routeDef: {
      path: "/oel/alkoholfri-oel/carlsberg-nordic-pilsner-daase",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholFriOelBC],
        title: "Carlsberg Nordic Pilsner 0,0% Dåse",
      },
      meta: {
        title: "Køb Carlsberg Nordic Pilsner 0,0% Dåse",
        description: "Carlsberg Nordic Pilsner 0,0% Dåse",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Alkoholfri",
      },
    },
  },
  {
    varenr: 2573,
    name: "Carlsberg Nordic Ale 0,0% Dåse",
    generalSearchPriority: 500,
    keywords:
      "letøl alkoholfri frokostøl alkoholdfri alkolholfri alkoholfrit øl uden let light ølight øllight light øl new ale dåseøl dåse øl alu aluminium dåser 33cl 33 cl 24x33cl 24x33 ramme",
    imageSingle: "produkter-oelvand.carlsberg-nordic-ale-daase.png",
    imagePlural: "produkter-oelvand.carlsberg-nordic-ale-daase-ramme.png",
    url: "/oel/alkoholfri-oel/carlsberg-nordic-ale-daase",
    categories: ["oelcideralkohol", "alkoholfrioel"],
    routeDef: {
      path: "/oel/alkoholfri-oel/carlsberg-nordic-ale-daase",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholFriOelBC],
        title: "Carlsberg Nordic Ale 0,0% Dåse",
      },
      meta: {
        title: "Køb Carlsberg Nordic Ale 0,0% Dåse",
        description: "Carlsberg Nordic Ale 0,0% Dåse",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Alkoholfri",
      },
    },
  },
  {
    varenr: 8653,
    name: "Heineken 0,0 % Dåse",
    showAlcPct: true,
    generalSearchPriority: 500,
    keywords:
      "letøl alkoholfri frokostøl alkoholdfri alkolholfri alkoholfrit øl uden let light ølight øllight light øl haineken hainiken hainikin Heiniken hiniken haniken heniken hiniken 24 24stk stk alu aluminium dåse dåser 33cl 33 cl 24x33cl 24x33 øl dåseøl ramme",
    imageSingle: "produkter-oelvand.heineken-00-daase.png",
    imagePlural: "produkter-oelvand.heineken-00-daase-ramme.png",
    url: "/oel/alkoholfri-oel/heineken-00-daase",
    categories: ["oelcideralkohol", "alkoholfrioel"],
    routeDef: {
      path: "/oel/alkoholfri-oel/heineken-00-daase",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholFriOelBC],
        title: "Heineken 0,0 % Dåse",
      },
      meta: {
        title: "Køb Heineken 0,0 % Dåse",
        description: "Heineken 0,0 % Dåse",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Alkoholfri",
      },
    },
  },
  {
    varenr: 8543,
    name: "Skovlyst Alkoholfri Lager Dåse",
    showAlcPct: true,
    generalSearchPriority: 500,
    keywords:
      "letøl alkoholfri frokostøl alkoholdfri alkoholfrit øl uden let light ølight øllight light øl 24 24stk stk alu aluminium dåse dåser 33cl 33 cl 24x33cl 24x33 øl dåseøl ramme",
    imageSingle: "produkter-oelvand.skovlyst-alkoholfri-lager-daase.png",
    // imagePlural: "produkter-oelvand.tuborg-nul-33cl-ramme.png",
    url: "/oel/alkoholfri-oel/skovlyst-alkoholfri-lager-daase",
    categories: ["oelcideralkohol", "alkoholfrioel"],
    routeDef: {
      path: "/oel/alkoholfri-oel/skovlyst-alkoholfri-lager-daase",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholFriOelBC],
        title: "Skovlyst Alkoholfri Lager Dåse",
      },
      meta: {
        title: "Køb Skovlyst Alkoholfri Lager Dåse",
        description: "Skovlyst Alkoholfri Lager Dåse",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Alkoholfri",
      },
    },
  },
  {
    varenr: 3553,
    name: "Tuborg 0,0% Dåse",
    showAlcPct: true,
    generalSearchPriority: 500,
    keywords:
      "letøl alkoholfri frokostøl alkoholdfri alkoholfrit øl uden let light ølight øllight light øl 24 24stk stk alu aluminium dåse dåser 33cl 33 cl 24x33cl 24x33 øl dåseøl ramme superlight tuborg 0 nul",
    imageSingle: "produkter-oelvand.tuborg-nul-33cl-daase.png",
    imagePlural: "produkter-oelvand.tuborg-nul-33cl-ramme.png",
    url: "/oel/alkoholfri-oel/tuborg-nul-daase",
    categories: ["oelcideralkohol", "alkoholfrioel"],
    routeDef: {
      path: "/oel/alkoholfri-oel/tuborg-nul-daase",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholFriOelBC],
        title: "Tuborg 0,0% Dåse",
      },
      meta: {
        title: "Køb Tuborg 0,0% Dåse",
        description: "Tuborg 0,0% Dåse",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Alkoholfri",
      },
    },
  },
  {
    varenr: 3563,
    name: "Tuborg 0,0% Citrus Dåse",
    generalSearchPriority: 500,
    showAlcPct: true,
    keywords:
      "letøl alkoholfri frokostøl alkoholdfri alkoholfrit øl uden let light ølight øllight light øl 24 24stk stk alu aluminium dåse dåser 33cl 33 cl 24x33cl 24x33 øl dåseøl ramme superlight tuborg 0 nul",
    imageSingle: "produkter-oelvand.tuborg-nul-citrus-33cl-daase.png",
    imagePlural: "produkter-oelvand.tuborg-nul-citrus-daase-ramme.png",
    url: "/oel/alkoholfri-oel/tuborg-nul-citrus-daase",
    categories: ["oelcideralkohol", "alkoholfrioel"],
    routeDef: {
      path: "/oel/alkoholfri-oel/tuborg-nul-citrus-daase",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholFriOelBC],
        title: "Tuborg 0,0% Citrus Dåse",
      },
      meta: {
        title: "Køb Tuborg 0,0% Citrus Dåse",
        description: "Tuborg 0,0% Citrus Dåse",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Alkoholfri",
      },
    },
  },
  {
    varenr: 3510,
    name: "KB Hvidtøl 1,7%",
    generalSearchPriority: 500,
    keywords:
      "letøl alkoholfri frokostøl alkoholdfri alkolholfri alkoholfrit øl uden let light ølight øllight light øl julebryg juleøl flaskeøl øl flasker glasflasker 33cl 33 cl 30x33cl 30x33 kasse nisseøl",
    imageSingle: "produkter-oelvand.kb-hvidtoel-flaske.png",
    imagePlural: "produkter-oelvand.3510-kasse.png",
    url: "/oel/letoel/kb-hvidtoel",
    categories: ["oelcideralkohol", "alkoholfrioel"],
    routeDef: {
      path: "/oel/letoel/kb-hvidtoel",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholFriOelBC],
        title: "KB Hvidtøl 1,7%",
      },
      meta: {
        title: "Køb KB Hvidtøl 1,7%",
        description: "KB Hvidtøl 1,7%",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Alkoholfri",
      },
    },
  },
  {
    varenr: 4530,
    name: "Vestfyen Light 2,7%",
    generalSearchPriority: 500,
    keywords:
      "letøl alkoholfri frokostøl alkoholdfri alkolholfri alkoholfrit øl uden let light ølight øllight light øl flaskeøl øl flasker glasflasker 33cl 33 cl 30x33cl 30x33 kasse vestfyn",
    imageSingle: "produkter-oelvand.vestfyn-light-flaske.png",
    imagePlural: "produkter-oelvand.4530-kasse.png",
    url: "/oel/letoel/vestfyn-light",
    categories: ["oelcideralkohol", "alkoholfrioel"],
    routeDef: {
      path: "/oel/letoel/vestfyn-light",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholFriOelBC],
        title: "Vestfyn Light 2,7%",
      },
      meta: {
        title: "Køb Vestfyn Light 2,7%",
        description: "Vestfyn Light 2,7%",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Alkoholfri",
      },
    },
  },

  /* *
   *
   * Alkoholsodavand
   *
   * */
  {
    varenr: 2810,
    name: "Somersby Apple Cider",
    generalSearchPriority: 500,
    keywords:
      "cider sommersby sommersbye sommersbi somersbi somersbi summersbi sumersby summerby summersby sumerby sommerby flasker glasflasker 27,5cl 27,5 cl 24x27,5cl 24x27,5 papkasser",
    imageSingle: "produkter-oelvand.2810-flaske.png",
    imagePlural: "produkter-oelvand.2810-kasse.png",
    imageSingleFirst: true,
    url: "/alkoholsodavand/somersby-apple-cider",
    categories: ["oelcideralkohol", "alkoholSodavand"],
    routeDef: {
      path: "/alkoholsodavand/somersby-apple-cider",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholSodavandBC],
        title: "Somersby Apple Cider",
      },
      meta: {
        title: "Køb Somersby Apple Cider",
        description: "Somersby Apple Cider",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "AlkoholSodavand",
      },
    },
  },
  {
    varenr: 2823,
    name: "Somersby Apple Dåse",
    generalSearchPriority: 500,
    keywords:
      "cider sommersby sommersbye sommersbi somersbi somersbi summersbi sumersby summerby summersby sumerby sommerby alu aluminium dåse dåser 33cl 33 cl 24x33cl 24x33 ramme",
    imageSingle: "produkter-oelvand.summersby-daase.png",
    imagePlural: "produkter-oelvand.2823-kasse.png",
    imageSingleFirst: true,
    url: "/alkoholsodavand/somersby-apple-cider-daase",
    categories: ["oelcideralkohol", "alkoholSodavand"],
    routeDef: {
      path: "/alkoholsodavand/somersby-apple-cider-daase",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholSodavandBC],
        title: "Somersby Apple Dåse",
      },
      meta: {
        title: "Køb Somersby Apple Dåse",
        description: "Somersby Apple Dåse",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "AlkoholSodavand",
      },
    },
  },
  {
    varenr: 2843,
    name: "Somersby Blackberry Dåse",
    generalSearchPriority: 500,
    keywords:
      "cider sommersby sommersbye sommersbi somersbi somersbi summersbi sumersby summerby summersby sumerby sommerby alu aluminium dåse dåser 33cl 33 cl 24x33cl 24x33 ramme",
    imageSingle: "produkter-oelvand.somersby-blackberry-daase.png",
    imagePlural: "produkter-oelvand.somersby-blackberry-daase-ramme.png",
    imageSingleFirst: true,
    url: "/alkoholsodavand/somersby-blackberry-daase",
    categories: ["oelcideralkohol", "alkoholSodavand"],
    routeDef: {
      path: "/alkoholsodavand/somersby-blackberry-daase",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholSodavandBC],
        title: "Somersby Blackberry Dåse",
      },
      meta: {
        title: "Køb Somersby Blackberry Dåse",
        description: "Somersby Blackberry Dåse",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "AlkoholSodavand",
      },
    },
  },
  {
    varenr: 2910,
    name: "Breezer Peach",
    generalSearchPriority: 500,
    keywords:
      "bacardi smirnoff ic flasker glasflasker 27,5cl 27,5 cl 24x27,5cl 24x27,5 mokai shakers breezers",
    imageSingle: "produkter-oelvand.breezer-peach-flaske.png",
    // imagePlural: "produkter-oelvand.2930-kasse.png",
    imageSingleFirst: true,
    url: "/alkoholsodavand/bacardi-breezer-peach",
    categories: ["oelcideralkohol", "alkoholSodavand"],
    routeDef: {
      path: "/alkoholsodavand/bacardi-breezer-peach",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholSodavandBC],
        title: "Bacardi Breezer Peach",
      },
      meta: {
        title: "Køb Bacardi Breezer Peach",
        description: "Bacardi Breezer Peach",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "AlkoholSodavand",
      },
    },
  },
  {
    varenr: 2930,
    name: "Breezer Orange",
    generalSearchPriority: 500,
    keywords:
      "appelsin bacardi smirnoff ic flasker glasflasker 27,5cl 27,5 cl 24x27,5cl 24x27,5 mokai shakers breezers",
    imageSingle: "produkter-oelvand.breezer-orange-flaske.png",
    // imagePlural: "produkter-oelvand.2930-kasse.png",
    imageSingleFirst: true,
    url: "/alkoholsodavand/bacardi-breezer-orange",
    categories: ["oelcideralkohol", "alkoholSodavand"],
    routeDef: {
      path: "/alkoholsodavand/bacardi-breezer-orange",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholSodavandBC],
        title: "Bacardi Breezer Orange",
      },
      meta: {
        title: "Køb Bacardi Breezer Orange",
        description: "Bacardi Breezer Orange",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "AlkoholSodavand",
      },
    },
  },
  {
    varenr: 2940,
    name: "Breezer Passion Mango",
    generalSearchPriority: 500,
    keywords:
      "bacardi smirnoff ic flasker glasflasker 27,5cl 27,5 cl 24x27,5cl 24x27,5 mokai shakers breezers",
    imageSingle: "produkter-oelvand.breezer-passion-mango-flaske.png",
    // imagePlural: "produkter-oelvand.2930-kasse.png",
    imageSingleFirst: true,
    url: "/alkoholsodavand/bacardi-breezer-passion-mango",
    categories: ["oelcideralkohol", "alkoholSodavand"],
    routeDef: {
      path: "/alkoholsodavand/bacardi-breezer-passion-mango",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholSodavandBC],
        title: "Bacardi Breezer Passion Mango",
      },
      meta: {
        title: "Køb Bacardi Breezer Passion Mango",
        description: "Bacardi Breezer Passion Mango",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "AlkoholSodavand",
      },
    },
  },
  {
    varenr: 2950,
    name: "Breezer Pineapple",
    generalSearchPriority: 500,
    keywords:
      "ananas bacardi smirnoff ic flasker glasflasker 27,5cl 27,5 cl 24x27,5cl 24x27,5 mokai shakers breezers",
    imageSingle: "produkter-oelvand.breezer-pineapple-flaske.png",
    // imagePlural: "produkter-oelvand.2950-kasse.png",
    imageSingleFirst: true,
    url: "/alkoholsodavand/bacardi-breezer-pineapple",
    categories: ["oelcideralkohol", "alkoholSodavand"],
    routeDef: {
      path: "/alkoholsodavand/bacardi-breezer-pineapple",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholSodavandBC],
        title: "Breezer Pineapple",
      },
      meta: {
        title: "Køb Breezer Pineapple",
        description: "Breezer Pineapple",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "AlkoholSodavand",
      },
    },
  },
  {
    varenr: 2960,
    name: "Breezer Lemon Lite",
    generalSearchPriority: 500,
    keywords:
      "citron bacardi smirnoff ic flasker glasflasker 27,5cl 27,5 cl 24x27,5cl 24x27,5 mokai shakers breezers",
    imageSingle: "produkter-oelvand.breezer-lemon-lite-flaske.png",
    // imagePlural: "produkter-oelvand.2960-kasse.png",
    imageSingleFirst: true,
    url: "/alkoholsodavand/bacardi-breezer-lemon-lite",
    categories: ["oelcideralkohol", "alkoholSodavand"],
    routeDef: {
      path: "/alkoholsodavand/bacardi-breezer-lemon-lite",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholSodavandBC],
        title: "Breezer Lemon Lite",
      },
      meta: {
        title: "Køb Breezer Lemon Lite",
        description: "Breezer Lemon Lite",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "AlkoholSodavand",
      },
    },
  },
  {
    varenr: 2970,
    name: "Breezer Strawberry",
    generalSearchPriority: 500,
    keywords:
      "citron bacardi smirnoff ic flasker glasflasker 27,5cl 27,5 cl 24x27,5cl 24x27,5 mokai shakers breezers",
    imageSingle: "produkter-oelvand.breezer-strawberry-flaske.png",
    // imagePlural: "produkter-oelvand.2960-kasse.png",
    imageSingleFirst: true,
    url: "/alkoholsodavand/bacardi-breezer-strawberry",
    categories: ["oelcideralkohol", "alkoholSodavand"],
    routeDef: {
      path: "/alkoholsodavand/bacardi-breezer-strawberry",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholSodavandBC],
        title: "Breezer Strawberry",
      },
      meta: {
        title: "Køb Breezer Strawberry",
        description: "Breezer Strawberry",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "AlkoholSodavand",
      },
    },
  },
  {
    varenr: 2980,
    name: "Breezer Raspberry Yuzu",
    generalSearchPriority: 500,
    keywords:
      "citron bacardi smirnoff ic flasker glasflasker 27,5cl 27,5 cl 24x27,5cl 24x27,5 mokai shakers breezers",
    imageSingle: "produkter-oelvand.breezer-raspberry-yuzu-flaske.png",
    // imagePlural: "produkter-oelvand.2960-kasse.png",
    imageSingleFirst: true,
    url: "/alkoholsodavand/bacardi-breezer-raspberry-yuzu",
    categories: ["oelcideralkohol", "alkoholSodavand"],
    routeDef: {
      path: "/alkoholsodavand/bacardi-breezer-raspberry-yuzu",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholSodavandBC],
        title: "Breezer Raspberry Yuzu",
      },
      meta: {
        title: "Køb Breezer Raspberry Yuzu",
        description: "Breezer Raspberry Yuzu",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "AlkoholSodavand",
      },
    },
  },
  {
    varenr: 2990,
    name: "Breezer Mango",
    generalSearchPriority: 500,
    keywords:
      "citron bacardi smirnoff ic flasker glasflasker 27,5cl 27,5 cl 24x27,5cl 24x27,5 mokai shakers breezers",
    imageSingle: "produkter-oelvand.breezer-mango-flaske.png",
    // imagePlural: "produkter-oelvand.2960-kasse.png",
    imageSingleFirst: true,
    url: "/alkoholsodavand/bacardi-breezer-mango",
    categories: ["oelcideralkohol", "alkoholSodavand"],
    routeDef: {
      path: "/alkoholsodavand/bacardi-breezer-mango",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholSodavandBC],
        title: "Breezer Mango",
      },
      meta: {
        title: "Køb Breezer Mango",
        description: "Breezer Mango",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "AlkoholSodavand",
      },
    },
  },
  {
    varenr: 24010,
    name: "Nohrlund Øko | Den Røde",
    generalSearchPriority: 500,
    keywords: "Vodka Jordbær Rabarber cocktails drinks norlund nordlund",
    smag: "Vodka, Jordbær & Rabarber",
    imageSingle: "produkter-oelvand.nohrlund-den-roede-flaske.png",
    imagePlural: "produkter-oelvand.nohrlund-kasse.png",
    imageSingleFirst: true,
    nohrlundGlasImg: "produkter-fadoel.nohrlund-den-roede-glas.png",
    nohrlundFadoelVarenr: "24015",
    noDesriptionProductView: true,
    description: () => (
      <React.Fragment>
        Denne cocktail fra Nohrlund smager af sommer fuld af jordbær og
        rabarber. Der bliver med nøje udvalgt de jordbær, som er mest modne og
        søde. Dette sammensat med sprød rabarber, vanilje og delikat vodka giver
        en perfekt sommercocktail.
      </React.Fragment>
    ),
    url: "/alkoholsodavand/nohrlund-oeko-den-roede",
    categories: ["oelcideralkohol", "alkoholSodavand"],
    routeDef: {
      path: "/alkoholsodavand/nohrlund-oeko-den-roede",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholSodavandBC],
        title: "Nohrlund Øko | Den Røde",
      },
      meta: {
        title: "Køb Nohrlund Øko | Den Røde",
        description: "Nohrlund Øko | Den Røde",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "AlkoholSodavand",
      },
    },
  },
  {
    varenr: 24020,
    generalSearchPriority: 500,
    name: "Nohrlund Øko | Den Hvide",
    keywords: "Gin Hyldeblomst Ingefær cocktails drinks norlund nordlund",
    smag: "Gin, Hyldeblomst & Ingefær",
    imageSingle: "produkter-oelvand.nohrlund-den-hvide-flaske.png",
    imagePlural: "produkter-oelvand.nohrlund-kasse.png",
    imageSingleFirst: true,
    nohrlundGlasImg: "produkter-fadoel.nohrlund-den-hvide-glas.png",
    nohrlundFadoelVarenr: "24025",
    noDesriptionProductView: true,
    description: () => (
      <React.Fragment>
        Denne læskende cocktail har en fantastisk balance af Ingefær og
        Hyldeblomst. Cocktailen er kraftig og sød og samspillet med den intense
        Gin, krydret enebær, diskret citrus, ingefær og hyldeblomst giver dig en
        cocktail du skal smage mere end én gang.
      </React.Fragment>
    ),
    url: "/alkoholsodavand/nohrlund-oeko-den-hvide",
    categories: ["oelcideralkohol", "alkoholSodavand"],
    routeDef: {
      path: "/alkoholsodavand/nohrlund-oeko-den-hvide",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholSodavandBC],
        title: "Nohrlund Øko | Den Hvide",
      },
      meta: {
        title: "Køb Nohrlund Øko | Den Hvide",
        description: "Nohrlund Øko | Den Hvide",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "AlkoholSodavand",
      },
    },
  },
  {
    varenr: 24030,
    generalSearchPriority: 500,
    name: "Nohrlund Øko | Den Gule",
    keywords: "Rom Hyben Havtorn cocktails drinks norlund nordlund",
    smag: "Rom, Hyben & Havtorn",
    imageSingle: "produkter-oelvand.nohrlund-den-gule-flaske.png",
    imagePlural: "produkter-oelvand.nohrlund-kasse.png",
    imageSingleFirst: true,
    nohrlundGlasImg: "produkter-fadoel.nohrlund-den-gule-glas.png",
    nohrlundFadoelVarenr: "24035",
    noDesriptionProductView: true,
    description: () => (
      <React.Fragment>
        Norhlund Rom, Hyben &amp; Havtorn har 3 nuancer der giver en perfekt
        nordisk cocktail. Havtorn giver frisk syre. Hyben giver en fyldig
        frugtsmag og sidst giver den lyse rom en krydret dybde.
      </React.Fragment>
    ),
    url: "/alkoholsodavand/nohrlund-oeko-den-gule",
    categories: ["oelcideralkohol", "alkoholSodavand"],
    routeDef: {
      path: "/alkoholsodavand/nohrlund-oeko-den-gule",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholSodavandBC],
        title: "Nohrlund Øko | Den Gule",
      },
      meta: {
        title: "Køb Nohrlund Øko | Den Gule",
        description: "Nohrlund Øko | Den Gule",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "AlkoholSodavand",
      },
    },
  },
  {
    varenr: 24060,
    name: "Nohrlund Øko | Den Sorte",
    generalSearchPriority: 500,
    keywords:
      "Karamelliseret Rom Espresso Chokoladebitter cocktails drinks norlund nordlund",
    smag: "Karamelliseret Rom, Espresso & Chokoladebitter",
    imageSingle: "produkter-oelvand.nohrlund-den-sorte-flaske.png",
    imagePlural: "produkter-oelvand.nohrlund-kasse.png",
    imageSingleFirst: true,
    nohrlundGlasImg: "produkter-fadoel.nohrlund-den-sorte-glas.png",
    noDesriptionProductView: true,
    description: () => (
      <React.Fragment>
        Cocktailen til efter middagen. Nohrlund producerer deres egen
        karamelliserede rom, fyldt af sødme og vanilje. Ristede kakao- og
        kaffebønner, samt appelsin- og citronskal udgør ingridienserne for
        chokoladebitteren. Denne cocktail bliver beskrevet som &quot;at nyde
        kaffe og petit four i ét glas&quot; og du vil kun blive fornøjet over
        den bitre og friske karakter.
      </React.Fragment>
    ),
    url: "/alkoholsodavand/nohrlund-oeko-den-sorte",
    categories: ["oelcideralkohol", "alkoholSodavand"],
    routeDef: {
      path: "/alkoholsodavand/nohrlund-oeko-den-sorte",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholSodavandBC],
        title: "Nohrlund Øko | Den Sorte",
      },
      meta: {
        title: "Køb Nohrlund Øko | Den Sorte",
        description: "Nohrlund Øko | Den Sorte",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "AlkoholSodavand",
      },
    },
  },
  {
    varenr: 24150,
    name: "Nohrlund Øko | Bramble",
    keywords: "drinks norlund nordlund",
    smag: "Gin, brombærsaft og citron",
    imageSingle: "produkter-oelvand.nohrlund-oeko-bramble-flaske.png",
    imagePlural: "produkter-oelvand.nohrlund-kasse.png",
    imageSingleFirst: true,
    url: "/alkoholsodavand/nohrlund-oeko-bramble",
    categories: ["oelcideralkohol", "alkoholSodavand"],
    routeDef: {
      path: "/alkoholsodavand/nohrlund-oeko-bramble",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholSodavandBC],
        title: "Nohrlund Øko | Bramble",
      },
      meta: {
        title: "Køb Nohrlund Øko | Bramble",
        description: "Nohrlund Øko | Bramble",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "AlkoholSodavand",
      },
    },
  },
  {
    varenr: 24070,
    name: "Nohrlund Øko | Mojito",
    generalSearchPriority: 500,
    keywords: "drinks norlund nordlund",
    smag: "Rom, mynte, lime og rørsukker",
    imageSingle: "produkter-oelvand.nohrlund-oeko-mojito-flaske.png",
    imagePlural: "produkter-oelvand.nohrlund-kasse.png",
    imageSingleFirst: true,
    url: "/alkoholsodavand/nohrlund-oeko-mojito",
    categories: ["oelcideralkohol", "alkoholSodavand"],
    routeDef: {
      path: "/alkoholsodavand/nohrlund-oeko-mojito",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholSodavandBC],
        title: "Nohrlund Øko | Mojito",
      },
      meta: {
        title: "Køb Nohrlund Øko | Mojito",
        description: "Nohrlund Øko | Mojito",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "AlkoholSodavand",
      },
    },
  },
  {
    varenr: 24080,
    name: "Nohrlund Øko | Passion Martini",
    generalSearchPriority: 500,
    keywords: "drinks norlund nordlund",
    smag: "Vodka, passionsfrugt, vaniljesirup og lime",
    imageSingle: "produkter-oelvand.nohrlund-oeko-passion-martini-flaske.png",
    imagePlural: "produkter-oelvand.nohrlund-kasse.png",
    imageSingleFirst: true,
    url: "/alkoholsodavand/nohrlund-oeko-passion-martini",
    categories: ["oelcideralkohol", "alkoholSodavand"],
    routeDef: {
      path: "/alkoholsodavand/nohrlund-oeko-passion-martini",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholSodavandBC],
        title: "Nohrlund Øko | Passion Martini",
      },
      meta: {
        title: "Køb Nohrlund Øko | Passion Martini",
        description: "Nohrlund Øko | Passion Martini",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "AlkoholSodavand",
      },
    },
  },
  {
    varenr: 24090,
    name: "Nohrlund Øko | Stormy Rom Ginger",
    generalSearchPriority: 500,
    keywords: "drinks norlund nordlund",
    smag: "17 år gammel whisky fra Skotland, rørsukker og citron",
    imageSingle: "produkter-oelvand.nohrlund-oeko-stormy-rom-ginger-flaske.png",
    imagePlural: "produkter-oelvand.nohrlund-kasse.png",
    imageSingleFirst: true,
    url: "/alkoholsodavand/nohrlund-oeko-stormy-rom-ginger",
    categories: ["oelcideralkohol", "alkoholSodavand"],
    routeDef: {
      path: "/alkoholsodavand/nohrlund-oeko-stormy-rom-ginger",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholSodavandBC],
        title: "Nohrlund Øko | Stormy Rom Ginger",
      },
      meta: {
        title: "Køb Nohrlund Øko | Stormy Rom Ginger",
        description: "Nohrlund Øko | Stormy Rom Ginger",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "AlkoholSodavand",
      },
    },
  },

  /* *
   *
   * Vin
   *
   * */

  {
    varenr: 2852,
    name: "Rødvin Shiraz Carbernet",
    generalSearchPriority: 500,
    keywords: "vin flasker glasflasker 75cl 75 cl 6x75cl 6x75 papkasser",
    usePluralImage: true,
    imagePlural: "produkter-oelvand.2852-kasse.png",
    url: "/vin/roedvin-shiraz-carbernet",
    categories: ["oelcideralkohol", "vin"],
    routeDef: {
      path: "/vin/roedvin-shiraz-carbernet",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, VinBC],
        title: "Rødvin Shiraz Carbernet",
      },
      meta: {
        title: "Køb Rødvin Shiraz Carbernet",
        description: "Rødvin Shiraz Carbernet",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Vin",
      },
    },
  },
  {
    varenr: 2862,
    name: "Hvidvin Chardonnay Semillon",
    generalSearchPriority: 500,
    keywords: "vin flasker glasflasker 75cl 75 cl 6x75cl 6x75 papkasser",
    usePluralImage: true,
    imagePlural: "produkter-oelvand.2862-kasse.png",
    url: "/vin/hvidvin-chardonnay-semillon",
    categories: ["oelcideralkohol", "vin"],
    routeDef: {
      path: "/vin/hvidvin-chardonnay-semillon",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, VinBC],
        title: "Hvidvin Chardonnay Semillon",
      },
      meta: {
        title: "Køb Hvidvin Chardonnay Semillon",
        description: "Hvidvin Chardonnay Semillon",
      },
      props: {
        menuPicker: "Alkohol",
        activeSidemenu: "Vin",
      },
    },
  },

  /* *
   *
   * Sodavand
   *
   * */

  /* *
   *
   * Coca Cola
   *
   * */
  {
    varenr: 5350,
    name: "Coca-Cola",
    generalSearchPriority: 500,
    keywords:
      "30 30stk stk flasker 25cl 25 cl 30x25cl 30x25 coca cola sodavand cocacola cocacolazero life",
    imageSingle: "produkter-oelvand.coca-cola-25cl-2.png",
    imagePlural: "produkter-oelvand.5350-kasse.png",
    categories: ["cocaCola"],
    url: "/sodavand/coca-cola/coca-cola-30x25cl",
    routeDef: {
      path: "/sodavand/coca-cola/coca-cola-30x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandCocaColaBC],
        title: "Coca-Cola 30x25cl",
      },
      meta: {
        title: "Coca-Cola 30x25cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "CocaCola",
      },
    },
  },
  {
    varenr: 8350,
    name: "Hancock Sport Cola",
    generalSearchPriority: 500,
    keywords:
      "30 30stk stk flasker 33cl 33 cl 33x25cl 33x25 cola sodavand kola",
    imageSingle: "produkter-oelvand.hancock-sport-cola-33cl-flaske.png",
    imagePlural: "produkter-oelvand.hancock-sport-cola-33cl-kasse.png",
    url: "/sodavand/diverse-sodavand/hancock-sport-cola-33cl",
    categories: ["diverseSodavand"],
    routeDef: {
      path: "/sodavand/diverse-sodavand/hancock-sport-cola-33cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandCocaColaBC],
        title: "Hancock Sport Cola",
      },
      meta: {
        title: "Køb Hancock Sport Cola – En dansk kultklassiker siden 1974",
        description:
          "Smag den ikoniske Hancock Sport Cola! Frisk, frugtagtig og med et strejf af cola – tappet på en unik ølflaske. En dansk sodavandsklassiker siden 1974.",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "DiverseSodavand",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading2>Smagen af nostalgi</Heading2>
        <Paragraph>
          Oplev den ikoniske Hancock Sport Cola - en klassiker siden 1974! Denne
          unikke sodavand forener en frisk og frugtagtig smag med et elegant
          strejf af cola, hvilket giver en forfriskende oplevelse, der skiller
          sig ud fra mængden. Modsat traditionelle sodavandsflasker tappes Sport
          Cola på en karakteristisk ølflaske, hvilket understreger dens unikke
          udtryk og retro-charme.
        </Paragraph>
        <Paragraph>
          Smagen rammer nostalgiske toner og vækker minder om barndommens
          &quot;skub-op&quot;is med colasmag. Hancock Sport Cola er en
          danskproduceret sodavand, der har været en fast del af danskernes
          hverdag i årtier. Med sin karakteristiske smag og unikke flaskeform
          har Sport Cola opnået kultstatus blandt sodavandsentusiaster og har
          endda sin egen fanskare online. Uanset om du er førstegangssmager
          eller en trofast fan, er Hancock Sport Cola et oplagt valg, når du vil
          nyde en danskproduceret klassiker med en historie og smag helt for sig
          selv.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 52353,
    name: "Jolly Cola Dåse",
    generalSearchPriority: 500,
    keywords:
      "18 18stk stk alu aluminium dåse dåser 33cl 33 cl 18x33cl 18x33 coca cola sodavand ramme dåsesodavand dåse",
    imageSingle: "produkter-oelvand.jolly-cola-daase.png",
    imagePlural: "produkter-oelvand.jolly-cola-daase-ramme.png",
    url: "/sodavand/diverse-sodavand/jolly-cola-daase",
    categories: ["diverseSodavand"],
    routeDef: {
      path: "/sodavand/diverse-sodavand/jolly-cola-daase",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandCocaColaBC],
        title: "Jolly Cola Dåse",
      },
      meta: {
        title:
          "Køb Jolly Cola 33 cl Dåse - Den Originale Danske Cola siden 1959",
        description:
          "Oplev den autentiske smag af Jolly Cola i en 33 cl dåse. En dansk klassiker siden 1959, der kombinerer forfriskning med nostalgi. Perfekt til enhver lejlighed.",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "DiverseSodavand",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading2>Danmarks Originale Cola</Heading2>
        <Paragraph>
          Jolly Cola er Danmarks originale cola, der siden 1959 har været en
          favorit blandt danskerne. Med sin forfriskende og lækre smag, fyldt
          med nostalgi, er den ideel til enhver lejlighed. Nu kan du nyde denne
          klassiker i en praktisk 33 cl dåse.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 5450,
    name: "Coca-Cola Zero",
    generalSearchPriority: 500,
    keywords:
      "sukkerfri sukker fri 30 30stk stk flasker 25cl 25 cl 30x25cl 30x25 coca cola zero light sodavand cocacola cocacolazero life",
    imageSingle: "produkter-oelvand.coca-cola-zero-25cl-1.png",
    imagePlural: "produkter-oelvand.5450-kasse.png",
    categories: ["cocaCola", "sukkerfri"],
    url: "/sodavand/coca-cola/coca-cola-zero-30x25cl",
    routeDef: {
      path: "/sodavand/coca-cola/coca-cola-zero-30x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandCocaColaBC],
        title: "Coca-Cola Zero 30x25cl",
      },
      meta: {
        title: "Coca-Cola Zero 30x25cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "CocaCola",
      },
    },
  },
  /* *
   *
   * Coca Cola 25cl Plast
   *
   * */
  {
    varenr: 5354,
    name: "Coca-Cola",
    generalSearchPriority: 500,
    keywords:
      "18 18stk stk flasker 25cl 25 cl 18x25cl 18x25 coca cola sodavand cocacola",
    imageSingle: "produkter-oelvand.coca-cola-25cl-plast.png",
    imagePlural: "produkter-oelvand.coca-cola-25cl-plast-ramme.png",
    categories: ["cocaCola"],
    url: "/sodavand/coca-cola/coca-cola-18x25cl",
    routeDef: {
      path: "/sodavand/coca-cola/coca-cola-18x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandCocaColaBC],
        title: "Coca-Cola 18x25cl",
      },
      meta: {
        title: "Coca-Cola 18x25cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "CocaCola",
      },
    },
  },
  {
    varenr: 5454,
    name: "Coca-Cola Zero",
    generalSearchPriority: 500,
    keywords:
      "sukkerfri sukker fri 18 18stk stk flasker 25cl 25 cl 18x25cl 18x25 coca cola zero light sodavand cocacola cocacolazero life",
    imageSingle: "produkter-oelvand.coca-cola-zero-plast-25cl.png",
    imagePlural: "produkter-oelvand.coca-cola-zero-plast-25cl-ramme.png",
    categories: ["cocaCola", "sukkerfri"],
    url: "/sodavand/coca-cola/coca-cola-zero-18x25cl",
    routeDef: {
      path: "/sodavand/coca-cola/coca-cola-zero-18x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandCocaColaBC],
        title: "Coca-Cola Zero 18x25cl",
      },
      meta: {
        title: "Coca-Cola Zero 18x25cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "CocaCola",
      },
    },
  },
  /* *
   *
   * Coca Cola 50cl
   *
   * */
  {
    varenr: 5352,
    name: "Coca-Cola",
    generalSearchPriority: 500,
    categories: ["cocaCola"],
    url: "/sodavand/coca-cola/coca-cola-24x50cl",
    keywords:
      "24 24stk stk flasker 50cl 50 cl 24x50cl 24x50 coca cola sodavand cocacola",
    imageSingle: "produkter-oelvand.coca-cola-50cl-2.png",
    imagePlural: "produkter-oelvand.coca-cola-50cl-ramme-1.png",
    routeDef: {
      path: "/sodavand/coca-cola/coca-cola-24x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandCocaColaBC],
        title: "Coca-Cola 24x50cl",
      },
      meta: {
        title: "Coca-Cola 24x50cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "CocaCola",
      },
    },
  },
  {
    varenr: 5452,
    name: "Coca-Cola Zero",
    generalSearchPriority: 500,
    categories: ["cocaCola", "sukkerfri"],
    keywords:
      "sukkerfri sukker fri 24 24stk stk flasker 50cl 50 cl 24x50cl 24x50 coca cola zero light sodavand cocacola cocacolazero life",
    imageSingle: "produkter-oelvand.coca-cola-zero-2021-50cl.png",
    imagePlural: "produkter-oelvand.coca-cola-zero-50cl-ramme-2.png",
    url: "/sodavand/coca-cola/coca-cola-zero-24x50cl",
    routeDef: {
      path: "/sodavand/coca-cola/coca-cola-zero-24x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandCocaColaBC],
        title: "Coca-Cola Zero 24x50cl",
      },
      meta: {
        title: "Coca-Cola Zero 24x50cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "CocaCola",
      },
    },
  },
  /* *
   *
   * Coca Cola 33cl dåse
   *
   * */
  {
    varenr: 5353,
    name: "Coca-Cola Dåse",
    generalSearchPriority: 500,
    categories: ["cocaCola"],
    keywords:
      "24 24stk stk alu aluminium dåse dåser 33cl 33 cl 24x33cl 24x33 coca cola sodavand ramme dåsesodavand dåse",
    imageSingle: "produkter-oelvand.coca-cola-33cl-daase-1.png",
    imagePlural: "produkter-oelvand.coca-cola-33cl-daase-ramme-1.png",
    url: "/sodavand/coca-cola/coca-cola-24x33cl-daase",
    routeDef: {
      path: "/sodavand/coca-cola/coca-cola-24x33cl-daase",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandCocaColaBC],
        title: "Coca-Cola 24x33cl Dåse",
      },
      meta: {
        title: "Coca-Cola 24x33cl Dåse",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "CocaCola",
      },
    },
  },
  {
    varenr: 5453,
    name: "Coca-Cola Zero Dåse",
    generalSearchPriority: 500,
    categories: ["cocaCola", "sukkerfri"],
    keywords:
      "sukkerfri sukker fri 24 24stk stk dåser 33cl 33 cl 24x33cl 24x33 coca cola zero light sodavand ramme dåsesodavand dåse",
    imageSingle: "produkter-oelvand.coca-cola-zero-2021-33cl-daase.png",
    imagePlural: "produkter-oelvand.coca-cola-zero-2021-33cl-daase-ramme.png",
    url: "/sodavand/coca-cola/coca-cola-zero-24x33cl-daase",
    routeDef: {
      path: "/sodavand/coca-cola/coca-cola-zero-24x33cl-daase",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandCocaColaBC],
        title: "Coca-Cola Zero 24x33cl Dåse",
      },
      meta: {
        title: "Coca-Cola Zero 24x33cl Dåse",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "CocaCola",
      },
    },
  },
  /* *
   *
   * Coca Cola 150cl
   *
   * */
  {
    varenr: 5351,
    name: "Coca-Cola",
    generalSearchPriority: 450,
    categories: ["cocaCola"],
    keywords:
      "8 8stk stk flasker 150cl 150 cl 8x150cl 8x150 coca cola sodavand",
    imageSingle: "produkter-oelvand.coca-cola-150cl-1.png",
    imagePlural: "produkter-oelvand.coca-cola-150cl-ramme-1.png",
    url: "/sodavand/coca-cola/coca-cola-8x150cl",
    routeDef: {
      path: "/sodavand/coca-cola/coca-cola-8x150cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandCocaColaBC],
        title: "Coca-Cola 8x150cl",
      },
      meta: {
        title: "Coca-Cola 8x150cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "CocaCola",
      },
    },
  },
  {
    varenr: 5451,
    name: "Coca-Cola Zero",
    generalSearchPriority: 450,
    categories: ["cocaCola", "sukkerfri"],
    keywords:
      "sukkerfri sukker fri 8 8stk stk flasker 150cl 150 cl 8x150cl 8x150 coca cola zero light sodavand",
    imageSingle: "produkter-oelvand.coca-cola-zero-150cl-1.png",
    imagePlural: "produkter-oelvand.coca-cola-zero-150cl-ramme-1.png",
    url: "/sodavand/coca-cola/coca-cola-zero-8x150cl",
    routeDef: {
      path: "/sodavand/coca-cola/coca-cola-zero-8x150cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandCocaColaBC],
        title: "Coca-Cola Zero 8x150cl",
      },
      meta: {
        title: "Coca-Cola Zero 8x150cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "CocaCola",
      },
    },
  },
  /* *
   *
   * Danskvand
   *
   * */
  {
    varenr: 4050,
    name: "Loka Danskvand",
    generalSearchPriority: 500,
    keywords:
      "ramløse ramløsa ramlossa ramlöse ramlose ramlosa danskvand dansk vand apollinaris med brus i appollinaris apollonaris apolinaris appolinaris flasker glasflasker 33cl 33 cl 20x33cl 20x33",
    imageSingle: "produkter-oelvand.loka-naturell-flaske.png",
    imagePlural: "produkter-oelvand.4050-kasse.png",
    categories: ["danskvand"],
    url: "/danskvand/loka-20x33cl",
    routeDef: {
      path: "/danskvand/loka-20x33cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, DanskvandBC],
        title: "Loka 20x33cl",
      },
      meta: {
        title: "Loka 20x33cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "Danskvand",
      },
    },
  },
  {
    varenr: 4060,
    name: "Loka Danskvand Citron brus",
    generalSearchPriority: 500,
    keywords:
      "ramløse ramløsa ramlossa ramlöse ramlose ramlosa danskvand dansk vand apollinaris med brus i appollinaris apollonaris apolinaris appolinaris flasker glasflasker 33cl 33 cl 20x33cl 20x33",
    imageSingle: "produkter-oelvand.loka-citron.png",
    imagePlural: "produkter-oelvand.4060-kasse.png",
    categories: ["danskvand"],
    url: "/danskvand/loka-citron-20x33cl",
    routeDef: {
      path: "/danskvand/loka-citron-20x33cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, DanskvandBC],
        title: "Loka Citron 20x33cl",
      },
      meta: {
        title: "Loka Citron 20x33cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "Danskvand",
      },
    },
  },
  {
    varenr: 5054,
    name: "Ramlösa Premium",
    generalSearchPriority: 500,
    keywords:
      "ramløse ramløsa ramlossa ramlöse ramlose ramlosa danskvand dansk vand apollinaris med brus i appollinaris apollonaris apolinaris appolinaris flasker glasflasker 33cl 33 cl 24x33cl 24x33",
    imageSingle: "produkter-oelvand.ramlosa-premium-med-brus.png",
    imagePlural: "produkter-oelvand.ramlosa-premium-med-brus-ramme.png",
    categories: ["danskvand"],
    url: "/danskvand/premium-24x33cl",
    routeDef: {
      path: "/danskvand/premium-24x33cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, DanskvandBC],
        title: "Ramlösa Premium 24x33cl",
      },
      meta: {
        title: "Ramlösa Premium 24x33cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "Danskvand",
      },
    },
  },
  {
    varenr: 5064,
    name: "Ramlösa Premium Citrus brus",
    generalSearchPriority: 500,
    keywords:
      "ramløse ramløsa ramlossa ramlöse ramlose ramlosa danskvand dansk vand apollinaris med brus i appollinaris apollonaris apolinaris appolinaris flasker glasflasker 33cl 33 cl 24x33cl 24x33",
    imageSingle: "produkter-oelvand.ramlosa-premium-med-brus-citrus.png",
    imagePlural: "produkter-oelvand.ramlosa-premium-med-brus-citrus-ramme.png",
    categories: ["danskvand"],
    url: "/danskvand/ramlosa-premium-citrus-24x33cl",
    routeDef: {
      path: "/danskvand/ramlosa-premium-citrus-24x33cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, DanskvandBC],
        title: "Ramlösa Premium Citrus 24x33cl",
      },
      meta: {
        title: "Ramlösa Premium Citrus 24x33cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "Danskvand",
      },
    },
  },
  {
    varenr: 4030,
    name: "Egekilde Brus",
    generalSearchPriority: 500,
    keywords:
      "danskvand dansk vand apollinaris med brus i appollinaris apollonaris apolinaris appolinaris flasker glasflasker 30cl 30 cl 30x30cl 30x30",
    imageSingle: "produkter-oelvand.egekilde-brus-30cl.png",
    imagePlural: "produkter-oelvand.4040-kasse.png",
    categories: ["danskvand"],
    url: "/danskvand/egekilde-brus-30x30cl",
    routeDef: {
      path: "/danskvand/egekilde-brus-30x30cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, DanskvandBC],
        title: "Egekilde Brus 30x30cl",
      },
      meta: {
        title: "Egekilde Brus 30x30cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "Danskvand",
      },
    },
  },
  {
    varenr: 4040,
    name: "Egekilde Citrus brus",
    generalSearchPriority: 500,
    keywords:
      "danskvand dansk vand apollinaris med brus i appollinaris apollonaris apolinaris appolinaris flasker glasflasker 30cl 30 cl 30x30cl 30x30",
    imageSingle: "produkter-oelvand.egekilde-citrus-30cl.png",
    imagePlural: "produkter-oelvand.4040-kasse.png",
    categories: ["danskvand"],
    url: "/danskvand/egekilde-brus-citrus-30x30cl",
    routeDef: {
      path: "/danskvand/egekilde-brus-citrus-30x30cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, DanskvandBC],
        title: "Egekilde Brus Citrus 30x30cl",
      },
      meta: {
        title: "Egekilde Brus Citrus 30x30cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "Danskvand",
      },
    },
    /*
    inStockText: () => (
      <React.Fragment>
        Forventes udsolgt fra producenten frem til februar 2023
      </React.Fragment>
    ),
    notice: () => (
      <React.Fragment>
        <Span blue marginTop="5px">
          Forventes udsolgt fra producenten frem til midt december 2022
        </Span>
      </React.Fragment>
    ),
    */
  },
  /* *
   *
   * Frem Sodavand
   *
   * */
  {
    varenr: 1030,
    name: "Frem Danskvand",
    generalSearchPriority: 500,
    categories: ["fremSodavand", "danskvand"],
    keywords:
      "frem sodavand fremsodavand  dansk vand apollinaris med brus i appollinaris apollonaris apolinaris appolinaris  ribe haribo harboe  flasker glasflasker 25cl 25 cl 24x25cl 24x25",
    imageSingle: "produkter-oelvand.frem-danskvand-25cl.png",
    imagePlural: "produkter-oelvand.1030-kasse.png",
    url: "/sodavand/frem-sodavand/frem-danskvand-24x25cl",
    routeDef: {
      path: "/sodavand/frem-sodavand/frem-danskvand-24x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandFremBC],
        title: "Frem Danskvand 24x25cl",
      },
      meta: {
        title: "Frem Danskvand 24x25cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "FremSodavand",
      },
    },
  },
  {
    varenr: 1040,
    name: "Frem Danskvand Citrus",
    generalSearchPriority: 500,
    categories: ["fremSodavand", "danskvand"],
    keywords:
      "frem sodavand fremsodavand  dansk vand apollinaris med brus i appollinaris apollonaris apolinaris appolinaris  ribe haribo harboe  flasker glasflasker 25cl 25 cl 24x25cl 24x25",
    imageSingle: "produkter-oelvand.frem-danskvand-citrus-25cl.png",
    imagePlural: "produkter-oelvand.1040-kasse.png",
    url: "/sodavand/frem-sodavand/frem-danskvand-citrus-24x25cl",
    routeDef: {
      path: "/sodavand/frem-sodavand/frem-danskvand-citrus-24x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandFremBC],
        title: "Frem Danskvand Citrus 24x25cl",
      },
      meta: {
        title: "Frem Danskvand Citrus 24x25cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "FremSodavand",
      },
    },
  },
  {
    varenr: 1140,
    name: "Frem Hyldeblomst Saft",
    generalSearchPriority: 500,
    categories: ["fremSodavand", "saft"],
    keywords:
      "frem sodavand fremsodavand  ribe haribo harboe  flasker glasflasker 25cl 25 cl 24x25cl 24x25 hyldeblomst",
    imageSingle: "produkter-oelvand.frem-hyldeblomst-25cl.png",
    imagePlural: "produkter-oelvand.1310-kasse.png",
    url: "/sodavand/frem-sodavand/frem-hyldeblomst-saft-24x25cl",
    routeDef: {
      path: "/sodavand/frem-sodavand/frem-hyldeblomst-saft-24x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandFremBC],
        title: "Frem Hyldeblomst Saft 24x25cl",
      },
      meta: {
        title: "Frem Hyldeblomst Saft 24x25cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "FremSodavand",
      },
    },
  },
  {
    varenr: 1120,
    name: "Frem Appelsin Light",
    generalSearchPriority: 500,
    categories: ["fremSodavand", "sukkerfri"],
    keywords:
      "sukkerfri sukker fri appelsin sodavand frem ribe haribo harboe  flasker glasflasker 25cl 25 cl 24x25cl 24x25",
    imageSingle: "produkter-oelvand.frem-appelsin-light-25cl.png",
    imagePlural: "produkter-oelvand.1120-kasse.png",
    url: "/sodavand/frem-sodavand/frem-appelsin-light-24x25cl",
    routeDef: {
      path: "/sodavand/frem-sodavand/frem-appelsin-light-24x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandFremBC],
        title: "Frem Appelsin Light 24x25cl",
      },
      meta: {
        title: "Frem Appelsin Light 24x25cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "FremSodavand",
      },
    },
  },
  {
    varenr: 1280,
    name: "Frem Blå Sport",
    generalSearchPriority: 500,
    categories: ["fremSodavand"],
    keywords:
      "frem sodavand fremsodavand  ribe haribo harboe  flasker glasflasker 25cl 25 cl 24x25cl 24x25",
    imageSingle: "produkter-oelvand.frem-blaa-sport-25cl.png",
    imagePlural: "produkter-oelvand.frem-blaa-sport-kasse.png",
    url: "/sodavand/frem-sodavand/frem-blaa-sport-24x25cl",
    routeDef: {
      path: "/sodavand/frem-sodavand/frem-blaa-sport-24x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandFremBC],
        title: "Frem Blå Sport 24x25cl",
      },
      meta: {
        title: "Frem Blå Sport 24x25cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "FremSodavand",
      },
    },
  },
  {
    varenr: 1310,
    name: "Frem Energisten",
    generalSearchPriority: 500,
    categories: ["fremSodavand"],
    keywords:
      "frem sodavand fremsodavand  ribe haribo harboe  flasker glasflasker 25cl 25 cl 24x25cl 24x25",
    imageSingle: "produkter-oelvand.frem-energisten-25cl.png",
    imagePlural: "produkter-oelvand.1310-kasse.png",
    url: "/sodavand/frem-sodavand/frem-energisten-24x25cl",
    routeDef: {
      path: "/sodavand/frem-sodavand/frem-energisten-24x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandFremBC],
        title: "Frem Energisten 24x25cl",
      },
      meta: {
        title: "Frem Energisten 24x25cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "FremSodavand",
      },
    },
  },
  {
    varenr: 1110,
    name: "Frem Appelsin",
    generalSearchPriority: 500,
    categories: ["fremSodavand"],
    keywords:
      "appelsin sodavand frem ribe haribo harboe  flasker glasflasker 25cl 25 cl 24x25cl 24x25",
    imageSingle: "produkter-oelvand.frem-appelsin-25cl.png",
    imagePlural: "produkter-oelvand.1110-kasse.png",
    url: "/sodavand/frem-sodavand/frem-appelsin-24x25cl",
    routeDef: {
      path: "/sodavand/frem-sodavand/frem-appelsin-24x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandFremBC],
        title: "Frem Appelsin 24x25cl",
      },
      meta: {
        title: "Frem Appelsin 24x25cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "FremSodavand",
      },
    },
  },
  {
    varenr: 1230,
    name: "Frem Ananas",
    generalSearchPriority: 500,
    categories: ["fremSodavand"],
    keywords:
      "sukkerfri sukker fri frem sodavand fremsodavand  ribe haribo harboe  flasker glasflasker 25cl 25 cl 24x25cl 24x25",
    imageSingle: "produkter-oelvand.frem-ananas-25cl.png",
    imagePlural: "produkter-oelvand.1110-kasse.png",
    url: "/sodavand/frem-sodavand/frem-ananas-24x25cl",
    routeDef: {
      path: "/sodavand/frem-sodavand/frem-ananas-24x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandFremBC],
        title: "Frem Ananas 24x25cl",
      },
      meta: {
        title: "Frem Ananas 24x25cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "FremSodavand",
      },
    },
  },
  {
    varenr: 1250,
    name: "Frem Abrikos",
    generalSearchPriority: 500,
    categories: ["fremSodavand"],
    keywords:
      "frem sodavand fremsodavand  ribe haribo harboe  flasker glasflasker 25cl 25 cl 24x25cl 24x25",
    imageSingle: "produkter-oelvand.frem-abrikos-25cl.png",
    imagePlural: "produkter-oelvand.1250-kasse.png",
    url: "/sodavand/frem-sodavand/frem-abrikos-24x25cl",
    routeDef: {
      path: "/sodavand/frem-sodavand/frem-abrikos-24x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandFremBC],
        title: "Frem Abrikos 24x25cl",
      },
      meta: {
        title: "Frem Abrikos 24x25cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "FremSodavand",
      },
    },
  },
  {
    varenr: 1270,
    name: "Frem Hindbær",
    generalSearchPriority: 500,
    categories: ["fremSodavand"],
    keywords:
      "frem rød sodavand rødsodavand vand fremsodavand  ribe haribo harboe  flasker glasflasker 25cl 25 cl 24x25cl 24x25 hindbærbrus",
    imageSingle: "produkter-oelvand.frem-hindbaer-25cl.png",
    imagePlural: "produkter-oelvand.1270-kasse.png",
    url: "/sodavand/frem-sodavand/frem-hindbaer-24x25cl",
    routeDef: {
      path: "/sodavand/frem-sodavand/frem-hindbaer-24x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandFremBC],
        title: "Frem Hindbær 24x25cl",
      },
      meta: {
        title: "Frem Hindbær 24x25cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "FremSodavand",
      },
    },
  },
  {
    varenr: 1290,
    name: "Frem Grøn Sport",
    generalSearchPriority: 500,
    categories: ["fremSodavand"],
    keywords:
      "frem grøn sodavand fremsodavand  ribe haribo harboe  flasker glasflasker 25cl 25 cl 24x25cl 24x25",
    imageSingle: "produkter-oelvand.frem-groen-sport-25cl.png",
    imagePlural: "produkter-oelvand.1290-kasse.png",
    url: "/sodavand/frem-sodavand/frem-groen-sport-24x25cl",
    routeDef: {
      path: "/sodavand/frem-sodavand/frem-groen-sport-24x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandFremBC],
        title: "Frem Grøn Sport 24x25cl",
      },
      meta: {
        title: "Frem Grøn Sport 24x25cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "FremSodavand",
      },
    },
  },
  {
    varenr: 1000,
    name: "Frem Vand Blandet",
    generalSearchPriority: 500,
    categories: ["fremSodavand"],
    keywords:
      "frem sodavand fremsodavand  ribe haribo harboe  flasker glasflasker 25cl 25 cl 24x25cl 24x25",
    imagePlural: "produkter-oelvand.1000-kasse.png",
    usePluralImage: true,
    popupDescription: () => (
      <React.Fragment>
        <Paragraph>
          Vi laver en blandet kasse med flere forskellige slags.
        </Paragraph>
        <Paragraph>
          Hvis I ønsker selv at blande en kasse, kan dette gøres ved at trykke
          på &quot;Bland selv&quot; og vælge antal, for de varer I ønsker at
          blande.
        </Paragraph>
      </React.Fragment>
    ),
    url: "/sodavand/frem-sodavand/frem-vand-blandet-24x25cl",
    routeDef: {
      path: "/sodavand/frem-sodavand/frem-vand-blandet-24x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandFremBC],
        title: "Frem Vand Blandet 24x25cl",
      },
      meta: {
        title: "Frem Vand Blandet 24x25cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "FremSodavand",
      },
    },
  },

  /* San Pellegrino Sodavand */

  /* San Pellegrino Sodavand Glas */
  {
    varenr: 9480,
    name: "San Pellegrino Limonata",
    generalSearchPriority: 500,
    keywords:
      "san pellegrino pelegrino pelligrino s.pellegrino s.pelegrino s.pelligrino italiensk italien sodavand flasker glasflasker 20cl 20 cl 24x20cl 24x20",
    productPageDescriptionLong: () => (
      <Paragraph>
        Limonata er i sandhed en forfriskende sodavand. Denne limonade har en
        lækker syrlig smag og duft af friskpresset citron. Limonata indeholder
        også 16% ren citronjuice uden farve- og tilsætningsstoffer.
      </Paragraph>
    ),
    imageSingle: "produkter-oelvand.san-pellegrino-limonata-20cl-2023.png",
    imagePlural: "produkter-oelvand.9480-kasse.png",
    categories: ["sanPellegrino", "sanPellegrinoSodavand"],
    url: "/sodavand/san-pellegrino-sodavand/san-pellegrino-limonata-24x20cl",
    routeDef: {
      path: "/sodavand/san-pellegrino-sodavand/san-pellegrino-limonata-24x20cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandSanPellegrinoBC],
        title: "San Pellegrino Limonata 24x20cl",
      },
      meta: {
        title: "San Pellegrino Limonata 24x20cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "SanPellegrinoSodavand",
      },
    },
  },
  {
    varenr: 9470,
    name: "San Pellegrino Aranciata",
    generalSearchPriority: 500,
    keywords:
      "san pellegrino pelegrino pelligrino s.pellegrino s.pelegrino s.pelligrino italiensk italien sodavand appelsin sodavand flasker glasflasker 20cl 20 cl 24x20cl 24x20",
    productPageDescriptionLong: () => (
      <Paragraph>
        San Pellegrino Aranciata har en unik smag og smager anderledes end så
        mange andre appelsinsodavand. Dette er dog en god ting og Aranciata
        fremhæver den ægte appelsinsmag. Denne appelsinsodavand indeholder 18%
        appelsinjuice uden farve- og tilsætningsstoffer.
      </Paragraph>
    ),
    imageSingle: "produkter-oelvand.san-pellegrino-aranciata-20cl-2023.png",
    imagePlural: "produkter-oelvand.9470-kasse.png",
    categories: ["sanPellegrino", "sanPellegrinoSodavand"],
    url: "/sodavand/san-pellegrino-sodavand/san-pellegrino-aranciata-24x20cl",
    routeDef: {
      path:
        "/sodavand/san-pellegrino-sodavand/san-pellegrino-aranciata-24x20cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandSanPellegrinoBC],
        title: "San Pellegrino Aranciata 24x20cl",
      },
      meta: {
        title: "San Pellegrino Aranciata 24x20cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "SanPellegrinoSodavand",
      },
    },
  },
  {
    varenr: 9520,
    name: "San Pellegrino Aranciata Rossa",
    generalSearchPriority: 500,
    keywords:
      "san pellegrino pelegrino pelligrino s.pellegrino s.pelegrino s.pelligrino italiensk italien sodavand appelsin sodavand blodappelsin flasker glasflasker 20cl 20 cl 24x20cl 24x20",
    productPageDescriptionLong: () => (
      <Paragraph>
        San Pellegrino Aranciata Rossa er perfekt for dem, der elsker at slukke
        deres tørst, mens du nyder den dejlige og intens duft af blodappelsin
        Denne appelsinsodavand indeholder 18% appelsinjuice uden farve- og
        tilsætningsstoffer.
      </Paragraph>
    ),
    imageSingle:
      "produkter-oelvand.san-pellegrino-aranciata-rossa-20cl-2023.png",
    imagePlural: "produkter-oelvand.9520-kasse.png",
    categories: ["sanPellegrino", "sanPellegrinoSodavand"],
    url:
      "/sodavand/san-pellegrino-sodavand/san-pellegrino-aranciata-rossa-24x20cl",
    routeDef: {
      path:
        "/sodavand/san-pellegrino-sodavand/san-pellegrino-aranciata-rossa-24x20cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandSanPellegrinoBC],
        title: "San Pellegrino Aranciata Rossa 24x20cl",
      },
      meta: {
        title: "San Pellegrino Aranciata Rossa 24x20cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "SanPellegrinoSodavand",
      },
    },
  },
  /* San Pellegrino Sodavand Dåse */
  {
    varenr: 9483,
    name: "San Pellegrino Limonata Dåse",
    generalSearchPriority: 500,
    keywords:
      "san pellegrino pelegrino pelligrino s.pellegrino s.pelegrino s.pelligrino italiensk italien sodavand aluminium alu dåser 33cl 33 cl 24x33cl 24x33 dåsesodavand dåse",
    imageSingle: "produkter-oelvand.san-pellegrino-limonata-daase-33cl.png",
    imagePlural: "produkter-oelvand.9483-kasse.png",
    categories: ["sanPellegrino", "sanPellegrinoSodavand"],
    url:
      "/sodavand/san-pellegrino-sodavand/san-pellegrino-limonata-daase-24x33c",
    routeDef: {
      path:
        "/sodavand/san-pellegrino-sodavand/san-pellegrino-limonata-daase-24x33c",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandSanPellegrinoBC],
        title: "San Pellegrino Limonata Dåse 24x33c",
      },
      meta: {
        title: "San Pellegrino Limonata Dåse 24x33c",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "SanPellegrinoSodavand",
      },
    },
  },
  {
    varenr: 9473,
    name: "San Pellegrino Aranciata Dåse",
    generalSearchPriority: 500,
    keywords:
      "san pellegrino pelegrino pelligrino s.pellegrino s.pelegrino s.pelligrino italiensk italien sodavand appelsin sodavand aluminium alu dåser 33cl 33 cl 24x33cl 24x33 dåsesodavand dåse",
    imageSingle: "produkter-oelvand.san-pellegrino-aranciata-daase-33cl.png",
    imagePlural: "produkter-oelvand.9473-kasse.png",
    categories: ["sanPellegrino", "sanPellegrinoSodavand"],
    url:
      "/sodavand/san-pellegrino-sodavand/san-pellegrino-aranciata-daase-24x33cl",
    routeDef: {
      path:
        "/sodavand/san-pellegrino-sodavand/san-pellegrino-aranciata-daase-24x33cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandSanPellegrinoBC],
        title: "San Pellegrino Aranciata Dåse 24x33cl",
      },
      meta: {
        title: "San Pellegrino Aranciata Dåse 24x33cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "SanPellegrinoSodavand",
      },
    },
  },
  {
    varenr: 9523,
    name: "San Pellegrino Aranci Rossa Dåse",
    generalSearchPriority: 500,
    keywords:
      "san pellegrino pelegrino pelligrino s.pellegrino s.pelegrino s.pelligrino italiensk italien sodavand appelsin sodavand blodappelsin aluminium alu dåser 33cl 33 cl 24x33cl 24x33 dåsesodavand dåse",
    imageSingle:
      "produkter-oelvand.san-pellegrino-aranciata-rossa-daase-33cl.png",
    imagePlural: "produkter-oelvand.9523-kasse.png",
    categories: ["sanPellegrino", "sanPellegrinoSodavand"],
    url:
      "/sodavand/san-pellegrino-sodavand/san-pellegrino-aranci-rossa-daase-24x33cl",
    routeDef: {
      path:
        "/sodavand/san-pellegrino-sodavand/san-pellegrino-aranci-rossa-daase-24x33cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandSanPellegrinoBC],
        title: "San Pellegrino Aranci Rossa Dåse 24x33cl",
      },
      meta: {
        title: "San Pellegrino Aranci Rossa Dåse 24x33cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "SanPellegrinoSodavand",
      },
    },
  },
  {
    varenr: 9503,
    name: "San Pellegrino Pompelmo Dåse",
    generalSearchPriority: 500,
    keywords:
      "san pellegrino pelligrino s.pellegrino s.pelegrino s.pelligrino italiensk italien sodavand aluminium alu dåser 33cl 33 cl 24x33cl 24x33 dåsesodavand dåse",
    imageSingle: "produkter-oelvand.san-pellegrino-pompelmo-daase-33cl.png",
    imagePlural: "produkter-oelvand.9503-kasse.png",
    categories: ["sanPellegrino", "sanPellegrinoSodavand"],
    url:
      "/sodavand/san-pellegrino-sodavand/san-pellegrino-pompelmo-daase-24x33cl",
    routeDef: {
      path:
        "/sodavand/san-pellegrino-sodavand/san-pellegrino-pompelmo-daase-24x33cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandSanPellegrinoBC],
        title: "San Pellegrino Pompelmo Dåse 24x33cl",
      },
      meta: {
        title: "San Pellegrino Pompelmo Dåse 24x33cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "SanPellegrinoSodavand",
      },
    },
  },
  {
    varenr: 9443,
    name: "San Pellegrino Melograno E Arancia Dåse",
    generalSearchPriority: 500,
    keywords:
      "san pellegrino pelligrino s.pellegrino s.pelegrino s.pelligrino italiensk italien sodavand aluminium alu dåser 33cl 33 cl 24x33cl 24x33 dåsesodavand dåse",
    imageSingle:
      "produkter-oelvand.san-pellegrino-melograno-e-arancia-daase-33cl.png",
    imagePlural:
      "produkter-oelvand.san-pellegrino-melograno-e-arancia-daase-kasse.png",
    categories: ["sanPellegrino", "sanPellegrinoSodavand"],
    url:
      "/sodavand/san-pellegrino-sodavand/san-pellegrino-melograno-e-arancia-daase",
    routeDef: {
      path:
        "/sodavand/san-pellegrino-sodavand/san-pellegrino-melograno-e-arancia-daase",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandSanPellegrinoBC],
        title: "San Pellegrino Melograno E Arancia Dåse 24x33cl",
      },
      meta: {
        title: "San Pellegrino Melograno E Arancia Dåse 24x33cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "SanPellegrinoSodavand",
      },
    },
  },
  /* Schweppes */
  {
    varenr: 2170,
    name: "Schweppes Lemon Fusion",
    generalSearchPriority: 500,
    keywords:
      "sodavand flasker glasflasker 25cl 25 cl 30x25cl 30x25 sweppes schweppers",
    imageSingle: "produkter-oelvand.schweppes-lemon-fusion-25cl.png",
    imagePlural: "produkter-oelvand.2170-kasse.png",
    categories: ["schweppes"],
    url: "/sodavand/schweppes/schweppes-lemon-fusion-30x25cl",
    routeDef: {
      path: "/sodavand/schweppes/schweppes-lemon-fusion-30x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandSchweppesBC],
        title: "Schweppes Lemon Fusion 30x25cl",
      },
      meta: {
        title: "Schweppes Lemon Fusion 30x25cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "Schweppes",
      },
    },
  },
  {
    varenr: 2172,
    name: "Schweppes Lemon Fusion",
    generalSearchPriority: 500,
    keywords:
      "sodavand flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50",
    imageSingle: "produkter-oelvand.schweppes-lemon-fusion-50cl.png",
    imagePlural: "produkter-oelvand.2172-kasse.png",
    categories: ["schweppes"],
    url: "/sodavand/schweppes/schweppes-lemon-fusion-24x50cl",
    routeDef: {
      path: "/sodavand/schweppes/schweppes-lemon-fusion-24x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandSchweppesBC],
        title: "Schweppes Lemon Fusion 24x50cl",
      },
      meta: {
        title: "Schweppes Lemon Fusion 24x50cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "Schweppes",
      },
    },
  },
  {
    varenr: 2171,
    name: "Schweppes Lemon Fusion",
    generalSearchPriority: 450,
    keywords:
      "sodavand flasker plastflasker plastikflasker 125cl 125 cl 8x125cl 8x125 sweppes schweppers",
    imageSingle: "produkter-oelvand.schweppes-lemon-fusion-150cl.png",
    imagePlural: "produkter-oelvand.2171-kasse.png",
    categories: ["schweppes"],
    url: "/sodavand/schweppes/schweppes-lemon-fusion-8x125cl",
    routeDef: {
      path: "/sodavand/schweppes/schweppes-lemon-fusion-8x125cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandSchweppesBC],
        title: "Schweppes Lemon Fusion 8x125cl",
      },
      meta: {
        title: "Schweppes Lemon Fusion 8x125cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "Schweppes",
      },
    },
  },
  {
    varenr: 2210,
    name: "Schweppes Indian Tonic",
    generalSearchPriority: 500,
    searchSignificance: 10,
    keywords:
      "sodavand flasker glasflasker 25cl 25 cl 30x25cl 30x25 sweppes schweppers tonicvand tonic fever",
    imageSingle: "produkter-oelvand.schweppes-indian-tonic-25cl.png",
    imagePlural: "produkter-oelvand.2210-kasse.png",
    categories: ["schweppes"],
    url: "/sodavand/schweppes/schweppes-tonic-30x25cl",
    routeDef: {
      path: "/sodavand/schweppes/schweppes-tonic-30x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandSchweppesBC],
        title: "Schweppes Indian Tonic 30x25cl",
      },
      meta: {
        title: "Schweppes Indian Tonic 30x25cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "Schweppes",
      },
    },
  },
  {
    varenr: 2211,
    name: "Schweppes Indian Tonic",
    generalSearchPriority: 450,
    searchSignificance: 10,
    keywords:
      "sodavand flasker plastflasker plastikflasker 125cl 125 cl 8x125cl 8x125 tonicvand tonic",
    imageSingle: "produkter-oelvand.schweppes-tonic-125cl.png",
    imagePlural: "produkter-oelvand.2211-kasse.png",
    categories: ["schweppes"],
    url: "/sodavand/schweppes/schweppes-tonic-8x125cl",
    routeDef: {
      path: "/sodavand/schweppes/schweppes-tonic-8x125cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandSchweppesBC],
        title: "Schweppes Tonic 8x125cl",
      },
      meta: {
        title: "Schweppes Tonic 8x125cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "Schweppes",
      },
    },
  },
  {
    varenr: 2220,
    name: "Schweppes Ginger Ale",
    generalSearchPriority: 500,
    keywords:
      "sodavand flasker glasflasker 25cl 25 cl 30x25cl 30x25 sweppes schweppers gingerbeer beer",
    imageSingle: "produkter-oelvand.schweppes-ginger-ale-25cl.png",
    imagePlural: "produkter-oelvand.2220-kasse.png",
    categories: ["schweppes"],
    url: "/sodavand/schweppes/schweppes-ginger-ale-30x25cl",
    routeDef: {
      path: "/sodavand/schweppes/schweppes-ginger-ale-30x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandSchweppesBC],
        title: "Schweppes Ginger Ale 30x25cl",
      },
      meta: {
        title: "Schweppes Ginger Ale 30x25cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "Schweppes",
      },
    },
  },
  {
    varenr: 2221,
    name: "Schweppes Ginger Ale",
    generalSearchPriority: 450,
    keywords:
      "sodavand flasker plastflasker plastikflasker 125cl 125 cl 8x125cl 8x125 sweppes schweppers",
    imageSingle: "produkter-oelvand.schweppes-ginger-ale-150cl.png",
    categories: ["schweppes"],
    url: "/sodavand/schweppes/schweppes-ginger-ale-8x125cl",
    routeDef: {
      path: "/sodavand/schweppes/schweppes-ginger-ale-8x125cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandSchweppesBC],
        title: "Schweppes Ginger Ale 8x125cl",
      },
      meta: {
        title: "Schweppes Ginger Ale 8x125cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "Schweppes",
      },
    },
  },
  /* Faxe Kondi */
  {
    varenr: 4310,
    name: "Faxe Kondi",
    generalSearchPriority: 500,
    searchSignificance: 15,
    categories: ["faxeKondi"],
    keywords:
      "sodavand kondivand faxekondi fakse kondi flasker glasflasker 25cl 25 cl 30x25cl 30x25",
    imageSingle: "produkter-oelvand.faxe-kondi-25cl-glas.png",
    imagePlural: "produkter-oelvand.4310-kasse.png",
    url: "/sodavand/faxe-kondi/faxe-kondi-30x25cl",
    routeDef: {
      path: "/sodavand/faxe-kondi/faxe-kondi-30x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandFaxeKondiBC],
        title: "Faxe Kondi 30x25cl",
      },
      meta: {
        title: "Faxe Kondi 30x25cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "FaxeKondi",
      },
    },
  },
  {
    varenr: 4314,
    name: "Faxe Kondi",
    generalSearchPriority: 500,
    searchSignificance: 14,
    categories: ["faxeKondi"],
    keywords:
      "sodavand kondivand faxekondi fakse kondi flasker plastflasker plastikflasker 33cl 33 cl 24x33cl 24x33",
    imageSingle: "produkter-oelvand.faxe-kondi-33cl.png",
    imagePlural: "produkter-oelvand.faxe-kondi-33cl-ramme.png",
    url: "/sodavand/faxe-kondi/faxe-kondi-24x33cl",
    routeDef: {
      path: "/sodavand/faxe-kondi/faxe-kondi-24x33cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandFaxeKondiBC],
        title: "Faxe Kondi 24x33cl",
      },
      meta: {
        title: "Faxe Kondi 24x33cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "FaxeKondi",
      },
    },
  },
  {
    varenr: 4312,
    name: "Faxe Kondi",
    generalSearchPriority: 500,
    searchSignificance: 13,
    categories: ["faxeKondi"],
    keywords:
      "sodavand kondivand faxekondi fakse kondi flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50",
    imageSingle: "produkter-oelvand.faxe-kondi-50cl-1.png",
    imagePlural: "produkter-oelvand.4312-kasse.png",
    url: "/sodavand/faxe-kondi/faxe-kondi-24x50cl",
    routeDef: {
      path: "/sodavand/faxe-kondi/faxe-kondi-24x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandFaxeKondiBC],
        title: "Faxe Kondi 24x50cl",
      },
      meta: {
        title: "Faxe Kondi 24x50cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "FaxeKondi",
      },
    },
  },
  {
    varenr: 4311,
    name: "Faxe Kondi",
    generalSearchPriority: 450,
    searchSignificance: 12,
    categories: ["faxeKondi"],
    keywords:
      "sodavand kondivand faxekondi fakse kondi flasker plastflasker plastikflasker 150cl 150 cl 6x150cl 6x150",
    imageSingle: "produkter-oelvand.faxe-kondi-150cl-1.png",
    imagePlural: "produkter-oelvand.4311-kasse.png",
    url: "/sodavand/faxe-kondi/faxe-kondi-6x150cl",
    routeDef: {
      path: "/sodavand/faxe-kondi/faxe-kondi-6x150cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandFaxeKondiBC],
        title: "Faxe Kondi 6x150cl",
      },
      meta: {
        title: "Faxe Kondi 6x150cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "FaxeKondi",
      },
    },
  },
  {
    varenr: 4330,
    name: "Faxe Kondi 0 kal",
    generalSearchPriority: 500,
    searchSignificance: 11,
    categories: ["faxeKondi", "sukkerfri"],
    keywords:
      "free sukkerfri sukker fri sodavand kondivand faxekondi fakse kondi flasker glasflasker 25cl 25 cl 30x25cl 30x25 light free",
    imageSingle: "produkter-oelvand.faxe-kondi-0kal-25cl-glas.png",
    imagePlural: "produkter-oelvand.4330-kasse.png",
    url: "/sodavand/faxe-kondi/faxe-kondi-0-kal-30x25cl",
    routeDef: {
      path: "/sodavand/faxe-kondi/faxe-kondi-0-kal-30x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandFaxeKondiBC],
        title: "Faxe Kondi 0 kal 30x25cl",
      },
      meta: {
        title: "Faxe Kondi 0 kal 30x25cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "FaxeKondi",
      },
    },
  },
  {
    varenr: 4334,
    name: "Faxe Kondi 0 kal",
    generalSearchPriority: 500,
    searchSignificance: 10,
    categories: ["faxeKondi", "sukkerfri"],
    keywords:
      "free sukkerfri sukker fri sodavand kondivand faxekondi fakse kondi flasker plastflasker plastikflasker 33cl 33 cl 24x33cl 24x33 light free",
    imageSingle: "produkter-oelvand.faxe-kondi-0kal-33cl.png",
    imagePlural: "produkter-oelvand.faxe-kondi-0kal-33cl-ramme.png",
    url: "/sodavand/faxe-kondi/faxe-kondi-0-kal-24x33cl",
    routeDef: {
      path: "/sodavand/faxe-kondi/faxe-kondi-0-kal-24x33cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandFaxeKondiBC],
        title: "Faxe Kondi 0 kal 24x33cl",
      },
      meta: {
        title: "Faxe Kondi 0 kal 24x33cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "FaxeKondi",
      },
    },
  },
  {
    varenr: 4332,
    name: "Faxe Kondi 0 kal",
    generalSearchPriority: 500,
    searchSignificance: 9,
    categories: ["faxeKondi", "sukkerfri"],
    keywords:
      "free sukkerfri sukker fri sodavand kondivand faxekondi fakse kondi flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50 light free",
    imageSingle: "produkter-oelvand.faxe-kondi-free-50cl-1.png",
    imagePlural: "produkter-oelvand.4332-kasse.png",
    url: "/sodavand/faxe-kondi/faxe-kondi-0-kal-24x50cl",
    routeDef: {
      path: "/sodavand/faxe-kondi/faxe-kondi-0-kal-24x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandFaxeKondiBC],
        title: "Faxe Kondi 0 kal 24x50cl",
      },
      meta: {
        title: "Faxe Kondi 0 kal 24x50cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "FaxeKondi",
      },
    },
  },
  {
    varenr: 4110,
    name: "Faxe Kondi Appelsin 0 kal",
    generalSearchPriority: 500,
    searchSignificance: 8,
    categories: ["faxeKondi", "sukkerfri"],
    keywords:
      "free sukkerfri sukker fri sodavand kondivand faxekondi fakse kondi flasker glasflasker 25cl 25 cl 30x25cl 30x25 light free",
    imageSingle: "produkter-oelvand.faxe-kondi-appelsin-0kal-25cl-glas.png",
    imagePlural:
      "produkter-oelvand.faxe-kondi-appelsin-0kal-25cl-glas-ramme.png",
    url: "/sodavand/faxe-kondi/faxe-kondi-appelsin-0-kal-30x25cl",
    routeDef: {
      path: "/sodavand/faxe-kondi/faxe-kondi-appelsin-0-kal-30x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandFaxeKondiBC],
        title: "Faxe Kondi Appelsin 0 kal 30x25cl",
      },
      meta: {
        title: "Faxe Kondi Appelsin 0 kal 30x25cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "FaxeKondi",
      },
    },
  },
  {
    varenr: 4313,
    name: "Faxe Kondi Dåse",
    generalSearchPriority: 500,
    searchSignificance: 5,
    categories: ["faxeKondi"],
    keywords:
      "sodavand kondivand faxekondi fakse kondi aluminium alu dåser 33cl 33 cl 24x33cl 24x33 dåsesodavand dåse",
    imageSingle: "produkter-oelvand.faxe-kondi-daase-33cl-3.png",
    imagePlural: "produkter-oelvand.4313-kasse.png",
    url: "/sodavand/faxe-kondi/faxe-kondi-daase-24x33cl",
    routeDef: {
      path: "/sodavand/faxe-kondi/faxe-kondi-daase-24x33cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandFaxeKondiBC],
        title: "Faxe Kondi Dåse 24x33cl",
      },
      meta: {
        title: "Faxe Kondi Dåse 24x33cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "FaxeKondi",
      },
    },
  },
  {
    varenr: 4333,
    name: "Faxe Kondi 0 kal Dåse",
    searchSignificance: 5,
    generalSearchPriority: 500,
    categories: ["faxeKondi", "sukkerfri"],
    keywords:
      "free sukkerfri sukker fri sodavand kondivand faxekondi fakse kondi aluminium alu dåser 33cl 33 cl 24x33cl 24x33 dåsesodavand dåse light free",
    imageSingle: "produkter-oelvand.faxe-kondi-0kal-daase-33cl-2.png",
    imagePlural: "produkter-oelvand.4333-kasse.png",
    url: "/sodavand/faxe-kondi/faxe-kondi-0-kal-daase-24x33cl",
    routeDef: {
      path: "/sodavand/faxe-kondi/faxe-kondi-0-kal-daase-24x33cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandFaxeKondiBC],
        title: "Faxe Kondi 0 kal Dåse 24x33cl",
      },
      meta: {
        title: "Faxe Kondi 0 kal Dåse 24x33cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "FaxeKondi",
      },
    },
  },
  /* Tuborg Squash */
  {
    varenr: 3110,
    name: "Tuborg Squash",
    generalSearchPriority: 500,
    categories: ["tuborgSquash"],
    keywords: "appelsin sodavand flasker glasflasker 25cl 25 cl 30x25cl 30x25",
    imageSingle: "produkter-oelvand.tuborg-squash-2025-25cl.png",
    imagePlural: "produkter-oelvand.3110-kasse.png",
    url: "/sodavand/tuborg-squash/tuborg-squash-30x25cl",
    routeDef: {
      path: "/sodavand/tuborg-squash/tuborg-squash-30x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandTuborgSquashBC],
        title: "Tuborg Squash 30x25cl",
      },
      meta: {
        title: "Tuborg Squash 30x25cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "TuborgSquash",
      },
    },
  },
  {
    varenr: 3140,
    name: "Tuborg Squash Sportsvand Sukkerfri",
    generalSearchPriority: 500,
    categories: ["tuborgSquash"],
    keywords: "appelsin sodavand flasker glasflasker 25cl 25 cl 30x25cl 30x25",
    imageSingle:
      "produkter-oelvand.tuborg-squash-sportsvand-sukkerfri-25cl.png",
    imagePlural:
      "produkter-oelvand.tuborg-squash-sportsvand-sukkerfri-25cl-kasse.png",
    url: "/sodavand/tuborg-squash/tuborg-squash-sportsvand-sukkerfri-30x25cl",
    routeDef: {
      path:
        "/sodavand/tuborg-squash/tuborg-squash-sportsvand-sukkerfri-30x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandTuborgSquashBC],
        title: "Tuborg Squash Sportsvand Sukkerfri 30x25cl",
      },
      meta: {
        title: "Tuborg Squash Sportsvand Sukkerfri 30x25cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "TuborgSquash",
      },
    },
  },
  {
    varenr: 3112,
    name: "Tuborg Squash",
    generalSearchPriority: 500,
    categories: ["tuborgSquash"],
    keywords:
      "appelsin sodavand flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50",
    imageSingle: "produkter-oelvand.tuborg-squash-2025-50cl.png",
    imagePlural: "produkter-oelvand.3112-kasse.png",
    url: "/sodavand/tuborg-squash/tuborg-squash-24x50cl",
    routeDef: {
      path: "/sodavand/tuborg-squash/tuborg-squash-24x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandTuborgSquashBC],
        title: "Tuborg Squash 24x50cl",
      },
      meta: {
        title: "Tuborg Squash 24x50cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "TuborgSquash",
      },
    },
  },
  {
    varenr: 3111,
    name: "Tuborg Squash",
    generalSearchPriority: 450,
    keywords:
      "appelsin sodavand flasker plastflasker plastikflasker 150cl 150 cl 8x150cl 8x150",
    imageSingle: "produkter-oelvand.tuborg-squash-2025-150cl.png",
    imagePlural: "produkter-oelvand.3111-kasse.png",
    categories: ["tuborgSquash"],
    url: "/sodavand/tuborg-squash/tuborg-squash-8x150cl",
    routeDef: {
      path: "/sodavand/tuborg-squash/tuborg-squash-8x150cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandTuborgSquashBC],
        title: "Tuborg Squash 8x150cl",
      },
      meta: {
        title: "Tuborg Squash 8x150cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "TuborgSquash",
      },
    },
  },
  {
    varenr: 3113,
    name: "Tuborg Squash Dåse",
    generalSearchPriority: 500,
    keywords:
      "appelsin sodavand aluminium alu dåser 33cl 33 cl 24x33cl 24x33 dåsesodavand dåse",
    imageSingle: "produkter-oelvand.tuborg-squash-2025-daase.png",
    imagePlural: "produkter-oelvand.3113-kasse.png",
    categories: ["tuborgSquash"],
    url: "/sodavand/tuborg-squash/tuborg-squash-daase-24x33cl",
    routeDef: {
      path: "/sodavand/tuborg-squash/tuborg-squash-daase-24x33cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandTuborgSquashBC],
        title: "Tuborg Squash Dåse 24x33cl",
      },
      meta: {
        title: "Tuborg Squash Dåse 24x33cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "TuborgSquash",
      },
    },
  },
  {
    varenr: 3123,
    name: "Tuborg Squash Light Dåse",
    generalSearchPriority: 500,
    keywords:
      "appelsin sodavand aluminium alu dåser 33cl 33 cl 24x33cl 24x33 dåsesodavand dåse",
    imageSingle:
      "produkter-oelvand.tuborg-squash-2025-daase-sukkerfri-33cl.png",
    imagePlural:
      "produkter-oelvand.tuborg-squash-appelsin-light-daase-ramme.png",
    categories: ["tuborgSquash"],
    url: "/sodavand/tuborg-squash/tuborg-squash-light-daase-24x33cl",
    routeDef: {
      path: "/sodavand/tuborg-squash/tuborg-squash-light-daase-24x33cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandTuborgSquashBC],
        title: "Tuborg Squash Light Dåse 24x33cl",
      },
      meta: {
        title: "Tuborg Squash Light Dåse 24x33cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "TuborgSquash",
      },
    },
  },
  /* Kinley */
  {
    varenr: 5270,
    name: "Kinley Hindbær",
    generalSearchPriority: 500,
    categories: ["kinleySodavand"],
    keywords:
      "rød sodavand rødsodavand vand vand flasker glasflasker 25cl 25 cl 30x25cl 30x25 hindbærbrus",
    imageSingle: "produkter-oelvand.kinley-hindbaer-25cl.png",
    imagePlural: "produkter-oelvand.5270-kasse.png",
    url: "/sodavand/kinley/kinley-hindbaer-30x25cl",
    routeDef: {
      path: "/sodavand/kinley/kinley-hindbaer-30x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandKinleyBC],
        title: "Kinley Hindbær 30x25cl",
      },
      meta: {
        title: "Kinley Hindbær 30x25cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "Kinley",
      },
    },
  },
  {
    varenr: 5070,
    name: "Kinley Citron",
    generalSearchPriority: 500,
    categories: ["kinleySodavand"],
    keywords:
      "gul sodavand vand citrus flasker glasflasker 25cl 25 cl 30x25cl 30x25 citronvand",
    imageSingle: "produkter-oelvand.kinley-citron-25cl.png",
    imagePlural: "produkter-oelvand.5070-kasse.png",
    url: "/sodavand/kinley/kinley-citron-30x25cl",
    routeDef: {
      path: "/sodavand/kinley/kinley-citron-30x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandKinleyBC],
        title: "Kinley Citron 30x25cl",
      },
      meta: {
        title: "Kinley Citron 30x25cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "Kinley",
      },
    },
  },
  /* Pepsi */
  {
    varenr: 4350,
    name: "Pepsi",
    generalSearchPriority: 500,
    categories: ["pepsi"],
    keywords:
      "sodavand flasker glasflasker 25cl 25 cl 30x25cl 30x25 pepsi cola pepsie pesie",
    imageSingle: "produkter-oelvand.pepsi-25cl.png",
    imagePlural: "produkter-oelvand.4350-kasse.png",
    url: "/sodavand/pepsi-cola/pepsi-30x25cl",
    routeDef: {
      path: "/sodavand/pepsi-cola/pepsi-30x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandPepsiColaBC],
        title: "Pepsi 30x25cl",
      },
      meta: {
        title: "Pepsi 30x25cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "Pepsi",
      },
    },
  },
  {
    varenr: 4360,
    name: "Pepsi Max",
    generalSearchPriority: 500,
    categories: ["pepsi", "sukkerfri"],
    keywords:
      "free sukkerfri sukker fri sodavand flasker glasflasker 25cl 25 cl 30x25cl 30x25 pepsi cola pepsie pesie pepsimax",
    imageSingle: "produkter-oelvand.pepsi-max-25cl.png",
    imagePlural: "produkter-oelvand.4360-kasse.png",
    url: "/sodavand/pepsi-cola/pepsi-max-30x25cl",
    routeDef: {
      path: "/sodavand/pepsi-cola/pepsi-max-30x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandPepsiColaBC],
        title: "Pepsi Max 30x25cl",
      },
      meta: {
        title: "Pepsi Max 30x25cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "Pepsi",
      },
    },
  },
  {
    varenr: 4352,
    name: "Pepsi",
    generalSearchPriority: 500,
    categories: ["pepsi"],
    keywords:
      "sodavand flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50 pepsi cola pepsie pesie",
    imageSingle: "produkter-oelvand.pepsi-50cl.png",
    imagePlural: "produkter-oelvand.4352-kasse.png",
    url: "/sodavand/pepsi-cola/pepsi-24x50cl",
    routeDef: {
      path: "/sodavand/pepsi-cola/pepsi-24x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandPepsiColaBC],
        title: "Pepsi 24x50cl",
      },
      meta: {
        title: "Pepsi 24x50cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "Pepsi",
      },
    },
  },
  {
    varenr: 4382,
    name: "Pepsi Max Lime",
    generalSearchPriority: 500,
    categories: ["pepsi", "sukkerfri"],
    keywords:
      "free sukkerfri sukker fri sodavand flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50 pepsi cola pepsie pesie pepsimax pepsimaxlime",
    imageSingle: "produkter-oelvand.pepsi-max-lime-50cl.png",
    imagePlural: "produkter-oelvand.4382-kasse.png",
    url: "/sodavand/pepsi-cola/pepsi-max-lime-24x50cl",
    routeDef: {
      path: "/sodavand/pepsi-cola/pepsi-max-lime-24x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandPepsiColaBC],
        title: "Pepsi Max Lime 24x50cl",
      },
      meta: {
        title: "Pepsi Max Lime 24x50cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "Pepsi",
      },
    },
  },
  {
    varenr: 4362,
    name: "Pepsi Max",
    generalSearchPriority: 500,
    categories: ["pepsi", "sukkerfri"],
    keywords:
      "free sukkerfri sukker fri sodavand flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50",
    imageSingle: "produkter-oelvand.pepsi-max-50cl.png",
    imagePlural: "produkter-oelvand.4362-kasse.png",
    url: "/sodavand/pepsi-cola/pepsi-max-24x50cl",
    routeDef: {
      path: "/sodavand/pepsi-cola/pepsi-max-24x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandPepsiColaBC],
        title: "Pepsi Max 24x50cl",
      },
      meta: {
        title: "Pepsi Max 24x50cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "Pepsi",
      },
    },
  },
  {
    varenr: 4372,
    name: "Pepsi Max Lime",
    generalSearchPriority: 500,
    categories: ["pepsi", "sukkerfri"],
    keywords:
      "free sukkerfri sukker fri sodavand flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50",
    imageSingle: "produkter-oelvand.pepsi-max-lime-50cl.png",
    // imagePlural: "produkter-oelvand.pepse-max-lime-50cl-kasse.png",
    url: "/sodavand/pepsi-cola/pepsi-max-lime-24x50cl",
    routeDef: {
      path: "/sodavand/pepsi-cola/pepsi-max-lime-24x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandPepsiColaBC],
        title: "Pepsi Max Lime 24x50cl",
      },
      meta: {
        title: "Pepsi Max Lime 24x50cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "Pepsi",
      },
    },
  },
  {
    varenr: 4361,
    name: "Pepsi Max",
    generalSearchPriority: 450,
    categories: ["pepsi", "sukkerfri"],
    keywords:
      "free sukkerfri sukker fri sodavand flasker plastflasker plastikflasker 150cl 150 cl 6x150cl 6x150",
    imageSingle: "produkter-oelvand.pepsi-max-150cl.png",
    imagePlural: "produkter-oelvand.4361-kasse.png",
    url: "/sodavand/pepsi-cola/pepsi-max-6x150cl",
    routeDef: {
      path: "/sodavand/pepsi-cola/pepsi-max-6x150cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandPepsiColaBC],
        title: "Pepsi Max 6x150cl",
      },
      meta: {
        title: "Pepsi Max 6x150cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "Pepsi",
      },
    },
  },
  {
    varenr: 4363,
    name: "Pepsi Max Dåse",
    generalSearchPriority: 500,
    categories: ["pepsi", "sukkerfri"],
    keywords:
      "free sukkerfri sukker fri sodavand aluminium alu dåser 33cl 33 cl 24x33cl 24x33 pepsi cola pepsie pesie dåsesodavand dåse pepsimax",
    imageSingle: "produkter-oelvand.pepsi-max-daase-33cl.png",
    imagePlural: "produkter-oelvand.4363-kasse.png",
    url: "/sodavand/pepsi-cola/pepsi-max-daase",
    routeDef: {
      path: "/sodavand/pepsi-cola/pepsi-max-daase",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandPepsiColaBC],
        title: "Pepsi Max Dåse",
      },
      meta: {
        title: "Pepsi Max Dåse",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "Pepsi",
      },
    },
  },
  /* Aquador */
  {
    varenr: 9233,
    name: "Aqua d'or uden brus",
    generalSearchPriority: 500,
    searchSignificance: 20,
    keywords:
      "aquador aqua aqua'd'or aquad'or door dor auqa dór kildevand vand 0,3 0.3 30 30cl flasker plastflasker plastikflasker cl 20x30cl 20x30 naturligt mineralvand",
    imageSingle: "produkter-oelvand.aquador-30cl.png",
    imagePlural: "produkter-oelvand.9233-kasse.png",
    palleItem: 9239,
    freeDeliveryBasis: 0.1,
    categories: ["kildevand", "aquador"],
    url: "/kildevand/aquador/aqua-dor-uden-brus-20x30cl",
    routeDef: {
      path: "/kildevand/aquador/aqua-dor-uden-brus-20x30cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandAquadorBC],
        title: "Aqua d'or uden brus 20x30cl",
      },
      meta: {
        title: "Aqua d'or uden brus 20x30cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "Aquador",
      },
    },
    popupDescription: () => (
      <Paragraph>
        Analytisk sammensætning i mg/l: Kalcium 36, Magnesium 3, Natrium 10,
        Hydrogenkarbonat 120, Sulfat 3 og Nitrat &lt;1. Hårdhed 6, PH 7.
      </Paragraph>
    ),
    productPageDescription: () => (
      <Paragraph fontSize="12px">
        Analytisk sammensætning i mg/l: Kalcium 36, Magnesium 3, Natrium 10,
        Hydrogenkarbonat 120, Sulfat 3 og Nitrat &lt;1. Hårdhed 6, PH 7.
      </Paragraph>
    ),
  },
  {
    varenr: 9222,
    name: "Aqua d'or uden brus",
    generalSearchPriority: 500,
    searchSignificance: 20,
    keywords:
      "aquador aqua aqua'd'or aquad'or door dor auqa dór kildevand vand flasker plastflasker plastikflasker 50cl 50 cl 20x50cl 20x50 naturligt mineralvand",
    imageSingle: "produkter-oelvand.aquador-50cl.png",
    imagePlural: "produkter-oelvand.9222-kasse.png",
    palleItem: 9212,
    categories: ["kildevand", "aquador"],
    url: "/kildevand/aquador/aqua-dor-uden-brus-20x50cl",
    routeDef: {
      path: "/kildevand/aquador/aqua-dor-uden-brus-20x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandAquadorBC],
        title: "Aqua d'or uden brus 20x50cl",
      },
      meta: {
        title: "Aqua d'or uden brus 20x50cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "Aquador",
      },
    },
    popupDescription: () => (
      <Paragraph>
        Analytisk sammensætning i mg/l: Kalcium 36, Magnesium 3, Natrium 10,
        Hydrogenkarbonat 120, Sulfat 3 og Nitrat &lt;1. Hårdhed 6, PH 7.
      </Paragraph>
    ),
    productPageDescription: () => (
      <Paragraph fontSize="12px">
        Analytisk sammensætning i mg/l: Kalcium 36, Magnesium 3, Natrium 10,
        Hydrogenkarbonat 120, Sulfat 3 og Nitrat &lt;1. Hårdhed 6, PH 7.
      </Paragraph>
    ),
  },
  {
    varenr: 9221,
    name: "Aqua d'or uden brus",
    generalSearchPriority: 500,
    searchSignificance: 20,
    keywords:
      "aquador aqua aqua'd'or aquad'or door dor auqa dór kildevand vand flasker plastflasker plastikflasker 125cl 125 cl 6x125cl 6x125 naturligt mineralvand",
    imageSingle: "produkter-oelvand.aquador-125cl-1.png",
    imagePlural: "produkter-oelvand.aquador-125cl-kolli.png",
    palleItem: 9219,
    categories: ["kildevand", "aquador"],
    url: "/kildevand/aquador/aqua-dor-uden-brus-6x125cl",
    routeDef: {
      path: "/kildevand/aquador/aqua-dor-uden-brus-6x125cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandAquadorBC],
        title: "Aqua d'or uden brus 6x125cl",
      },
      meta: {
        title: "Aqua d'or uden brus 6x125cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "Aquador",
      },
    },
    popupDescription: () => (
      <Paragraph>
        Analytisk sammensætning i mg/l: Kalcium 36, Magnesium 3, Natrium 10,
        Hydrogenkarbonat 120, Sulfat 3 og Nitrat &lt;1. Hårdhed 6, PH 7.
      </Paragraph>
    ),
    productPageDescription: () => (
      <Paragraph fontSize="12px">
        Analytisk sammensætning i mg/l: Kalcium 36, Magnesium 3, Natrium 10,
        Hydrogenkarbonat 120, Sulfat 3 og Nitrat &lt;1. Hårdhed 6, PH 7.
      </Paragraph>
    ),
  },
  {
    varenr: 9033,
    name: "Aqua d'or blid brus",
    generalSearchPriority: 500,
    searchSignificance: 15,
    keywords:
      "aquador aqua aqua'd'or aquad'or door dor auqa dór med m brus kildevand vand 0,3 0.3 30 30cl flasker plastflasker plastikflasker cl 15x30cl 15x30 naturligt mineralvand",
    imageSingle: "produkter-oelvand.aquador-30cl-brus.png",
    imagePlural: "produkter-oelvand.aquador-15x30cl-brus-ramme-1.png",
    categories: ["kildevand", "aquador"],
    url: "/kildevand/aquador/aqua-dor-blid-brus-15x30cl",
    routeDef: {
      path: "/kildevand/aquador/aqua-dor-blid-brus-15x30cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandAquadorBC],
        title: "Aqua d'or blid brus 15x30cl",
      },
      meta: {
        title: "Aqua d'or blid brus 15x30cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "Aquador",
      },
    },
    popupDescription: () => (
      <Paragraph>
        Tilsat kulsyre. Analytisk sammensætning i mg/l: Kalcium 36, Magnesium 3,
        Natrium 10, Hydrogenkarbonat 120, Sulfat 3 og Nitrat &lt;1. Hårdhed 6,
        PH 7.
      </Paragraph>
    ),
    productPageDescription: () => (
      <Paragraph fontSize="12px">
        Tilsat kulsyre. Analytisk sammensætning i mg/l: Kalcium 36, Magnesium 3,
        Natrium 10, Hydrogenkarbonat 120, Sulfat 3 og Nitrat &lt;1. Hårdhed 6,
        PH 7.
      </Paragraph>
    ),
  },
  {
    varenr: 9032,
    name: "Aqua d'or blid brus",
    generalSearchPriority: 500,
    searchSignificance: 15,
    keywords:
      "aquador aqua aqua'd'or aquad'or door dor auqa dór kildevand med m brus vand flasker plastflasker plastikflasker 50cl 50 cl 20x50cl 20x50",
    imageSingle: "produkter-oelvand.aquador-brus-50cl.png",
    imagePlural: "produkter-oelvand.9032-kasse.png",
    palleItem: 9039,
    categories: ["kildevand", "aquador"],
    url: "/kildevand/aquador/aqua-dor-blid-brus-12x50cl",
    routeDef: {
      path: "/kildevand/aquador/aqua-dor-blid-brus-12x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandAquadorBC],
        title: "Aqua d'or blid brus 12x50cl",
      },
      meta: {
        title: "Aqua d'or blid brus 12x50cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "Aquador",
      },
    },
    popupDescription: () => (
      <Paragraph>
        Tilsat kulsyre. Analytisk sammensætning i mg/l: Kalcium 36, Magnesium 3,
        Natrium 10, Hydrogenkarbonat 120, Sulfat 3 og Nitrat &lt;1. Hårdhed 6,
        PH 7.
      </Paragraph>
    ),
    productPageDescription: () => (
      <Paragraph fontSize="12px">
        Tilsat kulsyre. Analytisk sammensætning i mg/l: Kalcium 36, Magnesium 3,
        Natrium 10, Hydrogenkarbonat 120, Sulfat 3 og Nitrat &lt;1. Hårdhed 6,
        PH 7.
      </Paragraph>
    ),
  },
  {
    varenr: 9231,
    name: "Aqua d'or blid brus",
    generalSearchPriority: 500,
    keywords:
      "aquador aqua aqua'd'or aquad'or door dor auqa dór kildevand vand flasker plastflasker plastikflasker 125cl 125 cl 6x125cl 6x125",
    imageSingle: "produkter-oelvand.aquador-brus-125cl.png",
    imagePlural: "produkter-oelvand.aquador-brus-125cl-kolli.png",
    // palleItem: 9931,
    categories: ["kildevand", "aquador"],
    url: "/kildevand/aquador/aqua-dor-blid-brus-6x125cl",
    routeDef: {
      path: "/kildevand/aquador/aqua-dor-blid-brus-6x125cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandAquadorBC],
        title: "Aqua d'or blid brus 6x125cl",
      },
      meta: {
        title: "Aqua d'or blid brus 6x125cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "Aquador",
      },
    },
    popupDescription: () => (
      <Paragraph>
        Tilsat kulsyre. Analytisk sammensætning i mg/l: Kalcium 36, Magnesium 3,
        Natrium 10, Hydrogenkarbonat 120, Sulfat 3 og Nitrat &lt;1. Hårdhed 6,
        PH 7.
      </Paragraph>
    ),
    productPageDescription: () => (
      <Paragraph fontSize="12px">
        Tilsat kulsyre. Analytisk sammensætning i mg/l: Kalcium 36, Magnesium 3,
        Natrium 10, Hydrogenkarbonat 120, Sulfat 3 og Nitrat &lt;1. Hårdhed 6,
        PH 7.
      </Paragraph>
    ),
  },
  {
    varenr: 9062,
    name: "Aqua d'or citrus brus",
    generalSearchPriority: 500,
    keywords:
      "aquador aqua aqua'd'or aquad'or door dor auqa dór kildevand vand flasker plastflasker plastikflasker 50cl 50 cl 12x50cl 12x50 smag",
    imageSingle: "produkter-oelvand.aquador-brus-citrus-50cl.png",
    imagePlural: "produkter-oelvand.9062-kasse.png",
    categories: ["kildevand", "aquador"],
    url: "/kildevand/aquador/aqua-dor-citrus-brus-12x50cl",
    routeDef: {
      path: "/kildevand/aquador/aqua-dor-citrus-brus-12x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandAquadorBC],
        title: "Aqua d'or citrus brus 12x50cl",
      },
      meta: {
        title: "Aqua d'or citrus brus 12x50cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "Aquador",
      },
    },
    popupDescription: () => (
      <Paragraph>
        Ingredienser: Aqua d&apos;or naturligt mineralvand, kuldioxid, naturlig
        citronaroma, surhedsregulerende middel (citronsyre).
      </Paragraph>
    ),
    productPageDescription: () => (
      <Paragraph fontSize="12px">
        Ingredienser: Aqua d&apos;or naturligt mineralvand, kuldioxid, naturlig
        citronaroma, surhedsregulerende middel (citronsyre).
      </Paragraph>
    ),
  },
  {
    varenr: 9412,
    name: "Aqua d'or Zero Blåbær / Solbær brus",
    generalSearchPriority: 500,
    keywords:
      "aquador aqua aqua'd'or aquad'or door dor auqa dór kildevand vand flasker plastflasker plastikflasker 50cl 50 cl 12x50cl 12x50 smag",
    imageSingle: "produkter-oelvand.aquador-brus-hindbaer-solbaer-50cl.png",
    imagePlural: "produkter-oelvand.9412-kasse.png",
    categories: ["kildevand", "aquador"],
    url: "/kildevand/aquador/aqua-dor-zero-blaabaer-solbaer-brus-12x50cl",
    routeDef: {
      path: "/kildevand/aquador/aqua-dor-zero-blaabaer-solbaer-brus-12x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandAquadorBC],
        title: "Aqua d'or Zero Blåbær/Solbær brus 12x50cl",
      },
      meta: {
        title: "Aqua d'or Zero Blåbær/Solbær brus 12x50cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "Aquador",
      },
    },
  },
  {
    varenr: 21212,
    name: "Aqua d'dor Vitamins Recover uden brus",
    generalSearchPriority: 500,
    keywords:
      "aquador aqua aqua'd'or aquad'or door dor auqa dór kildevand vand flasker plastflasker plastikflasker 50cl 50 cl 12x50cl 12x50 smag",
    imageSingle: "produkter-oelvand.aquador-recover-50cl.png",
    // imagePlural: 'produkter-oelvand.bobli-hindbaer-ramme.png',
    categories: ["kildevand", "aquador"],
    url: "/kildevand/aquador/aqua-dor-vitamins-recover-uden-brus-12x50cl",
    routeDef: {
      path: "/kildevand/aquador/aqua-dor-vitamins-recover-uden-brus-12x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandAquadorBC],
        title: "Aqua d'dor Recover uden brus 12x50cl",
      },
      meta: {
        title: "Aqua d'dor Recover uden brus 12x50cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "Aquador",
      },
    },
  },
  {
    varenr: 21222,
    name: "Aqua d'dor Vitamins Protect uden brus",
    generalSearchPriority: 500,
    keywords:
      "aquador aqua aqua'd'or aquad'or door dor auqa dór kildevand vand flasker plastflasker plastikflasker 50cl 50 cl 12x50cl 12x50 smag",
    imageSingle: "produkter-oelvand.aquador-protect-50cl.png",
    // imagePlural: 'produkter-oelvand.bobli-hindbaer-ramme.png',
    categories: ["kildevand", "aquador"],
    url: "/kildevand/aquador/aqua-dor-vitamins-protect-uden-brus-12x50cl",
    routeDef: {
      path: "/kildevand/aquador/aqua-dor-vitamins-protect-uden-brus-12x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandAquadorBC],
        title: "Aqua d'dor Protect uden brus 12x50cl",
      },
      meta: {
        title: "Aqua d'dor Protect uden brus 12x50cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "Aquador",
      },
    },
  },
  {
    varenr: 21232,
    name: "Aqua d'dor Vitamins Levelup uden brus",
    generalSearchPriority: 500,
    keywords:
      "aquador aqua aqua'd'or aquad'or door dor auqa dór kildevand vand flasker plastflasker plastikflasker 50cl 50 cl 12x50cl 12x50 smag",
    imageSingle: "produkter-oelvand.aquador-levelup-50cl.png",
    // imagePlural: 'produkter-oelvand.bobli-hindbaer-ramme.png',
    categories: ["kildevand", "aquador"],
    url: "/kildevand/aquador/aqua-dor-vitamins-levelup-uden-brus-12x50cl",
    routeDef: {
      path: "/kildevand/aquador/aqua-dor-vitamins-levelup-uden-brus-12x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandAquadorBC],
        title: "Aqua d'dor Levelup uden brus 12x50cl",
      },
      meta: {
        title: "Aqua d'dor Levelup uden brus 12x50cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "Aquador",
      },
    },
  },
  {
    varenr: 21182,
    name: "Aqua d'or Zero Hyldeblomst / Lemonade brus",
    generalSearchPriority: 500,
    keywords:
      "aquador aqua aqua'd'or aquad'or door dor auqa dór kildevand vand flasker plastflasker plastikflasker 50cl 50 cl 12x50cl 12x50 smag",
    imageSingle: "produkter-oelvand.aquador-hyldeblomst-lemonade.png",
    imagePlural: "produkter-oelvand.aquador-hyldeblomst-lemonade-ramme.png",
    categories: ["kildevand", "aquador"],
    url: "/kildevand/aquador/aqua-dor-zero-hyldeblomst-lemonade-brus-12x50cl",
    routeDef: {
      path:
        "/kildevand/aquador/aqua-dor-zero-hyldeblomst-lemonade-brus-12x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandAquadorBC],
        title: "Aqua d'or Zero Hyldeblomst/Lemonade brus 12x50cl",
      },
      meta: {
        title: "Aqua d'or Zero Hyldeblomst/Lemonade brus 12x50cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "Aquador",
      },
    },
  },
  {
    varenr: 21112,
    name: "Aqua d'or Zero Appelsin / Mango brus",
    generalSearchPriority: 500,
    keywords:
      "aquador aqua aqua'd'or aquad'or door dor auqa dór kildevand vand flasker plastflasker plastikflasker 50cl 50 cl 12x50cl 12x50 smag",
    imageSingle: "produkter-oelvand.aquador-brus-appelsin-mango-50cl.png",
    imagePlural: "produkter-oelvand.aquador-brus-appelsin-mango-50cl-kolli.png",
    categories: ["kildevand", "aquador"],
    url: "/kildevand/aquador/aqua-dor-zero-appelsin-mango-brus-12x50cl",
    routeDef: {
      path: "/kildevand/aquador/aqua-dor-zero-appelsin-mango-brus-12x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandAquadorBC],
        title: "Aqua d'or Zero Appelsin/Mango brus 12x50cl",
      },
      meta: {
        title: "Aqua d'or Zero Appelsin/Mango brus 12x50cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "Aquador",
      },
    },
  },
  /* Denice Vand */
  {
    varenr: 9042,
    name: "Denice uden brus",
    generalSearchPriority: 500,
    searchSignificance: 20,
    keywords:
      "kildevand flasker plastflasker plastikflasker 50cl 50 cl 20x50cl 20x50 naturligt mineralvand",
    imageSingle: "produkter-oelvand.9042-flaske.png",
    imagePlural: "produkter-oelvand.9042-kasse.png",
    imagePluralFirst: true,
    antalKolliPalle: "20 stk pr. kolli",
    categories: ["kildevand", "kildevandDenice"],
    routeDef: {
      path: "/kildevand/denice-vand",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandDeniceBC],
        title: "Denice Vand uden brus 20x50cl",
      },
      meta: {
        title: "Denice Vand uden brus 20x50cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "KildevandDenice",
      },
    },
  },
  /* Diverse kildevand uden brus */
  {
    varenr: 4022,
    name: "Egekilde uden brus",
    generalSearchPriority: 500,
    searchSignificance: 18,
    keywords:
      "kildevand flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50 1080 45 x 24 x 50cl naturligt mineralvand",
    imageSingle: "produkter-oelvand.egekilde-50cl-uden-brus.png",
    imagePlural: "produkter-oelvand.egekilde-uden-brus-50cl-ramme.png",
  },
  {
    varenr: 4054,
    name: "Ramlösa Premium uden brus",
    generalSearchPriority: 500,
    searchSignificance: 18,
    keywords:
      "ramløse ramløsa ramlossa ramlöse ramlose ramlosa kildevand flasker plastflasker plastikflasker 33cl 33 cl 24x33cl 24x33 naturligt mineralvand",
    imageSingle: "produkter-oelvand.ramlosa-profil-33cl-uden-brus.png",
    imagePlural: "produkter-oelvand.ramlosa-profil-33cl-uden-brus-ramme.png",
  },
  /* Kildevand i paller */
  {
    varenr: 9252,
    name: "Denice 1/2-palle",
    generalSearchPriority: 400,
    keywords:
      "kildevand flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50 780 39 x 20 x 50cl",
    imageSingle: "produkter-oelvand.9252-flaske.png",
    imagePlural: "produkter-oelvand.denice-halvpalle1.png",
    productTypePalle: true,
    antalKolliPalle: "39 kolli = 780 flasker",
    // Antal kan overskrives i overwriteFields.js
    categories: ["kildevand", "kildevandPaller", "kildevandDenice"],
    url: "/kildevand/vand-paller/denice-halv-palle-20x50cl",
    routeDef: {
      path: "/kildevand/vand-paller/denice-halv-palle-20x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandPallerBC],
        title: "Denice 1/2-palle 20x50cl",
      },
      meta: {
        title: "Denice 1/2-palle 20x50cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "VandPaller",
      },
    },
  },
  {
    varenr: 9029,
    name: "Denice palle",
    generalSearchPriority: 400,
    keywords:
      "kildevand flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50 1560 78 x 20 x 50cl",
    imageSingle: "produkter-oelvand.9029-flaske.png",
    imagePlural: "produkter-oelvand.denice-helpalle1.png",
    productTypePalle: true,
    antalKolliPalle: "78 kolli = 1560 flasker",
    categories: ["kildevand", "kildevandPaller", "kildevandDenice"],
    url: "/kildevand/vand-paller/denice-palle-20x50cl",
    routeDef: {
      path: "/kildevand/vand-paller/denice-palle-20x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandPallerBC],
        title: "Denice palle 20x50cl",
      },
      meta: {
        title: "Denice palle 20x50cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "VandPaller",
      },
    },
  },
  {
    varenr: 9239,
    name: "Aqua d'or uden brus palle",
    generalSearchPriority: 400,
    keywords:
      "aquador aqua aqua'd'or aquad'or door dor auqa dór kildevand vand 0,3 0.3 30 30cl 117 x 20 x 30cl 2340 flasker plastflasker plastikflasker cl 20x30cl 20x30 naturligt mineralvand",
    imageSingle: "produkter-oelvand.aquador-30cl.png",
    imagePlural: "produkter-oelvand.9239-kasse.png",
    productTypePalle: true,
    categories: ["kildevand", "kildevandPaller", "aquadorPalle"],
    url: "/kildevand/vand-paller/aqua-dor-uden-brus-palle-20x30cl",
    routeDef: {
      path: "/kildevand/vand-paller/aqua-dor-uden-brus-palle-20x30cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandPallerBC],
        title: "Aqua d'or uden brus palle 20x30cl",
      },
      meta: {
        title: "Aqua d'or uden brus palle 20x30cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "VandPaller",
      },
    },
  },
  {
    varenr: 9212,
    name: "Aqua d'or uden brus palle",
    generalSearchPriority: 400,
    keywords:
      "aquador aqua aqua'd'or aquad'or door dor auqa dór kildevand vand flasker plastflasker plastikflasker 50cl 50 cl 20x50cl 20x50 78 x 20 x 50cl 1560 naturligt mineralvand",
    imageSingle: "produkter-oelvand.aquador-50cl.png",
    imagePlural: "produkter-oelvand.9212-kasse.png",
    productTypePalle: true,
    categories: ["kildevand", "kildevandPaller", "aquadorPalle"],
    url: "/kildevand/vand-paller/aqua-dor-uden-brus-palle-20x50cl",
    routeDef: {
      path: "/kildevand/vand-paller/aqua-dor-uden-brus-palle-20x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandPallerBC],
        title: "Aqua d'or uden brus palle 20x50cl",
      },
      meta: {
        title: "Aqua d'or uden brus palle 20x50cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "VandPaller",
      },
    },
  },
  {
    varenr: 9219,
    name: "Aqua d'or uden brus palle",
    generalSearchPriority: 400,
    keywords:
      "aquador aqua aqua'd'or aquad'or door dor auqa dór kildevand vand flasker plastflasker plastikflasker 125cl 125 cl 6x125cl 6x125 100 x 6 x 125cl 600 naturligt mineralvand",
    imageSingle: "produkter-oelvand.aquador-125cl.png",
    productTypePalle: true,
    categories: ["kildevand", "kildevandPaller", "aquadorPalle"],
    url: "/kildevand/vand-paller/aqua-dor-uden-brus-palle-6x125cl",
    routeDef: {
      path: "/kildevand/vand-paller/aqua-dor-uden-brus-palle-6x125cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandPallerBC],
        title: "Aqua d'or uden brus palle 6x125cl",
      },
      meta: {
        title: "Aqua d'or uden brus palle 6x125cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "VandPaller",
      },
    },
  },
  {
    varenr: 9039,
    name: "Aqua d'or blid brus palle",
    generalSearchPriority: 400,
    keywords:
      "aquador aqua aqua'd'or aquad'or door dor auqa dór kildevand vand flasker plastflasker plastikflasker 50cl 50 cl 12x50cl 12x50 114 x 12 x 50cl 1368",
    imageSingle: "produkter-oelvand.aquador-brus-50cl.png",
    productTypePalle: true,
    categories: ["kildevand", "kildevandPaller", "aquadorBlidPalle"],
    url: "/kildevand/vand-paller/aqua-dor-blid-brus-palle-12x50cl",
    routeDef: {
      path: "/kildevand/vand-paller/aqua-dor-blid-brus-palle-12x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandPallerBC],
        title: "Aqua d'or blid brus palle 12x50cl",
      },
      meta: {
        title: "Aqua d'or blid brus palle 12x50cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "VandPaller",
      },
    },
  },
  {
    varenr: 9069,
    name: "Aqua d'or brus citrus palle",
    generalSearchPriority: 400,
    keywords:
      "aquador aqua aqua'd'or aquad'or door dor auqa dór kildevand vand flasker plastflasker plastikflasker 50cl 50 cl 12x50cl 12x50 114 x 12 x 50cl 1368",
    imageSingle: "produkter-oelvand.aquador-brus-citrus-50cl.png",
    productTypePalle: true,
    categories: ["kildevand", "kildevandPaller", "aquadorBlidPalle"],
    url: "/kildevand/vand-paller/aqua-dor-brus-citrus-palle-12x50cl",
    routeDef: {
      path: "/kildevand/vand-paller/aqua-dor-brus-citrus-palle-12x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandPallerBC],
        title: "Aqua d'or brus citrus palle 12x50cl",
      },
      meta: {
        title: "Aqua d'or brus citrus palle 12x50cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "VandPaller",
      },
    },
  },
  {
    varenr: 9309,
    name: "Pure Water palle",
    generalSearchPriority: 400,
    keywords:
      "vand uden pant vand pap papvand postevand kildevand karton papkarton vandkarton 50cl 50 cl 12x50cl 12x50 1344 112 x 12 x 50cl",
    imageSingle: "produkter-oelvand.9309-flaske.png",
    imagePlural: "produkter-oelvand.9309-kasse.png",
    productTypePalle: true,
    noPant: true,
    categories: ["kildevand", "kildevandPaller", "palleUdenPant"],
    url: "/kildevand/vand-paller/pure-water-palle-12x50cl",
    routeDef: {
      path: "/kildevand/vand-paller/pure-water-palle-12x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandPallerBC],
        title: "Pure Water palle 12x50cl",
      },
      meta: {
        title: "Pure Water palle 12x50cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "VandPaller",
      },
    },
  },
  {
    varenr: 4029,
    name: "Egekilde uden brus palle",
    generalSearchPriority: 400,
    keywords:
      "kildevand flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50 1080 45 x 24 x 50cl naturligt mineralvand",
    imageSingle: "produkter-oelvand.egekilde-50cl-uden-brus.png",
    productTypePalle: true,
    categories: ["kildevand", "kildevandPaller", "egekildepalle"],
    url: "/kildevand/vand-paller/egekilde-uden-brus-palle-24x50cl",
    routeDef: {
      path: "/kildevand/vand-paller/egekilde-uden-brus-palle-24x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandPallerBC],
        title: "Egekilde uden brus palle 24x50cl",
      },
      meta: {
        title: "Egekilde uden brus palle 24x50cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "VandPaller",
      },
    },
  },
  /* Vand uden pant */
  {
    varenr: 9302,
    name: "Pure Water Vand",
    generalSearchPriority: 500,
    keywords:
      "vand uden pant vand pap papvand postevand kildevand karton papkarton vandkarton 50cl 50 cl 12x50cl 12x50 tetra pak tetrapak",
    imageSingle: "produkter-oelvand.purewater-bio.png",
    imagePlural: "produkter-oelvand.9302-kasse.png",
    categories: ["kildevand", "kildevandUdenPant"],
    url: "/kildevand/vand-uden-pant",
    routeDef: {
      path: "/kildevand/vand-uden-pant",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandUdenPantBC],
        title: "Vand uden pant",
      },
      meta: {
        title: "Vand uden pant i 50cl papkarton leveres, også i hele paller",
        description:
          "Leder du efter vand uden pant? Så prøv de 50cl praktiske, flotte papflasker fra Pure Water. Køb idag, levering imorgen.",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "VandUdenPant",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Pure Water er en praktisk vand. Ikke nok med at den er{" "}
          <Bold>fritaget for pant</Bold>, så er den også udført i en emballage,
          der står stabilt i køleskabet og kan foldes helt flad og smides ud
          efter brug.
        </Paragraph>
        <Paragraph>
          Pure Waters pap emballage er den mest miljørigtige opbevaringsform af
          vand til dato.
        </Paragraph>
        <Paragraph>
          Vandet <Bold>har lang holdbarhed</Bold> - over 1 år. Holdbarheden på
          papvandet er faktisk det dobbelte af det på plastikflasker grundet det
          ideelle miljø for vandet.
        </Paragraph>
      </React.Fragment>
    ),
    productPageXtraDescription: () => (
      <React.Fragment>
        <Row>
          <Col xs={12} md={6} paddingTop="0">
            <Heading3>Vand uden pant fra Tyskland</Heading3>
            <Paragraph>
              Pure Water kommer fra den Tyske Fläming kilde ved Wiesenburg.
              Kildesprings dybden er 101 m
            </Paragraph>
            <Paragraph>
              Leveres i FSC certificerede Tetra Pak kartoner. FSC-mærket sikrer
              bl.a. omfattende omtanke for natur, dyr og mennesker i skove. Læs
              her om{" "}
              <LinkStyled
                external
                to="https://dk.fsc.org/dk-da/hvad-goer-fsc-unik/10-gode-grunde-til-at-vaelge-fsc"
              >
                10 gode grunde til at du som forbruger skal kigge efter
                FSC-mærket, når du handler ind
              </LinkStyled>
              .
            </Paragraph>
            <Paragraph>
              <LinkStyled
                external
                to="https://www.tetrapak.com/solutions/packaging/packages/tetra-prisma-aseptic#benefits"
              >
                Tetra Tak kartoner er lavet hovedsageligt af pap
              </LinkStyled>
              , som er et genanvendeligt materiale.
            </Paragraph>
            <Paragraph>
              Gratis levering i København og omegn ved køb af kun 10 kasser.
            </Paragraph>
            <Paragraph>
              Papkassen med 12 stk. måler 385mmx280mmx180mm (LxBxH) vægt: 6,49
              Kg.
            </Paragraph>
            <Paragraph>Udendigt mål skruelåg: 28 mm.</Paragraph>
            <Paragraph fontSize="12px">
              Mineralindhold i mg/l: <br />
              Bikarbonat(HCO3) 84,8. Kalcium(ca) 18,8. Sulfat(SO4) 7,91.
              Klorid(Cl) 38,8. Natrium(Na) 17,5. Magnesium(Mg) 8,4. Kalium(K)
              1,23.
            </Paragraph>
          </Col>
          <Col xs={12} md={6} paddingTop="0">
            <Heading4>Hovedfordele ved Pure Water vand uden pant</Heading4>
            <List check fontSize="14px">
              <li>Ingen pant</li>
              <li>Lang holdbarhed, mindst 1 år</li>
              <li>Miljørigtig</li>
              <li>Pladsbesparende</li>
              <li>Let at folde og smide ud</li>
              <li>Står stabilt i køleskabet</li>
            </List>
          </Col>
        </Row>
        <Row greyLight>
          <Col xs={12} paddingBottom="0" paddingTop="10px">
            <Spacer size="0" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={3} textCenter>
            <ImagePopup name="oel-vand.pure-water-palle.jpg" />
            <Paragraph fontSize="12px">
              EUR Palle med 1344 50cl Pure Water papkartoner
            </Paragraph>
          </Col>
          <Col xs={12} sm={9}>
            <Heading3>Stor rabat ved køb af hele paller, spar 36%</Heading3>
            <Paragraph>
              <Bold>Pallepriser:</Bold>
              <br />
              <Span fontSize="12px">
                12 kartoner á 50cl pr. papkasse, 112 kasser pr. palle = 1344
                kartoner, ingen pant
              </Span>
            </Paragraph>
            <Table paddingTd="5px 0px">
              <tr>
                <Td>Pris pr. kasse á 12 stk:</Td>
                <Td textRight>
                  <Bold>
                    DKK <Price varenr="9309" />
                  </Bold>{" "}
                  <MomsChange />
                </Td>
              </tr>
              <tr>
                <Td>Pris pr. stk.:</Td>
                <Td textRight>
                  <Bold>
                    DKK <Price prStk varenr="9309" />
                  </Bold>{" "}
                  <MomsChange />
                </Td>
              </tr>
              <tr>
                <Td>Samlet pris for 1344 kartoner:</Td>
                <Td textRight>
                  <Bold>
                    <PriceSum varenr={9309} itemPrice numberOfPaller={1} />
                  </Bold>{" "}
                  <MomsChange />
                </Td>
              </tr>
            </Table>
            <Paragraph paddingTop="10px">
              Ovenstående priser gælder kun ved køb af hele paller. Pallen er
              80x120x1350 cm (BxLxH). Vægt ca. 750kg.
              <br />
              Pallen køres så langt det er muligt med løftevogn. I må selv sætte
              varerne på plads.
            </Paragraph>
            <Paragraph>
              <LinkButton
                arrow
                to="/kildevand/vand-paller/pure-water-palle-12x50cl"
                title="Bestil Pure Water i paller online"
              >
                Bestil Pure Water i paller online
              </LinkButton>
            </Paragraph>
            <Paragraph>
              Priserne er med fri levering i hele landet (brofaste øer). I
              København og omegn leveres med egne biler. I resten af landet
              sendes med fragtmand. Ordrer givet inden kl. 14 leveres oftest
              næste hverdag. Panten på eur-paller er{" "}
              <Price embPrice varenr="888" momsIndication />
            </Paragraph>
          </Col>
        </Row>
        <Row greyLight>
          <Col xs={12} paddingBottom="0" paddingTop="10px">
            <Spacer size="0" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={3} textCenter>
            <Image
              name="kildevand.private_label2.png"
              alt="Vand med logo"
              title="50 cl vand med logo"
            />
            <Paragraph fontSize="12px">
              Eksempel på kundedekoreret karton
            </Paragraph>
          </Col>
          <Col xs={12} sm={9}>
            <Heading3>Vand med eget logo</Heading3>
            <Paragraph>
              Vi får en del henvendelser vedrørende Pure Water vand med eget
              logo. Dette er muligt, men det kræver en mindstebestilling på
              20.000 stk., svarende til ca. 15 paller. Leveringen er gratis og
              kan ske gradvist med en palle af gangen. Alle paller skal aftages
              inden 1 år.
            </Paragraph>
            <Paragraph>
              <Bold>Pris:</Bold>
              <br />
              Pure Water med eget logo, 50cl:{" "}
              <Bold>
                DKK <Price prStk varenr="9309" />
              </Bold>{" "}
              <MomsChange /> inkl. levering.
            </Paragraph>
            <Paragraph>
              Vi sælger også{" "}
              <LinkStyled to="/kildevand/vand-med-logo">
                vand med eget logo på plastikflasker
              </LinkStyled>
              .<br />
              Bestilling: kun 1 palle.
            </Paragraph>
            <Paragraph>
              <Bold>Pris:</Bold>
              <br />
              Vand med eget logo, plastikflaske, 50cl:{" "}
              <Bold>
                <Price prStk varenr="9850" /> kr.
              </Bold>{" "}
              <MomsChange /> + pant inkl. levering.
              <br />
              <Span fontSize="12px">
                Panten er <Money money="120" momsIndication /> pr. flaske
              </Span>
            </Paragraph>
            <Hr />
            <Heading4>Designfiler: Vand uden pant med eget logo</Heading4>
            <LinkStyled external to="/pdf/TPA500Square_Keyline.pdf">
              TPA500Square_Keyline.pdf
            </LinkStyled>
            <br />
            <LinkStyled external to="/pdf/TPA500Square_AddWaterKeyline.pdf">
              TPA500Square_AddWaterKeyline.pdf
            </LinkStyled>
          </Col>
        </Row>
      </React.Fragment>
    ),
  },
  /* San Pellegrino Vand */
  {
    varenr: 9420,
    name: "S. Pellegrino brus",
    generalSearchPriority: 480,
    searchSignificance: 10,
    keywords:
      "san pelligrino s.pellegrino s.pelegrino s.pelligrino pelegrino pellegrino kildevand flasker glasflasker 25cl 25 cl 24x25cl 24x25 danskvand dansk vand",
    descriptionXtra: "Glas med kapsel",
    imageSingle: "produkter-oelvand.san-pellegrino-glas-25cl.png",
    imagePlural: "produkter-oelvand.9420-kasse.png",
    categories: ["kildevand", "kildevandSanPellegrino"],
    url: "/kildevand/san-pellegrino-vand/san-pellegrino-brus-24x25cl",
    routeDef: {
      path: "/kildevand/san-pellegrino-vand/san-pellegrino-brus-24x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandSanPellegrinoBC],
        title: "San Pellegrino brus 24x25cl",
      },
      meta: {
        title: "San Pellegrino brus 24x25cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "KildevandSanPellegrino",
      },
    },
  },
  {
    varenr: 9432,
    name: "S. Pellegrino brus",
    generalSearchPriority: 480,
    searchSignificance: 10,
    keywords:
      "san pelligrino s.pellegrino s.pelegrino s.pelligrino pelegrino pellegrino kildevand flasker glasflasker 50cl 50 cl 20x50cl 20x50 danskvand dansk vand",
    descriptionXtra: "Glas med kapsel",
    imageSingle: "produkter-oelvand.san-pellegrino-glas-50cl.png",
    imagePlural: "produkter-oelvand.9432-kasse.png",
    categories: ["kildevand", "kildevandSanPellegrino"],
    url: "/kildevand/san-pellegrino-vand/san-pellegrino-brus-20x50cl",
    routeDef: {
      path: "/kildevand/san-pellegrino-vand/san-pellegrino-brus-20x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandSanPellegrinoBC],
        title: "San Pellegrino brus 20x50cl",
      },
      meta: {
        title: "San Pellegrino brus 20x50cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "KildevandSanPellegrino",
      },
    },
  },
  {
    varenr: 9421,
    name: "S. Pellegrino brus",
    generalSearchPriority: 480,
    searchSignificance: 10,
    keywords:
      "san pelligrino s.pellegrino s.pelegrino s.pelligrino pelegrino pellegrino kildevand flasker glasflasker 100cl 100 cl 12x100cl 12x100 danskvand dansk vand",
    descriptionXtra: "Glas med kapsel",
    imageSingle: "produkter-oelvand.san-pellegrino-glas-100cl.png",
    imagePlural: "produkter-oelvand.9421-kasse.png",
    categories: ["kildevand", "kildevandSanPellegrino"],
    url: "/kildevand/san-pellegrino-vand/san-pellegrino-brus-12x100cl",
    routeDef: {
      path: "/kildevand/san-pellegrino-vand/san-pellegrino-brus-12x100cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandSanPellegrinoBC],
        title: "San Pellegrino brus 12x100cl",
      },
      meta: {
        title: "San Pellegrino brus 12x100cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "KildevandSanPellegrino",
      },
    },
  },
  {
    varenr: 9422,
    name: "S. Pellegrino brus plast",
    generalSearchPriority: 480,
    keywords:
      "san pelligrino s.pellegrino s.pelegrino s.pelligrino pelegrino pellegrino kildevand flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50 danskvand dansk vand",
    descriptionXtra: "Plast med skruelåg",
    imageSingle: "produkter-oelvand.san-pellegrino-plast-50cl.png",
    imagePlural: "produkter-oelvand.9422-kasse.png",
    categories: ["kildevand", "kildevandSanPellegrino"],
    url: "/kildevand/san-pellegrino-vand/san-pellegrino-brus-plast-24x50cl",
    routeDef: {
      path: "/kildevand/san-pellegrino-vand/san-pellegrino-brus-plast-24x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandSanPellegrinoBC],
        title: "San Pellegrino brus plast 24x50cl",
      },
      meta: {
        title: "San Pellegrino brus plast 24x50cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "KildevandSanPellegrino",
      },
    },
  },
  {
    varenr: 9430,
    name: "S. Pellegrino brus",
    generalSearchPriority: 500,
    keywords:
      "san pelligrino s.pellegrino s.pelegrino s.pelligrino pelegrino pellegrino kildevand flasker glasflasker 25cl 25 cl 24x25cl 24x25",
    descriptionXtra: "Glas med skruelåg",
    imageSingle: "produkter-oelvand.san-pellegrino-glas-skruelaag-25cl.png",
    imagePlural:
      "produkter-oelvand.san-pellegrino-glas-skruelaag-25cl-papkasse.png",
    categories: ["kildevand", "kildevandSanPellegrino"],
    url: "/kildevand/san-pellegrino-vand/san-pellegrino-brus-24x25cl-skruelaag",
    routeDef: {
      path:
        "/kildevand/san-pellegrino-vand/san-pellegrino-brus-24x25cl-skruelaag",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandSanPellegrinoBC],
        title: "San Pellegrino brus 24x25cl",
      },
      meta: {
        title: "San Pellegrino brus 24x25cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "KildevandSanPellegrino",
      },
    },
  },
  /* Acqua Panna */
  {
    varenr: 9450,
    name: "Acqua Panna uden brus",
    generalSearchPriority: 500,
    keywords:
      "san pellegrino pelegrino pelligrino s.pellegrino s.pelegrino s.pelligrino aqua pana kildevand acquapanna aquapanna acuapanna aqua acua flasker glasflasker 25cl 25 cl 24x25cl 24x25 naturligt mineralvand",
    descriptionXtra: "Glas med kapsel",
    imageSingle: "produkter-oelvand.san-pellegrino-acqua-panna-glas-25cl.png",
    imagePlural: "produkter-oelvand.9450-kasse.png",
    categories: ["kildevand", "kildevandAcquaPanna"],
    url: "/kildevand/san-pellegrino-vand/acqua-panna-uden-brus-24x25cl",
    routeDef: {
      path: "/kildevand/san-pellegrino-vand/acqua-panna-uden-brus-24x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandSanPellegrinoBC],
        title: "Acqua Panna uden brus 24x25cl",
      },
      meta: {
        title: "Acqua Panna uden brus 24x25cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "KildevandSanPellegrino",
      },
    },
  },
  {
    varenr: 9462,
    name: "Acqua Panna uden brus",
    generalSearchPriority: 500,
    keywords:
      "san pellegrino pelegrino pelligrino s.pellegrino s.pelegrino s.pelligrino aqua pana kildevand acquapanna aquapanna acuapanna aqua acua flasker glasflasker 50cl 50 cl 20x50cl 20x50 naturligt mineralvand",
    descriptionXtra: "Glas med kapsel",
    imageSingle: "produkter-oelvand.san-pellegrino-acqua-panna-glas-50cl.png",
    imagePlural: "produkter-oelvand.9462-kasse.png",
    categories: ["kildevand", "kildevandAcquaPanna"],
    url: "/kildevand/san-pellegrino-vand/acqua-panna-uden-brus-20x50cl",
    routeDef: {
      path: "/kildevand/san-pellegrino-vand/acqua-panna-uden-brus-20x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandSanPellegrinoBC],
        title: "Acqua Panna uden brus 20x50cl",
      },
      meta: {
        title: "Acqua Panna uden brus 20x50cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "KildevandSanPellegrino",
      },
    },
  },
  {
    varenr: 9451,
    name: "Acqua Panna uden brus",
    generalSearchPriority: 500,
    keywords:
      "san pellegrino pelegrino pelligrino s.pellegrino s.pelegrino s.pelligrino aqua pana kildevand acquapanna aquapanna acuapanna aqua acua flasker glasflasker 100cl 100 cl 12x100cl 12x100 naturligt mineralvand",
    descriptionXtra: "Glas med kapsel",
    imageSingle: "produkter-oelvand.san-pellegrino-acqua-panna-glas-100cl.png",
    imagePlural: "produkter-oelvand.9451-kasse.png",
    categories: ["kildevand", "kildevandAcquaPanna"],
    url: "/kildevand/san-pellegrino-vand/acqua-panna-uden-brus-12x100cl",
    routeDef: {
      path: "/kildevand/san-pellegrino-vand/acqua-panna-uden-brus-12x100cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandSanPellegrinoBC],
        title: "Acqua Panna uden brus 12x100cl",
      },
      meta: {
        title: "Acqua Panna uden brus 12x100cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "KildevandSanPellegrino",
      },
    },
  },
  {
    varenr: 9452,
    name: "Acqua Panna uden brus",
    generalSearchPriority: 500,
    keywords:
      "san pellegrino pelegrino pelligrino s.pellegrino s.pelegrino s.pelligrino aqua pana kildevand acquapanna aquapanna acuapanna aqua acua flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50 naturligt mineralvand",
    descriptionXtra: "Plast med skruelåg",
    imageSingle: "produkter-oelvand.san-pellegrino-acqua-panna-plast-50cl.png",
    imagePlural: "produkter-oelvand.9452-kasse.png",
    categories: ["kildevand", "kildevandAcquaPanna"],
    url: "/kildevand/san-pellegrino-vand/acqua-panna-uden-brus-24x50cl",
    routeDef: {
      path: "/kildevand/san-pellegrino-vand/acqua-panna-uden-brus-24x50cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandSanPellegrinoBC],
        title: "Acqua Panna uden brus 24x50cl",
      },
      meta: {
        title: "Acqua Panna uden brus 24x50cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "KildevandSanPellegrino",
      },
    },
  },
  {
    varenr: 9460,
    name: "Acqua Panna uden brus",
    generalSearchPriority: 500,
    keywords:
      "san pellegrino pelegrino pelligrino s.pellegrino s.pelegrino s.pelligrino aqua pana acquapanna aquapanna acuapanna aqua acua flasker glasflasker 25cl 25 cl 24x25cl 24x25 naturligt mineralvand",
    descriptionXtra: "Glas med skruelåg",
    imageSingle:
      "produkter-oelvand.san-pellegrino-acqua-panna-glas-skruelaag-25cl.png",
    imagePlural: "produkter-oelvand.acqua-panna-25cl-skruelaag-ramme.png",
    categories: ["kildevand", "kildevandAcquaPanna"],
    url:
      "/kildevand/san-pellegrino-vand/acqua-panna-skruelaag-uden-brus-24x25cl",
    routeDef: {
      path:
        "/kildevand/san-pellegrino-vand/acqua-panna-skruelaag-uden-brus-24x25cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandSanPellegrinoBC],
        title: "Acqua Panna uden brus 24x25cl",
      },
      meta: {
        title: "Acqua Panna uden brus 24x25cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "KildevandSanPellegrino",
      },
    },
  },
  /* Vitamin Well */
  {
    varenr: 9630,
    name: "Vitamin Well Defence uden brus",
    generalSearchPriority: 500,
    keywords:
      "kildevand flasker plastflasker plastikflasker 50cl 50 cl 12x50cl 12x50 vitaminwell",
    imageSingle: "produkter-oelvand.9630-flaske.png",
    imagePlural: "produkter-oelvand.9630-kasse.png",
    smag: "Citrus/Hyldeblomst",
    categories: ["kildevand", "vitaminWell"],
    url: "/kildevand/vitamin-well/vitamin-well-defence",
    routeDef: {
      path: "/kildevand/vitamin-well/vitamin-well-defence",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandVitaminWellBC],
        title: "Vitamin Well Defence 12x50cl",
      },
      meta: {
        title: "Vitamin Well Defence 12x50cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "VitaminWell",
      },
    },
    indhold: () => (
      <React.Fragment>
        <Row>
          <Col xs={12} lg={6}>
            <Table lines2>
              <tr>
                <Td colspan="2" bold textRight>
                  500ml
                </Td>
              </tr>
              <tr>
                <Td>Energi</Td>
                <Td textRight>357 kJ/ 85 kcal</Td>
              </tr>
              <tr>
                <Td>Kulhydrater</Td>
                <Td textRight>21g</Td>
              </tr>
              <tr>
                <Td>heraf sukkerater</Td>
                <Td textRight>21g</Td>
              </tr>
              <tr>
                <Td>Fedt</Td>
                <Td textRight>0g</Td>
              </tr>
              <tr>
                <Td>Protein</Td>
                <Td textRight>0g</Td>
              </tr>
              <tr>
                <Td>Fibrer</Td>
                <Td textRight>0g</Td>
              </tr>
              <tr>
                <Td>Salt</Td>
                <Td textRight>0,13g</Td>
              </tr>
            </Table>
          </Col>
          <Col xs={12} lg={6}>
            <Table lines2>
              <tr>
                <Td colspan="2" bold textRight>
                  500ml
                </Td>
                <Td bold textRight>
                  ADI
                </Td>
              </tr>
              <tr>
                <Td>Vitamin B12</Td>
                <Td textRight>1,25µg</Td>
                <Td textRight>50%</Td>
              </tr>
              <tr>
                <Td>Vitamin C</Td>
                <Td textRight>80mg</Td>
                <Td textRight>100%</Td>
              </tr>
              <tr>
                <Td>Vitamin E</Td>
                <Td textRight>6mg</Td>
                <Td textRight>50%</Td>
              </tr>
              <tr>
                <Td>Vitamin D</Td>
                <Td textRight>4µg</Td>
                <Td textRight>80%</Td>
              </tr>
              <tr>
                <Td>Folsyre</Td>
                <Td textRight>50µg</Td>
                <Td textRight>25%</Td>
              </tr>
            </Table>
          </Col>
          <Col xs={12}>
            <Paragraph>
              En flaske indeholder desuden Hyldebærekstrakt 150 mg &amp; Grøn
              te-ekstrakt 77,5 mg
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
  },
  {
    varenr: 9680,
    name: "Vitamin Well Recover uden brus",
    generalSearchPriority: 500,
    keywords:
      "kildevand flasker plastflasker 50cl 50 cl 12x50cl 12x50 vitaminwell",
    imageSingle: "produkter-oelvand.vitamin-well-recover-50cl.png",
    imagePlural: "produkter-oelvand.9630-kasse.png",
    smag: "Hyldeblomst/Fersken",
    categories: ["kildevand", "vitaminWell"],
    url: "/kildevand/vitamin-well/vitamin-well-recover",
    routeDef: {
      path: "/kildevand/vitamin-well/vitamin-well-recover",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandVitaminWellBC],
        title: "Vitamin Well Recover 12x50cl",
      },
      meta: {
        title: "Vitamin Well Recover 12x50cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "VitaminWell",
      },
    },
    indhold: () => <React.Fragment>Vitamin B12 + Folsyre.</React.Fragment>,
  },
  {
    varenr: 9620,
    name: "Vitamin Well Celebrate uden brus",
    generalSearchPriority: 500,
    keywords:
      "kildevand flasker plastflasker plastikflasker 50cl 50 cl 12x50cl 12x50 vitaminwell",
    imageSingle: "produkter-oelvand.vitamin-well-celebrate-flaske-50cl.png",
    imagePlural: "produkter-oelvand.vitamin-well-celebrate-kasse.png",
    smag: "Mango/Ananas",
    categories: ["kildevand", "vitaminWell"],
    url: "/kildevand/vitamin-well/vitamin-well-celebrate",
    routeDef: {
      path: "/kildevand/vitamin-well/vitamin-well-celebrate",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandVitaminWellBC],
        title: "Vitamin Well Celebrate 12x50cl",
      },
      meta: {
        title: "Vitamin Well Celebrate 12x50cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "VitaminWell",
      },
    },
    indhold: () => (
      <React.Fragment>
        <Row>
          <Col xs={12} lg={6}>
            <Table lines2>
              <tr>
                <Td colspan="2" bold textRight>
                  500ml
                </Td>
              </tr>
              <tr>
                <Td>Energi</Td>
                <Td textRight>357 kJ/ 85 kcal</Td>
              </tr>
              <tr>
                <Td>Kulhydrater</Td>
                <Td textRight>21g</Td>
              </tr>
              <tr>
                <Td>heraf sukkerater</Td>
                <Td textRight>21g</Td>
              </tr>
              <tr>
                <Td>Fedt</Td>
                <Td textRight>0g</Td>
              </tr>
              <tr>
                <Td>Protein</Td>
                <Td textRight>0g</Td>
              </tr>
              <tr>
                <Td>Fibrer</Td>
                <Td textRight>0g</Td>
              </tr>
              <tr>
                <Td>Salt</Td>
                <Td textRight>0,13g</Td>
              </tr>
            </Table>
          </Col>
          <Col xs={12} lg={6}>
            <Table lines2>
              <tr>
                <Td colspan="2" bold textRight>
                  500ml
                </Td>
                <Td bold textRight>
                  ADI
                </Td>
              </tr>
              <tr>
                <Td>Vitamin D</Td>
                <Td textRight>4µg</Td>
                <Td textRight>80%</Td>
              </tr>
              <tr>
                <Td>Vitamin E</Td>
                <Td textRight>7,2mg</Td>
                <Td textRight>60%</Td>
              </tr>
              <tr>
                <Td>Niacin</Td>
                <Td textRight>8mg</Td>
                <Td textRight>50%</Td>
              </tr>
              <tr>
                <Td>Folsyre</Td>
                <Td textRight>50µg</Td>
                <Td textRight>25%</Td>
              </tr>
              <tr>
                <Td>Vitamin B12</Td>
                <Td textRight>1,25µg</Td>
                <Td textRight>50%</Td>
              </tr>
              <tr>
                <Td>Biotin</Td>
                <Td textRight>25µg</Td>
                <Td textRight>50%</Td>
              </tr>
              <tr>
                <Td>Pantothensyre</Td>
                <Td textRight>2,4mg</Td>
                <Td textRight>40%</Td>
              </tr>
            </Table>
          </Col>
          <Col xs={12}>
            <Paragraph>
              Limited Edition. Støttet af håndboldspiller Mikkel Hansen mod
              mobning. Med købet af denne flaske støtter du kampen mod mobning.
              For hver købt flaske doneres 1 kr til MH24.
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
  },
  {
    varenr: 9690,
    name: "Vitamin Well Hydrate uden brus",
    generalSearchPriority: 500,
    keywords:
      "kildevand flasker plastflasker plastikflasker 50cl 50 cl 12x50cl 12x50 vitaminwell",
    imageSingle: "produkter-oelvand.vitamin-well-hydrate-flaske-50cl.png",
    imagePlural: "produkter-oelvand.vitamin-well-hydrate-ramme.png",
    smag: "Rabarber/Jordbær",
    categories: ["kildevand", "vitaminWell"],
    url: "/kildevand/vitamin-well/vitamin-well-hydrate",
    routeDef: {
      path: "/kildevand/vitamin-well/vitamin-well-hydrate",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandVitaminWellBC],
        title: "Vitamin Well Hydrate 12x50cl",
      },
      meta: {
        title: "Vitamin Well Hydrate 12x50cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "VitaminWell",
      },
    },
    indhold: () => (
      <React.Fragment>
        <Row>
          <Col xs={12} lg={6}>
            <Table lines2>
              <tr>
                <Td colspan="2" bold textRight>
                  500ml
                </Td>
              </tr>
              <tr>
                <Td>Energi</Td>
                <Td textRight>357 kJ/ 85 kcal</Td>
              </tr>
              <tr>
                <Td>Kulhydrater</Td>
                <Td textRight>21g</Td>
              </tr>
              <tr>
                <Td>heraf sukkerater</Td>
                <Td textRight>21g</Td>
              </tr>
              <tr>
                <Td>Fedt</Td>
                <Td textRight>0g</Td>
              </tr>
              <tr>
                <Td>Protein</Td>
                <Td textRight>0g</Td>
              </tr>
              <tr>
                <Td>Fibrer</Td>
                <Td textRight>0g</Td>
              </tr>
              <tr>
                <Td>Salt</Td>
                <Td textRight>0,13g</Td>
              </tr>
            </Table>
          </Col>
          <Col xs={12} lg={6}>
            <Table lines2>
              <tr>
                <Td colspan="2" bold textRight>
                  500ml
                </Td>
                <Td bold textRight>
                  ADI
                </Td>
              </tr>
              <tr>
                <Td>Vitamin B12</Td>
                <Td textRight>1,25µg</Td>
                <Td textRight>50%</Td>
              </tr>
              <tr>
                <Td>Vitamin C</Td>
                <Td textRight>80mg</Td>
                <Td textRight>80%</Td>
              </tr>
              <tr>
                <Td>Vitamin D</Td>
                <Td textRight>4µg</Td>
                <Td textRight>80%</Td>
              </tr>
              <tr>
                <Td>Vitamin E</Td>
                <Td textRight>6mg</Td>
                <Td textRight>50%</Td>
              </tr>
              <tr>
                <Td>Niacin</Td>
                <Td textRight>8mg</Td>
                <Td textRight>50%</Td>
              </tr>
              <tr>
                <Td>Biotin</Td>
                <Td textRight>25µg</Td>
                <Td textRight>50%</Td>
              </tr>
              <tr>
                <Td>Pantothensyre</Td>
                <Td textRight>2,4mg</Td>
                <Td textRight>50%</Td>
              </tr>
              <tr>
                <Td>Folsyre</Td>
                <Td textRight>50µg</Td>
                <Td textRight>25%</Td>
              </tr>
            </Table>
          </Col>
          <Col xs={12}>
            <Paragraph>
              Limited Edition. Støttet af håndboldspiller Mikkel Hansen mod
              mobning. Med købet af denne flaske støtter du kampen mod mobning.
              For hver købt flaske doneres 1 kr til MH24.
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
  },
  {
    varenr: 9640,
    name: "Vitamin Well Reload uden brus",
    generalSearchPriority: 500,
    keywords:
      "kildevand flasker plastflasker plastikflasker 50cl 50 cl 12x50cl 12x50 vitaminwell",
    imageSingle: "produkter-oelvand.9640-flaske.png",
    imagePlural: "produkter-oelvand.9640-kasse.png",
    smag: "Citron/Lime",
    categories: ["kildevand", "vitaminWell"],
    url: "/kildevand/vitamin-well/vitamin-well-reload",
    routeDef: {
      path: "/kildevand/vitamin-well/vitamin-well-reload",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandVitaminWellBC],
        title: "Vitamin Well Reload 12x50cl",
      },
      meta: {
        title: "Vitamin Well Reload 12x50cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "VitaminWell",
      },
    },
    indhold: () => (
      <React.Fragment>
        <Row>
          <Col xs={12} lg={6}>
            <Table lines2>
              <tr>
                <Td colspan="2" bold textRight>
                  500ml
                </Td>
              </tr>
              <tr>
                <Td>Energi</Td>
                <Td textRight>357 kJ/ 85 kcal</Td>
              </tr>
              <tr>
                <Td>Kulhydrater</Td>
                <Td textRight>21g</Td>
              </tr>
              <tr>
                <Td>heraf sukkerater</Td>
                <Td textRight>21g</Td>
              </tr>
              <tr>
                <Td>Fedt</Td>
                <Td textRight>0g</Td>
              </tr>
              <tr>
                <Td>Protein</Td>
                <Td textRight>0g</Td>
              </tr>
              <tr>
                <Td>Fibrer</Td>
                <Td textRight>0g</Td>
              </tr>
              <tr>
                <Td>Salt</Td>
                <Td textRight>0,13g</Td>
              </tr>
            </Table>
          </Col>
          <Col xs={12} lg={6}>
            <Table lines2>
              <tr>
                <Td colspan="2" bold textRight>
                  500ml
                </Td>
                <Td bold textRight>
                  ADI
                </Td>
              </tr>
              <tr>
                <Td>Niacin</Td>
                <Td textRight>8mg</Td>
                <Td textRight>50%</Td>
              </tr>
              <tr>
                <Td>Pantothensyre</Td>
                <Td textRight>2,4mg</Td>
                <Td textRight>40%</Td>
              </tr>
              <tr>
                <Td>Biotin</Td>
                <Td textRight>25µg</Td>
                <Td textRight>50%</Td>
              </tr>
              <tr>
                <Td>Vitamin B12</Td>
                <Td textRight>1,25µg</Td>
                <Td textRight>50%</Td>
              </tr>
              <tr>
                <Td>Vitamin D</Td>
                <Td textRight>4µg</Td>
                <Td textRight>80%</Td>
              </tr>
              <tr>
                <Td>Vitamin E</Td>
                <Td textRight>7,2µg</Td>
                <Td textRight>60%</Td>
              </tr>
              <tr>
                <Td>Folsyre</Td>
                <Td textRight>50µg</Td>
                <Td textRight>25%</Td>
              </tr>
            </Table>
          </Col>
        </Row>
      </React.Fragment>
    ),
  },
  {
    varenr: 9610,
    name: "Vitamin Well Antioxidant uden brus",
    generalSearchPriority: 500,
    keywords:
      "kildevand flasker plastflasker plastikflasker 50cl 50 cl 12x50cl 12x50 vitaminwell",
    imageSingle: "produkter-oelvand.9610-flaske.png",
    imagePlural: "produkter-oelvand.9610-kasse.png",
    smag: "Fersken",
    categories: ["kildevand", "vitaminWell"],
    url: "/kildevand/vitamin-well/vitamin-well-antioxidant",
    routeDef: {
      path: "/kildevand/vitamin-well/vitamin-well-antioxidant",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandVitaminWellBC],
        title: "Vitamin Well Antioxidant 12x50cl",
      },
      meta: {
        title: "Vitamin Well Antioxidant 12x50cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "VitaminWell",
      },
    },
    indhold: () => (
      <React.Fragment>
        <Row>
          <Col xs={12} lg={6}>
            <Table lines2>
              <tr>
                <Td colspan="2" bold textRight>
                  500ml
                </Td>
              </tr>
              <tr>
                <Td>Energi</Td>
                <Td textRight>357 kJ/ 85 kcal</Td>
              </tr>
              <tr>
                <Td>Kulhydrater</Td>
                <Td textRight>21g</Td>
              </tr>
              <tr>
                <Td>heraf sukkerater</Td>
                <Td textRight>21g</Td>
              </tr>
              <tr>
                <Td>Fedt</Td>
                <Td textRight>0g</Td>
              </tr>
              <tr>
                <Td>Protein</Td>
                <Td textRight>0g</Td>
              </tr>
              <tr>
                <Td>Fibrer</Td>
                <Td textRight>0g</Td>
              </tr>
              <tr>
                <Td>Salt</Td>
                <Td textRight>0,13g</Td>
              </tr>
            </Table>
          </Col>
          <Col xs={12} lg={6}>
            <Table lines2>
              <tr>
                <Td colspan="2" bold textRight>
                  500ml
                </Td>
                <Td bold textRight>
                  ADI
                </Td>
              </tr>
              <tr>
                <Td>Vitamin C</Td>
                <Td textRight>80mg</Td>
                <Td textRight>100%</Td>
              </tr>
              <tr>
                <Td>Vitamin E</Td>
                <Td textRight>12mg</Td>
                <Td textRight>100%</Td>
              </tr>
            </Table>
          </Col>
          <Col xs={12}>
            <Paragraph>
              En flaske indeholder desuden Grøn te-ekstrakt 40 mg,
              Granatæbleekstrakt 10 mg, Hyldebærekstrakt 13 mg &
              Drueskalekstrakt 13 mg
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
  },
  {
    varenr: 9660,
    name: "Vitamin Well Awake uden brus",
    generalSearchPriority: 500,
    keywords:
      "kildevand flasker plastflasker plastikflasker 50cl 50 cl 12x50cl 12x50 vitaminwell",
    imageSingle: "produkter-oelvand.9660-flaske.png",
    imagePlural: "produkter-oelvand.9660-kasse.png",
    smag: "Hindbær",
    categories: ["kildevand", "vitaminWell"],
    url: "/kildevand/vitamin-well/vitamin-well-awake",
    routeDef: {
      path: "/kildevand/vitamin-well/vitamin-well-awake",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandVitaminWellBC],
        title: "Vitamin Well Awake 12x50cl",
      },
      meta: {
        title: "Vitamin Well Awake 12x50cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "VitaminWell",
      },
    },
    indhold: () => (
      <React.Fragment>
        <Row>
          <Col xs={12} lg={6}>
            <Table lines2>
              <tr>
                <Td colspan="2" bold textRight>
                  500ml
                </Td>
              </tr>
              <tr>
                <Td>Energi</Td>
                <Td textRight>357 kJ/ 85 kcal</Td>
              </tr>
              <tr>
                <Td>Kulhydrater</Td>
                <Td textRight>21g</Td>
              </tr>
              <tr>
                <Td>heraf sukkerater</Td>
                <Td textRight>21g</Td>
              </tr>
              <tr>
                <Td>Fedt</Td>
                <Td textRight>0g</Td>
              </tr>
              <tr>
                <Td>Protein</Td>
                <Td textRight>0g</Td>
              </tr>
              <tr>
                <Td>Fibrer</Td>
                <Td textRight>0g</Td>
              </tr>
              <tr>
                <Td>Salt</Td>
                <Td textRight>0,13g</Td>
              </tr>
            </Table>
          </Col>
          <Col xs={12} lg={6}>
            <Table lines2>
              <tr>
                <Td colspan="2" bold textRight>
                  500ml
                </Td>
                <Td bold textRight>
                  ADI
                </Td>
              </tr>
              <tr>
                <Td>Vitamin D</Td>
                <Td textRight>4µg</Td>
                <Td textRight>80%</Td>
              </tr>
              <tr>
                <Td>Vitamin E</Td>
                <Td textRight>7,2mg</Td>
                <Td textRight>60%</Td>
              </tr>
              <tr>
                <Td>Niacin</Td>
                <Td textRight>8mg</Td>
                <Td textRight>50%</Td>
              </tr>
              <tr>
                <Td>Vitamin B12</Td>
                <Td textRight>1,25µg</Td>
                <Td textRight>50%</Td>
              </tr>
              <tr>
                <Td>Biotin</Td>
                <Td textRight>25µg</Td>
                <Td textRight>50%</Td>
              </tr>
              <tr>
                <Td>Pantothensyre</Td>
                <Td textRight>2,5mg</Td>
                <Td textRight>40%</Td>
              </tr>
            </Table>
          </Col>
          <Col xs={12}>
            <Paragraph>
              <Bold>Koffeinindhold: 15 mg / 100ml.</Bold> Svarer til 1 kop
              kaffe. Anbefales ikke til gravide, ammende eller børn.
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
  },
  {
    varenr: 9670,
    name: "Vitamin Well Upgrade uden brus",
    keywords:
      "kildevand flasker plastflasker plastikflasker 50cl 50 cl 12x50cl 12x50 vitaminwell",
    imageSingle: "produkter-oelvand.9670-flaske.png",
    imagePlural: "produkter-oelvand.vitamin-well-upgrade-ramme.png",
    smag: "Citron/Kaktus",
    categories: ["kildevand", "vitaminWell"],
    url: "/kildevand/vitamin-well/vitamin-well-upgrade",
    routeDef: {
      path: "/kildevand/vitamin-well/vitamin-well-upgrade",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandVitaminWellBC],
        title: "Vitamin Well Upgrade 12x50cl",
      },
      meta: {
        title: "Vitamin Well Upgrade 12x50cl",
        description: "",
      },
      props: {
        menuPicker: "Kildevand",
        activeSidemenu: "VitaminWell",
      },
    },
    indhold: () => (
      <React.Fragment>
        <Row>
          <Col xs={12} lg={6}>
            <Table lines2>
              <tr>
                <Td colspan="2" bold textRight>
                  500ml
                </Td>
              </tr>
              <tr>
                <Td>Energi</Td>
                <Td textRight>357 kJ/ 85 kcal</Td>
              </tr>
              <tr>
                <Td>Kulhydrater</Td>
                <Td textRight>21g</Td>
              </tr>
              <tr>
                <Td>heraf sukkerater</Td>
                <Td textRight>21g</Td>
              </tr>
              <tr>
                <Td>Fedt</Td>
                <Td textRight>0g</Td>
              </tr>
              <tr>
                <Td>Protein</Td>
                <Td textRight>0g</Td>
              </tr>
              <tr>
                <Td>Fibrer</Td>
                <Td textRight>0g</Td>
              </tr>
              <tr>
                <Td>Salt</Td>
                <Td textRight>0,13g</Td>
              </tr>
            </Table>
          </Col>
          <Col xs={12} lg={6}>
            <Table lines2>
              <tr>
                <Td colspan="2" bold textRight>
                  500ml
                </Td>
                <Td bold textRight>
                  ADI
                </Td>
              </tr>
              <tr>
                <Td>Vitamin D</Td>
                <Td textRight>4µg</Td>
                <Td textRight>80%</Td>
              </tr>
              <tr>
                <Td>Vitamin E</Td>
                <Td textRight>7mg</Td>
                <Td textRight>60%</Td>
              </tr>
              <tr>
                <Td>Niacin</Td>
                <Td textRight>8mg</Td>
                <Td textRight>50%</Td>
              </tr>
              <tr>
                <Td>Folsyre</Td>
                <Td textRight>50µg</Td>
                <Td textRight>25%</Td>
              </tr>
              <tr>
                <Td>Vitamin B12</Td>
                <Td textRight>2,5mg</Td>
                <Td textRight>50%</Td>
              </tr>
              <tr>
                <Td>Biotin</Td>
                <Td textRight>25µg</Td>
                <Td textRight>50%</Td>
              </tr>
              <tr>
                <Td>Pantothensyre</Td>
                <Td textRight>4,2mg</Td>
                <Td textRight>40%</Td>
              </tr>
            </Table>
          </Col>
        </Row>
      </React.Fragment>
    ),
  },
  /* K: Cocio */
  {
    varenr: 3450,
    name: "Cocio",
    generalSearchPriority: 500,
    keywords:
      "kakao cacao chokolade drik chokolademælk cacaomælk kakaomælk mælk flasker glasflasker 27cl 27 cl 12x27cl 12x27",
    imageSingle: "produkter-oelvand.cocio-27cl.png",
    imagePlural: "produkter-oelvand.cocio-12x27cl-ramme.png",
    categories: ["cocio"],
    url: "/cocio/cocio-27cl-glasflaske",
    routeDef: {
      path: "/cocio/cocio-27cl-glasflaske",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, CocioBC],
        title: "Cocio 27cl glasflaske",
      },
      meta: {
        title: "Cocio 27cl glasflaske",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Cocio",
      },
    },
  },
  {
    varenr: 3452,
    name: "Cocio",
    generalSearchPriority: 500,
    keywords:
      "kakao cacao chokolade drik chokolademælk cacaomælk kakaomælk mælk flasker glasflasker 40cl 40 cl 18x40cl 18x40",
    imageSingle: "produkter-oelvand.cocio-40cl.png",
    imagePlural: "produkter-oelvand.3452-kasse.png",
    categories: ["cocio"],
    url: "/cocio/cocio-40cl-glasflaske",
    routeDef: {
      path: "/cocio/cocio-40cl-glasflaske",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, CocioBC],
        title: "Cocio 40cl glasflaske",
      },
      meta: {
        title: "Cocio 40cl glasflaske",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Cocio",
      },
    },
  },
  {
    varenr: 3453,
    name: "Cocio dåse",
    generalSearchPriority: 500,
    keywords:
      "kakao cacao chokolade drik chokolademælk cacaomælk kakaomælk mælk alu aluminium dåse dåser 25cl 25 cl 24x25cl 24x25 ramme",
    imageSingle: "produkter-oelvand.cocio-25cl-daase.png",
    imagePlural: "produkter-oelvand.3453-kasse.png",
    categories: ["cocio"],
    url: "/cocio/cocio-25cl-daase",
    routeDef: {
      path: "/cocio/cocio-25cl-daase",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, CocioBC],
        title: "Cocio 25cl dåse",
      },
      meta: {
        title: "Cocio 25cl dåse",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Cocio",
      },
    },
  },
  /* K: Red Bull */
  {
    varenr: 9563,
    name: "Red Bull Dåse",
    generalSearchPriority: 500,
    keywords:
      "redbull energi energidrik energy monster booster aluminium alu dåser 25cl 25 cl 24x25cl 24x25 papbakke dåsesodavand dåse",
    imageSingle: "produkter-oelvand.9563-flaske.png",
    imagePlural: "produkter-oelvand.9563-kasse.png",
    categories: ["redbull"],
    url: "/red-bull/red-bull-daase",
    routeDef: {
      path: "/red-bull/red-bull-daase",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, RedBullBC],
        title: "Red Bull Dåse",
      },
      meta: {
        title: "Red Bull Dåse",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "RedBull",
      },
    },
  },
  {
    varenr: 9573,
    name: "Red Bull Light Dåse",
    generalSearchPriority: 500,
    keywords:
      "sukkerfri sukker fri redbull energi energidrik energy monster booster aluminium alu dåser 25cl 25 cl 24x25cl 24x25 papbakke dåsesodavand dåse",
    imageSingle: "produkter-oelvand.9573-flaske.png",
    imagePlural: "produkter-oelvand.9573-kasse.png",
    categories: ["redbull"],
    url: "/red-bull/red-bull-light-daase",
    routeDef: {
      path: "/red-bull/red-bull-light-daase",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, RedBullBC],
        title: "Red Bull Light Dåse",
      },
      meta: {
        title: "Red Bull Light Dåse",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "RedBull",
      },
    },
  },

  /* K: Søbogaard */
  {
    varenr: 6140,
    name: "Søbo Øko Hyldeblomst",
    generalSearchPriority: 500,
    searchSignificance: 50,
    keywords:
      "søbogaard soebogaard saft sobogaard søbogård soebogård såbygaard søborgaard søbygaard sobogård Søborggård soeborggård flasker glasflasker 25cl 25 cl 24x25cl 24x25 plastkasser økologisk økologiske hyldeblomst",
    imageSingle: "produkter-oelvand.6140-flaske.png",
    imagePlural: "produkter-oelvand.soebogaard-oeko-hyldeblomst-24stk.png",
    categories: ["soebogaard"],
    url: "/soebogaard/soebogaard-oeko-hyldeblomst-24stk",
    routeDef: {
      path: "/soebogaard/soebogaard-oeko-hyldeblomst-24stk",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SoebogaardBC],
        title: "Søbogaard Øko Hyldeblomst",
      },
      meta: {
        title: "Søbogaard Øko Hyldeblomst",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Soebogaard",
      },
    },
  },
  {
    varenr: 6110,
    name: "Søbo Øko Æblemost",
    generalSearchPriority: 500,
    keywords:
      "søbogaard soebogaard saft sobogaard søbogård soebogård såbygaard søborgaard søbygaard sobogård Søborggård soeborggård flasker glasflasker 25cl 25 cl 24x25cl 24x25 plastkasser økologisk økologiske",
    imageSingle: "produkter-oelvand.6110-flaske.png",
    imagePlural: "produkter-oelvand.soebogaard-oeko-aeblemost-24stk.png",
    categories: ["soebogaard"],
    url: "/soebogaard/soebogaard-oeko-aeblemost-24stk",
    routeDef: {
      path: "/soebogaard/soebogaard-oeko-aeblemost-24stk",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SoebogaardBC],
        title: "Søbogaard Øko Æblemost",
      },
      meta: {
        title: "Søbogaard Øko Æblemost",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Soebogaard",
      },
    },
  },
  {
    varenr: 6130,
    name: "Søbo Øko Appelsin",
    generalSearchPriority: 500,
    keywords:
      "søbogaard soebogaard saft sobogaard søbogård soebogård såbygaard søborgaard søbygaard sobogård Søborggård soeborggård flasker glasflasker 25cl 25 cl 30x25cl 30x25 plastkasser økologisk økologiske",
    imageSingle: "produkter-oelvand.6130-flaske.png",
    imagePlural: "produkter-oelvand.soebo-oeko-appelsin-ramme.png",
    categories: ["soebogaard"],
    url: "/soebogaard/soebogaard-oeko-appelsin",
    routeDef: {
      path: "/soebogaard/soebogaard-oeko-appelsin",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SoebogaardBC],
        title: "Søbogaard Øko Appelsin",
      },
      meta: {
        title: "Søbogaard Øko Appelsin",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Soebogaard",
      },
    },
  },
  {
    varenr: 6120,
    name: "Søbo Øko Solbær",
    generalSearchPriority: 500,
    keywords:
      "søbogaard soebogaard saft sobogaard søbogård soebogård såbygaard søborgaard søbygaard sobogård Søborggård soeborggård flasker glasflasker 25cl 25 cl 24x25cl 24x25 plastkasser økologisk økologiske",
    imageSingle: "produkter-oelvand.6120-flaske.png",
    imagePlural: "produkter-oelvand.soebogaard-oeko-solbaer-24stk.png",
    categories: ["soebogaard"],
    url: "/soebogaard/soebogaard-oeko-solbaer-24stk",
    routeDef: {
      path: "/soebogaard/soebogaard-oeko-solbaer-24stk",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SoebogaardBC],
        title: "Søbogaard Øko Solbær",
      },
      meta: {
        title: "Søbogaard Øko Solbær",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Soebogaard",
      },
    },
  },
  {
    varenr: 6190,
    name: "Søbo Øko Æble-Solbær",
    generalSearchPriority: 500,
    keywords:
      "søbogaard soebogaard saft sobogaard søbogård soebogård såbygaard søborgaard søbygaard sobogård Søborggård soeborggård flasker glasflasker 25cl 25 cl 30x25cl 30x25 plastkasser økologisk økologiske",
    imageSingle: "produkter-oelvand.6190-flaske.png",
    imagePlural: "produkter-oelvand.soebo-oeko-aeble-solbaer-ramme.png",
    categories: ["soebogaard"],
    url: "/soebogaard/soebogaard-oeko-aeble-solbaer",
    routeDef: {
      path: "/soebogaard/soebogaard-oeko-aeble-solbaer",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SoebogaardBC],
        title: "Søbogaard Øko Æble-Solbær",
      },
      meta: {
        title: "Søbogaard Øko Æble-Solbær",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Soebogaard",
      },
    },
  },
  {
    varenr: 6170,
    name: "Søbo Øko Kirsebær",
    generalSearchPriority: 500,
    keywords:
      "søbogaard soebogaard saft sobogaard søbogård soebogård såbygaard søborgaard søbygaard sobogård Søborggård soeborggård flasker glasflasker 25cl 25 cl 30x25cl 30x25 plastkasser økologisk økologiske kirsebærsaft",
    imageSingle: "produkter-oelvand.6170-flaske.png",
    imagePlural: "produkter-oelvand.soebogaard-sjaelevarmer-ramme.png",
    categories: ["soebogaard"],
    url: "/soebogaard/soebogaard-oeko-kirsebaer",
    routeDef: {
      path: "/soebogaard/soebogaard-oeko-kirsebaer",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SoebogaardBC],
        title: "Søbogaard Øko Kirsebær",
      },
      meta: {
        title: "Søbogaard Øko Kirsebær",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Soebogaard",
      },
    },
  },
  {
    varenr: 6180,
    name: "Søbo Øko Æble-Kirsebær",
    generalSearchPriority: 500,
    keywords:
      "søbogaard soebogaard saft sobogaard søbogård soebogård såbygaard søborgaard søbygaard sobogård Søborggård soeborggård flasker glasflasker 25cl 25 cl 30x25cl 30x25 plastkasser økologisk økologiske",
    imageSingle: "produkter-oelvand.6180-flaske.png",
    imagePlural: "produkter-oelvand.soebo-oeko-aeble-kirsebaer-ramme.png",
    categories: ["soebogaard"],
    url: "/soebogaard/soebogaard-oeko-aeble-kirsebaer",
    routeDef: {
      path: "/soebogaard/soebogaard-oeko-aeble-kirsebaer",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SoebogaardBC],
        title: "Søbogaard Øko Æble-Kirsebær",
      },
      meta: {
        title: "Søbogaard Øko Æble-Kirsebær",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Soebogaard",
      },
    },
  },
  {
    varenr: 6260,
    name: "Søbo Øko Jordbær",
    generalSearchPriority: 500,
    keywords:
      "søbogaard soebogaard saft sobogaard søbogård soebogård såbygaard søborgaard søbygaard sobogård Søborggård soeborggård flasker glasflasker 25cl 25 cl 30x25cl 30x25 plastkasser økologisk økologiske",
    imageSingle: "produkter-oelvand.6260-flaske.png",
    imagePlural: "produkter-oelvand.soebogaard-jordbaer-ramme.png",
    categories: ["soebogaard"],
    url: "/soebogaard/soebogaard-oeko-jordbaer",
    routeDef: {
      path: "/soebogaard/soebogaard-oeko-jordbaer",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SoebogaardBC],
        title: "Søbogaard Øko Jordbær",
      },
      meta: {
        title: "Søbogaard Øko Jordbær",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Soebogaard",
      },
    },
  },
  {
    varenr: 6210,
    name: "Søbo Øko Tranebær",
    generalSearchPriority: 500,
    keywords:
      "søbogaard soebogaard saft sobogaard søbogård soebogård såbygaard søborgaard søbygaard sobogård Søborggård soeborggård tranebærsaft flasker glasflasker 25cl 25 cl 30x25cl 30x25 plastkasser økologisk økologiske",
    imageSingle: "produkter-oelvand.6210-flaske.png",
    imagePlural: "produkter-oelvand.soebo-oeko-tranebaer-ramme.png",
    categories: ["soebogaard"],
    url: "/soebogaard/soebogaard-oeko-tranebaer",
    routeDef: {
      path: "/soebogaard/soebogaard-oeko-tranebaer",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SoebogaardBC],
        title: "Søbogaard Øko Tranebær",
      },
      meta: {
        title: "Søbogaard Øko Tranebær",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Soebogaard",
      },
    },
  },
  {
    varenr: 6160,
    name: "Søbo Øko Hindbær",
    generalSearchPriority: 500,
    keywords:
      "søbogaard soebogaard saft sobogaard søbogård soebogård såbygaard søborgaard søbygaard sobogård Søborggård soeborggård flasker glasflasker 25cl 25 cl 30x25cl 30x25 plastkasser økologisk økologiske",
    imageSingle: "produkter-oelvand.6160-flaske.png",
    imagePlural: "produkter-oelvand.soebo-oeko-aeble-kirsebaer-ramme.png",
    categories: ["soebogaard"],
    url: "/soebogaard/soebogaard-oeko-hindbaer",
    routeDef: {
      path: "/soebogaard/soebogaard-oeko-hindbaer",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SoebogaardBC],
        title: "Søbogaard Øko Hindbær",
      },
      meta: {
        title: "Søbogaard Øko Hindbær",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Soebogaard",
      },
    },
  },
  {
    varenr: 6240,
    name: "Søbo Øko Rabarber",
    generalSearchPriority: 500,
    keywords:
      "søbogaard soebogaard saft sobogaard søbogård soebogård såbygaard søborgaard søbygaard sobogård Søborggård soeborggård flasker glasflasker 25cl 25 cl 30x25cl 30x25 plastkasser økologisk økologiske",
    imageSingle: "produkter-oelvand.6240-flaske.png",
    imagePlural: "produkter-oelvand.soebo-oeko-rabarber-ramme.png",
    categories: ["soebogaard"],
    url: "/soebogaard/soebogaard-oeko-rabarber",
    routeDef: {
      path: "/soebogaard/soebogaard-oeko-rabarber",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SoebogaardBC],
        title: "Søbogaard Øko Rabarber",
      },
      meta: {
        title: "Søbogaard Øko Rabarber",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Soebogaard",
      },
    },
  },
  {
    varenr: 6230,
    name: "Søbo Øko Sjælevarmer",
    generalSearchPriority: 500,
    keywords:
      "søbogaard soebogaard saft sobogaard søbogård soebogård såbygaard søborgaard søbygaard sobogård Søborggård soeborggård flasker glasflasker 25cl 25 cl 30x25cl 30x25 plastkasser økologisk økologiske",
    imageSingle: "produkter-oelvand.6230-flaske.png",
    imagePlural: "produkter-oelvand.soebogaard-sjaelevarmer-ramme.png",
    categories: ["soebogaard"],
    url: "/soebogaard/soebogaard-oeko-sjaelevarmer",
    routeDef: {
      path: "/soebogaard/soebogaard-oeko-sjaelevarmer",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SoebogaardBC],
        title: "Søbogaard Øko Sjælevarmer",
      },
      meta: {
        title: "Søbogaard Øko Sjælevarmer",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Soebogaard",
      },
    },
  },
  {
    varenr: 6250,
    name: "Søbo Øko Sport Brus",
    generalSearchPriority: 500,
    keywords:
      "søbogaard soebogaard saft sobogaard søbogård soebogård såbygaard søborgaard søbygaard sobogård Søborggård soeborggård flasker glasflasker 25cl 25 cl 30x25cl 30x25 plastkasser økologisk økologiske",
    imageSingle: "produkter-oelvand.6250-flaske.png",
    imagePlural: "produkter-oelvand.soebogaard-sport-ramme.png",
    categories: ["soebogaard"],
    url: "/soebogaard/soebogaard-oeko-sport-brus",
    routeDef: {
      path: "/soebogaard/soebogaard-oeko-sport-brus",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SoebogaardBC],
        title: "Søbogaard Øko Sport Brus",
      },
      meta: {
        title: "Søbogaard Øko Sport Brus",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Soebogaard",
      },
    },
  },
  {
    varenr: 6280,
    name: "Søbo Øko Blåbær",
    generalSearchPriority: 500,
    keywords:
      "søbogaard soebogaard saft sobogaard søbogård soebogård såbygaard søborgaard søbygaard sobogård Søborggård soeborggård flasker glasflasker 25cl 25 cl 30x25cl 30x25 plastkasser økologisk økologiske",
    imageSingle: "produkter-oelvand.6280-flaske.png",
    imagePlural: "produkter-oelvand.soebogaard-blaabaer-ramme.png",
    categories: ["soebogaard"],
    url: "/soebogaard/soebogaard-oeko-blaabaer",
    routeDef: {
      path: "/soebogaard/soebogaard-oeko-blaabaer",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SoebogaardBC],
        title: "Søbogaard Øko Blåbær",
      },
      meta: {
        title: "Søbogaard Øko Blåbær",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Soebogaard",
      },
    },
  },
  {
    varenr: 6220,
    name: "Søbogaard Øko Jordbær-Hyldeblomst",
    generalSearchPriority: 500,
    keywords:
      "søbogaard soebogaard saft sobogaard søbogård soebogård såbygaard søborgaard søbygaard sobogård Søborggård soeborggård flasker glasflasker 25cl 25 cl 30x25cl 30x25 plastkasser økologisk økologiske",
    imageSingle: "produkter-oelvand.6220-flaske.png",
    imagePlural: "produkter-oelvand.soebogaard-jordbaer-hyldeblomst-ramme.png",
    categories: ["soebogaard"],
    url: "/soebogaard/soebogaard-oeko-jordbaer-hyldeblomst",
    routeDef: {
      path: "/soebogaard/soebogaard-oeko-jordbaer-hyldeblomst",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SoebogaardBC],
        title: "Søbogaard Øko Jordbær-Hyldeblomst",
      },
      meta: {
        title: "Søbogaard Øko Jordbær-Hyldeblomst",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Soebogaard",
      },
    },
  },
  {
    varenr: 6300,
    name: "Søbo Øko Sluk Hydeblomst Brus",
    generalSearchPriority: 500,
    keywords:
      "søbogaard soebogaard saft sobogaard søbogård soebogård såbygaard søborgaard søbygaard sobogård Søborggård soeborggård flasker glasflasker 25cl 25 cl 30x25cl 30x25 plastkasser økologisk økologiske",
    imageSingle: "produkter-oelvand.6300-flaske.png",
    imagePlural: "produkter-oelvand.soebogaard-slukkert-ramme.png",
    categories: ["soebogaard"],
    url: "/soebogaard/soebogaard-oeko-sluk-hydeblomst-brus",
    routeDef: {
      path: "/soebogaard/soebogaard-oeko-sluk-hydeblomst-brus",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SoebogaardBC],
        title: "Søbogaard Øko Sluk Hydeblomst Brus",
      },
      meta: {
        title: "Søbogaard Øko Sluk Hydeblomst Brus",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Soebogaard",
      },
    },
  },
  {
    varenr: 6290,
    name: "Søbo Øko Mango/Appelsin",
    generalSearchPriority: 500,
    keywords:
      "søbogaard soebogaard saft sobogaard søbogård soebogård såbygaard søborgaard søbygaard sobogård Søborggård soeborggård flasker glasflasker 25cl 25 cl 30x25cl 30x25 plastkasser økologisk økologiske",
    imageSingle: "produkter-oelvand.6290-flaske.png",
    imagePlural: "produkter-oelvand.soebo-oeko-mango-appelsin-ramme.png",
    categories: ["soebogaard"],
    url: "/soebogaard/soebogaard-oeko-mango/appelsin",
    routeDef: {
      path: "/soebogaard/soebogaard-oeko-mango/appelsin",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SoebogaardBC],
        title: "Søbogaard Øko Mango/Appelsin",
      },
      meta: {
        title: "Søbogaard Øko Mango/Appelsin",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Soebogaard",
      },
    },
  },
  {
    varenr: 6330,
    name: "Søbo Øko Citrus Lemona",
    keywords:
      "søbogaard soebogaard saft sobogaard søbogård soebogård såbygaard søborgaard søbygaard sobogård Søborggård soeborggård flasker glasflasker 25cl 25 cl 30x25cl 30x25 plastkasser økologisk økologiske citron",
    imageSingle: "produkter-oelvand.6330-flaske.png",
    imagePlural: "produkter-oelvand.soebogaard-citron-ramme.png",
    categories: ["soebogaard"],
    url: "/soebogaard/soebogaard-oeko-citrus-lemona",
    routeDef: {
      path: "/soebogaard/soebogaard-oeko-citrus-lemona",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SoebogaardBC],
        title: "Søbogaard Øko Citrus Lemona",
      },
      meta: {
        title: "Søbogaard Øko Citrus Lemona",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Soebogaard",
      },
    },
  },
  {
    varenr: 6340,
    name: "Søbo Øko Ingefær",
    generalSearchPriority: 500,
    keywords:
      "søbogaard soebogaard saft sobogaard søbogård soebogård såbygaard søborgaard søbygaard sobogård Søborggård soeborggård flasker glasflasker 25cl 25 cl 30x25cl 30x25 plastkasser økologisk økologiske",
    imageSingle: "produkter-oelvand.6340-flaske.png",
    imagePlural: "produkter-oelvand.soebogaard-ingefaer-ramme.png",
    categories: ["soebogaard"],
    url: "/soebogaard/soebogaard-oeko-ingefaer",
    routeDef: {
      path: "/soebogaard/soebogaard-oeko-ingefaer",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SoebogaardBC],
        title: "Søbogaard Øko Ingefær",
      },
      meta: {
        title: "Søbogaard Øko Ingefær",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Soebogaard",
      },
    },
  },
  /* Antons */
  {
    varenr: 32110,
    name: "Antons Øko Æblemost saft",
    generalSearchPriority: 500,
    searchSignificance: 50,
    keywords:
      "anton flasker glasflasker 25cl 25 cl 24x25cl 24x25 plastkasser økologisk økologiske",
    imageSingle: "produkter-oelvand.antons-oeko-aeblemost-flaske.png",
    imagePlural: "produkter-oelvand.1000-kasse.png",
    categories: ["antons"],
    url: "/antons-saft/antons-oeko-aeblemost-saft",
    routeDef: {
      path: "/antons-saft/antons-oeko-aeblemost-saft",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, AntonsBC],
        title: "Antons Øko Æblemost saft",
      },
      meta: {
        title: "Antons Øko Æblemost saft",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Antons",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Antons æblemost: en tradition af smag og kvalitet</Heading3>
        <Paragraph>
          Oplev essensen af ægte, solmodne æbler med Antons Æblemost – et
          nostalgisk tilbageblik på en æra, hvor smagen var i centrum.
          Inspireret af barndomsminder, hvor friske æbler blev omsorgsfuldt
          forvandlet til most ved det lokale gårdmosteri ”Klejsgård”,
          genopfinder Anton denne tradition med moderne finesse.
        </Paragraph>
        <Paragraph>
          Antons æblemost er ikke blot en drik; det er en hyldest til fortidens
          smagsoplevelser. Gennem traditionelle metoder sikrer Anton, at hver
          dråbe af hans æblemost er en ren fest for sanserne. Denne
          koldpressede, ufiltrerede og økologiske tilgang sikrer, at Antons
          Æblemost bevarer alle de naturlige aromaer, vitaminer og mineraler fra
          de friskplukkede æbler.
        </Paragraph>
        <Heading3>Smagens mangfoldighed</Heading3>
        <Paragraph>
          Med tusindvis af æblesorter i verden og over 750 sorter fra pometet i
          Tåstrup, er hver flaske Antons Æblemost en unik smagsoplevelse.
          Æblernes naturlige duft- og smagsstoffer varierer afhængigt af sort,
          klima og jordbundstype. Derfor kan Antons æblemost have subtile
          forskelle i smagsprofilen, hvilket gør hver flaske til en spændende
          opdagelse.
        </Paragraph>
        <Heading3>Mere end bare en drik</Heading3>
        <Paragraph>
          Antons Æblemost er overraskende alsidig. Den kan omdannes til en
          aromatisk sirup, der forvandler fiskeretter og desserter. Dens friske,
          syrlige noter og sødme af modne æbler udfolder sig anderledes ved
          forskellige serveringstemperaturer. Perfekt til brunch, frokost eller
          en afslappende stund. Antons Æblemost er en smagsoplevelse, der
          appellerer til alle sanser.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        <Paragraph>
          Oplev Antons Æblemost, koldpresset og økologisk fra friske, solmodne
          æbler. Hver dråbe er en hyldest til klassiske smagsoplevelser, perfekt
          til alle måltider.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 32120,
    name: "Antons Øko Solbær saft",
    generalSearchPriority: 500,
    searchSignificance: 50,
    keywords:
      "anton flasker glasflasker 25cl 25 cl 24x25cl 24x25 plastkasser økologisk økologiske",
    imageSingle: "produkter-oelvand.antons-oeko-solbaer-saft-flaske.png",
    imagePlural: "produkter-oelvand.1000-kasse.png",
    categories: ["antons"],
    url: "/antons-saft/antons-oeko-solbaer-saft",
    routeDef: {
      path: "/antons-saft/antons-oeko-solbaer-saft",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, AntonsBC],
        title: "Antons Øko Solbær saft",
      },
      meta: {
        title: "Antons Øko Solbær saft",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Antons",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Antons Solbær: Økologisk kraft i hver dråbe</Heading3>
        <Paragraph>
          Opdag den dybe og fyldige smag af Antons Solbær, en økologisk drik
          skabt fra hjertet af den frodige østjyske natur i Juelsminde. Denne
          lille øko-bombe er et produkt af Antons egen biodynamiske
          solbærplantage, hvor naturen selv spiller en stor rolle i plejen af
          solbærene. Fra humlebier til musvitkasser og ørentvister-bosteder,
          hver detalje bidrager til at skabe de perfekte økologiske solbær.
        </Paragraph>
        <Paragraph>
          Antons beslutning om at producere sin egen saft stammer fra et ønske
          om at tilbyde et sundere og mere miljøvenligt alternativ til
          konventionel saft. Ved at anvende sine økologisk dyrkede solbær, har
          Anton skabt en solbærsaft, der ikke blot smager fantastisk, men også
          respekterer og fremmer et sundt miljø.
        </Paragraph>
        <Heading3>En symfoni af smag</Heading3>
        <Paragraph>
          Solbær, der botanisk hører til Ribes-familien, har en unik smagsprofil
          skabt af hundredvis af naturlige smags- og duftstoffer. Fra citrus og
          blomster til fyrrenåle og grøn peber, hver nuance bidrager til Antons
          Solbærs komplekse og dybe karakter. Den karakteristiske skarpe smag
          kommer fra solbær mercaptan, en svovlforbindelse, der giver solbær
          deres signaturaroma.
        </Paragraph>
        <Heading3>Alsige anvendelsesmuligheder</Heading3>
        <Paragraph>
          Antons Solbær er ikke kun en saft, men en kulinarisk oplevelse. Med
          sin markante smag er saften ideel til at ledsage rødt kød, berige
          gryderetter og løfte en osteanretning. Den kan også forvandle
          desserter, sorbeter og drinks. Serveres den køligt, fremhæves den
          syrlige og friske smag, mens en lidt højere serveringstemperatur
          bringer de modne solbærs fyldige mundfølelse og aroma til deres ret.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        <Paragraph>
          Nyd Antons økologiske solbærsaft fra biodynamiske solbær. En unik smag
          med noter af citrus, blomster og grøn peber, perfekt til madlavning og
          forfriskende drikke.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 32130,
    name: "Antons Øko Appelsin saft",
    generalSearchPriority: 500,
    searchSignificance: 50,
    keywords:
      "anton flasker glasflasker 25cl 25 cl 24x25cl 24x25 plastkasser økologisk økologiske",
    imageSingle: "produkter-oelvand.antons-oeko-appelsin-saft-flaske.png",
    imagePlural: "produkter-oelvand.1000-kasse.png",
    categories: ["antons"],
    url: "/antons-saft/antons-oeko-appelsin-saft",
    routeDef: {
      path: "/antons-saft/antons-oeko-appelsin-saft",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, AntonsBC],
        title: "Antons Øko Appelsin saft",
      },
      meta: {
        title: "Antons Øko Appelsin saft",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Antons",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Antons Appelsin: En friskpresset solstråle</Heading3>
        <Paragraph>
          Nyd den rene essens af solen med Antons Appelsin, en ren og naturlig
          appelsinsaft uden tilsætningsstoffer. Denne saft er ikke blot en fryd
          for ganen, men også en kilde til vitalitet, fyldt med C-vitamin,
          kalium og magnesium, som understøtter hjerte- og karsundheden.
        </Paragraph>
        <Paragraph>
          Anton forpligter sig til at bruge lokalt dyrket frugt, når det er
          muligt, og vælger omhyggeligt kun økologiske appelsiner fra udlandet,
          for at sikre den højeste kvalitet og smag. Dette valg er et bevidst
          kompromis for at kunne levere den bedste appelsinsaft, selv når
          frugten ikke kan dyrkes lokalt.
        </Paragraph>
        <Heading3>En kalejdoskopisk smagsoplevelse</Heading3>
        <Paragraph>
          Appelsinen, den mest elskede af alle citrusfrugter, byder på et rigt
          farvespil af smagsnuancer. Den får sin karakteristiske orange farve
          fra carotenoider, og dens unikke smag skyldes en symfoni af omkring
          450 naturlige smagsstoffer. Disse elementer kombinerer friske,
          syrlige, søde og blomsteragtige toner med en antydning af fyldig
          umami. Denne mangfoldighed skaber en oplevelse, der vækker sanserne og
          efterlader en frisk og let stikkende fornemmelse i munden.
        </Paragraph>
        <Heading3>Mere end bare en morgenjuice</Heading3>
        <Paragraph>
          Antons Appelsin er utrolig alsidig. Den kan nydes som en del af
          morgenmåltidet, til brunch, sammen med dessert, eller bruges kreativt
          i is, sorbeter og drinks - den klassiske Vodka/Appelsinsaft med is er
          blot et eksempel. Den friske, syrlige smag kommer bedst til sin ret,
          når saften serveres køligt, mens en mindre afkølet servering fremhæver
          frugtigheden og den fyldige konsistens.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        <Paragraph>
          Nyd Antons rene, naturlige appelsinsaft, rig på C-vitamin og
          mineraler. En kalejdoskopisk blanding af friske, syrlige og søde
          nuancer, ideel til enhver lejlighed.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 32140,
    name: "Antons Øko Hyldeblomst saft",
    generalSearchPriority: 500,
    searchSignificance: 50,
    keywords:
      "anton flasker glasflasker 25cl 25 cl 24x25cl 24x25 plastkasser økologisk økologiske",
    imageSingle: "produkter-oelvand.antons-oeko-hyldeblomst-saft-flaske.png",
    imagePlural: "produkter-oelvand.1000-kasse.png",
    categories: ["antons"],
    url: "/antons-saft/antons-oeko-hyldeblomst-saft",
    routeDef: {
      path: "/antons-saft/antons-oeko-hyldeblomst-saft",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, AntonsBC],
        title: "Antons Øko Hyldeblomst saft",
      },
      meta: {
        title: "Antons Øko Hyldeblomst saft",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Antons",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Antons Hyldeblomst: Sommerens essens i hver dråbe</Heading3>
        <Paragraph>
          Antons Hyldeblomst bringer en duft af sommer og sol direkte til dit
          glas, hvilket afspejler hyldeblomstens stigende popularitet i de
          senere år. Denne forfriskende drik er en hyldest til tradition og
          natur.
        </Paragraph>
        <Paragraph>
          Hyldetræet, som har været en del af den danske flora siden
          stenalderen, har en rig kulturel historie. Det har været kendt som
          frugtbarhedsgudinden Frejas træ i nordisk mytologi og som Hyldemors
          træ i folketraditioner. Hylden blev historisk set brugt som et middel
          mod sygdomme og troet at beskytte mod det onde – en tanke, der
          genlyder i dagens fokus på biodiversitet og insektliv.
        </Paragraph>
        <Heading3>En palet af smagsnoter</Heading3>
        <Paragraph>
          Hyldeblomster indeholder over hundrede forskellige naturlige smags- og
          duftstoffer. Denne rige samling inkluderer elementer som limonen, der
          tilføjer citrusnoter, geraniol for blomsteragtige toner, citronellol
          der minder om citrus og rose, samt menthol og eucalyptol for en frisk,
          kølende fornemmelse. Disse ingredienser er hemmeligheden bag
          hyldeblomstens komplekse smagsprofil, fuld af blomster- og
          friskhedsnuancer.
        </Paragraph>
        <Heading3>Perfekt til enhver lejlighed</Heading3>
        <Paragraph>
          Antons Hyldeblomst er en ideel ledsager til en varm sommerdag, brunch,
          en let frokost, eller som et kreativt twist i desserter og drinks.
          Serveret koldt, kommer de friske blomster- og citrusnoter til deres
          ret, mens en mindre afkølet servering fremhæver hyldeblomstens sødme
          og dybde.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        <Paragraph>
          Oplev en forfriskende smag af sommer med Antons Hyldeblomst, rig på
          naturlige aromaer. Ideel til varme dage, brunch, eller som et twist i
          desserter og drinks.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 32160,
    name: "Antons Øko Hindbær saft",
    generalSearchPriority: 500,
    searchSignificance: 50,
    keywords:
      "anton flasker glasflasker 25cl 25 cl 24x25cl 24x25 plastkasser økologisk økologiske",
    imageSingle: "produkter-oelvand.antons-oeko-hindbaer-saft-flaske.png",
    imagePlural: "produkter-oelvand.1000-kasse.png",
    categories: ["antons"],
    url: "/antons-saft/antons-oeko-hindbaer-saft",
    routeDef: {
      path: "/antons-saft/antons-oeko-hindbaer-saft",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, AntonsBC],
        title: "Antons Øko Hindbær saft",
      },
      meta: {
        title: "Antons Øko Hindbær saft",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Antons",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>
          Antons Hindbær: En eksplosion af friskhed og vildskab
        </Heading3>
        <Paragraph>
          Antons Hindbær er en hyldest til dette alsidige og historisk
          betydningsfulde bær. I århundreder har hindbær været en favorit på
          grund af sin unikke smag, og det har endda inspireret til udviklingen
          af nye bærtyper som Boisenbær, Loganbær, Taybær og Tummelbær. Sorten,
          der bruges i Antons Hindbær, er omhyggeligt udvalgt for dens særlige
          smagsprofil. I 2015 blev Antons Hindbær anerkendt med en ærespræmie og
          guldmedalje i Internationalt FOOD Contest, hvilket understreger dets
          exceptionelle kvalitet.
        </Paragraph>
        <Heading3>En botanisk overraskelse</Heading3>
        <Paragraph>
          Hindbæret er botanisk set en samling af små stenfrugter, kendt som en
          flerflodsfrugt. Dets særlige smag og blomsternoter skyldes
          smagsstoffer som alfa-ionon og beta-ionon, der også findes i violer.
          Denne komplekse smagssammensætning vækker minder om barndom, sol og
          sommer.
        </Paragraph>
        <Heading3>Alsidige kulinariske muligheder</Heading3>
        <Paragraph>
          Antons Hindbær er en alsidig ingrediens, der kan berige alt fra en
          brunch til en let frokost med kødretter som kalv og kylling. Den er
          også en fremragende tilføjelse til kreative drinks, f.eks. en cocktail
          med vodka, hindbærsaft, lakridssirup og knust is. Den kølige servering
          fremhæver hindbærets citrusnoter og friskhed, mens en knap så kold
          servering tillader sødmen og smagen af modne hindbær at træde mere i
          forgrunden.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        <Paragraph>
          Nyd Antons Hindbær, en prisvindende, alsidig saft med unikke
          smagsnoter af blomster og barndom. Perfekt til madlavning, drinks,
          eller som en frisk drik.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 32240,
    name: "Antons Øko Rabarber saft",
    generalSearchPriority: 500,
    searchSignificance: 50,
    keywords:
      "anton flasker glasflasker 25cl 25 cl 24x25cl 24x25 plastkasser økologisk økologiske",
    imageSingle: "produkter-oelvand.antons-oeko-rabarber-saft-flaske.png",
    imagePlural: "produkter-oelvand.1000-kasse.png",
    categories: ["antons"],
    url: "/antons-saft/antons-oeko-rabarber-saft",
    routeDef: {
      path: "/antons-saft/antons-oeko-rabarber-saft",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, AntonsBC],
        title: "Antons Øko Rabarber saft",
      },
      meta: {
        title: "Antons Øko Rabarber saft",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Antons",
      },
    },
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>
          Antons Rabarber: En perfekt balance af surhed og sødme
        </Heading3>
        <Paragraph>
          Oplev den forførende smag af Antons Rabarber, der frembringer det
          bedste fra over 70 forskellige rabarbersorter. Blandt disse er det
          specielt de røde rabarber, kategoriseret under &quot;Jordbær
          Rabarber&quot;, der fanger interessen med varianter som Marshall Early
          Red, Svendborg Vinrabarber og Spangsbjerg. Disse sorter bidrager ikke
          kun med en levende farve til saften, men også med de sunde
          anthocyaniner, som er kilden til farven.
        </Paragraph>
        <Paragraph>
          Fra at være en beskeden grøntsag i fortiden, er rabarber nu blevet en
          favorit på tværs af det nordiske køkken, brugt i alt fra kager og grød
          til saft og marmelade.
        </Paragraph>
        <Heading3>En smag af tradition og forår</Heading3>
        <Paragraph>
          Rabarber, botanisk en grøntsag ligesom bladselleri og asparges, spirer
          frem tidligt på foråret. Dens popularitet i England i det tidlige 20.
          århundrede, forstærket af udviklingen af nye sorter og mere
          tilgængelige sukkerpriser, har ført til dens udbredte brug. For mange
          vil Antons Rabarbersaft vække minder om barndommens sommerbesøg hos
          mormor.
        </Paragraph>
        <Heading3>Kulinarisk alsidighed</Heading3>
        <Paragraph>
          Antons Rabarber er et alsidigt valg for det moderne nordiske køkken.
          Den er ideel til fiskeretter, drinks, lette frokoster, desserter,
          eller som en kreativ ingrediens i køkkenet – prøv f.eks. at indkoge
          den og glacere asparges. Saftens friske, syrlig-søde smag med fine
          frugt- og blomsternoter ændrer karakter afhængig af
          serveringstemperaturen. Serveres den køligt, fremhæves friskheden og
          syrligheden, mens en lidt varmere servering bringer frugt- og
          rabarbernoter samt en fyldigere mundfølelse frem.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        <Paragraph>
          Nyd Antons Rabarber, rig på smag og farve fra udvalgte sorter. En
          alsidig saft, der vækker minder og beriger ethvert måltid.
        </Paragraph>
      </React.Fragment>
    ),
  },
  /* K: Læskedrikke */
  {
    varenr: 21032,
    name: "Aqua d'or Black Tea Fersken Zero",
    generalSearchPriority: 500,
    keywords:
      "aquador aqua aqua'd'or aquad'or door dor auqa dór flasker plastflasker plastikflasker 50cl 50 cl 12x50cl 12x50 ice tea",
    imageSingle: "produkter-oelvand.aquador-black-tea-fersken-zero-50cl.png",
    imagePlural: "produkter-oelvand.21032-kasse.png",
    categories: ["iceTea"],
    url: "/ice-tea/aqua-dor-black-tea-fersken-zero",
    routeDef: {
      path: "/ice-tea/aqua-dor-black-tea-fersken-zero",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, IceTeaBC],
        title: "Aqua d'or Black Tea Fersken Zero",
      },
      meta: {
        title: "Aqua d'or Black Tea Fersken Zero",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "IceTea",
      },
    },
  },
  {
    varenr: 21052,
    name: "Aqua d'or Ice Tea Mango Zero",
    generalSearchPriority: 500,
    keywords:
      "aquador aqua aqua'd'or aquad'or door dor auqa dór flasker plastflasker plastikflasker 50cl 50 cl 12x50cl 12x50",
    imageSingle: "produkter-oelvand.aquador-mango-ice-tea.png",
    imagePlural: "produkter-oelvand.aquado-mango-ice-tea-ramme.png",
    categories: ["iceTea"],
    url: "/ice-tea/aqua-dor-black-tea-mango-zero",
    routeDef: {
      path: "/ice-tea/aqua-dor-black-tea-mango-zero",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, IceTeaBC],
        title: "Aqua d'or Black Tea Mango Zero",
      },
      meta: {
        title: "Aqua d'or Black Tea Mango Zero",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "IceTea",
      },
    },
  },
  {
    varenr: 3410,
    name: "Rynkeby Æblemost",
    generalSearchPriority: 500,
    keywords:
      "saft aeblemost bornholms mosteri flasker glasflasker 27,5cl 27,5 cl 12x27,5cl 12x27,5 papkasser",
    imageSingle: "produkter-oelvand.rynkeby-aeblemost-275-flaske.png",
    imagePlural: "produkter-oelvand.rynkeby-aeblemost-kasse.png",
    url: "/juice/rynkeby-aeblemost",
    categories: ["juice"],
    routeDef: {
      path: "/juice/rynkeby-aeblemost",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, JuiceBC],
        title: "Rynkeby Æblemost",
      },
      meta: {
        title: "Rynkeby Æblemost",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Juice",
      },
    },
  },
  {
    varenr: 3430,
    name: "Solita Juice",
    generalSearchPriority: 500,
    keywords: "flasker glasflasker 27,5cl 27,5 cl 12x27,5cl 12x27,5 papkasser",
    imageSingle: "produkter-oelvand.solita-275-flaske.png",
    imagePlural: "produkter-oelvand.rynkeby-aeblemost-kasse.png",
    url: "/juice/solita-juice",
    categories: ["juice"],
    routeDef: {
      path: "/juice/solita-juice",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, JuiceBC],
        title: "Solita Juice",
      },
      meta: {
        title: "Solita Juice",
        description: "",
      },
      props: {
        menuPicker: "VandSodavand",
        activeSidemenu: "Juice",
      },
    },
  },
  /* Kulsyre */
  {
    varenr: 8944,
    name: "Kulsyre 4 kg",
    showOnlyPluralPrice: true,
    productTypeKulsyre: true,
    generalSearchPriority: 1000,
    keywords:
      "co2 kuldioxid kullsyre kulsyreflasker kulsyreflaske kolsyra trykflaske tryk flaske un1013 4 kg 4kg levnesmiddel kludioxid kulsyrer kulsyrepatron stigrør",
    productTypeReservedel: true,
    imageSingle: "kulsyre.4-kg-kulsyreflaske-roed-properSize.png",
    url: "/kulsyre/kulsyre-4kg",
    categories: ["kulsyre", "kulsyreSodastream"],
    routeDef: {
      path: "/kulsyre/kulsyre-4kg",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KulsyreBC],
        title: "Kulsyre 4kg",
      },
      meta: {
        title: "4 kg Kulsyreflaske | Køb CO2 flaske nemt online - Skafte.dk",
        description:
          "Køb CO2 kulsyre flaske på 4 kg til enten Fadøl, akvarie, svejsning eller sodastream. Gratis fragt ved køb af 2. stk. Spørgsmål? så hjælper vi - Skafte.dk.",
      },
      props: {
        menuPicker: "Kulsyre",
        activeSidemenu: "Kulsyre",
      },
    },
    description: () => (
      <React.Fragment>
        <Paragraph bold>
          CO<sub>2</sub> er af levnedsmiddelkvalitet.
        </Paragraph>
        <Paragraph>
          Til fadøl, danskvand, akvarier, svejsning m.m.
          <br />
          Flasken er fyldt og rækker til 15-20 fustager.
        </Paragraph>
        <Paragraph>
          Panten på flasken er <Price embPrice varenr="8944" momsIndication />
        </Paragraph>
        <Paragraph>
          Aluminiumsflaske uden stigrør:
          <br />
          Diameter 15,4cm | Højde 59cm | Tomvægt 7,25kg
          <br />
          Passer i standard underskab
        </Paragraph>
      </React.Fragment>
    ),
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Mængderabat ved køb af flere 4kg kulsyreflasker:
          <br />
        </Paragraph>
        <Table lines1>
          <tbody>
            <tr>
              <Td>6-15</Td>
              <Td textRight>
                <PriceSum varenr={[8944, 705]} itemPrice /> pr. stk.
              </Td>
            </tr>
            <tr>
              <Td>16-32</Td>
              <Td textRight>
                <PriceSum varenr={[8944, 711]} itemPrice /> pr. stk.
              </Td>
            </tr>
          </tbody>
        </Table>
        <Paragraph marginTop="10px">
          Rabatten bliver fratrukket i kurven.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 8940,
    name: "Kulsyre 6 kg",
    showOnlyPluralPrice: true,
    productTypeKulsyre: true,
    generalSearchPriority: 1000,
    keywords:
      "co2 kuldioxid kullsyre kulsyreflasker kulsyreflaske kolsyra trykflaske tryk flaske un1013 6 kg 6kg levnesmiddel kludioxid kulsyrer kulsyrepatron stigrør",
    imageSingle: "kulsyre.6-kg-kulsyreflaske-roed.png",
    productTypeReservedel: true,
    url: "/kulsyre/kulsyre-6kg",
    categories: ["kulsyre"],
    routeDef: {
      path: "/kulsyre/kulsyre-6kg",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, KulsyreBC],
        title: "Kulsyre 6kg",
      },
      meta: {
        title: "6 kg Kulsyreflaske | Køb stor CO2 flaske  online - Skafte.dk",
        description:
          "Køb 6 kg kulsyreflaske (CO2) til enten Fadøl, sodastream, akvarie eller svejsning. køb 2 stk. og få gratis fragt. Se også udstyr til forskellig koblinger.",
      },
      props: {
        menuPicker: "Kulsyre",
        activeSidemenu: "Kulsyre",
      },
    },
    description: () => (
      <React.Fragment>
        <Paragraph bold>
          CO<sub>2</sub> er af levnedsmiddelkvalitet.
        </Paragraph>
        <Paragraph>
          Til fadøl, danskvand, akvarier, svejsning m.m.
          <br />
          Flasken er fyldt og rækker til 25-30 fustager.
        </Paragraph>
        <Paragraph>
          Panten på flasken er <Price embPrice varenr="8940" momsIndication />
        </Paragraph>
        <Paragraph>
          Aluminiumsflaske uden stigrør:
          <br />
          Diameter 16cm | Højde 72cm | Tomvægt 9kg
        </Paragraph>
      </React.Fragment>
    ),
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Paragraph>
          Mængderabat ved køb af flere 6kg kulsyreflasker:
          <br />
        </Paragraph>
        <Table lines1>
          <tbody>
            <tr>
              <Td>6-15</Td>
              <Td textRight>
                <PriceSum varenr={[8940, 706]} itemPrice /> pr. stk.
              </Td>
            </tr>
            <tr>
              <Td>16-32</Td>
              <Td textRight>
                <PriceSum varenr={[8940, 712]} itemPrice /> pr. stk.
              </Td>
            </tr>
          </tbody>
        </Table>
        <Paragraph marginTop="10px">
          Rabatten bliver fratrukket i kurven.
        </Paragraph>
      </React.Fragment>
    ),
  },
  /* Opfyldning af kulsyreflasker */
  {
    varenr: 8962,
    name: "Opfyldning af privat kulsyreflaske 2kg",
    generalSearchPriority: 500,
    kulsyreOpfyldning: true,
    categories: ["kulsyreOpfyldning"],
    keywords:
      "co2 kuldioxid kullsyre kulsyreflasker kulsyreflaske kolsyra trykflaske tryk flaske un1013 6 kg 6kg levnesmiddel kludioxid kulsyrer opfyldning",
    imageSingle: "kulsyre.opfylding-2kg.png",
    url: "/kulsyre/opfyldning-af-kulsyreflasker",
    urlDontShow: true,
    description: () => (
      <React.Fragment>
        <Paragraph>
          Opfyldning af privat 1-2 kg kulsyreflaske på fyldeanlæg.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 8964,
    name: "Opfyldning af privat kulsyreflaske 4kg",
    generalSearchPriority: 500,
    kulsyreOpfyldning: true,
    categories: ["kulsyreOpfyldning"],
    keywords:
      "co2 kuldioxid kullsyre kulsyreflasker kulsyreflaske kolsyra trykflaske tryk flaske un1013 6 kg 6kg levnesmiddel kludioxid kulsyrer opfyldning",
    imageSingle: "kulsyre.opfylding-4kg.png",
    url: "/kulsyre/opfyldning-af-kulsyreflasker",
    urlDontShow: true,
    description: () => (
      <React.Fragment>
        <Paragraph>
          Opfyldning af privat 2-4 kg kulsyreflaske på fyldeanlæg.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 8966,
    name: "Opfyldning af privat kulsyreflaske 6kg",
    generalSearchPriority: 500,
    kulsyreOpfyldning: true,
    categories: ["kulsyreOpfyldning"],
    keywords:
      "co2 kuldioxid kullsyre kulsyreflasker kulsyreflaske kolsyra trykflaske tryk flaske un1013 6 kg 6kg levnesmiddel kludioxid kulsyrer opfyldning",
    imageSingle: "kulsyre.opfylding-6kg.png",
    url: "/kulsyre/opfyldning-af-kulsyreflasker",
    urlDontShow: true,
    description: () => (
      <React.Fragment>
        <Paragraph>
          Opfyldning af privat 4-6 kg kulsyreflaske på fyldeanlæg.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 8970,
    name: "Opfyldning af privat kulsyreflaske 10kg",
    generalSearchPriority: 500,
    kulsyreOpfyldning: true,
    categories: ["kulsyreOpfyldning"],
    keywords:
      "co2 kuldioxid kullsyre kulsyreflasker kulsyreflaske kolsyra trykflaske tryk flaske un1013 6 kg 6kg levnesmiddel kludioxid kulsyrer opfyldning",
    imageSingle: "kulsyre.opfylding-10kg.png",
    url: "/kulsyre/opfyldning-af-kulsyreflasker",
    urlDontShow: true,
    description: () => (
      <React.Fragment>
        <Paragraph>
          Opfyldning af privat 6-10 kg kulsyreflaske på fyldeanlæg.
        </Paragraph>
      </React.Fragment>
    ),
  },

  /* Andet */
  /* Fadøl */
  {
    varenr: 6010,
    name: "Brdr. Adelhardt Øko Squash",
    generalSearchPriority: 500,
    keywords:
      "brødrene adelhart adelhard appelsin sodavand flasker glasflasker 27,5cl 27,5 cl 24x27,5cl 24x27,5 papbakke økologiske",
    imageSingle: "produkter-oelvand.6010-flaske.png",
    imagePlural: "produkter-oelvand.6010-kasse.png",
  },
  {
    varenr: 6070,
    name: "Brdr. Adelhardt Øko Lemon",
    generalSearchPriority: 500,
    keywords:
      "brødrene adelhart adelhard sodavand flasker glasflasker 27,5cl 27,5 cl 24x27,5cl 24x27,5 papbakke økologisk økologiske",
    imageSingle: "produkter-oelvand.adelhardt-lemon-flaske.png",
    // imagePlural: 'produkter-oelvand.6070-kasse.png',
  },
  {
    varenr: 6040,
    name: "Brdr. Adelhardt Øko Hyldeblomst",
    generalSearchPriority: 500,
    keywords:
      "brødrene adelhart adelhard brødrene adelhart adelhard sodavand flasker glasflasker 27,5cl 27,5 cl 24x27,5cl 24x27,5 papbakke økologisk økologiske",
    imageSingle: "produkter-oelvand.6040-flaske.png",
    imagePlural: "produkter-oelvand.6040-kasse.png",
  },
  {
    varenr: 6030,
    name: "Brdr. Adelhardt Øko Rabarber",
    generalSearchPriority: 500,
    keywords:
      "brødrene adelhart adelhard sodavand flasker glasflasker 27,5cl 27,5 cl 24x27,5cl 24x27,5 papbakke økologisk økologiske",
    imageSingle: "produkter-oelvand.6030-flaske.png",
    imagePlural: "produkter-oelvand.6030-kasse.png",
  },
  {
    varenr: 6050,
    name: "Brdr. Adelhardt Øko Cola",
    generalSearchPriority: 500,
    keywords:
      "brødrene adelhart adelhard sodavand flasker glasflasker 27,5cl 27,5 cl 24x27,5cl 24x27,5 papbakke økologisk økologiske",
    imageSingle: "produkter-oelvand.6050-flaske.png",
    imagePlural: "produkter-oelvand.6050-kasse.png",
  },
  {
    varenr: 4053,
    name: "Ramlösa Dåse",
    generalSearchPriority: 500,
    keywords:
      "ramløse ramløsa ramlossa ramlöse ramlose ramlosa dansk vand apollinaris med brus i appollinaris apollonaris apolinaris appolinaris  danskvand aluminium alu dåser 33cl 33 cl 24x33cl 24x33 dåsesodavand dåse dåser sodavand",
    imageSingle: "produkter-oelvand.ramlosa-daase-33cl-sleek.png",
    imagePlural: "produkter-oelvand.4053-kasse.png",
  },
  {
    varenr: 4063,
    name: "Ramlösa Citrus Dåse brus",
    generalSearchPriority: 500,
    keywords:
      "ramløse ramløsa ramlossa ramlöse ramlose ramlosa dansk vand apollinaris med brus i appollinaris apollonaris apolinaris appolinaris  danskvand aluminium alu dåser 33cl 33 cl 24x33cl 24x33 dåsesodavand dåse dåser sodavand",
    imageSingle: "produkter-oelvand.ramlosa-citrus-33cl-daase-2021.png",
    imagePlural: "produkter-oelvand.ramlosa-citrus-33cl-daase-2021-ramme.png",
  },
  {
    varenr: 5113,
    name: "Fanta Orange Dåse",
    generalSearchPriority: 500,
    keywords:
      "appelsin sodavand aluminium alu dåser 33cl 33 cl 24x33cl 24x33 dåsesodavand dåse",
    imageSingle: "produkter-oelvand.5113-flaske.png",
    imagePlural: "produkter-oelvand.5113-kasse.png",
  },
  {
    varenr: 5052,
    name: "Ramlösa Natur brus",
    generalSearchPriority: 500,
    keywords:
      "ramløse ramløsa ramlossa ramlöse ramlose ramlosa dansk vand apollinaris med brus i appollinaris apollonaris apolinaris appolinaris  danskvand flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50",
    imageSingle: "produkter-oelvand.5052-flaske.png",
    imagePlural: "produkter-oelvand.5052-kasse.png",
  },
  {
    varenr: 5062,
    name: "Ramlösa Citrus brus",
    generalSearchPriority: 500,
    keywords:
      "ramløse ramløsa ramlossa ramlöse ramlose ramlosa dansk vand apollinaris med brus i appollinaris apollonaris apolinaris appolinaris  danskvand flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50",
    imageSingle: "produkter-oelvand.5062-flaske.png",
    imagePlural: "produkter-oelvand.5062-kasse.png",
  },
  {
    varenr: 5112,
    name: "Fanta Orange",
    generalSearchPriority: 500,
    keywords:
      "appelsin sodavand flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50",
    imageSingle: "produkter-oelvand.fanta-50cl-2024.png",
    imagePlural: "produkter-oelvand.fanta-50cl-2024-ramme.png",
  },
  {
    varenr: 5122,
    name: "Fanta Zero Orange",
    generalSearchPriority: 500,
    keywords:
      "sukkerfri sukker fri appelsin sodavand flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50",
    imageSingle: "produkter-oelvand.5122-flaske.png",
    imagePlural: "produkter-oelvand.5122-kasse.png",
  },
  {
    varenr: 5132,
    name: "Fanta Exotic",
    generalSearchPriority: 500,
    keywords:
      "sodavand flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50",
    imageSingle: "produkter-oelvand.5132-flaske.png",
    imagePlural: "produkter-oelvand.5132-kasse.png",
  },
  {
    varenr: 5051,
    name: "Ramlösa",
    generalSearchPriority: 450,
    keywords:
      "ramløse ramløsa ramlossa ramlöse ramlose ramlosa dansk vand apollinaris med brus i appollinaris apollonaris apolinaris appolinaris  danskvand flasker plastflasker plastikflasker 150cl 150 cl 8x150cl 8x150",
    imageSingle: "produkter-oelvand.5051-flaske.png",
    imagePlural: "produkter-oelvand.5051-kasse.png",
  },
  {
    varenr: 5061,
    name: "Ramlösa Citrus",
    generalSearchPriority: 450,
    keywords:
      "ramløse ramløsa ramlossa ramlöse ramlose ramlosa dansk vand apollinaris med brus i appollinaris apollonaris apolinaris appolinaris  danskvand flasker plastflasker plastikflasker 150cl 150 cl 8x150cl 8x150",
    imageSingle: "produkter-oelvand.5061-flaske.png",
    imagePlural: "produkter-oelvand.5061-kasse.png",
  },
  {
    varenr: 5111,
    name: "Fanta Orange",
    generalSearchPriority: 450,
    keywords:
      "appelsin sodavand flasker plastflasker plastikflasker 150cl 150 cl 8x150cl 8x150",
    imageSingle: "produkter-oelvand.5111-flaske.png",
    imagePlural: "produkter-oelvand.5111-kasse.png",
  },
  {
    varenr: 6550,
    name: "Orangina",
    generalSearchPriority: 500,
    keywords: "appelsin sodavand flasker glasflasker 25cl 25 cl 32x25cl 32x25",
    imageSingle: "produkter-oelvand.6550-flaske.png",
    imagePlural: "produkter-oelvand.6550-kasse.png",
  },
  {
    varenr: 5110,
    name: "Fanta Orange",
    generalSearchPriority: 500,
    keywords: "appelsin sodavand flasker glasflasker 25cl 25 cl 30x25cl 30x25",
    imageSingle: "produkter-oelvand.5110-flaske.png",
    imagePlural: "produkter-oelvand.5110-kasse.png",
  },
  {
    varenr: 5310,
    name: "Sprite",
    generalSearchPriority: 500,
    keywords: "sodavand flasker glasflasker 25cl 25 cl 30x25cl 30x25",
    imageSingle: "produkter-oelvand.5310-flaske.png",
    imagePlural: "produkter-oelvand.5310-kasse.png",
  },
  {
    varenr: 4250,
    name: "Passion",
    generalSearchPriority: 500,
    keywords: "sodavand flasker glasflasker 25cl 25 cl 24x25cl 24x25",
    imageSingle: "produkter-oelvand.thor-passion-flaske-25cl.png",
    imagePlural: "produkter-oelvand.1250-kasse.png",
  },
  {
    varenr: 4220,
    name: "Supermalt Ginger Beer",
    generalSearchPriority: 500,
    keywords:
      "sodavand flasker glasflasker 33cl 33 cl 24x33cl 24x33 gingerbeer",
    imageSingle: "produkter-oelvand.supermalt-ginger-beer.png",
    imagePlural: "produkter-oelvand.supermalt-ginger-beer-ramme.png",
  },
  {
    varenr: 5000,
    name: "Blandet Vand",
    generalSearchPriority: 500,
    keywords: "sodavand flasker glasflasker 25cl 25 cl 30x25cl 30x25",
    imagePlural: "produkter-oelvand.5000-kasse.png",
    usePluralImage: true,
    popupDescription: () => (
      <React.Fragment>
        <Paragraph>
          Vi laver en blandet kasse med flere forskellige slags.
        </Paragraph>
        <Paragraph>
          Hvis I ønsker selv at blande en kasse, kan dette gøres ved at trykke
          på &quot;Bland selv&quot; og vælge antal, for de varer I ønsker at
          blande.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 9122,
    name: "Kildevæld uden brus",
    searchSignificance: 19,
    generalSearchPriority: 500,
    keywords:
      "carlsberg kildevand kildvæld killevæld kildevald flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50 naturligt mineralvand",
    imageSingle: "produkter-oelvand.9122-flaske.png",
    imagePlural: "produkter-oelvand.9122-kasse.png",
  },
  {
    varenr: 4032,
    name: "Egekilde brus",
    generalSearchPriority: 500,
    searchSignificance: 15,
    keywords:
      "kildevand flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50",
    imageSingle: "produkter-oelvand.egekilde-50cl-uden-brus.png",
    imagePlural: "produkter-oelvand.4032-kasse.png",
  },
  {
    varenr: 9142,
    name: "Kildevæld Citron/Hyld u. brus",
    generalSearchPriority: 500,
    keywords:
      "carlsberg kildevand kildvæld killevæld kildevald flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50",
    imageSingle: "produkter-oelvand.9142-flaske.png",
    imagePlural: "produkter-oelvand.kildevaeld-citron-hyldeblomst-ramme.png",
  },
  {
    varenr: 9152,
    name: "Kildevæld Hind/Brom u. brus",
    generalSearchPriority: 500,
    keywords:
      "carlsberg kildevand kildvæld killevæld kildevald flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50",
    smallDescription: () => (
      <React.Fragment>
        <Span block grey fontSize="13px" margin="10px 0">
          1 uges leveringstid
        </Span>
      </React.Fragment>
    ),
    description: () => (
      <Paragraph grey fontSize="13px">
        1 uges leveringstid
      </Paragraph>
    ),
    imageSingle: "produkter-oelvand.9152-flaske.png",
    imagePlural: "produkter-oelvand.9152-kasse.png",
  },
  {
    varenr: 4102,
    name: "Egekilde Hylde/Lime brus",
    generalSearchPriority: 500,
    imageSingle: "produkter-oelvand.egekilde-hylde-lime-brus.png",
    imagePlural: "produkter-oelvand.egekilde-hyldeblomst-lime-ramme.png",
    keywords:
      "kildevand flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50",
  },
  {
    varenr: 4042,
    name: "Egekilde Citrus brus",
    generalSearchPriority: 500,
    keywords:
      "kildevand flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50",
    imageSingle: "produkter-oelvand.4042-flaske.png",
    imagePlural: "produkter-oelvand.4042-kasse.png",
  },
  {
    varenr: 4072,
    name: "Egekilde Hindbær brus",
    generalSearchPriority: 500,
    keywords:
      "kildevand flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50 hindbærbrus",
    imageSingle: "produkter-oelvand.4072-flaske.png",
    imagePlural: "produkter-oelvand.4072-kasse.png",
  },
  {
    varenr: 4082,
    name: "Egekilde Blåbær brus",
    generalSearchPriority: 500,
    keywords:
      "kildevand flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50",
    imageSingle: "produkter-oelvand.4082-flaske.png",
    imagePlural: "produkter-oelvand.4082-kasse.png",
  },
  {
    varenr: 8331,
    name: "Jacobsen Christmas Ale",
    generalSearchPriority: 500,
    keywords:
      "øl flasker glasflasker 75cl 75 cl 6x75cl 6x75 papkasser jakobsen",
    imageSingle: "produkter-oelvand.jacobsen-christmas-ale-75cl.png",
    imagePlural: "produkter-oelvand.jacobsen-christmas-ale-75cl-ramme.png",
    inStockText: () => (
      <React.Fragment>
        Udsolgt. Kommer igen <Datoer type="julebrygNextYear" />
      </React.Fragment>
    ),
    smallDescription: () => (
      <React.Fragment>
        <Span blue block fontSize="13px" paddingTop="5px">
          Kan leveres fra <Datoer type="julebryg" />
        </Span>
      </React.Fragment>
    ),
    description: () => (
      <Paragraph blue fontSize="13px">
        Kan leveres fra <Datoer type="julebryg" />
      </Paragraph>
    ),
  },
  {
    varenr: 8941,
    name: "Blandingsgas 8 liter 30%/70%",
    showOnlyPluralPrice: true,
    keywords:
      "co2 kuldioxid kullsyre kuldsyre kulsyreflasker kulsyreflaske kolsyra trykflaske tryk flaske un1013",
    imageSingle: "kulsyre.blandingsgas-8liter.png",
    productTypeReservedel: true,
  },
  {
    varenr: 8945,
    name: "Kulsyrebatteri 450kg",
    showOnlyPluralPrice: true,
    keywords:
      "co2 kuldioxid kullsyre kuldsyre kulsyreflasker kulsyreflaske kolsyra trykflaske tryk flaske un1013 stigrør",
    imageSingle: "kulsyre.kulsyre-batteri-450kg.png",
    productTypeReservedel: true,
  },
  {
    varenr: 772,
    name: "Teknikker pr. time",
    imageSingle: "reservedele.teknikker.png",
    productTypeReservedel: true,
    postnord: true,
  },
  {
    varenr: 790,
    name: "Gavekort",
    smallDescription: () => (
      <React.Fragment>
        <Paragraph>
          Gavekort pr. <Price varenr="790" /> Lægger du flere i kurven bliver
          beløbet samlet på ét gavekort.
        </Paragraph>
        <Paragraph>
          Ønsker du flere gavekort så læg det totale beløb i kurven og skriv
          hvor mange gavekort vi skal lave.
        </Paragraph>
      </React.Fragment>
    ),
    description: () => (
      <React.Fragment>
        <Paragraph>
          Gavekort pr. <Price varenr="790" /> Lægger du flere i kurven bliver
          beløbet samlet på ét gavekort.
        </Paragraph>
        <Paragraph>
          Ønsker du flere gavekort så læg det totale beløb i kurven og skriv
          hvor mange gavekort vi skal lave.
        </Paragraph>
      </React.Fragment>
    ),
    imageSingle: "style.gavekort.jpg",
    productTypeReservedel: true,
    postnord: true,
  },
  {
    varenr: 611,
    name: "Leje flaskekøleskab",
    keywords: "køleskab",
    invoiceDisplay: "SimpleItemRow",
    searchDisplay: BigView,
    description: () => (
      <React.Fragment>
        <Paragraph>
          Køleskabet leveres med fire udtagelige hylder og kan indeholde op til
          240 x 50cl flasker (5x48 flasker).
        </Paragraph>
        <Paragraph>
          Køleskabet er{" "}
          <Span bold underline>
            udelukkende
          </Span>{" "}
          beregnet til praktisk køling af flasker og dåser og er ikke til pynt
          eller display. Køleskabet har ikke lys og bærer præg af slid og brug.
          Køleevnen fungerer optimalt.
        </Paragraph>
      </React.Fragment>
    ),
    IconBoxes: () => (
      <React.Fragment>
        <IconBox calendar>
          <Lejeperiode days />
          <Lejeperiode />
        </IconBox>
        <IconBox dimension>
          Mål og vægt
          <Span block fontSize="12px">
            Vægt: 89 kg - Mål: B 59cm | H 198,5cm | D 59,5cm - Håndtag 6cm dybt
          </Span>
        </IconBox>
        <IconBox power>Max 300W | &#8773;5 kWh / døgn</IconBox>
      </React.Fragment>
    ),
    packImages: [
      {
        name: "packs.aquador-koeleskab1.gif",
        imageDescription: () => "Køleskab",
      },
    ],
    imageSingle: "packs.aquador-koeleskab1.gif",
    imageMaxHeight: "300px",
    productTypePack: true,

    recieptItemView: RecieptPackEntryView,
  },
  {
    varenr: 3934,
    name: "Leje af Modular 20 Tryktank",
    invoiceDisplay: "SimpleItemRow",
    description: () => (
      <React.Fragment>
        <Paragraph>
          Tryktanken kræves til Modular 20 fustager. Medfølger gratis når du
          lejer anlæg og køber modular 20 fustager.
        </Paragraph>
      </React.Fragment>
    ),
    packImages: [
      {
        name: "produkter-fadoel.modular-tryktank.png",
        imageDescription: () => "Modular 20 Tryktank",
      },
    ],
    imageSingle: "produkter-fadoel.modular-tryktank.png",
    imageMaxHeight: "300px",
    dontIndex: true,
    productTypePack: true,

    recieptItemView: RecieptPackEntryView,
  },
  {
    varenr: 3935,
    name: "Leje af Modular 20 Tryktank",
    invoiceDisplay: "SimpleItemRow",
    description: () => (
      <React.Fragment>
        <Paragraph>
          Tryktanken kræves til Modular 20 fustager. Medfølger lejet anlæg.
        </Paragraph>
      </React.Fragment>
    ),
    packImages: [
      {
        name: "produkter-fadoel.modular-tryktank.png",
        imageDescription: () => "Modular 20 Tryktank",
      },
    ],
    imageSingle: "produkter-fadoel.modular-tryktank.png",
    imageMaxHeight: "300px",
    dontIndex: true,
    productTypePack: true,

    recieptItemView: RecieptPackEntryView,
  },
  {
    varenr: 3754,
    name: "Royal Julekit",
    // imageSingle: 'produkter-fadoel.royal-unibrew-glas-40cl.png',
    royalUnibrewJulekittet: true,
    onlyRemove: true,
    dontIndex: true,
  },
  {
    varenr: 4779,
    name: "Royal X-Mas",
    imageSingle: "produkter-oelvand.royal-xmas-daase-33cl.png",
    // imagePlural: 'produkter-oelvand.royal-oekologisk-ufiltreret-lager-ramme.png',
    onlyRemove: true,
    dontIndex: true,
  },
  {
    varenr: 4953,
    name: "Royal Blanche",
    imageSingle: "produkter-oelvand.royal-blanch-daase.png",
    // imagePlural: 'produkter-oelvand.royal-oekologisk-ufiltreret-lager-ramme.png',
    onlyRemove: true,
    dontIndex: true,
  },
  {
    varenr: 13064,
    name: "12 x Heineken Glas",
    imageSingle: "produkter-fadoel.heineken.png",
    // imagePlural: 'produkter-oelvand.royal-oekologisk-ufiltreret-lager-ramme.png',
    onlyRemove: true,
    dontIndex: true,
  },
  /*
  {
    varenr: 10000,
    name: 'Herslev Bryghus Smagskasse',
    smallDescription: () => 'Smagskassen indeholder Herslev Øko Juleøl, Herslev Øko Stjernebryg og Herslev Øko Snefri (0,5%).',
    imageSingle: 'produkter-fadoel.herslev-bryghus-gaveaeske.png',
  },
  */
  {
    varenr: 10009,
    name: "Herslev Bryghus Smagskasse",
    imageSingle: "produkter-fadoel.herslev-bryghus-gaveaeske.png",
    onlyRemove: true,
    dontIndex: true,
  },
  {
    varenr: 888,
    name: "EUR-palle",
    imageSingle: "produkter-oelvand.eur-palle.png",
    cannotBeManuallyBought: true,
    simpleItem: true,
  },
  {
    varenr: 725,
    name: "Rabat Bobli 3 for 2",
    cannotBeManuallyBought: true,
    simpleItem: true,
  },
  {
    varenr: 706,
    name: "Kulsyre(6kg) mængderabat",
    cannotBeManuallyBought: true,
    simpleItem: true,
  },
  {
    varenr: 705,
    name: "Kulsyre(4kg) mængderabat",
    cannotBeManuallyBought: true,
    simpleItem: true,
  },
  {
    varenr: 712,
    name: "Kulsyre(6kg) mængderabat",
    cannotBeManuallyBought: true,
    simpleItem: true,
  },
  {
    varenr: 711,
    name: "Kulsyre(4kg) mængderabat",
    cannotBeManuallyBought: true,
    simpleItem: true,
  },
  {
    varenr: 733,
    name: "Kulsyre(6kg) mængderabat",
    cannotBeManuallyBought: true,
    simpleItem: true,
  },
  {
    varenr: 732,
    name: "Kulsyre(4kg) mængderabat",
    cannotBeManuallyBought: true,
    simpleItem: true,
  },
  {
    varenr: 603,
    name: "Tillæg: Leje af anlæg pr. hane, uden øl",
    cannotBeManuallyBought: true,
    simpleItem: true,
  },
  {
    varenr: 605,
    name: "Tillæg: Leje af vogn, uden køb af øl",
    cannotBeManuallyBought: true,
    simpleItem: true,
  },
  {
    varenr: 663,
    name: "Tillæg: Levering af farligt gods",
    cannotBeManuallyBought: true,
    simpleItem: true,
    postnord: true,
  },
  /*
  Returpant: Kasser uden flasker
  */
  {
    varenr: 800,
    name: "Returpant plastikkasse uden flasker",
    keywords: "pant",
    smallDescription: () => "Beregnet til de fleste glasflasker i 33- og 25cl",
    returPant: true,
    packImages: [
      { name: "returpant.cola-kasse.png" },
      { name: "returpant.frem-kasse.png" },
      { name: "returpant.carlsberg-kasse.png" },
      { name: "returpant.tuborg-kasse.png" },
      { name: "returpant.ramlose-kasse.png" },
    ],
    invoiceDisplay: "ReturPantItemRow",
    recieptItemView: ReturPantRecieptEntryView,
    imageSingle: "returpant.cola-kasse.png",
  },
  {
    varenr: 801,
    name: "Returpant plastikkasse uden flasker",
    keywords: "pant",
    smallDescription: () => "Beregnet til 25cl Søbogaard glasflasker",
    returPant: true,
    packImages: [{ name: "returpant.soebogaard-kasse.png" }],
    invoiceDisplay: "ReturPantItemRow",
    recieptItemView: ReturPantRecieptEntryView,
    imageSingle: "returpant.soebogaard-kasse.png",
  },
  {
    varenr: 805,
    name: "Returpant gul plastikkasse uden flasker",
    keywords: "pant",
    smallDescription: () => "Beregnet til Pellegrino glasflasker",
    returPant: true,
    packImages: [{ name: "returpant.pellegrino-kasse.png" }],
    invoiceDisplay: "ReturPantItemRow",
    recieptItemView: ReturPantRecieptEntryView,
    imageSingle: "returpant.pellegrino-kasse.png",
  },
  /*
  Returpant: Kasser med flasker
  */
  {
    varenr: 813,
    name: "Returpant sanp. 24x25 gul",
    keywords: "pant",
    smallDescription: () =>
      "Plastikkasse med 24 San Pellegrino 25cl glasflasker",
    returPant: true,
    invoiceDisplay: "ReturPantItemRow",
    packImages: [{ name: "returpant.pellegrino-kasse-med-flasker.png" }],
    imageSingle: "returpant.pellegrino-kasse-med-flasker.png",
    recieptItemView: ReturPantRecieptEntryView,
  },
  {
    varenr: 820,
    name: "Returpant sanp. 20x50 gul",
    keywords: "pant",
    smallDescription: () =>
      "Plastikkasse med 20 San Pellegrino 50cl glasflasker",
    returPant: true,
    invoiceDisplay: "ReturPantItemRow",
    recieptItemView: ReturPantRecieptEntryView,
    packImages: [{ name: "returpant.sanpellegrino-20x50-tom.png" }],
    imageSingle: "returpant.sanpellegrino-20x50-tom.png",
  },
  {
    varenr: 812,
    name: "Returpant sanp. 12x100 gul",
    keywords: "pant",
    smallDescription: () =>
      "Plastikkasse med 12 San Pellegrino 100cl glasflasker",
    returPant: true,
    invoiceDisplay: "ReturPantItemRow",
    recieptItemView: ReturPantRecieptEntryView,
    packImages: [{ name: "returpant.sanpellegrino-12x100-tom.png" }],
    imageSingle: "returpant.sanpellegrino-12x100-tom.png",
  },
  {
    varenr: 819,
    name: "Returpant 20x33 Loka",
    keywords: "pant",
    smallDescription: () => "Plastikkasse med 20 Loka 33cl glasflasker",
    returPant: true,
    invoiceDisplay: "ReturPantItemRow",
    packImages: [{ name: "returpant.ramlose-kasse-med-flasker.png" }],
    recieptItemView: ReturPantRecieptEntryView,
    imageSingle: "returpant.ramlose-kasse-med-flasker.png",
  },
  {
    varenr: 829,
    name: "Returpant 30x25 Søbogaard",
    keywords: "pant",
    smallDescription: () => "Plastikkasse med 30 Søbogaard 25cl glasflasker",
    returPant: true,
    invoiceDisplay: "ReturPantItemRow",
    packImages: [{ name: "returpant.soebogaard-kasse-med-flasker.png" }],
    recieptItemView: ReturPantRecieptEntryView,
    imageSingle: "returpant.soebogaard-kasse-med-flasker.png",
  },
  {
    varenr: 824,
    name: "Returpant 24x25/33",
    keywords: "pant",
    smallDescription: () => "Plastikkasse med 24 25cl eller 33cl glasflasker",
    returPant: true,
    invoiceDisplay: "ReturPantItemRow",
    packImages: [{ name: "returpant.frem-med-flasker.png" }],
    recieptItemView: ReturPantRecieptEntryView,
    imageSingle: "returpant.frem-med-flasker.png",
  },
  {
    varenr: 830,
    name: "Returpant 30x30/33 Glasflasker",
    keywords: "pant",
    smallDescription: () => "Plastikkasse med 30 glasflasker",
    returPant: true,
    invoiceDisplay: "ReturPantItemRow",
    packImages: [
      { name: "returpant.cola-med-flasker.png" },
      { name: "returpant.carlsberg-med-flasker.png" },
      { name: "returpant.tuborg-med-flasker.png" },
    ],
    recieptItemView: ReturPantRecieptEntryView,
    imageSingle: "returpant.cola-med-flasker.png",
  },
  /*
  Returpant: Fustager og kulsyre
  */
  {
    varenr: 875,
    name: "Returpant fustage 200",
    keywords: "pant på fustager",
    smallDescription: () =>
      "Carlsberg | Tuborg | Royal | Albani | Hancock | Thor | Jacobsen | Ale no. 16 | Heineken | Newcastle | Edelweiss | Kilkenny | Guinness | Depot Pilsner | Grimbergen | Kronenbourg | Brooklyn | Nørrebro",
    returPant: true,
    packImages: [
      { name: "produkter-fadoel.carlsberg-fustage.png" },
      { name: "produkter-fadoel.royal-fustage.png" },
      { name: "produkter-fadoel.albani-fustage.png" },
      { name: "produkter-fadoel.hancock-fustage.png" },
      { name: "produkter-fadoel.vestfyen-fustage1.png" },
      { name: "produkter-fadoel.heineken-fustage.png" },
      { name: "produkter-fadoel.newcastle-fustage.png" },
      { name: "produkter-fadoel.edelweiss-fustage.png" },
      { name: "produkter-fadoel.guinness-fustage.png" },
      { name: "produkter-fadoel.grimbergen-fustage.png" },
      { name: "produkter-fadoel.kronenbourg-fustage.png" },
      { name: "produkter-fadoel.hancock-fustage.png" },
      { name: "produkter-fadoel.royal-uni-20l-2.png" },
    ],
    invoiceDisplay: "ReturPantItemRow",
    recieptItemView: ReturPantRecieptEntryView,
    imageSingle: "produkter-fadoel.carlsberg-fustage.png",
  },
  {
    varenr: 876,
    name: "Returpant fustage 250",
    keywords: "pant på fustager",
    smallDescription: () => "Platan | Skovlyst",
    returPant: true,
    packImages: [
      { name: "produkter-fadoel.platan-fustage.png" },
      { name: "produkter-fadoel.skovlyst-fustage.png" },
    ],
    invoiceDisplay: "ReturPantItemRow",
    recieptItemView: ReturPantRecieptEntryView,
    imageSingle: "produkter-fadoel.platan-fustage.png",
  },
  {
    varenr: 877,
    name: "Returpant fustage 300",
    keywords: "pant på fustager",
    smallDescription: () =>
      "Paulaner | Hoegaarden | Staropramen | Fransizkaner | Leffe",
    returPant: true,
    packImages: [{ name: "produkter-fadoel.leffe-blonde-fustage.png" }],
    invoiceDisplay: "ReturPantItemRow",
    recieptItemView: ReturPantRecieptEntryView,
    imageSingle: "produkter-fadoel.leffe-blonde-fustage.png",
  },
  {
    varenr: 878,
    name: "Returpant fustage 400",
    keywords: "pant på fustager",
    smallDescription: () => "Svaneke Bryghus",
    returPant: true,
    packImages: [{ name: "produkter-fadoel.svaneke-fustage.png" }],
    invoiceDisplay: "ReturPantItemRow",
    recieptItemView: ReturPantRecieptEntryView,
    imageSingle: "produkter-fadoel.svaneke-fustage.png",
  },
  {
    varenr: 879,
    name: "Returpant fustage 500",
    keywords: "pant på fustager",
    smallDescription: () => "Herslev Bryghus | Skands |TeeDawn | Ørbæk Bryghus",
    returPant: true,
    packImages: [
      { name: "produkter-fadoel.herslev-fustage1.png" },
      { name: "produkter-fadoel.noerrebro-fustage.png" },
    ],
    invoiceDisplay: "ReturPantItemRow",
    recieptItemView: ReturPantRecieptEntryView,
    imageSingle: "produkter-fadoel.herslev-fustage1.png",
  },
  {
    varenr: 870,
    name: "Returpant kulsyreflasker",
    keywords: "pant",
    smallDescription: () =>
      "Lyse- og mørkegrønne kulsyreflasker 4-, 6- og 10kg",
    returPant: true,
    packImages: [
      { name: "kulsyre.kulsyre-4kg-v2.png" },
      { name: "kulsyre.kulsyre-6kg.png" },
      { name: "returpant.kulsyre-carlsberg.png" },
    ],
    invoiceDisplay: "ReturPantItemRow",
    recieptItemView: ReturPantRecieptEntryView,
    imageSingle: "kulsyre.kulsyre-4kg-v2.png",
  },
  {
    varenr: 872,
    name: "Returpant kulsyreflaske rødt ventilhåndtag",
    keywords: "pant",
    smallDescription: () =>
      "Lysegrønne kulsyreflasker 4- og 6kg med rødt ventilhåndtag",
    returPant: true,
    packImages: [
      { name: "kulsyre.4-kg-kulsyreflaske-roed-properSize.png" },
      { name: "kulsyre.6-kg-kulsyreflaske-roed.png" },
    ],
    invoiceDisplay: "ReturPantItemRow",
    recieptItemView: ReturPantRecieptEntryView,
    imageSingle: "kulsyre.6-kg-kulsyreflaske-roed.png",
  },
  /*
  Returpant: Poser og paller
  */
  {
    varenr: 864,
    name: "Returpant pose med engangsglas",
    keywords: "pant",
    smallDescription: () =>
      "Pose med engangsglas til Dansk Retursystem, som optæller og afregner",
    packageNounExt: "ke",
    returPant: true,
    packImages: [{ name: "retursystem.retursystem-posemfyld.png" }],
    invoiceDisplay: "ReturPantItemRow",
    recieptItemView: ReturPantRecieptEntryView,
    imageSingle: "retursystem.retursystem-posemfyld.png",
  },
  {
    varenr: 865,
    name: "Returpant pose med plast/dåser",
    keywords: "pant",
    smallDescription: () =>
      "Pose plast/dåser til Dansk Retursystem, som optæller og afregner",
    packageNounExt: "ke",
    returPant: true,
    packImages: [{ name: "retursystem.retursystem-posemfyld.png" }],
    invoiceDisplay: "ReturPantItemRow",
    recieptItemView: ReturPantRecieptEntryView,
    imageSingle: "retursystem.retursystem-posemfyld.png",
  },
  {
    varenr: 887,
    name: "Returpant Europalle retur",
    keywords: "palle pant",
    smallDescription: () => "Returnering af EUR-palle",
    returPant: true,
    packImages: [
      { name: "produkter-oelvand.eur-palle.png" },
      { name: "returpant.halv-palle.png" },
      { name: "returpant.palleramme.png" },
    ],
    invoiceDisplay: "ReturPantItemRow",
    recieptItemView: ReturPantRecieptEntryView,
    imageSingle: "produkter-oelvand.eur-palle.png",
  },
  /* Corona Covid-19 tilbud */
  {
    varenr: 54313,
    name: "Faxe Kondi Dåse",
    imageSingle: "produkter-oelvand.faxe-kondi-daase-33cl-3.png",
    imagePlural: "produkter-oelvand.faxe-kondi-daase-33cl-ramme.png",
    dontIndex: true,
    usePluralImage: true,
  },
  {
    varenr: 54333,
    name: "Faxe Kondi 0 kal Dåse",
    imageSingle: "produkter-oelvand.faxe-kondi-0kal-daase-33cl-2.png",
    imagePlural: "produkter-oelvand.faxe-kondi-0kal-daase-33cl-ramme.png",
    dontIndex: true,
    usePluralImage: true,
  },
  {
    varenr: 54353,
    name: "Pepsi Dåse",
    keywords: "pepsi cola pepsie pesie",
    imageSingle: "produkter-oelvand.pepsi-33cl-daase.png",
    imagePlural: "produkter-oelvand.pepsi-33cl-daase-ramme.png",
    dontIndex: true,
    usePluralImage: true,
  },
  {
    varenr: 54363,
    name: "Pepsi Max Dåse",
    keywords: "pepsi cola pepsie pesie pepsimax",
    imageSingle: "produkter-oelvand.pepsi-max-daase-33cl.png",
    imagePlural: "produkter-oelvand.pepsi-max-daase-33cl-ramme.png",
    dontIndex: true,
    usePluralImage: true,
  },
  {
    varenr: 54043,
    name: "Egekilde Citrus Dåse",
    imageSingle: "produkter-oelvand.egekilde-citrus-33cl-daase-1.png",
    imagePlural: "produkter-oelvand.egekilde-citrus-33cl-daase-ramme-1.png",
    dontIndex: true,
    usePluralImage: true,
  },
  {
    varenr: 54113,
    name: "Nikoline Dåse",
    imageSingle: "produkter-oelvand.nikoline-appelsin-33cl-daase.png",
    imagePlural: "produkter-oelvand.nikoline-appelsin-33cl-daase-ramme.png",
    dontIndex: true,
    usePluralImage: true,
  },
  {
    varenr: 54953,
    name: "Slots Classic Dåse",
    imageSingle: "produkter-oelvand.slots-classic-33cl-daase.png",
    imagePlural: "produkter-oelvand.slots-classic-33cl-daase-ramme.png",
    alkohol: true,
    dontIndex: true,
    usePluralImage: true,
  },
  {
    varenr: 54653,
    name: "Royal Classic Dåse",
    imageSingle: "produkter-oelvand.royal-classic-33cl-daase.png",
    imagePlural: "produkter-oelvand.royal-classic-33cl-daase-ramme.png",
    alkohol: true,
    dontIndex: true,
    usePluralImage: true,
  },
  {
    varenr: 54813,
    name: "Odense Pilsner Dåse",
    imageSingle: "produkter-oelvand.odense-pilsner-33cl-daase.png",
    imagePlural: "produkter-oelvand.odense-pilsner-33cl-daase-ramme.png",
    alkohol: true,
    dontIndex: true,
    usePluralImage: true,
  },
  {
    varenr: 54613,
    name: "Royal Pilsner Dåse",
    imageSingle: "produkter-oelvand.royal-pilsner-33cl-daase.png",
    imagePlural: "produkter-oelvand.royal-pilsner-33cl-daase-ramme.png",
    alkohol: true,
    dontIndex: true,
    usePluralImage: true,
  },
  {
    varenr: 54633,
    name: "Royal Øko Pilsner Dåse",
    keywords: "økologisk",
    imageSingle: "produkter-oelvand.royal-oeko-pilsner-33cl-daase.png",
    imagePlural: "produkter-oelvand.royal-oeko-pilsner-33cl-daase-ramme1.png",
    alkohol: true,
    dontIndex: true,
    usePluralImage: true,
  },
  {
    varenr: 54643,
    name: "Royal Øko Classic",
    keywords: "økologisk",
    imageSingle: "produkter-oelvand.royal-oeko-classic-33cl-daase.png",
    imagePlural: "produkter-oelvand.royal-oeko-classic-33cl-daase-ramme1.png",
    alkohol: true,
    dontIndex: true,
    usePluralImage: true,
  },
  {
    varenr: 54673,
    name: "Royal Export",
    imageSingle: "produkter-oelvand.royal-export-33cl-daase.png",
    imagePlural: "produkter-oelvand.royal-export-33cl-daase-ramme.png",
    alkohol: true,
    dontIndex: true,
    usePluralImage: true,
  },
  {
    varenr: 54713,
    name: "Ceres Top Dåse",
    imageSingle: "produkter-oelvand.ceres-top-33cl-daase.png",
    imagePlural: "produkter-oelvand.ceres-top-33cl-daase-ramme1.png",
    alkohol: true,
    dontIndex: true,
    usePluralImage: true,
  },
  {
    varenr: 123456,
    name: "Pakketilbud",
    invoiceDisplay: "SimpleItemRow",
    description: () => (
      <React.Fragment>
        <Paragraph fontSize="12px" marginTop="-10px">
          <KulsyreMedfoelger />
        </Paragraph>
        <HideAtDate date="25/8/2019 6:00">
          <Paragraph fontSize="18px" red>
            Fadølsanlæg udsolgt fredag 23/8 og lørdag 24/8 2019.
          </Paragraph>
        </HideAtDate>
        <List check fontSize="14px">
          <li>
            <TooltipModalLink title="Fadølshane">
              <Row>
                <Col xs={12} paddingBottom="0px">
                  <Heading4>Fadølshane</Heading4>
                </Col>
                <ScreenSizeDetector
                  paddingBottom={[[767, "0px"], "15px"]}
                  paddingTop={[[767, "0px"], "15px"]}
                >
                  <Col sm={12} md={6}>
                    <Paragraph>
                      Bestil så mange haner du ønsker. Lejeprisen er{" "}
                      <u>pr. hane</u> - ikke pr. anlæg.
                    </Paragraph>
                    <Paragraph>
                      Alternativt til flere enkelthaner er nedenstående
                      dobbeltanlæg. Bemærk dog at et dobbeltanlæg kun køler
                      ligeså godt som et enkeltanlæg. Du skal altså ikke vælge
                      et dobbeltanlæg for at kunne tappe øllet hurtigere.
                      Dobbeltanlæg anbefales til højest 4 - 6 fustager, afhængig
                      af arrangementets længde.
                    </Paragraph>
                  </Col>
                </ScreenSizeDetector>
                <ScreenSizeDetector paddingTop={[[767, "0px"], "15px"]}>
                  <Col sm={12} md={6}>
                    <Paragraph>
                      Yderligere fordele ved 2 enkeltanlæg fremfor 1
                      dobbeltanlæg er sikkerheden hvis der skulle opstå et
                      teknisk problem med køleren. Opstår et problem på et
                      enkeltanlæg påvirker det ikke den anden hane. Sker det på
                      et dobbeltanlæg er begge haner ude af funktion.
                    </Paragraph>
                    <Paragraph>
                      Eneste ulempe ved 2 enkeltanlæg overfor et dobbeltanlæg er
                      at 2 enkelte anlæg fylder og vejer mere.
                    </Paragraph>
                  </Col>
                </ScreenSizeDetector>
              </Row>
            </TooltipModalLink>
          </li>
          <li>
            <TooltipModalLink title="Køleanlæg og kulsyre">
              <Row>
                <Col xs={12} paddingBottom="0px">
                  <Heading4>Køleanlæg og kulsyre</Heading4>
                </Col>
                <ScreenSizeDetector
                  paddingBottom={[[767, "0px"], "15px"]}
                  paddingTop={[[767, "0px"], "15px"]}
                >
                  <Col sm={12} md={6}>
                    <Paragraph>
                      Der er mulighed for tre forskellige størrelser køleanlæg.
                    </Paragraph>
                    <Paragraph>
                      Vi medleverer automatisk det køleanlæg, der bedst passer
                      til det antal fustager du bestiller.
                    </Paragraph>
                    <Paragraph>
                      Fadølsanlægget har isbank og køler øllet ved
                      gennemstrømning umiddelbart før det tappes.
                    </Paragraph>
                  </Col>
                </ScreenSizeDetector>
                <ScreenSizeDetector paddingTop={[[767, "0px"], "15px"]}>
                  <Col sm={12} md={6}>
                    <Paragraph>
                      Skal tilsluttes almindelig 230v strøm mindst 3 timer før
                      brug.
                    </Paragraph>
                    <Paragraph>
                      Skal øllet tappes meget hurtgt, så giv os besked i
                      kommentarfeltet eller når vi ringer, så vi kan finde den
                      bedste løsning til dig.
                    </Paragraph>
                    <Paragraph>
                      Vi medleverer naturligvis kulsyre nok til de bestilte
                      fustager.
                    </Paragraph>
                  </Col>
                </ScreenSizeDetector>
              </Row>
            </TooltipModalLink>
          </li>
          <li>
            <MonteringPaaEgetBordModal />
          </li>
        </List>
      </React.Fragment>
    ),
    IconBoxes: () => (
      <React.Fragment>
        <IconBox storage>
          Altid ledige anlæg
          <Span block fontSize="12px">
            <Sikrest10DageFoerText />
          </Span>
        </IconBox>
        <IconBox calendar>
          <Lejeperiode days />
          <Lejeperiode />
        </IconBox>
        <IconBox dimension>
          Mål &amp; vægt
          <Span block fontSize="12px">
            Anlæg: 45 kg - Mål: B 60cm | H 40cm | D 40cm
          </Span>
        </IconBox>
      </React.Fragment>
    ),
    betingerFustager: true,
    packageNoun: "hane",
    packImages: [
      {
        name: "packs.basis-5.png",
        imageDescription: () =>
          "Eksempel på fadølsanlæg bestående af hane, køleanlæg og kulsyre. Fadølshanen skal monteres på dit eget bord.",
        imageMaxHeight: "246px",
      },
      {
        name: "packs.bordkantsojle-med-maal.png",
        imageDescription: () =>
          "Fadølshanen skal monteres på egen bordplade på op til 60mm i tykkelse. Hanen går 35mm ind over bordet og skruetvingen er 125mm lang.",
      },
    ],
    imageSingle: "packs.basis-5.png",
    productTypePack: true,

    recieptItemView: RecieptPackEntryView,
    draughtmasterStkWithPackageNoun: true,
  },
  /*
  {
    varenr: 52113,
    name: "Jolly Appelsin Light Dåse",
    keywords: "sodavand haribo harboe dåser 33cl 33 cl 24x33cl 24x33",
    imageSingle: "produkter-oelvand.jolly-appelsin-light-daase.png",
    imagePlural: "produkter-oelvand.jolly-appelsin-ramme.png",
  },
  */
  {
    varenr: 9889,
    name: "PL Arpe Kjeldsholm uden brus",
    dontIndex: true,
  },
  {
    varenr: 9890,
    name: "PL Arpe Kjeldsholm med brus",
    dontIndex: true,
  },
  {
    varenr: 9894,
    name: "PL Arpe kjeldshom med brus og citrus",
    dontIndex: true,
  },
];
